export function LineStyleIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="currentcolor" {...props}>
      <path d="M0.75 2V3H11.25V2H0.75Z" />
      <circle cx="1.5" cy="8.5" r="0.75" />
      <circle cx="4.5" cy="8.5" r="0.75" />
      <circle cx="7.5" cy="8.5" r="0.75" />
      <circle cx="10.5" cy="8.5" r="0.75" />
      <rect x="0.75" y="5" width="2.5" height="1" />
      <rect x="4.75" y="5" width="2.5" height="1" />
      <rect x="8.75" y="5" width="2.5" height="1" />
    </svg>
  );
}
