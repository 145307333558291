import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { Vec2, Vec2Utils } from '@paper/models/src/math/vec2';
import { Rect, RectUtils } from '@paper/models/src/math/rect';
import { ulid } from 'ulid';
import { action, runInAction } from 'mobx';
import { TreeNode } from '../tree/TreeNode';
import { Tool } from '../toolbar/ToolState';
import { makeResizeRect } from '../move-tool/make-resize-rect';
import { assert } from '@paper/models/src/assert';
import { CLICK_VS_DRAG_THRESHOLD_PX, DOUBLE_CLICK_TIME_MS } from '../move-tool/move-tool';
import { BuiltInComponentMap, ComponentMeta } from '../built-in-ui/built-in-ui';
import { resizeCursorMap, ResizeHandlePosition } from '../selection/resize-handles-logic';
import { createTreeNodeData } from '@paper/models/src/file/tree-node-schema';

type Props = {
  overlayEl: HTMLCanvasElement;
};

export const DrawTool = observer(({ overlayEl }: Props) => {
  const editorState = useEditor();

  const componentToDraw = editorState.drawToolState.componentToDraw;
  const componentMeta = BuiltInComponentMap[componentToDraw]!;
  assert(componentMeta);

  useEffect(() => {
    const {
      toolState,
      cameraState,
      snapState,
      treeUtils,
      fileState,
      pointerState,
      keyState,
      hudState,
      selectionState,
      drawToolState,
      cursorState,
      layerTreeState,
      tickState,
    } = editorState;

    let pointerDownPosViewport: Vec2 | null = null;
    let pointerDownTime: number | null = null;
    let anchorPoint: Vec2 | null = null;
    let drawBounds: Rect | null = null;
    let qualifiesAsDraw = false;

    function onPointerDown(e: PointerEvent) {
      // Keep all pointer events flowing to the canvas until we release
      overlayEl.setPointerCapture(e.pointerId);

      bindDrawHandlers();

      pointerDownPosViewport = { x: e.clientX, y: e.clientY };
      pointerDownTime = Date.now();
      anchorPoint = cameraState.viewportToWorld(pointerDownPosViewport);
      qualifiesAsDraw = false;

      // Treat drawing as transient changes until we're done
      fileState.fileDataObserver?.startTreatingChangesAsTransient();
    }

    function onPointerUp(e: PointerEvent) {
      if (!anchorPoint || !pointerDownPosViewport) {
        return;
      }

      let insertedNode: TreeNode | null = drawToolState.drawingNode;
      // End the draw or insert a new node if we never started drawing
      if (qualifiesAsDraw) {
        stopDrawing();
      } else {
        // Click instead of a drag draw: insert a new node at the pointer with a minimum size
        const minNodeWidth = 100;
        const minNodeHeight = 100;
        const adjustedPosition: Vec2 = {
          x: anchorPoint.x - minNodeWidth / 2,
          y: anchorPoint.y - minNodeHeight / 2,
        };
        insertedNode = createNodeAndAddToTree(adjustedPosition, minNodeWidth, minNodeHeight);
      }

      // Run post insert tasks
      if (insertedNode) {
        // Give the component a chance to do any post-draw logic
        runInAction(() => {
          if (typeof componentMeta?.postDrawHook === 'function') {
            componentMeta.postDrawHook(editorState, insertedNode);
          }
        });
      }

      // Clear caches
      anchorPoint = null;
      pointerDownPosViewport = null;
      pointerDownTime = null;
      qualifiesAsDraw = false;

      // Stop treating changes as transient (will send a final non-transient edit to the server)
      fileState.fileDataObserver?.endTreatingChangesAsTransient();
      // Release pointer capture
      overlayEl.releasePointerCapture(e.pointerId);
      // Change active tool back to move
      toolState.setActiveTool(Tool.Move);
    }
    const startDrawing = action(() => {
      assert(anchorPoint);

      // Inform the state that we're drawing a node
      drawToolState.setIsDrawing(true);

      // Create the new node
      drawBounds = RectUtils.fromPoints([anchorPoint, anchorPoint]);
      const newNode = createNodeAndAddToTree(anchorPoint, RectUtils.width(drawBounds), RectUtils.height(drawBounds));
      if (!newNode) {
        console.warn('Failed to create drawing node');
        stopDrawing();
        return;
      }

      // Update the state
      drawToolState.setDrawingNode(newNode);
      // Select the new node as we draw
      selectionState.selectIds([newNode.id]);
    });

    const stopDrawing = action(() => {
      unbindDrawHandlers();

      anchorPoint = null;
      drawBounds = null;

      // Stop drawing in state
      drawToolState.stopDrawing();
      // Change active tool back to move
      toolState.setActiveTool(Tool.Move);
      // Clear snaps
      snapState.clearSnaps();
      // Reset tool cursor
      cursorState.setToolCursorClass('cursor-crosshair');
    });

    // Keep track of the previous draw control point so we can calculate deltas for temporary drags
    let previousControlPoint: Vec2 = { x: 0, y: 0 };
    function draw() {
      if (!anchorPoint) {
        return;
      }

      runInAction(() => {
        if (anchorPoint && drawToolState.drawingNode) {
          const forceAspectRatio = keyState.isShiftDown ? true : false;
          const originalRect = {
            minX: anchorPoint.x,
            minY: anchorPoint.y,
            maxX: anchorPoint.x + 1,
            maxY: anchorPoint.y + 1,
            width: 1,
            height: 1,
          };

          const controlPoint = snapState.makeSnappedControlPointForDrawingTools(
            pointerState.cursorPosWorld,
            drawToolState.drawingNode.bounds,
            forceAspectRatio,
            null,
            null
          );

          // Figure out if it's a temporary move (spacebar) or a normal draw
          if (keyState.keyCodeDown.has('Space') === true && drawBounds !== null) {
            // It's a temporary move, move the bounds
            const distanceToMove = Vec2Utils.subtract(controlPoint, previousControlPoint);
            anchorPoint.x += distanceToMove.x;
            anchorPoint.y += distanceToMove.y;
            drawBounds = RectUtils.fromPoints([anchorPoint, controlPoint]);
          } else {
            // It's a draw, expand the bounds
            drawBounds = makeResizeRect(anchorPoint, controlPoint, originalRect, keyState.isAltDown, forceAspectRatio);
          }

          // Apply the new bounds to the node
          RectUtils.roundInPlace(drawBounds);
          drawToolState.drawingNode.setXInWorld(drawBounds.minX);
          drawToolState.drawingNode.setYInWorld(drawBounds.minY);
          drawToolState.drawingNode.setWidth(RectUtils.width(drawBounds) || 1);
          drawToolState.drawingNode.setHeight(RectUtils.height(drawBounds) || 1);

          previousControlPoint.x = controlPoint.x;
          previousControlPoint.y = controlPoint.y;
        }
      });

      // Use resize cursor and keep it updated based on position
      if (pointerState.cursorPosWorld.x > anchorPoint.x && pointerState.cursorPosWorld.y > anchorPoint.y) {
        // bottom right
        cursorState.setToolCursorClass(resizeCursorMap[ResizeHandlePosition.BottomRight]);
      } else if (pointerState.cursorPosWorld.x > anchorPoint.x && pointerState.cursorPosWorld.y < anchorPoint.y) {
        // top right
        cursorState.setToolCursorClass(resizeCursorMap[ResizeHandlePosition.TopRight]);
      } else if (pointerState.cursorPosWorld.x < anchorPoint.x && pointerState.cursorPosWorld.y > anchorPoint.y) {
        // bottom left
        cursorState.setToolCursorClass(resizeCursorMap[ResizeHandlePosition.BottomLeft]);
      } else if (pointerState.cursorPosWorld.x < anchorPoint.x && pointerState.cursorPosWorld.y < anchorPoint.y) {
        // top left
        cursorState.setToolCursorClass(resizeCursorMap[ResizeHandlePosition.TopLeft]);
      }

      // Ask for graphics redraw
      hudState.requestDraw();
    }

    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        // Escape cancels drawing
        stopDrawing();
      }
    }

    function createNodeAndAddToTree(position: Vec2, width: number, height: number): TreeNode | null {
      // Figure out if we should just insert or start a brush draw
      let targetParent = treeUtils.rootNode;

      let shouldInsertIntoParent = false; // hoverNode can have children + is in DOM layout mode
      const canHaveChildren = pointerState.hoveredNode?.canHaveChildren;
      if (canHaveChildren) {
        targetParent = pointerState.hoveredNode;
      }

      const newNode = treeUtils.createNode(componentToDraw, targetParent);
      if (!newNode) {
        console.warn('Failed to create new node');
        return null;
      }
      newNode.setXInWorld(position.x);
      newNode.setYInWorld(position.y);
      newNode.setStyle('width', width);
      newNode.setStyle('height', height);

      // Select the new node
      selectionState.selectIds([newNode.id]);
      // Make sure the node is visible in the layer tree
      layerTreeState.ensureNodesAreVisibleInLayerTree([newNode]);

      return newNode;
    }

    function onTickWithPointerDown() {
      if (!anchorPoint || !pointerDownPosViewport) {
        return;
      }

      // Check if we're actually dragging/drawing yet
      if (qualifiesAsDraw === false) {
        assert(pointerDownTime);
        const movementVector = Vec2Utils.subtract(pointerState.cursorPos, pointerDownPosViewport);
        const movementDistance = Vec2Utils.length(movementVector);
        const timeSincePointerDown = Date.now() - pointerDownTime;

        // If we haven't moved far enough and it hasn't hit the timing threshold yet, bail out
        if (movementDistance < CLICK_VS_DRAG_THRESHOLD_PX && timeSincePointerDown < DOUBLE_CLICK_TIME_MS) {
          return;
        }
        // If we made it this far, we now qualify as a draw
        startDrawing();
        qualifiesAsDraw = true;
      } else {
        draw();
      }
    }

    function bindDrawHandlers() {
      window.addEventListener('blur', stopDrawing);
      window.addEventListener('keydown', onKeyDown);
      tickState.subToTick(onTickWithPointerDown);
    }

    function unbindDrawHandlers() {
      window.removeEventListener('blur', stopDrawing);
      window.removeEventListener('keydown', onKeyDown);
      tickState.unsubToTick(onTickWithPointerDown);
    }

    overlayEl.addEventListener('pointerdown', onPointerDown);
    window.addEventListener('pointerup', onPointerUp);

    cursorState.setToolCursorClass('cursor-crosshair');

    return () => {
      overlayEl.removeEventListener('pointerdown', onPointerDown);
      window.removeEventListener('pointerup', onPointerUp);
      unbindDrawHandlers();
      cursorState.setToolCursorClass(null);
    };
  }, [editorState, componentToDraw, componentMeta, overlayEl]);

  return null;
});
