import { contains } from '@paper/models/src/math/collision';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { ComponentMeta } from './built-in-ui';
import { assert } from '../../assert';
import { PropertyPanels } from '../properties/property-panel';
import { addFill } from '../properties/fill-property';
import { BoxTreeIcon } from '../../components/tree-icons';

// Built in components
export const Box = ({
  node,
  ref,
  style,
  children,
}: {
  node: TreeNode;
  ref: React.RefObject<HTMLDivElement>;
  style: React.CSSProperties;
  children: React.ReactNode;
}) => {
  return (
    <div
      ref={ref}
      // className="*:shrink-0" consider this?
      style={{
        contain: 'content',
        wordWrap: 'break-word',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const BoxMeta: ComponentMeta = {
  label: 'Box',
  component: Box,
  canHaveChildren: true,
  props: {},
  propertyPanels: new Set([
    PropertyPanels.Position,
    PropertyPanels.Layout,
    PropertyPanels.Fill,
    PropertyPanels.Outline,
  ]),
  layerTreeIcon: BoxTreeIcon,
  postDrawHook: (editorState: EditorState, thisNode: TreeNode) => {
    // If this is a top level Box, it has some special "Frame" behaviors
    if (thisNode.parent?.isRoot) {
      // Add a background color
      addFill([thisNode]);
    }

    // If we drew this Box completely around other elements in its parent, reparent them into this Box
    const parent = thisNode.parent;
    assert(parent);

    let reparentedNodes: TreeNode[] = [];
    for (const node of parent.children) {
      if (node === thisNode) continue;
      if (contains(thisNode.bounds, node.bounds)) {
        editorState.treeUtils.moveNodeToNewParent(node, thisNode);
        reparentedNodes.push(node);
      }

      editorState.layerTreeState.ensureNodesAreVisibleInLayerTree(reparentedNodes);
    }
  },
};
