import { observer } from 'mobx-react-lite';
import { PropertyPanels } from '../properties/property-panel';
import { useEditor } from '../editor-context';
import { reducePropertyValues } from '../properties/reduce-property-values';
import { NumberInput } from '../properties/number-input';
import { Field } from '../../components/field';

export const FontSizeProperty = observer(() => {
  const { propertiesState } = useEditor();

  const values = propertiesState.getStyleValues('fontSize');
  const inputValue = reducePropertyValues(values.keys(), '0px');

  function commitValue(value: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Typography]) {
      node.setStyle('fontSize', value);
    }
  }

  return (
    <Field.Root>
      <Field.Icon>
        <svg width="16" height="14" viewBox="0 0 16 14" fill="currentcolor">
          <path
            fillRule="evenodd"
            d="M7.82178 11.5039L10.8873 3H12.2285L15.2968 11.5039H13.9961L13.1413 8.99707H9.97413L9.12799 11.5039H7.82178ZM11.508 4.40212H11.6023L12.808 7.99707H10.3079L11.508 4.40212Z"
          />
          <path d="M3.5 1.25L5.75 4H4V10H5.75L3.5 12.75L1.25 10H3V4H1.25L3.5 1.25Z" />
        </svg>
      </Field.Icon>
      <Field.Control>
        <NumberInput min={0} value={inputValue} onValueCommit={commitValue} />
      </Field.Control>
    </Field.Root>
  );
});
