interface PaddingIconProps extends React.ComponentProps<'svg'> {
  side?: 'all' | 'x' | 'y' | 'left' | 'right' | 'top' | 'bottom';
}

export function PaddingIcon({ side = 'all', ...props }: PaddingIconProps) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" stroke="currentcolor" {...props}>
      <rect x="0.5" y="0.5" width="11" height="11" rx="1.5" />
      {side === 'all' && <rect x="3.5" y="3.5" width="5" height="5" />}
      {['x', 'left'].includes(side) && <line x1="3.5" y1="3" x2="3.5" y2="9" />}
      {['x', 'right'].includes(side) && <line x1="8.5" y1="3" x2="8.5" y2="9" />}
      {['y', 'top'].includes(side) && <line x1="3" y1="3.5" x2="9" y2="3.5" />}
      {['y', 'bottom'].includes(side) && <line x1="3" y1="8.5" x2="9" y2="8.5" />}
    </svg>
  );
}
