import { TreeNode } from '../tree/TreeNode';
import { ResizeHandlePosition } from './resize-handles-logic';

export function resizeHandleDoubleClickLogic(nodes: TreeNode[], handle: ResizeHandlePosition, clickCount: number) {
  // Make sure all of the nodes are eligible for auto sizing (otherwise do nothing)
  for (const node of nodes) {
    // Only apply auto sizing to text nodes and things that have DOM layout children
    if (node.canEditText === false && (node.childrenAreFixed === true || node.children.length === 0)) {
      continue;
    }

    // Height-only auto case:
    if (handle === ResizeHandlePosition.Bottom || handle === ResizeHandlePosition.Top) {
      node.setHeight('auto');
    }

    // Width-only auto case:
    if (handle === ResizeHandlePosition.Left || handle === ResizeHandlePosition.Right) {
      node.setWidth('auto');
    }

    // Both height and width auto case:
    if (
      handle === ResizeHandlePosition.TopLeft ||
      handle === ResizeHandlePosition.BottomRight ||
      handle === ResizeHandlePosition.BottomLeft ||
      handle === ResizeHandlePosition.TopRight
    ) {
      node.setHeight('auto');
      node.setWidth('auto');
    }
  }
}
