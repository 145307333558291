import { action, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { EditorState } from '../EditorState';
import { Rect, RectUtils } from '@paper/models/src/math/rect';
import { Vec2 } from '@paper/models/src/math/vec2';

/** Keeps track and renders the HUD graphics onto a canvas */
export class HUDState {
  // ----- Constructor ----- //
  constructor(private readonly editorState: EditorState) {
    makeObservable(this);

    // Redraw for hovered node change
    const hoveredNodeDisposer = reaction(() => {
      const pointerState = this.editorState.pointerState;
      const hoveredTreeNode = pointerState.hoveredNode;
      const shouldShowHoverHighlight = this.editorState.toolState.shouldShowHoverHighlight;
      return { hoveredTreeNode, shouldShowHoverHighlight };
    }, this.requestDraw);
    this.disposers.push(hoveredNodeDisposer);

    // Redraw for selected nodes change
    const selectionDisposer = reaction(() => {
      return this.editorState.selectionState.selectedNodes;
    }, this.requestDraw);
    this.disposers.push(selectionDisposer);

    // redraw after multiplayer selection changes
    const multiplayerSelectionDisposer = reaction(() => {
      return Object.values(this.editorState.multiplayerState.userData).map((user) => user.selectedNodes);
    }, this.requestDraw);
    this.disposers.push(multiplayerSelectionDisposer);

    // Wire up a listener to detect changes to the device pixel ratio in case the user switches screens or browser-zooms
    this.devicePixelRatioMediaQuery.addEventListener('change', this.updateDevicePixelRatio);
  }

  // ----- Disposers ----- //
  disposers: Array<IReactionDisposer> = [];
  dispose = () => {
    for (const disposer of this.disposers) {
      disposer();
    }

    // Remove the device pixel ratio listener
    this.devicePixelRatioMediaQuery.removeEventListener('change', this.updateDevicePixelRatio);
  };

  // ----- Device pixel ratio ----- //
  devicePixelRatioMediaQuery = window.matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
  updateDevicePixelRatio = () => {
    this.devicePixelRatio = window.devicePixelRatio;
  };
  devicePixelRatio = window.devicePixelRatio;

  // ----- Graphics HUD drawing ----- //
  /** Keeps track of the current HUD canvas element */
  @observable accessor hudEl: HTMLCanvasElement | null = null;
  @observable accessor ctx: CanvasRenderingContext2D | null = null;
  @action setHUDEl = (el: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
    this.hudEl = el;
    this.ctx = ctx;
  };

  /** Whether the graphics have changes that need re-render */
  private hasChanges = false;
  /** Marks as needing a redraw on the next render */
  requestDraw = () => {
    this.hasChanges = true;
  };

  /** Functions can request that we redraw every frame, like when we're select brushing, dragging a node, or autopanning */
  requestContinuousDraw = (requestId: string) => {
    this.continuousDrawRequests.add(requestId);
  };
  cancelContinuousDraw = (requestId: string) => {
    this.continuousDrawRequests.delete(requestId);
  };
  continuousDrawRequests: Set<string> = new Set();

  /** Functions that draw using viewport scale */
  viewportDrawingFunctions: Set<(ctx: CanvasRenderingContext2D) => void> = new Set();
  /** Functions that draw using world scale */
  worldDrawingFunctions: Set<(ctx: CanvasRenderingContext2D) => void> = new Set();

  /** Happens every RAF, bails if hasChanges is false */
  @action render = () => {
    if (!this.hasChanges && this.continuousDrawRequests.size === 0) {
      return;
    }
    if (!this.hudEl || !this.ctx) {
      return;
    }

    this.hasChanges = false;

    this.clear();
    this.drawWorldAdjusted();
    this.drawViewportAdjusted();
  };

  /** Adjusts the canvas to the camera world points and then draws */
  drawWorldAdjusted = () => {
    const ctx = this.ctx;
    const cameraState = this.editorState.cameraState;
    if (!ctx || !cameraState) {
      return;
    }

    // Save the current context state
    ctx.save();
    // Apply the camera's pan and zoom transformations (rounding function need to match CameraState's)
    ctx.translate(cameraState.panRounded.x, cameraState.panRounded.y);
    ctx.scale(cameraState.scale, cameraState.scale);

    for (const func of this.worldDrawingFunctions) {
      func(ctx);
    }

    // Reset the canvas transformations
    ctx.restore();
  };

  /** Draws in 1:1 to window viewport scale and position */
  drawViewportAdjusted = () => {
    if (!this.ctx) {
      return;
    }

    for (const func of this.viewportDrawingFunctions) {
      func(this.ctx);
    }
  };

  clear = () => {
    if (!this.hudEl || !this.ctx) {
      return;
    }
    this.ctx.clearRect(0, 0, this.hudEl.width, this.hudEl.height);
  };
}
