import { EditorState } from './EditorState';
import { SnapAxis } from './snaps/find-snaps';
import { Tool } from './toolbar/ToolState';
import { AZERTY_FR_DARWIN, HotkeysManager, QWERTZ_DE_DARWIN, US_LAYOUT_QWERTY_DARWIN } from '../hotkey';
import { action } from 'mobx';
import { addFlexToNode, determineFlexAction, removeFlexFromNode, wrapNodesWithFlex } from './tree/wrap-nodes-with-flex';
import { toast } from 'sonner';

export const { createHotkeysZone, createIgnoreZone, HotkeysProvider } = new HotkeysManager<EditorState>({
  layouts: {
    US_LAYOUT_QWERTY_DARWIN,
    AZERTY_FR_DARWIN,
    QWERTZ_DE_DARWIN,
  },
  logLevel: 'none',
});

export const EditorHotkeysZone = createHotkeysZone({
  id: 'editor',
  name: 'Editor',
  description: 'Keyboard shortcuts for the editor',
  isGlobal: true,
  shouldPreventDefault: () => true,
  hotkeys: {
    delete: {
      name: 'Delete Selection',
      hotkey: ['Backspace', 'Delete'],
      onKeyDown: (_e, editorState) => {
        for (const node of editorState.selectionState.selectedNodes ?? []) {
          editorState.treeUtils.deleteNodes([node.id]);
        }
      },
    },
    selectAll: {
      name: 'Select All',
      hotkey: 'Mod a',
      onKeyDown: (_e, editorState) => {
        editorState.selectionState.selectIds(editorState.treeUtils.rootNode.children.map((node) => node.id));
      },
    },
    undo: {
      name: 'Undo',
      hotkey: 'Mod z',
      onKeyDown: (_e, editorState) => {
        editorState.undoManager.undo();
      },
    },
    redo: {
      name: 'Redo',
      hotkey: 'Mod Shift z',
      onKeyDown: (_e, editorState) => {
        editorState.undoManager.redo();
      },
    },
    togglePanTool: {
      name: 'Pan Tool',
      hotkey: 'Space',
      onKeyDown: (_e, editorState) => {
        if (!editorState.pointerState.pointerIsBusy && editorState.toolState.activeTool !== Tool.Pan) {
          editorState.toolState.setActiveTool(Tool.Pan);
        }
      },
      onKeyUp: (_e, editorState) => {
        if (!editorState.pointerState.pointerIsBusy && editorState.toolState.activeTool === Tool.Pan) {
          if (editorState.keyState.keyCodeDown.has('KeyZ')) {
            editorState.toolState.setActiveTool(Tool.Zoom);
          } else {
            editorState.toolState.setActiveTool(Tool.Move);
          }
        }
      },
    },
    toggleZoomTool: {
      name: 'Zoom Tool',
      hotkey: ['z', 'Alt z'],
      onKeyDown: (e, editorState) => {
        if (!editorState.pointerState.pointerIsBusy && editorState.toolState.activeTool !== Tool.Zoom) {
          editorState.toolState.setActiveTool(Tool.Zoom);
        }
      },
    },
    preventSave: {
      name: 'Prevent Save',
      hotkey: 'Mod s',
      onKeyDown: (e) => {
        e.preventDefault();
      },
    },
    drawTool: {
      name: 'Draw Tool',
      hotkey: ['b', 'f'],
      onKeyDown: (_e, editorState) => {
        editorState.toolState.setActiveTool(Tool.Draw);
        editorState.drawToolState.setComponentToDraw('Box');
      },
    },
    rectangleTool: {
      name: 'Rectangle Tool',
      hotkey: 'r',
      onKeyDown: (_e, editorState) => {
        editorState.toolState.setActiveTool(Tool.Draw);
        editorState.drawToolState.setComponentToDraw('Rectangle');
      },
    },
    textTool: {
      name: 'Text Tool',
      hotkey: 't',
      onKeyDown: (e, editorState) => {
        editorState.toolState.setActiveTool(Tool.Text);
        editorState.textTool.setAllowDrawing(true);
        editorState.textTool.startEditingIfSelectionIsSoloTextNode();
        e.preventDefault();
      },
    },
    editText: {
      name: 'Edit Text',
      hotkey: 'Enter',
      onKeyDown: (e, editorState) => {
        if (
          editorState.selectionState.selectedNodes.length === 1 &&
          editorState.selectionState.selectedNodes[0]?.canEditText
        ) {
          editorState.toolState.setActiveTool(Tool.Text);
          editorState.textTool.startEditingTextNode(editorState.selectionState.selectedNodes[0], true);
          e.preventDefault();
        }
      },
    },
    toggleUI: {
      name: 'Toggle UI',
      hotkey: ['.', 'Mod .', 'Mod \\'],
      onKeyDown: (_e, editorState) => {
        editorState.setRenderUI(!editorState.renderUI);
      },
    },
    moveTool: {
      name: 'Move Tool',
      hotkey: ['v', 'Escape'],
      onKeyDown: (_e, editorState) => {
        if (!editorState.pointerState.pointerIsBusy) {
          editorState.toolState.setActiveTool(Tool.Move);
        }
      },
    },
    // Nudge commands
    nudgeUp: {
      name: 'Nudge Up',
      hotkey: ['ArrowUp', 'Shift ArrowUp', 'Meta ArrowUp', 'Meta Shift ArrowUp', 'w', 'Shift w'],
      onKeyDown: (e, editorState) => handleNudge(editorState, 'up', e.shiftKey, e.metaKey),
    },
    nudgeDown: {
      name: 'Nudge Down',
      hotkey: ['ArrowDown', 'Shift ArrowDown', 'Meta ArrowDown', 'Meta Shift ArrowDown', 's', 'Shift s'],
      onKeyDown: (e, editorState) => handleNudge(editorState, 'down', e.shiftKey, e.metaKey),
    },
    nudgeLeft: {
      name: 'Nudge Left',
      hotkey: ['ArrowLeft', 'Shift ArrowLeft', 'Meta ArrowLeft', 'Meta Shift ArrowLeft', 'a', 'Shift a'],
      onKeyDown: (e, editorState) => {
        handleNudge(editorState, 'left', e.shiftKey, e.metaKey);
        // Warn that Shift+A moved to Shift+F
        if (!localStorage.getItem('has-been-told-about-shift-f')) {
          toast.info('Tip: Press Shift+F to add flex layout');
          localStorage.setItem('has-been-told-about-shift-f', 'true');
        }
      },
    },
    nudgeRight: {
      name: 'Nudge Right',
      hotkey: ['ArrowRight', 'Shift ArrowRight', 'Meta ArrowRight', 'Meta Shift ArrowRight', 'd', 'Shift d'],
      onKeyDown: (e, editorState) => handleNudge(editorState, 'right', e.shiftKey, e.metaKey),
    },
    addFlex: {
      name: 'Add Flex',
      hotkey: 'Shift f',
      onKeyDown: (e, editorState) => {
        const action = determineFlexAction(editorState.selectionState.selectedNodes);
        if (action === 'add-flex-to-node') {
          editorState.selectionState.selectedNodes.forEach((node) => addFlexToNode(editorState, node));
        } else if (action === 'remove-flex') {
          // Note: shift f hotkey is supposed to add flex, so if the determined action is to remove, instead wrap in a new flex
          wrapNodesWithFlex(editorState, editorState.selectionState.selectedNodes);
        } else if (action === 'wrap-in-flex') {
          wrapNodesWithFlex(editorState, editorState.selectionState.selectedNodes);
        }
      },
    },
    removeFlex: {
      name: 'Remove Flex',
      hotkey: 'Mod Shift f',
      onKeyDown: (e, editorState) => {
        const action = determineFlexAction(editorState.selectionState.selectedNodes);
        if (action === 'remove-flex') {
          editorState.selectionState.selectedNodes.forEach((node) => removeFlexFromNode(node));
        }
      },
    },
    zoomReset: {
      name: 'Reset Zoom',
      hotkey: ['0', 'Mod 0'],
      onKeyDown: (e, editorState) => {
        e.preventDefault();
        editorState.cameraState.resetZoom();
      },
    },
    zoomIn: {
      name: 'Zoom In',
      hotkey: ['+', 'Mod +', '=', 'Mod ='],
      onKeyDown: (e, editorState) => {
        editorState.cameraState.zoomIn();
      },
    },
    zoomOut: {
      name: 'Zoom Out',
      hotkey: ['-', 'Mod -'],
      onKeyDown: (e, editorState) => {
        editorState.cameraState.zoomOut();
      },
    },
    zoomToFit: {
      name: 'Zoom to Fit',
      hotkey: ['1', 'Shift 1'],
      onKeyDown: (e, editorState) => {
        editorState.cameraState.zoomToFit(editorState.treeUtils.totalBounds);
      },
    },
    zoomToSelection: {
      name: 'Zoom to Selection',
      hotkey: ['2', 'Shift 2'],
      onKeyDown: (_e, editorState) => {
        const bounds = editorState.selectionState.selectionBoundsRect ?? editorState.treeUtils.totalBounds;
        editorState.cameraState.zoomToFit(bounds);
      },
    },
  },
});

// Helper function for nudge operations
const handleNudge = action(
  (state: EditorState, direction: 'up' | 'down' | 'left' | 'right', isShift: boolean, isMeta: boolean) => {
    const amount = isShift ? 10 : 1;
    const snapShowTimeMs = 667;

    for (const node of state.selectionState.selectedNodes) {
      if (direction === 'up') {
        if (isMeta) {
          node.setHeight(Math.max(node.height - amount, 1));
        } else {
          node.setYInLocal(node.y - amount);
        }
      } else if (direction === 'down') {
        if (isMeta) {
          node.setHeight(Math.max(node.height + amount, 1));
        } else {
          node.setYInLocal(node.y + amount);
        }
      } else if (direction === 'left') {
        if (isMeta) {
          node.setWidth(Math.max(node.width - amount, 1));
        } else {
          node.setXInLocal(node.x - amount);
        }
      } else if (direction === 'right') {
        if (isMeta) {
          node.setWidth(Math.max(node.width + amount, 1));
        } else {
          node.setXInLocal(node.x + amount);
        }
      }

      // Draw snaps if new position matches a snap
      if (state.selectionState.selectionBoundsRect) {
        state.snapState.findSnapsForRect(state.selectionState.selectionBoundsRect, SnapAxis.XY, 0);
        state.snapState.clearSnapsAfterDelay(snapShowTimeMs);
      }

      // Draw HUD after the change
      state.hudState.requestDraw();
    }
  }
);
