import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { RectUtils, RectWithSize } from '@paper/models/src/math/rect';
import { Vec2, Vec2Utils } from '@paper/models/src/math/vec2';
import { runInAction } from 'mobx';
import { makeResizeRect } from '../move-tool/make-resize-rect';
import { assert } from '../../assert';
import { isPointInRect } from '@paper/models/src/math/collision';
import { resizeHandleDoubleClickLogic } from './resize-handle-double-click';
import { DOUBLE_CLICK_TIME_MS } from '../move-tool/move-tool';

export const ResizeHandlesLogic = observer(() => {
  const editorState = useEditor();
  const overlayEl = editorState.hudState.hudEl;

  useEffect(() => {
    if (!overlayEl) return;

    const {
      moveToolState,
      pointerState,
      keyState,
      cameraState,
      selectionState,
      hudState,
      fileState,
      snapState,
      cursorState,
      tickState,
    } = editorState;

    let hoveredHandle: ResizeHandlePosition | null = null;
    let isResizing: boolean = false;
    /** This will be the opposite corner of the selection bounds from the resize handle */
    let anchorPoint: Vec2 | null = null;
    /** At the time of pointer down, the control point that the pointer can move around */
    let pointerDownControlPoint: Vec2 | null = null;
    /** Stores the time and handle last pointeredDown so we can detect double click. Not nullable because it needs to survive pointerUp anyway */
    let doubleClickDetection: { time: number; handle: ResizeHandlePosition; count: number } = {
      time: 0,
      handle: 0,
      count: 0,
    };
    /** Store the position of the original pointer down so we can calculate a delta of movement and maintain a "stable offset" of pointer position to edge of box */
    let pointerDownPos: Vec2 | null = null;
    /** When resizing on only one plane, we lock the position of the other control point axis */
    let controlPointLockX: number | null = null;
    let controlPointLockY: number | null = null;
    /** Store the original rect so we can later compare center points and aspect ratios */
    let originalRect: RectWithSize | null = null;
    /** Whether a pointerDown -> pointerUp sequence made any changes to the size of the selection */
    let resizeMadeSizeChanges: boolean = false;
    /** When we start resizing, we need to store the original bounds of the nodes so that we can resize them proportionally */
    let originalNodeBoundsMap: Record<string, RectWithSize> = {};

    function onPointerMove(e: PointerEvent) {
      // Don't do anything if we're already resizing
      if (isResizing) return;

      // TODO: make this better with a handle registration system
      if (pointerState.isHoveringHandle !== RESIZE_HANDLE_ID && hoveredHandle !== null) {
        pointerState.setIsHoveringHandle(RESIZE_HANDLE_ID);
        cursorState.setHandleCursorClass(resizeCursorMap[hoveredHandle]);
      } else if (pointerState.isHoveringHandle === RESIZE_HANDLE_ID && hoveredHandle === null) {
        pointerState.setIsHoveringHandle(null);
        cursorState.setHandleCursorClass(null);
      }

      checkForHover();
    }

    function checkForHover() {
      hoveredHandle = null;

      // If pointer is down and we're dragging or provisional dragging, don't hover resize handles
      if (moveToolState.dragState.isAtLeastProvisionalDrag) return;

      const bounds = selectionState.selectionBoundsRect;
      if (!bounds) return;
      const boundsWidth = bounds.width;
      const boundsHeight = bounds.height;
      const boundsWidthViewport = boundsWidth * cameraState.scale;
      const boundsHeightViewport = boundsHeight * cameraState.scale;

      // Show handle if one dimension has enough space to show them
      const handleIsShowing = shouldShowResizeHandles(boundsWidthViewport, boundsHeightViewport);
      if (!handleIsShowing) {
        return;
      }

      // The size in world points on the outside of the selection border
      const outsidePadding = HOVER_HANDLE_SIZE / 2 / cameraState.scale;
      // For inside the selection bounds,
      // If it's a very small box, we use 0
      // Otherwise, we use the smaller of the outer threshold or 12% of the bounds width
      const insidePaddingX = boundsWidth < 8 ? 0 : Math.min(outsidePadding, boundsWidth * 0.12);
      const insidePaddingY = boundsHeight < 8 ? 0 : Math.min(outsidePadding, boundsHeight * 0.12);

      const topHoverBounds = {
        minX: bounds.minX - outsidePadding,
        minY: bounds.minY - outsidePadding,
        maxX: bounds.maxX + outsidePadding,
        maxY: bounds.minY + insidePaddingY,
      };
      const rightHoverBounds = {
        minX: bounds.maxX - insidePaddingX,
        minY: bounds.minY - outsidePadding,
        maxX: bounds.maxX + outsidePadding,
        maxY: bounds.maxY + outsidePadding,
      };
      const bottomHoverBounds = {
        minX: bounds.minX - outsidePadding,
        minY: bounds.maxY - insidePaddingY,
        maxX: bounds.maxX + outsidePadding,
        maxY: bounds.maxY + outsidePadding,
      };
      const leftHoverBounds = {
        minX: bounds.minX - outsidePadding,
        minY: bounds.minY - outsidePadding,
        maxX: bounds.minX + insidePaddingX,
        maxY: bounds.maxY + outsidePadding,
      };

      const intersectsTop = isPointInRect(pointerState.cursorPosWorld, topHoverBounds);
      const intersectsRight = isPointInRect(pointerState.cursorPosWorld, rightHoverBounds);
      const intersectsBottom = isPointInRect(pointerState.cursorPosWorld, bottomHoverBounds);
      const intersectsLeft = isPointInRect(pointerState.cursorPosWorld, leftHoverBounds);

      if (intersectsTop && intersectsLeft) {
        hoveredHandle = ResizeHandlePosition.TopLeft;
      } else if (intersectsTop && intersectsRight) {
        hoveredHandle = ResizeHandlePosition.TopRight;
      } else if (intersectsBottom && intersectsLeft) {
        hoveredHandle = ResizeHandlePosition.BottomLeft;
      } else if (intersectsBottom && intersectsRight) {
        hoveredHandle = ResizeHandlePosition.BottomRight;
      } else if (intersectsTop) {
        hoveredHandle = ResizeHandlePosition.Top;
      } else if (intersectsBottom) {
        hoveredHandle = ResizeHandlePosition.Bottom;
      } else if (intersectsLeft) {
        hoveredHandle = ResizeHandlePosition.Left;
      } else if (intersectsRight) {
        hoveredHandle = ResizeHandlePosition.Right;
      }

      // TODO: make this better
      if (hoveredHandle !== null) {
        pointerState.setIsHoveringHandle(RESIZE_HANDLE_ID);
        cursorState.setHandleCursorClass(resizeCursorMap[hoveredHandle]);
      } else if (pointerState.isHoveringHandle === RESIZE_HANDLE_ID && hoveredHandle === null) {
        pointerState.setIsHoveringHandle(null);
        cursorState.setHandleCursorClass(null);
      }
    }

    let lastControlPoint: Vec2 | null = null;
    function handleNewInput() {
      if (!isResizing) return;
      const bounds = selectionState.selectionBoundsRect;
      if (!bounds) return;
      if (!anchorPoint || !originalRect || !pointerDownControlPoint) return; // expected to always be set in this case

      // ----- Check for modifiers ----- //
      const mirror = keyState.isAltDown;
      const forceAspectRatio = keyState.isShiftDown;

      const pointerDelta = Vec2Utils.subtract(pointerState.cursorPosWorld, pointerDownPos!);
      const lengthOfPointerDelta = Vec2Utils.length(pointerDelta);
      const newControlPoint = Vec2Utils.add(pointerDownControlPoint, pointerDelta);

      // If this is the first tick since the mouse went down, we want the control point delta to be 0, so just clone the controlPoint
      if (lastControlPoint === null) {
        lastControlPoint = { ...newControlPoint };
      }

      if (lengthOfPointerDelta === 0 && resizeMadeSizeChanges === false) {
        // If we haven't moved at all yet, don't do anything (or you'll get immediate snapped resizing on pointer down before mouse movement)
        return;
      }

      // ----- Set up the control point and the snap check point ----- //
      const controlPoint = snapState.makeSnappedControlPointForDrawingTools(
        newControlPoint,
        bounds,
        forceAspectRatio,
        controlPointLockX,
        controlPointLockY
      );

      // ----- Make the new selection bounds ----- //
      // Note we want to avoid using 0's which will create divide by zero errors and leave things stuck at 0 size

      let newSelectionBounds: RectWithSize;
      if (keyState.keyCodeDown.has('Space') === false) {
        // It's a normal resize
        newSelectionBounds = makeResizeRect(
          anchorPoint,
          controlPoint,
          originalRect,
          mirror,
          forceAspectRatio,
          hoveredHandle ?? undefined
        );
        // Update the snap state's version of our bounds for drawing snaps
        snapState.setCurrentBounds(newSelectionBounds);
      } else {
        // It's a temporary move drag
        const controlPointDelta = Vec2Utils.subtract(controlPoint, lastControlPoint);
        anchorPoint.x += controlPointDelta.x;
        anchorPoint.y += controlPointDelta.y;
        newSelectionBounds = RectUtils.fromPoints([anchorPoint, controlPoint]);

        // Recalculate snaps for the full rectangle
        const snaps = snapState.findSnapsForRect(newSelectionBounds);
        if (snaps.xSnaps.length > 0) {
          newSelectionBounds.minX += snaps.xSnaps[0]!.distanceSigned;
          newSelectionBounds.maxX += snaps.xSnaps[0]!.distanceSigned;
        }
        if (snaps.ySnaps.length > 0) {
          newSelectionBounds.minY += snaps.ySnaps[0]!.distanceSigned;
          newSelectionBounds.maxY += snaps.ySnaps[0]!.distanceSigned;
        }
      }

      const newSelectionWidth = newSelectionBounds.width || 1;
      const newSelectionHeight = newSelectionBounds.height || 1;
      const originalSelectionWidth = originalRect.width || 1;
      const originalSelectionHeight = originalRect.height || 1;
      let percentChangeX = newSelectionWidth / originalSelectionWidth;
      let percentChangeY = newSelectionHeight / originalSelectionHeight;
      lastControlPoint.x = controlPoint.x;
      lastControlPoint.y = controlPoint.y;

      // If we made no changes to the size, return early (this allows for double clicks without resizing)
      if (
        resizeMadeSizeChanges === false &&
        // rounding is important here or fractional sized things will immediately resize when you pointer down the resize handle
        newSelectionWidth === Math.round(originalSelectionWidth) &&
        newSelectionHeight === Math.round(originalSelectionHeight) &&
        newSelectionBounds.minX === originalRect.minX &&
        newSelectionBounds.minY === originalRect.minY
      ) {
        return;
      } else {
        // Flip the bit that indicates we did make a change during this pointerDown -> pointerUp sequence
        resizeMadeSizeChanges = true;
      }

      // ----- Adjust the nodes to match the new selection bounds ----- //
      runInAction(() => {
        assert(originalRect);
        for (const node of selectionState.selectedNodes) {
          const originalNodeBounds = originalNodeBoundsMap[node.id];
          if (!originalNodeBounds) continue;
          const originalNodeWidth = originalNodeBounds.width || 1;
          const originalNodeHeight = originalNodeBounds.height || 1;

          // Position adjustments
          const nodeXFromBoundsLeft = (originalNodeBounds.minX - originalRect.minX) * percentChangeX;
          const nodeYFromBoundsTop = (originalNodeBounds.minY - originalRect.minY) * percentChangeY;
          // Note: important not to round before we check if its equal to the current position
          const newX = newSelectionBounds.minX + nodeXFromBoundsLeft;
          const newY = newSelectionBounds.minY + nodeYFromBoundsTop;
          if (node.isFixedLayout && (node.xInWorld !== newX || node.yInWorld !== newY)) {
            node.setXInWorld(Math.round(newX));
            node.setYInWorld(Math.round(newY));
          }

          // Size adjustments
          const newNodeWidth = Math.max(1, percentChangeX * originalNodeWidth);
          const newNodeHeight = Math.max(1, percentChangeY * originalNodeHeight);
          if (node.width !== newNodeWidth) {
            node.setWidth(Math.round(newNodeWidth));
          }
          if (node.height !== newNodeHeight) {
            node.setHeight(Math.round(newNodeHeight));
          }
        }

        hudState.requestDraw();
      });

      // Keep the cursor updated if it's a diagonal drag
      const cursorFlipPoint = mirror ? RectUtils.center(originalRect) : { ...anchorPoint };
      if (controlPointLockX === null && controlPointLockY === null) {
        if (controlPoint.x > cursorFlipPoint.x && controlPoint.y > cursorFlipPoint.y) {
          // bottom right
          cursorState.setHandleCursorClass(resizeCursorMap[ResizeHandlePosition.BottomRight]);
        } else if (controlPoint.x > cursorFlipPoint.x && controlPoint.y < cursorFlipPoint.y) {
          // top right
          cursorState.setHandleCursorClass(resizeCursorMap[ResizeHandlePosition.TopRight]);
        } else if (controlPoint.x < cursorFlipPoint.x && controlPoint.y > cursorFlipPoint.y) {
          // bottom left
          cursorState.setHandleCursorClass(resizeCursorMap[ResizeHandlePosition.BottomLeft]);
        } else if (controlPoint.x < cursorFlipPoint.x && controlPoint.y < cursorFlipPoint.y) {
          // top left
          cursorState.setHandleCursorClass(resizeCursorMap[ResizeHandlePosition.TopLeft]);
        }
      }
    }

    function onPointerDown(e: PointerEvent) {
      if (e.button !== 0) return; // Only handle left clicks
      if (hoveredHandle === null) return;
      const bounds = selectionState.selectionBoundsRect;
      if (!bounds) return;

      // Detect double clicks
      const now = Date.now();
      if (doubleClickDetection.handle === hoveredHandle && now - doubleClickDetection.time < DOUBLE_CLICK_TIME_MS) {
        // It's a double click
        doubleClickDetection.time = now;
        doubleClickDetection.count += 1;

        // We don't want to hide the selection highlight when double clicking into auto
        selectionState.addIgnoreStyleChange('resize-double-click');
        // Resize the nodes
        resizeHandleDoubleClickLogic(selectionState.selectedNodes, hoveredHandle, doubleClickDetection.count);
        // Stop ignoring style changes
        selectionState.removeIgnoreStyleChange('resize-double-click');
      } else {
        // Reset the double click detection
        doubleClickDetection.time = now;
        doubleClickDetection.handle = hoveredHandle;
        doubleClickDetection.count = 1;
      }

      // Capture future pointer events for the duration of the resize
      (e.target as HTMLElement).setPointerCapture(e.pointerId);

      if (hoveredHandle === ResizeHandlePosition.TopLeft) {
        anchorPoint = { x: bounds.maxX, y: bounds.maxY };
        pointerDownControlPoint = { x: bounds.minX, y: bounds.minY };
      } else if (hoveredHandle === ResizeHandlePosition.TopRight) {
        anchorPoint = { x: bounds.minX, y: bounds.maxY };
        pointerDownControlPoint = { x: bounds.maxX, y: bounds.minY };
      } else if (hoveredHandle === ResizeHandlePosition.BottomLeft) {
        anchorPoint = { x: bounds.maxX, y: bounds.minY };
        pointerDownControlPoint = { x: bounds.minX, y: bounds.maxY };
      } else if (hoveredHandle === ResizeHandlePosition.BottomRight) {
        anchorPoint = { x: bounds.minX, y: bounds.minY };
        pointerDownControlPoint = { x: bounds.maxX, y: bounds.maxY };
      } else if (hoveredHandle === ResizeHandlePosition.Top) {
        anchorPoint = { x: bounds.minX, y: bounds.maxY };
        pointerDownControlPoint = { x: bounds.maxX, y: bounds.minY };
        controlPointLockX = bounds.maxX;
      } else if (hoveredHandle === ResizeHandlePosition.Right) {
        anchorPoint = { x: bounds.minX, y: bounds.minY };
        pointerDownControlPoint = { x: bounds.maxX, y: bounds.maxY };
        controlPointLockY = bounds.maxY;
      } else if (hoveredHandle === ResizeHandlePosition.Bottom) {
        anchorPoint = { x: bounds.minX, y: bounds.minY };
        pointerDownControlPoint = { x: bounds.maxX, y: bounds.maxY };
        controlPointLockX = bounds.maxX;
      } else if (hoveredHandle === ResizeHandlePosition.Left) {
        anchorPoint = { x: bounds.maxX, y: bounds.minY };
        pointerDownControlPoint = { x: bounds.minX, y: bounds.maxY };
        controlPointLockY = bounds.maxY;
      }

      // Store the original rect so we can compare it to the new rect when we resize
      originalRect = { ...bounds };

      // Store the original pointer down position so we can calculate a stable offset
      pointerDownPos = { ...pointerState.cursorPosWorld };

      // Store the original ratio of the node size to the selection bounds so that we can resize each node the correct amount
      for (const node of selectionState.selectedNodes) {
        originalNodeBoundsMap[node.id] = { ...node.bounds };
      }

      pointerState.setIsControlledByHandle(RESIZE_HANDLE_ID);
      isResizing = true;
      // Treat file changes as transient until the resize is ended
      fileState.fileDataObserver?.startTreatingChangesAsTransient();
      // Bind the handlers
      bindResizeHandlers();
    }

    function onPointerUp(e: PointerEvent) {
      if (e.button !== 0) return; // Only handle left clicks
      if (isResizing) {
        // Stop treating changes as transient (will send a final non-transient edit to the server)
        fileState.fileDataObserver?.endTreatingChangesAsTransient();

        (e.target as HTMLElement).releasePointerCapture(e.pointerId);

        // If the pointerDown -> pointerUp actually made changes to the size,
        // we should reset our double click detection so that future clicks count as click 0 again
        if (resizeMadeSizeChanges) {
          doubleClickDetection.time = 0;
          doubleClickDetection.handle = 0;
          doubleClickDetection.count = 0;
        }

        pointerState.setIsControlledByHandle(null);
        snapState.clearSnaps();
        isResizing = false;
        anchorPoint = null;
        originalRect = null;
        controlPointLockX = null;
        controlPointLockY = null;
        pointerDownPos = null;
        resizeMadeSizeChanges = false;
        lastControlPoint = null;
        unbindResizeHandlers();
      }
    }

    function frameLoopWhilePointerDown() {
      handleNewInput();
      hudState.requestDraw();
    }

    function bindResizeHandlers() {
      window.addEventListener('pointerup', onPointerUp);
      tickState.subToTick(frameLoopWhilePointerDown);
    }

    function unbindResizeHandlers() {
      window.removeEventListener('pointerup', onPointerUp);
      tickState.unsubToTick(frameLoopWhilePointerDown);
    }

    overlayEl.addEventListener('pointermove', onPointerMove); // we need pointer move all the time to check for hovers (so it's not in the resizeHandlers)
    overlayEl.addEventListener('pointerdown', onPointerDown);
    return () => {
      if (!overlayEl) return;

      overlayEl.removeEventListener('pointerdown', onPointerDown);
      overlayEl.removeEventListener('pointermove', onPointerMove);
      unbindResizeHandlers();

      if (pointerState.isHoveringHandle === RESIZE_HANDLE_ID) {
        pointerState.setIsHoveringHandle(null);
        cursorState.setHandleCursorClass(null);
      }
      if (pointerState.isControlledByHandle === RESIZE_HANDLE_ID) {
        pointerState.setIsControlledByHandle(null);
      }
    };
  }, [editorState, overlayEl]);

  return null;
});

/** Which direction the resize can move in */
export enum ResizeAxis {
  X = 0,
  Y = 1,
  XY = 2,
}

/** Which handle is being used */
export enum ResizeHandlePosition {
  TopLeft = 0,
  Top = 1,
  TopRight = 2,
  Right = 3,
  BottomRight = 4,
  Bottom = 5,
  BottomLeft = 6,
  Left = 7,
}

// Tailwind classes for the cursor
export const resizeCursorMap = {
  [ResizeHandlePosition.TopLeft]: 'cursor-nwse-resize',
  [ResizeHandlePosition.Top]: 'cursor-ns-resize',
  [ResizeHandlePosition.TopRight]: 'cursor-nesw-resize',
  [ResizeHandlePosition.Right]: 'cursor-ew-resize',
  [ResizeHandlePosition.BottomRight]: 'cursor-nwse-resize',
  [ResizeHandlePosition.Bottom]: 'cursor-ns-resize',
  [ResizeHandlePosition.BottomLeft]: 'cursor-nesw-resize',
  [ResizeHandlePosition.Left]: 'cursor-ew-resize',
};

export const VISUAL_HANDLE_SIZE = 8;
export const HOVER_HANDLE_SIZE = 18;
/** Minimum size of the bounding selection box required to show any resize handles at all */
export const MINIMUM_SIZE_REQUIRED_TO_SHOW_HANDLE = VISUAL_HANDLE_SIZE;

export const RESIZE_HANDLE_ID = 'resize-handle';

// Show the handles if one dimension is large enough to show them
export function shouldShowResizeHandles(widthViewport: number, heightViewport: number) {
  return (
    widthViewport >= MINIMUM_SIZE_REQUIRED_TO_SHOW_HANDLE || heightViewport >= MINIMUM_SIZE_REQUIRED_TO_SHOW_HANDLE
  );
}
