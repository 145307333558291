import { runInAction } from 'mobx';
import { EditorState } from '../EditorState';
import type { UserSelectNodeMessage } from '@paper/models/src/websocket/user-select-node-message';

export const handleUserSelectNodeMessage = (editorState: EditorState, messageData: UserSelectNodeMessage) => {
  const multiplayerState = editorState.multiplayerState;
  const clientId = messageData.clientId;

  if (typeof clientId !== 'string') {
    console.warn('clientId is missing from UserSelectNodeMessage, ignoring message');
    return;
  }

  const userData = multiplayerState.userData[clientId];
  if (userData === undefined) {
    // Make sure the user join has made it through first
    console.warn('Received UserSelectNodeMessage before the user was in local cache, ignoring selection change.');
    return;
  }

  runInAction(() => {
    userData.selectedNodes = messageData.nodeIds;
  });
};
