import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { Vec2, Vec2Utils } from '@paper/models/src/math/vec2';
import { Rect, RectUtils, RectWithSize } from '@paper/models/src/math/rect';
import { Tool } from '../toolbar/ToolState';
import { assert } from '@paper/models/src/assert';
import { CLICK_VS_DRAG_THRESHOLD_PX, DOUBLE_CLICK_TIME_MS } from '../move-tool/move-tool';
import { SELECTION_BOUNDS_COLOR, SELECTION_BRUSH_FILL_COLOR } from '../move-tool/select-brush-graphic';

type Props = {
  overlayEl: HTMLCanvasElement;
};

export const ZoomTool = observer(({ overlayEl }: Props) => {
  const editorState = useEditor();

  useEffect(() => {
    const { pointerState, keyState, hudState, cursorState, tickState, toolState, zoomToolState, cameraState } =
      editorState;

    let anchorPoint: Vec2 | null = null;

    function onPointerDown(e: PointerEvent) {
      // Keep all pointer events flowing to the canvas until we release
      overlayEl.setPointerCapture(e.pointerId);

      bindDrawHandlers();

      anchorPoint = { x: e.clientX, y: e.clientY };
      zoomToolState.setZoomBounds(RectUtils.fromPoints([anchorPoint, anchorPoint]));
    }

    function onPointerUp(e: PointerEvent) {
      // Release pointer capture
      overlayEl.releasePointerCapture(e.pointerId);

      if (zoomToolState.isDrawingZoomBrush === false || !anchorPoint || zoomToolState.zoomBounds === null) {
        return;
      }

      // Check if we moved far enough to qualify as a draw to zoom into or if it's just a click
      const movementVector = Vec2Utils.subtract(pointerState.cursorPos, anchorPoint);
      const movementDistance = Vec2Utils.length(movementVector);
      const qualifiesAsDraw = movementDistance > CLICK_VS_DRAG_THRESHOLD_PX;

      // ----- Zooming operations ----- //
      if (qualifiesAsDraw === false) {
        //  Click without drag, zoom in or out based on alt key
        const nextZoomLevel = keyState.isAltDown ? cameraState.scale / 2 : cameraState.scale * 2;
        cameraState.zoomTowardPoint(nextZoomLevel, pointerState.cursorPosWorld, true);
      } else {
        // Drew a zoom box - zoom to fit the rectangle
        const boundsWorld = cameraState.viewportToWorldRect(zoomToolState.zoomBounds, false);

        if (keyState.isAltDown) {
          // Zoom out - based on the center of the zoom bounds
          const newScale = cameraState.scale / 2;
          const center = RectUtils.center(boundsWorld);
          cameraState.zoomTowardPoint(newScale, center, true);
        } else {
          // Zoom in, zoom to fit
          cameraState.zoomToFit(boundsWorld, 0);
        }
      }

      // Clear caches (do this last)
      stopDrawing();
    }

    function stopDrawing() {
      unbindDrawHandlers();

      anchorPoint = null;
      zoomToolState.clearZoomBounds();
    }

    // Keep track of the previous draw control point so we can calculate deltas for temporary drags
    let previousControlPoint: Vec2 = { x: 0, y: 0 };

    // Move the zoom bounds and draw the graphic every tick based on cursor movement
    function onTickWithPointerDown() {
      if (!anchorPoint) {
        return;
      }

      const controlPoint = { ...pointerState.cursorPos };

      // Figure out if it's a temporary move (spacebar) or a normal draw
      if (keyState.keyCodeDown.has('Space') === true && zoomToolState.zoomBounds !== null) {
        // It's a temporary move, move the bounds
        const distanceToMove = Vec2Utils.subtract(controlPoint, previousControlPoint);
        anchorPoint.x += distanceToMove.x;
        anchorPoint.y += distanceToMove.y;
        zoomToolState.setZoomBounds(RectUtils.fromPoints([anchorPoint, controlPoint]));
      } else {
        // It's a draw, expand the bounds
        zoomToolState.setZoomBounds(RectUtils.fromPoints([anchorPoint, controlPoint]));
      }

      // Update the previous control point for next time
      previousControlPoint.x = controlPoint.x;
      previousControlPoint.y = controlPoint.y;

      // Ask for graphics redraw
      hudState.requestDraw();
    }

    // Zoom tool is simple enough I thought we'd just include the graphic here rather than breaking it out into its own file
    function drawZoomGraphic(ctx: CanvasRenderingContext2D) {
      const bounds = zoomToolState.zoomBounds;
      if (!bounds) {
        return;
      }

      ctx.fillStyle = SELECTION_BRUSH_FILL_COLOR;
      ctx.fillRect(bounds.minX, bounds.minY, bounds.width, bounds.height);

      ctx.strokeStyle = SELECTION_BOUNDS_COLOR;
      ctx.lineWidth = 1;
      ctx.strokeRect(bounds.minX, bounds.minY, bounds.width, bounds.height);
    }

    function onKeyDown(e: KeyboardEvent) {
      // Keys that only apply if we're drawing
      if (zoomToolState.isDrawingZoomBrush) {
        if (e.key === 'Escape') {
          // Escape cancels drawing
          stopDrawing();
        }
      }

      // Keys that always apply
      if (e.key === 'Alt') {
        // Holding alt switches to zooming out
        cursorState.setToolCursorClass('cursor-zoom-out');
      }
    }

    function onKeyUp(e: KeyboardEvent) {
      if (e.key === 'Alt') {
        // Releasing alt switches back into zooming in
        cursorState.setToolCursorClass('cursor-zoom-in');
      }
    }

    function bindDrawHandlers() {
      window.addEventListener('blur', stopDrawing);
      tickState.subToTick(onTickWithPointerDown);
    }

    function unbindDrawHandlers() {
      window.removeEventListener('blur', stopDrawing);
      tickState.unsubToTick(onTickWithPointerDown);
    }

    overlayEl.addEventListener('pointerdown', onPointerDown);
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('pointerup', onPointerUp);
    // Register our drawing function
    hudState.viewportDrawingFunctions.add(drawZoomGraphic);

    // Set the initial cursor to zoom-in
    if (keyState.isAltDown) {
      cursorState.setToolCursorClass('cursor-zoom-out');
    } else {
      cursorState.setToolCursorClass('cursor-zoom-in');
    }

    return () => {
      overlayEl.removeEventListener('pointerdown', onPointerDown);
      window.removeEventListener('pointerup', onPointerUp);
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
      unbindDrawHandlers();
      cursorState.setToolCursorClass(null);
      hudState.viewportDrawingFunctions.delete(drawZoomGraphic);
      // Shouldn't be possible to have zoom bounds here, but clear it just to be safe
      zoomToolState.clearZoomBounds();
    };
  }, [editorState, overlayEl]);

  return null;
});
