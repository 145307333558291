import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { PropertyPanels } from './property-panel';
import { PanelCaption, PanelHeader, PanelTitle } from '../../components/panel';
import { GhostIconButton } from '../../components/ghost-icon-button';
import { PlusIcon } from '../../icons/plus-icon';
import { TreeNode } from '../tree/TreeNode';
import { Color, ColorUtils } from '@paper/models/src/colors/Color';
import { MinusIcon } from '../../icons/minus-icon';
import { ColorPicker } from '../color-picker/color-picker';
import { assert } from '../../assert';
import { Field } from '../../components/field';
import { ColorInput } from '../../components/color-input';
import { NumberInput } from './number-input';
import { reducePropertyValues } from './reduce-property-values';
import { PaddingIcon } from '../../icons/padding-icon';
import { LineWidthIcon } from '../../icons/line-width-icon';
import { EyeIcon } from '../../icons/eye-icon';
import { EyeClosedIcon } from '../../icons/eye-closed-icon';
import { LineStyleIcon } from '../../icons/line-style-icon';
import { PanelRow } from '../../components/panel';

export const OutlineProperty = observer(() => {
  const { propertiesState } = useEditor();
  const collapsed = propertiesState.collapsedPanels.has(PropertyPanels.Outline);

  const nodes = propertiesState.nodesPerProperty[PropertyPanels.Outline];
  const everyNodeHasOutline = nodes.every(hasOutlineValues);
  const someNodesHaveOutline = nodes.some(hasOutlineValues);

  const panelHeader = (
    <PanelHeader
      inactive={someNodesHaveOutline === false}
      onPointerDown={(event) => {
        if (someNodesHaveOutline) return;
        if (event.target instanceof HTMLButtonElement) return;
        addOutline(nodes);
        propertiesState.expandPanel(PropertyPanels.Outline);
      }}
    >
      <PanelTitle
        collapsible={someNodesHaveOutline}
        collapsed={collapsed}
        onCollapsedChange={(collapsed) =>
          collapsed
            ? propertiesState.collapsePanel(PropertyPanels.Outline)
            : propertiesState.expandPanel(PropertyPanels.Outline)
        }
      >
        Outline
      </PanelTitle>

      {!someNodesHaveOutline && (
        <GhostIconButton
          onClick={(event) => {
            addOutline(nodes);
            propertiesState.expandPanel(PropertyPanels.Outline);
            event.currentTarget.blur();
          }}
        >
          <PlusIcon />
        </GhostIconButton>
      )}
    </PanelHeader>
  );

  if (collapsed) {
    return <div>{panelHeader}</div>;
  }

  if (everyNodeHasOutline) {
    const outlineStyleValues = propertiesState.getStyleValues('outlineStyle');
    const outlineStyle = reducePropertyValues(outlineStyleValues.keys());

    const outlineWidthValues = propertiesState.getStyleValues('outlineWidth');
    const outlineWidth = reducePropertyValues(outlineWidthValues.keys(), '0px');

    const outlineOffsetValues = propertiesState.getStyleValues('outlineOffset');
    const outlineOffset = reducePropertyValues(outlineOffsetValues.keys(), '0px');

    const outlineColor = nodes[0]?.styleMeta.outlineColor;
    const outlineVisible = isOutlineVisible(nodes[0]!);

    const isMixedOutlineColor = hasMixedOutlineColor(nodes);

    return (
      <div>
        {panelHeader}

        {isMixedOutlineColor ? (
          <PanelCaption onPointerDown={() => replaceMixedOutlineColor(nodes)}>
            Click to replace mixed colors
          </PanelCaption>
        ) : (
          <PanelRow>
            <div
              data-inactive={outlineVisible ? undefined : ''}
              className="data-inactive:text-gray-3 flex grow items-center gap-2"
            >
              <ColorPicker
                color={outlineColor!}
                onColorChange={(color) => {
                  for (const node of nodes) {
                    node.setStyleMeta('outlineColor', ColorUtils.clone(color));
                    node.setStyle('outlineColor', ColorUtils.cssString(color));
                  }
                }}
              />
              <Field.Control>
                <ColorInput
                  color={outlineColor!}
                  onColorCommit={(color) => {
                    for (const node of nodes) {
                      node.setStyleMeta('outlineColor', ColorUtils.clone(color));
                      node.setStyle('outlineColor', ColorUtils.cssString(color));
                    }
                  }}
                />
              </Field.Control>

              <GhostIconButton
                onClick={(event) => {
                  const handler = outlineVisible ? hideOutline : showOutline;
                  handler(nodes);
                  event.currentTarget.blur();
                }}
              >
                {outlineVisible ? <EyeIcon /> : <EyeClosedIcon />}
              </GhostIconButton>
              <GhostIconButton
                onClick={(event) => {
                  removeOutline(nodes);
                  event.currentTarget.blur();
                }}
              >
                <MinusIcon />
              </GhostIconButton>
            </div>
          </PanelRow>
        )}

        <PanelRow>
          <Field.Root>
            <Field.Icon>
              <LineWidthIcon />
            </Field.Icon>
            <Field.Control>
              <NumberInput
                min={0}
                value={outlineWidth}
                onValueCommit={(width) => {
                  for (const node of nodes) {
                    node.setStyle('outlineWidth', width);
                  }
                }}
              />
            </Field.Control>
          </Field.Root>

          <Field.Root>
            <Field.Icon>
              <PaddingIcon />
            </Field.Icon>
            <Field.Control>
              <NumberInput
                value={outlineOffset}
                onValueCommit={(offset) => {
                  for (const node of nodes) {
                    node.setStyle('outlineOffset', offset);
                  }
                }}
              />
            </Field.Control>
          </Field.Root>

          <Field.Root className="min-w-23">
            <Field.Icon>
              <LineStyleIcon />
            </Field.Icon>
            <Field.Control>
              <select
                value={outlineStyle}
                onChange={(event) => {
                  if (event.target.value !== 'Mixed') {
                    for (const node of nodes) {
                      node.setStyle('outlineStyle', event.target.value);
                    }
                  }
                }}
              >
                {outlineStyle === 'Mixed' && (
                  <>
                    <option value="Mixed">Mixed</option>
                    <hr />
                  </>
                )}
                <option value="solid">Solid</option>
                <option value="dashed">Dashed</option>
                <option value="dotted">Dotted</option>
                <option value="double">Double</option>
              </select>
            </Field.Control>
            <Field.Caret />
          </Field.Root>
        </PanelRow>
      </div>
    );
  }

  if (someNodesHaveOutline) {
    return (
      <div>
        {panelHeader}
        <PanelCaption onPointerDown={() => replaceMixedOutline(nodes)}>Click to replace mixed outline</PanelCaption>
      </div>
    );
  }

  return panelHeader;
});

function addOutline(nodes: TreeNode[]) {
  for (const node of nodes) {
    const color = ColorUtils.new('000000')!;
    node.setStyleMeta('outlineColor', color);
    node.setStyle('outlineStyle', 'solid');
    node.setStyle('outlineWidth', 1);
    node.setStyle('outlineColor', ColorUtils.cssString(color));
  }
}

function removeOutline(nodes: TreeNode[]) {
  for (const node of nodes) {
    node.setStyleMeta('outlineColor', null);
    node.setStyle('outlineStyle', undefined);
    node.setStyle('outlineWidth', undefined);
    node.setStyle('outlineOffset', undefined);
    node.setStyle('outlineColor', undefined);
  }
}

function replaceMixedOutline(nodes: TreeNode[]) {
  let outlineColor: Color | null | undefined;
  let outlineStyle: string | undefined;
  let outlineWidth: string | number | undefined;
  let outlineOffset: string | number | undefined;

  const nodesToBackfill: TreeNode[] = [];

  for (const node of nodes) {
    if (!hasOutlineValues(node) || !isOutlineVisible(node)) {
      // These nodes will be backfilled ones we find an outline
      nodesToBackfill.push(node);
      continue;
    }

    outlineColor ??= node.styleMeta.outlineColor;
    outlineStyle ??= node.styles.outlineStyle;
    outlineWidth ??= node.styles.outlineWidth;
    outlineOffset ??= node.styles.outlineOffset;

    if (outlineColor) {
      node.setStyleMeta('outlineColor', ColorUtils.clone(outlineColor));
      node.setStyle('outlineStyle', outlineStyle);
      node.setStyle('outlineWidth', outlineWidth);
      node.setStyle('outlineOffset', outlineOffset);
      node.setStyle('outlineColor', ColorUtils.cssString(outlineColor));
    }
  }

  // Apply the outline value to all the nodes we initially
  // saw that didn't have a outline value on them.
  if (outlineColor) {
    for (const node of nodesToBackfill) {
      node.setStyleMeta('outlineColor', ColorUtils.clone(outlineColor));
      node.setStyle('outlineStyle', outlineStyle);
      node.setStyle('outlineWidth', outlineWidth);
      node.setStyle('outlineOffset', outlineOffset);
      node.setStyle('outlineColor', ColorUtils.cssString(outlineColor));
    }
  }
}

function replaceMixedOutlineColor(nodes: TreeNode[]) {
  let outlineColor: Color | null | undefined;

  for (const node of nodes) {
    outlineColor ??= node.styleMeta.outlineColor;

    if (node.styleMeta.outlineColor) {
      node.setStyleMeta('outlineColor', ColorUtils.clone(outlineColor!));
      node.setStyle('outlineColor', ColorUtils.cssString(outlineColor!));
    }
  }
}

function hasOutlineValues(node: TreeNode) {
  return node.styles.outlineStyle !== undefined;
}

function hasMixedOutlineColor(nodes: TreeNode[]) {
  if (nodes.length === 0) {
    return false;
  }

  assert(nodes[0]?.styleMeta.outlineColor, 'Expected to have an outline color');

  for (const node of nodes) {
    assert(node.styleMeta.outlineColor, 'Expected to have an outline color');

    if (ColorUtils.isEqual(node.styleMeta.outlineColor, nodes[0].styleMeta.outlineColor) === false) {
      return true;
    }

    if (isOutlineVisible(node) !== isOutlineVisible(nodes[0]!)) {
      return true;
    }
  }
}

function isOutlineVisible(node: TreeNode) {
  return hasOutlineValues(node) && node.styles.outlineColor !== 'transparent';
}

function showOutline(nodes: TreeNode[]) {
  for (const node of nodes) {
    const outlineColor = node.styleMeta.outlineColor;
    assert(outlineColor, 'Expected that the node would have an outline color');
    node.setStyle('outlineColor', ColorUtils.cssString(outlineColor));
  }
}

function hideOutline(nodes: TreeNode[]) {
  for (const node of nodes) {
    node.setStyle('outlineColor', 'transparent');
  }
}
