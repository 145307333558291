import { observer } from 'mobx-react-lite';
import { Field } from '../../components/field';
import { useEditor } from '../editor-context';
import { PanelHeader, PanelTitle } from '../../components/panel';
import { PanelRow } from '../../components/panel';
import { ColorInput } from '../../components/color-input';
import { ColorPicker } from '../color-picker/color-picker';
import { Color, ColorUtils } from '@paper/models/src/colors/Color';
import { assert } from '@paper/models/src/assert';
import { DEFAULT_CANVAS_COLOR } from '../canvas/canvas-background-and-cursors';

export const CanvasColorProperty = observer(() => {
  const editorState = useEditor();
  const { pageState } = editorState;

  const commitValue = (color: Color) => {
    pageState.setActivePageCanvasColor(color);
  };

  const currentValue = (pageState.activePage.canvasColor as Color) ?? ColorUtils.new(DEFAULT_CANVAS_COLOR);
  assert(currentValue, 'Error: could not parse canvas color');

  return (
    <div>
      <PanelHeader>
        <PanelTitle collapsible={false}>Page</PanelTitle>
      </PanelHeader>
      <PanelRow>
        <div className="flex grow items-center gap-2">
          <ColorPicker
            color={currentValue}
            onColorChange={(color) => {
              commitValue(color);
            }}
          />
          <Field.Control>
            <ColorInput
              color={currentValue}
              onColorCommit={(color) => {
                commitValue(color);
              }}
            />
          </Field.Control>
        </div>
      </PanelRow>
    </div>
  );
});
