import { observer } from 'mobx-react-lite';
import { EditorState } from '../editor/EditorState';
import { TreeNode } from '../editor/tree/TreeNode';

export interface TreeIconProps {
  editorState: EditorState;
  node: TreeNode;
}

export const BoxTreeIcon = observer((props: TreeIconProps) => {
  const node = props.node;

  if (node.styles.display === 'flex') {
    if (node.styles.flexDirection === 'column' || node.styles.flexDirection === 'column-reverse') {
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <rect x="2" y="3" width="12" height="4.5" rx="1" fill="currentcolor" fillOpacity="0.1" />
          <rect x="2.5" y="3.5" width="11" height="3.5" rx="0.5" fill="none" stroke="currentcolor" />
          <rect x="2" y="9" width="12" height="4.5" rx="1" fill="currentcolor" fillOpacity="0.1" />
          <rect x="2.5" y="9.5" width="11" height="3.5" rx="0.5" fill="none" stroke="currentcolor" />
        </svg>
      );
    }

    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor">
        <rect x="2" y="3" width="5" height="10" rx="1" fillOpacity="0.125" />
        <rect x="2.5" y="3.5" width="4" height="9" rx="0.5" fill="none" stroke="currentcolor" />
        <rect x="8.5" y="3" width="5" height="10" rx="1" fillOpacity="0.125" />
        <rect x="9" y="3.5" width="4" height="9" rx="0.5" fill="none" stroke="currentcolor" />
      </svg>
    );
  }

  const aspectRatio = node.width / node.height;

  let dx = 0;
  let dy = 0;

  if (aspectRatio > 1.2) {
    dx = 1;
  } else if (aspectRatio < 0.8) {
    dy = 1;
  }

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor">
      <rect x={3 - dx} y={3 - dy} width={10 + dx * 2} height={10 + dy * 2} fillOpacity="0.125" />
      <path transform={`translate(${-1 * dx} ${dy})`} d="M4 9L4 12H7V13H4H3V12V9H4Z" />
      <path transform={`translate(${-1 * dx} ${-1 * dy})`} d="M7 3H4H3V4V7H4L4 4H7V3Z" />
      <path transform={`translate(${dx} ${-1 * dy})`} d="M9 3H12H13V4V7H12V4H9V3Z" />
      <path transform={`translate(${dx} ${dy})`} d="M12 9V12H9V13H12H13V12V9H12Z" />
    </svg>
  );
});

export const RectangleTreeIcon = observer((props: TreeIconProps) => {
  const node = props.node;
  const aspectRatio = node.height > 0 ? node.width / node.height : 0;
  const d = Math.abs(1 - aspectRatio);
  const max = d < 0.15 ? 8 : d < 0.5 ? 10 : d < 5 ? 12 : 14;

  let width = Math.min(max, max * aspectRatio);
  let height = Math.min(max, max / aspectRatio);

  // Thin lines:
  if (width < 1 && node.width < 8) {
    return <span className="bg-currentcolor block h-4 w-px" />;
  }
  if (height < 1 && node.height < 8) {
    return <span className="bg-currentcolor block h-px w-4" />;
  }

  width = Math.max(1, width);
  height = Math.max(1, height);

  const styles = node.styles;

  let btlr = String(styles.borderTopLeftRadius ?? styles.borderRadius ?? 0);
  btlr = ((parseFloat(btlr) * width) / node.width).toFixed(1) + 'px';

  let btrr = String(styles.borderTopRightRadius ?? styles.borderRadius ?? 0);
  btrr = ((parseFloat(btrr) * width) / node.width).toFixed(1) + 'px';

  let bblr = String(styles.borderBottomLeftRadius ?? styles.borderRadius ?? 0);
  bblr = ((parseFloat(bblr) * width) / node.width).toFixed(1) + 'px';

  let bbrr = String(styles.borderBottomRightRadius ?? styles.borderRadius ?? 0);
  bbrr = ((parseFloat(bbrr) * width) / node.width).toFixed(1) + 'px';

  return (
    <span
      className="outline-currentcolor block outline-1"
      style={{
        width,
        height,
        borderTopLeftRadius: btlr,
        borderTopRightRadius: btrr,
        borderBottomLeftRadius: bblr,
        borderBottomRightRadius: bbrr,
      }}
    >
      <span className="bg-currentcolor rounded-inherit block size-full opacity-12.5" />
    </span>
  );
});

export function ImageIcon() {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="currentcolor">
      <path
        d="M10.6538 9.5H1.51135H1.34615C0.87908 9.5 0.5 9.0968 0.5 8.6V7V1.4C0.5 0.9032 0.87908 0.5 1.34615 0.5H10.6538C11.1209 0.5 11.5 0.9032 11.5 1.4V8.6C11.5 9.0968 11.1209 9.5 10.6538 9.5Z"
        fillOpacity="0.125"
      />
      <path
        d="M0.5 7V8.6C0.5 9.0968 0.87908 9.5 1.34615 9.5H1.51135H10.6538C11.1209 9.5 11.5 9.0968 11.5 8.6V1.4C11.5 0.9032 11.1209 0.5 10.6538 0.5H1.34615C0.87908 0.5 0.5 0.9032 0.5 1.4V7ZM0.5 7L3.5 3.5L9 9"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 4.75C9.1904 4.75 9.75 4.19036 9.75 3.5C9.75 2.80964 9.1904 2.25 8.5 2.25C7.80964 2.25 7.25 2.80964 7.25 3.5C7.25 4.19036 7.80964 4.75 8.5 4.75Z"
        fillOpacity="0.9"
      />
    </svg>
  );
}

export function TextTreeIcon() {
  return <span className="font-medium tracking-[-0.025em]">Aa</span>;
}

export function ShaderTreeIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor">
      <path
        d="M7.52156 2.26089C7.81991 2.09826 8.18043 2.09826 8.47878 2.26089L12.9824 4.71579C13.3038 4.89098 13.5038 5.22778 13.5038 5.59382V10.4062C13.5038 10.7722 13.3038 11.109 12.9824 11.2842L8.47878 13.7391C8.18043 13.9017 7.81991 13.9017 7.52156 13.7391L3.01797 11.2842C2.69657 11.109 2.49658 10.7722 2.49658 10.4062V5.59382C2.49658 5.22778 2.69658 4.89098 3.01797 4.71579L7.52156 2.26089Z"
        fillOpacity="0.125"
      />
      <path
        fillOpacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.28226 1.82188C7.72978 1.57793 8.27056 1.57793 8.71809 1.82188L13.2217 4.27678C13.7038 4.53957 14.0038 5.04475 14.0038 5.59382V10.4062C14.0038 10.9552 13.7038 11.4604 13.2217 11.7232L8.71809 14.1781C8.27056 14.4221 7.72978 14.4221 7.28226 14.1781L2.77866 11.7232C2.29657 11.4604 1.99658 10.9552 1.99658 10.4062V5.59382C1.99658 5.04475 2.29657 4.53957 2.77867 4.27678L7.28226 1.82188ZM8.23948 2.6999C8.0903 2.61859 7.91004 2.61859 7.76087 2.6999L3.44441 5.0528L6.16061 6.621C6.57996 6.06261 7.24768 5.70142 7.99976 5.70142C8.75214 5.70142 9.42009 6.0629 9.83941 6.62168L12.5564 5.05304L8.23948 2.6999ZM10.2519 7.5382C10.2824 7.68739 10.2983 7.84183 10.2983 8C10.2983 9.10756 9.515 10.0322 8.47212 10.25H8.5V13.1581L12.7431 10.8452C12.9038 10.7576 13.0038 10.5892 13.0038 10.4062V5.94944L10.2519 7.5382ZM7.52739 10.25C6.48452 10.0322 5.70117 9.10756 5.70117 8C5.70117 7.84152 5.71721 7.68679 5.74775 7.53734L2.99658 5.94895V10.4062C2.99658 10.5892 3.09658 10.7576 3.25728 10.8452L7.5 13.1579V10.25H7.52739ZM9.29834 8C9.29834 8.71719 8.71694 9.29858 7.99976 9.29858C7.28257 9.29858 6.70117 8.71719 6.70117 8C6.70117 7.28281 7.28257 6.70142 7.99976 6.70142C8.71694 6.70142 9.29834 7.28281 9.29834 8Z"
      />
    </svg>
  );
}

export function PageTreeIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12.5 5.5L9.5 2.5H4.5C3.948 2.5 3.5 2.948 3.5 3.5V12.5C3.5 13.052 3.948 13.5 4.5 13.5H11.5C12.052 13.5 12.5 13.052 12.5 12.5V5.5Z"
        fill="currentcolor"
        fillOpacity="0.125"
      />
      <path
        d="M12.5 5.5L9.5 2.5M12.5 5.5V12.5C12.5 13.052 12.052 13.5 11.5 13.5H4.5C3.948 13.5 3.5 13.052 3.5 12.5V3.5C3.5 2.948 3.948 2.5 4.5 2.5H9.5M12.5 5.5H9.5V2.5"
        stroke="currentcolor"
      />
    </svg>
  );
}
