export function SplitPaddingIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <rect x="4" y="1" width="8" height="1" fill="currentcolor" />
      <rect x="4" y="14" width="8" height="1" fill="currentcolor" />
      <rect x="1" y="4" width="1" height="8" fill="currentcolor" />
      <rect x="14" y="4" width="1" height="8" fill="currentcolor" />
      <rect x="5.5" y="5.5" width="5" height="5" stroke="currentcolor" />
    </svg>
  );
}
