import { useId } from 'react';

export function WarningIcon(props: React.ComponentProps<'svg'>) {
  const gradientId = useId();
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" {...props}>
      <path
        d="M8.44441 0.609101C8.01781 -0.106679 6.98121 -0.106679 6.5546 0.609102L0.16069 11.3371C-0.276312 12.0703 0.252015 13.0003 1.10559 13.0003H13.8934C14.747 13.0003 15.2753 12.0703 14.8383 11.3371L8.44441 0.609101Z"
        fill={`url('#${gradientId}')`}
      />
      <path
        d="M7.49978 3.78711C7.11761 3.78711 6.81199 4.10471 6.82668 4.48659L6.98056 8.48749C6.99129 8.76648 7.22058 8.98712 7.49978 8.98712C7.77898 8.98712 8.00826 8.76648 8.01899 8.48749L8.17288 4.4866C8.18756 4.10471 7.88195 3.78711 7.49978 3.78711Z"
        fill="black"
      />
      <path
        d="M7.5 11.2266C7.91421 11.2266 8.25 10.8908 8.25 10.4766C8.25 10.0624 7.91421 9.72656 7.5 9.72656C7.08578 9.72656 6.75 10.0624 6.75 10.4766C6.75 10.8908 7.08578 11.2266 7.5 11.2266Z"
        fill="black"
      />
      <defs>
        <radialGradient id={gradientId} cy="60%">
          <stop offset="0%" stopColor="#ffdd00" />
          <stop offset="50%" stopColor="#ffcc15" />
        </radialGradient>
      </defs>
    </svg>
  );
}
