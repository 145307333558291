import { action, runInAction } from 'mobx';
import { EditorState } from '../EditorState';
import { getImageInfo, ImageInfo } from './get-image-info';
import { TreeNode } from '../tree/TreeNode';
import { Vec2, Vec2Utils } from '@paper/models/src/math/vec2';
import { createTreeNodeData, TreeNodeType } from '@paper/models/src/file/tree-node-schema';
import { createMultipleSortOrderKeys } from '@paper/models/src/file/create-sort-order-key';
import { positionPastedNodes } from './position-pasted-nodes';
import { postImageToServer } from './post-image-to-server';
import { toast } from 'sonner';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

export async function createNewImageNodeFromFiles(
  editorState: EditorState,
  files: File[],
  positionAt: 'cursor' | 'selection'
) {
  // ------ Get image info, dimensions, DPI, etc------ //
  const imageFileNodePromises: Array<Promise<ImageInfo>> = [];
  for (const file of files) {
    if (file.type.includes('image')) {
      if (file.size > MAX_FILE_SIZE) {
        console.warn('Image file is too large, ignoring');
        toast.warning(`Upload file is too large: ${(file.size / (1024 * 1024)).toFixed(1)} MB`);
        continue;
      }

      // Image file
      imageFileNodePromises.push(getImageInfo(file));
    } else {
      // Not an image, ignore it
      continue;
    }
  }
  // Wait for all of our image info to be built
  const imageInfos = await Promise.all(imageFileNodePromises);

  // ------ Create the new nodes ------ //
  runInAction(() => {
    // Find where we should add the nodes
    let targetParentNode: TreeNode = editorState.treeUtils.rootNode;
    let pastePosition: Vec2;

    if (positionAt === 'cursor') {
      // Drag event
      const nodeUnderCursor = editorState.treeUtils.getNodeFromPoint(editorState.pointerState.cursorPosWorld);
      if (nodeUnderCursor) {
        if (nodeUnderCursor.canHaveChildren) {
          targetParentNode = nodeUnderCursor;
        } else if (nodeUnderCursor.container) {
          targetParentNode = nodeUnderCursor.container;
        }
      }

      pastePosition = Vec2Utils.subtract(editorState.pointerState.cursorPosWorld, {
        x: targetParentNode.xInWorld,
        y: targetParentNode.yInWorld,
      });
    } else {
      // Paste event
      const selectedNode = editorState.selectionState.selectedNodes[0];
      if (selectedNode && selectedNode.canHaveChildren) {
        targetParentNode = selectedNode;
      } else if (selectedNode && selectedNode.container) {
        targetParentNode = selectedNode.container;
      }

      if (targetParentNode.isRoot) {
        // Paste into the center of the viewport
        pastePosition = editorState.cameraState.center;
      } else {
        // Paste into the center of the target container
        pastePosition = {
          x: Math.round(targetParentNode.width / 2),
          y: Math.round(targetParentNode.height / 2),
        };
      }
    }

    // Create the new nodes
    const newNodesToAdd: Array<TreeNodeType> = [];
    for (let i = 0; i < imageInfos.length; i++) {
      const imageInfo = imageInfos[i]!;
      const newNodeData = createImageNodeData(imageInfo);
      newNodesToAdd.push(newNodeData);
    }

    // Arrange the nodes in a grid around the paste position
    positionPastedNodes(newNodesToAdd, pastePosition);

    // Add the new nodes to the tree
    const highestExistingSortKey = editorState.treeIndex.getHighestChildSortKeyForParent(targetParentNode.id);
    const sortKeys = createMultipleSortOrderKeys(imageInfos.length, highestExistingSortKey, null);

    for (let i = 0; i < newNodesToAdd.length; i++) {
      const newNodeData = newNodesToAdd[i]!;
      editorState.treeUtils.addNode(newNodeData, targetParentNode.id, sortKeys[i]!);
    }

    // Select the nodes that were created
    editorState.selectionState.selectIds(newNodesToAdd.map((node) => node.id));
  });

  // ------ Upload the images to the server ------ //
  for (const imageInfo of imageInfos) {
    postImageToServer(editorState, imageInfo.file).then(
      action((finalUrl: string | null) => {
        // File upload was successful, update the node
        if (finalUrl) {
          // We don't want an undo event to undo back to the temporary URL
          editorState.undoManager.startIgnoringChanges('update-image-node-url');

          const nodeData = editorState.fileState.data.nodes[imageInfo.threadedId];
          if (nodeData) {
            nodeData.props ??= {};
            nodeData.props.imageUrl = finalUrl;
          }

          editorState.undoManager.stopIgnoringChanges('update-image-node-url');
        } else {
          // File upload wasn't successful, rollback the node creation
          delete editorState.fileState.data.nodes[imageInfo.threadedId];
          toast.error('Error uploading image, please try again.');
        }

        // Regardless, clear memory from the temp URL
        URL.revokeObjectURL(imageInfo.tempImageUrl);
      })
    );
  }
}

function createImageNodeData(imageInfo: ImageInfo): TreeNodeType {
  const newNodeData = createTreeNodeData();
  // Use the assigned itemId as the nodeId so we can track it in other steps
  newNodeData.id = imageInfo.threadedId;
  newNodeData.label = 'Image';
  newNodeData.component = 'Image';
  newNodeData.props = { imageUrl: imageInfo.tempImageUrl };
  newNodeData.styles.width = imageInfo.width / imageInfo.pixelDensity;
  newNodeData.styles.height = imageInfo.height / imageInfo.pixelDensity;

  return newNodeData;
}
