import { Vec2 } from '@paper/models/src/math/vec2';
import { EditorState } from './EditorState';
import { UserFocusType } from '@paper/models/src/websocket/multiplayer-user';
import { action, computed, makeObservable, observable } from 'mobx';
import { TreeNode } from './tree/TreeNode';
import { isPointInRect } from '@paper/models/src/math/collision';

export class PointerState {
  constructor(public editorState: EditorState) {
    makeObservable(this);
  }

  /**
   * The current viewport position of the pointer
   * May change many times per frame so don't overuse observers
   */
  @observable accessor cursorPos: Vec2 = { x: 0, y: 0 };
  @action setCursorPos = (newX: number, newY: number) => {
    if (typeof newX !== 'number' || typeof newY !== 'number') {
      console.warn('Invalid cursor position. Expected numbers, got:', { newX, newY });
      return;
    }
    this.cursorPos.x = newX;
    this.cursorPos.y = newY;

    this.editorState.multiplayerState.sendCursorPos();
  };

  /** Whether the user's pointer is over the interface, canvas, or blurred in another window */
  @observable accessor focus: UserFocusType = UserFocusType.Blurred;
  @action setFocus = (focus: UserFocusType) => {
    if (focus === this.focus) return; // don't do anything if the focus hasn't changed

    this.focus = focus;
    this.editorState.multiplayerState.sendFocusChange();
  };

  /**
   * The current world position of the pointer
   * May change many times per frame so don't overuse observers
   */
  @computed({ keepAlive: true }) get cursorPosWorld(): Vec2 {
    // Deference camera pan so this will recalculate when the pan moves, possibly placing the cursor over a different node
    const worldPanX = this.editorState.cameraState.panRounded.x;
    const worldPanY = this.editorState.cameraState.panRounded.y;
    return this.editorState.cameraState.viewportToWorld(this.cursorPos, false); // don't round or we'll get bad results when zoomed in
  }

  /** The currently hovered TreeNode, does not apply any business logic like top level artboard filtering */
  @computed({ keepAlive: true }) get hoveredNode(): TreeNode | null {
    if (this.editorState.pageState.activePageId === null || this.focus !== UserFocusType.Canvas) {
      return null;
    }

    /** Adds viewport units to the bounds all all nodes to make hover easier */
    const padHoverBoundsBy = 4 * this.editorState.cameraState.scaleInverse;
    return this.editorState.treeUtils.getNodeFromPoint(this.cursorPosWorld, padHoverBoundsBy);
  }

  /** Whether the pointer is hovering over the selection bounds */
  @computed({ keepAlive: true }) get isHoveringSelectionBounds(): boolean {
    if (this.focus !== UserFocusType.Canvas || this.isHoveringHandle) {
      return false;
    }

    let isHoveringSelectionBounds = false;
    const selectionBoundsRect = this.editorState.selectionState.selectionBoundsRect;
    if (selectionBoundsRect !== null) {
      // Check for selection bounds hover if we're not over a resize handle
      isHoveringSelectionBounds = isPointInRect(this.cursorPosWorld, selectionBoundsRect);
    }
    return isHoveringSelectionBounds;
  }

  @observable accessor isHoveringHandle: string | null = null;
  @action setIsHoveringHandle = (handleId: string | null) => {
    if (this.isHoveringHandle !== handleId) {
      this.isHoveringHandle = handleId;
    }
  };

  /** If a handle is currently controlling what's happening with pointer input */
  @observable accessor isControlledByHandle: string | null = null;
  @action setIsControlledByHandle = (handleId: string | null) => {
    if (this.isControlledByHandle !== handleId) {
      this.isControlledByHandle = handleId;
    }
  };

  /** Returns true if the pointer is currently actively doing something (so don't switch tools or DO use autopan) */
  @computed get pointerIsBusy(): boolean {
    return (
      this.editorState.moveToolState.dragState.isDragging === true || // dragging
      this.editorState.moveToolState.selectionBrushState.isSelectBrushing === true || // selection brushing
      this.editorState.pointerState.isControlledByHandle !== null || // moving a handle or resizing
      this.editorState.drawToolState.isDrawing === true || // drawing
      this.editorState.textTool.isDrawingText === true || // drawing text
      this.editorState.zoomToolState.isDrawingZoomBrush === true // drawing a zoom brush
    );
  }
}
