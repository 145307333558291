import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { LineMeasurement, TextToolState } from './TextToolState';
import { assert } from '../../assert';
import { RectWithSize } from '@paper/models/src/math/rect';

// Constants at the top
const CURSOR_BLINK_TIME = 525;
const DEFAULT_CARET_COLOR = 'rgba(72, 72, 72, 1)';
const DEFAULT_LINE_HEIGHT = 16;

// Helper function to get line height
const getLineHeight = (styles: CSSStyleDeclaration): number => parseInt(styles.lineHeight) || DEFAULT_LINE_HEIGHT;

// Draw the caret, the blinking cursor that shows where the next character will be inserted
const drawCaret = (ctx: CanvasRenderingContext2D, textTool: TextToolState) => {
  if (
    !textTool.editingTextNode ||
    !textTool.isFocused ||
    textTool.textElem === null ||
    textTool.measurementsAreProcessing
  ) {
    return;
  }

  // Hide if there's a text selection
  if (textTool.anchorPosition !== textTool.caretPosition) {
    return;
  }

  const now = Date.now();
  if (now > textTool.lastCaretBlinkTime + CURSOR_BLINK_TIME) {
    textTool.lastCaretBlinkTime = now;
    textTool.caretIsBlinkedOn = !textTool.caretIsBlinkedOn;
  }

  if (!textTool.caretIsBlinkedOn) return;

  assert(textTool.caretLine, 'Unexpected: could not build caret position to draw');

  ctx.fillStyle = textTool.editingTextNode.styles.color ?? DEFAULT_CARET_COLOR;

  const nodeOffsetX = textTool.editingTextNode.xInWorld;
  const nodeOffsetY = textTool.editingTextNode.yInWorld;
  // When we draw, offset the xposition by 0.5 so it's centered on the edge of the range selection measurement
  // (otherwise it feels stuck to the next character instead of centered between them)
  const cameraState = textTool.editorState.cameraState;
  let caretWidth: number;
  if (cameraState.scale < 2) {
    // We want a 1px wide caret when around normal zoom, but let it be 2px when zooming in
    caretWidth = 1 / cameraState.scale;
  } else {
    caretWidth = 2 / cameraState.scale;
  }

  if (textTool.chars.length === 0) {
    // There's no text yet so draw the caret using the line info
    const x = nodeOffsetX + textTool.caretLine.rect.minX;
    const y = nodeOffsetY + textTool.caretLine.rect.minY;
    const height = textTool.caretLine.rect.height;
    ctx.fillRect(x, y, caretWidth, height);
    return;
  }

  // There is text, check if we're inserting after all text and the last character is a line break
  if (textTool.caretPosition >= textTool.chars.length && textTool.chars[textTool.chars.length - 1]?.code === 10) {
    // Get the line height from the DOM element's computed style
    const textElStyles = window.getComputedStyle(textTool.textElem);
    const lineHeight = getLineHeight(textElStyles);
    const textAlign = textElStyles.textAlign || 'left';
    // X is the start of the \n's line
    let x;
    if (textAlign === 'center') {
      // center
      x = nodeOffsetX + textTool.caretLine.rect.minX + textTool.caretLine.rect.width / 2;
    } else if (textAlign === 'right') {
      // right
      x = nodeOffsetX + textTool.caretLine.rect.maxX;
    } else {
      // left
      x = nodeOffsetX + textTool.caretLine.rect.minX;
    }
    const y = nodeOffsetY + textTool.caretLine.rect.minY + lineHeight;
    ctx.fillRect(x, y, caretWidth, lineHeight);
    return;
  }

  // Normal text case, draw the caret
  let y: number = nodeOffsetY + textTool.caretLine.rect.minY;
  let height: number = textTool.caretLine.rect.height;

  // Find x position of the caret
  let x: number;
  if (textTool.caretPosition >= textTool.chars.length) {
    // At the end of the text, use the last character's maxX position
    x = nodeOffsetX + textTool.chars[textTool.chars.length - 1]!.maxX;
  } else {
    // Otherwise, use the character's minX position
    x = nodeOffsetX + textTool.chars[textTool.caretPosition]!.minX;
  }

  ctx.fillRect(x, y, caretWidth, height);
};

// Draw the selection, the highlighted text that shows which text has been selected
const drawSelection = (ctx: CanvasRenderingContext2D, textTool: TextToolState) => {
  if (!textTool.editingTextNode || textTool.anchorPosition === textTool.caretPosition || textTool.lines.length === 0)
    return;

  // Figure out which caret comes first
  let startCaret: number;
  let endCaret: number;
  let startLine: LineMeasurement | null;
  let endLine: LineMeasurement | null;
  if (textTool.anchorPosition < textTool.caretPosition) {
    startCaret = textTool.anchorPosition;
    endCaret = textTool.caretPosition;
    startLine = textTool.anchorLine;
    endLine = textTool.caretLine;
  } else {
    startCaret = textTool.caretPosition;
    endCaret = textTool.anchorPosition;
    startLine = textTool.caretLine;
    endLine = textTool.anchorLine;
  }

  if (!startLine || !endLine) {
    console.warn('Unexpected: selection start and end lines not found');
    return;
  }

  // Build out the actual rects to draw:
  const rects: Array<RectWithSize> = [];

  for (let i = startLine.index; i <= endLine.index; i++) {
    const thisLine = textTool.lines[i]!;
    assert(thisLine);
    const lineRect: RectWithSize = { ...thisLine.rect };
    if (i === startLine.index) {
      // Move the minX to match the start character
      lineRect.minX = textTool.chars[startCaret]?.minX ?? lineRect.minX;
      lineRect.width = lineRect.maxX - lineRect.minX;
    }
    if (i === endLine.index) {
      // Move the maxX to match the end character
      lineRect.maxX = textTool.chars[endCaret]?.minX ?? thisLine.rect.maxX;
      lineRect.width = lineRect.maxX - lineRect.minX;
    }
    rects.push(lineRect);
  }

  // Draw the selection
  ctx.fillStyle = 'oklch(55% 45% 258 / 30%)';
  const nodeOffsetX = textTool.editingTextNode.xInWorld;
  const nodeOffsetY = textTool.editingTextNode.yInWorld;
  for (const rect of rects) {
    ctx.fillRect(rect.minX + nodeOffsetX, rect.minY + nodeOffsetY, rect.width, rect.height);
  }
};

export const TextCaretAndSelectionGraphics = () => {
  const editorState = useEditor();

  useEffect(() => {
    const { hudState, textTool } = editorState;

    const handleDrawSelection = (ctx: CanvasRenderingContext2D) => drawSelection(ctx, textTool);
    const handleDrawCaret = (ctx: CanvasRenderingContext2D) => drawCaret(ctx, textTool);

    hudState.worldDrawingFunctions.add(handleDrawSelection);
    hudState.worldDrawingFunctions.add(handleDrawCaret);

    return () => {
      hudState.worldDrawingFunctions.delete(handleDrawSelection);
      hudState.worldDrawingFunctions.delete(handleDrawCaret);
    };
  }, [editorState]);

  return null;
};
