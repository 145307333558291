import clsx from 'clsx';

interface TreeItemProps extends React.ComponentProps<'div'> {
  indent?: number;
  highContrast?: boolean;
  highlighted?: boolean;
  selected?: boolean;
  parentSelected?: boolean;
}

export const TREE_ITEM_INDENT_WIDTH = 16;

export function TreeItem({
  className,
  indent = 0,
  selected,
  highContrast,
  highlighted,
  parentSelected,
  ...props
}: TreeItemProps) {
  return (
    <div
      data-highlighted={highlighted ? '' : undefined}
      data-selected={selected ? '' : undefined}
      data-parent-selected={parentSelected ? '' : undefined}
      className={clsx('TreeItem', className)}
      {...props}
      style={{
        paddingLeft: TREE_ITEM_INDENT_WIDTH * indent || undefined,
        contentVisibility: 'auto',
        containIntrinsicSize: 'auto',
        ...props.style,
      }}
    />
  );
}
