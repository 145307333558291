import { assert } from '../assert';

// We'll keep our keys within the printable ASCII range to minimize potential errors in other systems
export const MIN_CHAR_CODE = 33; // '!'
export const MAX_CHAR_CODE = 126; // '~'
export const MIN_CHAR = String.fromCharCode(MIN_CHAR_CODE);
export const MAX_CHAR = String.fromCharCode(MAX_CHAR_CODE);
const DEFAULT_CHAR_CODE = Math.floor((MAX_CHAR_CODE + MIN_CHAR_CODE) / 2);

/**
 * Creates a sort order key roughly half way between two sibling keys
 * We use ASCII chars instead of numbers to give us way more possible values per digit
 * Sort keys are sortable, we can use string comparison to sort them
 */
export function createSortOrderKey(prevKey: string | null, nextKey: string | null): string {
  const prev = prevKey ?? MIN_CHAR;
  const next = nextKey ?? MAX_CHAR;
  if (prev >= next) {
    console.error('prevKey must be less than nextKey, returning same key', prevKey, nextKey);
    return prev;
  }

  let newKey = '';
  // Find the maximum length of the two keys
  const maxLength = Math.max(prev.length, next.length);

  // Build out the new key one character at a time
  for (let i = 0; i < maxLength; i++) {
    // Get the character code at position i for both keys
    // If the key is shorter than i, use MIN_CHAR_CODE for prev and MAX_CHAR_CODE for next
    const prevCode = prev.charCodeAt(i) || MIN_CHAR_CODE;
    const nextCode = next.charCodeAt(i) || MAX_CHAR_CODE;

    if (prevCode === nextCode) {
      // If the characters are the same, add this character to the new key and
      // continue to the next position
      newKey += String.fromCharCode(prevCode);
    } else {
      // If the characters differ, find the midpoint between them
      const midCode = Math.floor((prevCode + nextCode) / 2);
      // Add the character represented by the midpoint code to the new key
      newKey += String.fromCharCode(midCode);
      // If the midpoint is different from the previous character,
      // we've found a unique position and can return the new key
      if (midCode !== prevCode) {
        return newKey;
      }
      // If midCode === prevCode, we continue to the next iteration
      // This handles cases where the difference between prevCode and nextCode is 1
    }
  }

  // If we made it this far, we've exhausted all characters and need to add a new digit
  return newKey + String.fromCharCode(DEFAULT_CHAR_CODE);
}

/**
 * Generates multiple sort order keys, roughly evenly distributed between the given bounds.
 * @param keyCountNeeded The number of keys to generate.
 * @param lowerBoundKey The lower bound key (inclusive). Use null for the minimum possible key.
 * @param upperBoundKey The upper bound key (exclusive). Use null for the maximum possible key.
 * @returns An array of generated sort order keys.
 */
export function createMultipleSortOrderKeys(
  keyCountNeeded: number,
  lowerBoundKey: string | null,
  upperBoundKey: string | null
): string[] {
  if (keyCountNeeded <= 0) {
    return [];
  }
  const effectiveLower = lowerBoundKey ?? MIN_CHAR;
  const effectiveUpper = upperBoundKey ?? MAX_CHAR;
  if (effectiveLower >= effectiveUpper) {
    throw new Error('lowerBoundKey must be less than upperBoundKey');
  }

  const keys: string[] = [];
  // As we go, we'll add more jobs to process to the queue so we can evenly distribute the midpoints between keys we discover
  const queue: { prevKey: string; nextKey: string }[] = [{ prevKey: effectiveLower, nextKey: effectiveUpper }];

  while (keys.length < keyCountNeeded && queue.length > 0) {
    const { prevKey, nextKey } = queue.shift()!;

    // First find the midpoint between prevKey and nextKey
    const midPoint = createSortOrderKey(prevKey, nextKey);

    // Add the midpoint to the keys array
    keys.push(midPoint);

    // Add the next jobs to the queue
    if (keys.length < keyCountNeeded) {
      queue.push({ prevKey: midPoint, nextKey: nextKey });
      queue.push({ prevKey: prevKey, nextKey: midPoint });
    }
  }

  return keys.sort();
}
