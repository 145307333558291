import React from 'react';
import { observer } from 'mobx-react-lite';
import { SharePanel } from '../share-panel/share-panel';
import { useEditor } from '../editor-context';
import { FillProperty } from './fill-property';
import { LayoutProperties } from '../properties-layout/layout-properties';
import { PositionProperties } from './position-properties';
import { PropsPanel } from '../properties-props/props-panel';
import { CustomPanels } from './custom-panels';
import { TypographyProperties } from '../properties-typography/typography-properties';
import { NoSelectionProperties } from '../properties-no-selection/no-selection-properties';
import { OutlineProperty } from './outline-property';

export enum PropertyPanels {
  Position = 'Position',
  Layout = 'Layout',
  Fill = 'Fill',
  Typography = 'Typography',
  Outline = 'Outline',
}

export const PropertyPanel = observer(() => {
  const editorState = useEditor();
  const { selectionState, propertiesState } = editorState;

  return (
    <div className="PropertyPanel">
      <div className="py-1.5">
        <SharePanel />
      </div>

      <div className="border-bottom" />

      <div className="flex flex-col gap-1 py-1.5">
        {selectionState.selectedNodeIds.size === 0 ? (
          <NoSelectionProperties />
        ) : (
          // Selection change should unmount the inputs so that side effects run, like committing the value
          <React.Fragment key={selectionState.timestamp}>
            {propertiesState.nodesPerProperty[PropertyPanels.Position].length > 0 && <PositionProperties />}
            {propertiesState.nodesPerProperty[PropertyPanels.Layout].length > 0 && <LayoutProperties />}
            {propertiesState.nodesPerProperty[PropertyPanels.Typography].length > 0 && <TypographyProperties />}
            {propertiesState.nodesPerProperty[PropertyPanels.Fill].length > 0 && <FillProperty />}
            {propertiesState.nodesPerProperty[PropertyPanels.Outline].length > 0 && <OutlineProperty />}

            {/* Props from selected components */}
            <PropsPanel />

            {/* Display custom panels for the selected components */}
            <CustomPanels />
          </React.Fragment>
        )}
      </div>
    </div>
  );
});
