import { FileEditMessage } from '@paper/models/src/websocket/file-edit-message';
import { EditorState } from '../EditorState';
import { runInAction } from 'mobx';
import { assert } from '../../assert';
import { applyEdit } from '@paper/models/src/delta/apply-edit';

export function handleFileEditMessage(editorState: EditorState, message: FileEditMessage) {
  assert(
    message.fileId === editorState.fileState.data.id,
    `Unexpected: received edit for file that is not open: ${message.fileId}`
  );
  assert(editorState.fileState.fileDataObserver, 'Unexpected: no file data observer');

  // Pause local observer for file data since this is a remote edit
  const time = Date.now();
  editorState.fileState.fileDataObserver.pauseObserving(time.toString());

  // Apply the edit to the file
  applyEdit(editorState.fileState.data, message.edit);

  // Resume local observer for file data
  editorState.fileState.fileDataObserver.resumeObserving(time.toString());

  // If we move to an Event system, perhaps the HUD could subscribe to this instead of coding it here
  editorState.hudState.requestDraw();
}
