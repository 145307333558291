import { Font } from './FontState';

const appleStyleToWeight: Record<string, number> = {
  'Ultralight': 100,
  'Ultralight Italic': 100,
  'Thin': 200,
  'Thin Italic': 200,
  'Light': 300,
  'Light Italic': 300,
  'Regular': 400,
  'Regular Italic': 400,
  'Medium': 500,
  'Medium Italic': 500,
  'Semibold': 600,
  'Semibold Italic': 600,
  'Bold': 700,
  'Bold Italic': 700,
  'Heavy': 800,
  'Heavy Italic': 800,
  'Black': 900,
  'Black Italic': 900,
};

export const BUILT_IN_FONTS: Font[] = [];

for (const style in appleStyleToWeight) {
  BUILT_IN_FONTS.push({
    family: 'UI Sans-Serif',
    style,
    weight: appleStyleToWeight[style]!,
    isItalic: style.includes('Italic'),
  });
}

for (const style of ['Regular', 'Regular Italic', 'Bold', 'Bold Italic']) {
  BUILT_IN_FONTS.push({
    family: 'UI Monospace',
    style,
    weight: appleStyleToWeight[style]!,
    isItalic: style.includes('Italic'),
  });
}

export const DEFAULT_FONT = BUILT_IN_FONTS[6]!;
export const DEFAULT_FONT_CSS = {
  fontFamily: 'system-ui, sans-serif',
  fontWeight: 400,
};
