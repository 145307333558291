import {
  convertHslToRgb,
  convertLabToLch,
  convertLchToLab,
  convertOklabToRgb,
  convertP3ToXyz65,
  convertRgbToHsl,
  convertRgbToOklab,
  convertXyz65ToP3,
  convertXyz65ToRgb,
} from 'culori/fn';
import { ColorUtils } from './Color';
import type { Color, Hex, Hsl, Oklch } from './Color';
import type { Hsv, Lrgb, Oklab, P3, Rgb, Xyz65 } from 'culori/fn';
import { clamp } from '@paper/models/src/math/clamp';

export function convertOklchToP3(oklch: Oklch): P3 {
  return convertXyz65ToP3(convertOklabToXyz65(convertOklchToOklab(oklch)));
}

export const convertP3ToOklch = (p3: P3, defaultHue?: number): Oklch => {
  return convertOklabToOklch(convertRgbToOklab(convertXyz65ToRgb(convertP3ToXyz65(p3))), defaultHue);
};

export function convertOklchToOklab(oklch: Omit<Oklch, 'mode'>) {
  return convertLchToLab(oklch) as unknown as Oklab;
}

export function convertOklabToOklch(oklab: Omit<Oklab, 'mode'>, defaultHue?: number) {
  const oklch = convertLabToLch(oklab, 'oklch');
  oklch.h ??= defaultHue ?? 0;

  if (defaultHue !== undefined && defaultHue !== oklch.h) {
    const a: Color = { value: oklch as Oklch };
    const b: Color = { value: { ...oklch, h: defaultHue } };
    if (ColorUtils.isEqual(a, b)) {
      oklch.h = defaultHue;
    }
  }

  return oklch as Oklch;
}

const CHROMA_EDGE = 0.00001; // Effectively gray
const BLACK_EDGE = 0.0001; // Effectively black
const WHITE_EDGE = 0.9999; // Effectively white

export function convertOklchToHsl(oklch: Oklch): Hsl {
  const hsl = convertRgbToHsl(convertOklabToRgb(convertOklchToOklab(oklch)));

  // If grayscale, take the hue from the closest color with some chroma
  if (oklch.c === 0) {
    hsl.h = convertOklchToHsl({
      mode: 'oklch',
      l: clamp(oklch.l, BLACK_EDGE, WHITE_EDGE),
      c: clamp(oklch.c, CHROMA_EDGE, 1),
      h: oklch.h,
      alpha: oklch.alpha,
    }).h;
  }

  hsl.h ??= 0;
  return hsl as Hsl;
}

export function convertHslToOklch(hsl: Hsl): Oklch {
  const oklch = convertOklabToOklch(convertRgbToOklab(convertHslToRgb(hsl)));

  // Maintain a microsaturation and/or microlightness that doesn't affect actual rendering,
  // but maintains the hue during the conversions. The resulting OKLCH must roundtrip
  // OKLCH → HSL → OKLCH without loss of information.
  if (hsl.s === 0 || hsl.l === 0) {
    return convertHslToOklch({
      ...convertOklchToHsl({
        mode: 'oklch',
        l: clamp(oklch.l, BLACK_EDGE, WHITE_EDGE),
        c: clamp(oklch.c, CHROMA_EDGE, 1),
        h: 0,
      }),
      h: hsl.h,
      alpha: hsl.alpha,
    });
  }

  return oklch;
}

export function convertHslToHsv({ h = 0, s, l, alpha }: Hsl): Hsv {
  const v = l + s * Math.min(l, 1 - l);

  let s2 = s;
  if (v !== 0) {
    s2 = 2 * (1 - l / v);
  }

  const hsv: Hsv = { mode: 'hsv', h, s: s2, v, alpha };

  if (alpha !== undefined) {
    hsv.alpha = alpha;
  }

  return hsv;
}

export function convertHsvToHsl({ h = 0, s, v, alpha }: Hsv): Hsl {
  const l = v * (1 - s / 2);

  let s2 = 0;
  if (l !== 0 && l !== 1) {
    s2 = (v - l) / Math.min(l, 1 - l);
  } else if (l === 0) {
    s2 = s;
  }

  const hsl: Hsl = { mode: 'hsl', h, s: s2, l };

  if (alpha !== undefined) {
    hsl.alpha = alpha;
  }

  return hsl;
}

export function convertOklchToHsv(oklch: Oklch): Hsv {
  return convertHslToHsv(convertOklchToHsl(oklch));
}

export function convertHsvToOklch(hsv: Hsv): Oklch {
  return convertHslToOklch(convertHsvToHsl(hsv));
}

export function convertOklchToRgb(oklch: Oklch): Rgb {
  return convertOklabToRgb(convertOklchToOklab(oklch));
}

export function convertRgbToOklch(rgb: Rgb, defaultHue?: number): Oklch {
  return convertOklabToOklch(convertRgbToOklab(rgb), defaultHue);
}

export function convertOklchToHex(oklch: Oklch): Hex {
  return convertRgbToHex(convertOklchToRgb(oklch));
}

export function convertHexToOklch(hex: Hex, defaultHue?: number): Oklch {
  return convertRgbToOklch(convertHexToRgb(hex), defaultHue);
}

export function convertHexToRgb({ hex, alpha }: Hex): Rgb {
  return {
    mode: 'rgb',
    r: ((hex >> 16) & 0xff) / 255,
    g: ((hex >> 8) & 0xff) / 255,
    b: (hex & 0xff) / 255,
    alpha,
  };
}

export function convertRgbToHex({ r, g, b, alpha }: Rgb): Hex {
  const bits = [r, g, b].map((b) => convertNumberToHex(clamp(b * 255, 0, 255), 2));
  return {
    mode: 'hex',
    hex: parseInt(bits.join(''), 16),
    alpha,
  };
}

export function convertNumberToHex(number: number, length: number) {
  return Math.round(number).toString(16).toUpperCase().padStart(length, '0');
}

// Adapted from:
// https://www.w3.org/TR/css-color-4/#color-conversion-code
export function convertOklabToXyz65(oklab: Omit<Oklab, 'mode'>) {
  const { l, a, b } = oklab;

  const [L, M, S] = [
    (1.0 * l + 0.3963377773761749 * a + 0.2158037573099136 * b) ** 3,
    (1.0 * l + -0.1055613458156586 * a + -0.0638541728258133 * b) ** 3,
    (1.0 * l + -0.0894841775298119 * a + -1.2914855480194092 * b) ** 3,
  ];

  const xyz: Xyz65 = {
    mode: 'xyz65',
    x: 1.2268798758459243 * L + -0.5578149944602171 * M + 0.2813910456659647 * S,
    y: -0.0405757452148008 * L + 1.112286803280317 * M + -0.0717110580655164 * S,
    z: -0.0763729366746601 * L + -0.4214933324022432 * M + 1.5869240198367816 * S,
  };

  if (oklab.alpha !== undefined) {
    xyz.alpha = oklab.alpha;
  }

  return xyz;
}

interface LinearP3 {
  mode: 'lp3';
  r: number;
  g: number;
  b: number;
}

export function convertOklabToLinearP3({ l: L, a, b }: Omit<Oklab, 'mode'>): LinearP3 {
  // First convert OKLab to LMS using precise coefficients
  let l_ = L + 0.3963377773761749 * a + 0.2158037573099136 * b;
  let m_ = L - 0.1055613458156586 * a - 0.0638541728258133 * b;
  let s_ = L - 0.0894841775298119 * a - 1.2914855480194092 * b;

  // Non-linear transformation
  let l = l_ * l_ * l_;
  let m = m_ * m_ * m_;
  let s = s_ * s_ * s_;

  // Convert LMS directly to Linear P3 using official coefficients
  return {
    mode: 'lp3',
    r: 3.127768971361874 * l - 2.2571357625916395 * m + 0.12936679122976516 * s,
    g: -1.0910090184377979 * l + 2.413331710306922 * m - 0.32232269186912466 * s,
    b: -0.02601080193857028 * l - 0.508041331704167 * m + 1.5340521336427373 * s,
  };
}
