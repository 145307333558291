import { TreeNode } from '../tree/TreeNode';
import { useEditor } from '../editor-context';
import { observer } from 'mobx-react-lite';
import { NodeLabelInput } from './node-label-input';
import clsx from 'clsx';

interface NodeLabelProps extends React.ComponentProps<'div'> {
  node: TreeNode;
}

export const NodeLabel = observer(({ className, node, ...props }: NodeLabelProps) => {
  const { layerTreeState } = useEditor();
  const isEditing = layerTreeState.editingLabelNodeId === node.id;

  return (
    <div
      className={clsx('flex grow items-center', className)}
      onDoubleClick={() => layerTreeState.setEditingLabelNodeId(node.id)}
      {...props}
    >
      {isEditing ? <NodeLabelInput node={node} /> : node.label}
    </div>
  );
});
