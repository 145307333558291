/** The result of a style value where multiple selected nodes have different values */
export const MIXED_STYLE_VALUE = 'Mixed';

/**
 * - Reduces `SelectionValues` object to a single common string value, fast
 * - Produces `MIXED_STYLE_VALUE` when values are mixed
 * - Imposes `defaultValue` on undefined values
 *   (So that e.g. `borderRadius: "0px"` and `borderRadius: undefined` can produce "0px").
 */
type Values = Iterable<string | undefined>;
export function reducePropertyValues(values: Values, defaultValue: string): string;
export function reducePropertyValues(values: Values, defaultValue?: string): string | undefined;
export function reducePropertyValues(values: Values, defaultValue?: string) {
  let acc: string | undefined;

  for (const value of values) {
    const thisValue = value ?? defaultValue;

    if (acc === undefined) {
      acc = thisValue;
      continue;
    }

    // Bail as soon as we run into a mixed value
    if (acc !== thisValue) {
      return MIXED_STYLE_VALUE;
    }
  }

  // Empty iterable
  if (acc === undefined) {
    acc = defaultValue;
  }

  return acc;
}
