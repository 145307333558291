import { observer } from 'mobx-react-lite';
import { PropConfig } from '../built-in-ui/built-in-ui';
import { TreeNode } from '../tree/TreeNode';
import { PanelRow } from '../../components/panel';

export const PropsInputSwitch = observer(({ config, nodes }: { config: PropConfig; nodes: Array<TreeNode> }) => {
  const currentValue = nodes[0]!.props?.[config.propName] ?? config.defaultValue ?? false;

  const commitValue = (value: boolean) => {
    for (const node of nodes) {
      node.setProp(config.propName, value);
    }
  };

  return (
    <div>
      <div className="flex h-7 items-center pt-2 pl-5 text-[11px] font-medium">{config.label}</div>
      <PanelRow>
        <input type="checkbox" checked={currentValue} onChange={(e) => commitValue(e.target.checked)} />
      </PanelRow>
    </div>
  );
});
