import { observer } from 'mobx-react-lite';
import { TreeNode } from '../tree/TreeNode';
import { useEditor } from '../editor-context';
import { RectUtils } from '@paper/models/src/math/rect';
import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';
import { BoxTreeIcon } from '../../components/tree-icons';

export const RowIconButton = observer(({ node }: { node: TreeNode }) => {
  const editorState = useEditor();

  function handleDoubleClick(e: React.MouseEvent) {
    e.stopPropagation();
    editorState.cameraState.centerOnPoint(RectUtils.center(node.bounds), 1, true);
  }

  const TreeItemIcon = BuiltInComponentMap[node.component]?.layerTreeIcon ?? BoxTreeIcon;

  return (
    <span className="-mx-1 -my-2 flex px-1 py-2" onDoubleClick={handleDoubleClick}>
      <span className="text-gray-1 flex h-3 w-3 items-center justify-center opacity-60 *:shrink-0">
        <TreeItemIcon editorState={editorState} node={node} />
      </span>
    </span>
  );
});
