import { observer } from 'mobx-react-lite';
import { SERVICE_URI } from '../root/api-address';
import { useRoot } from '../root/root-context';

export function AuthButton() {
  return <a href={`${SERVICE_URI.API_URI}/auth/sign-in?final_redirect_uri=${location.href}`}>Login</a>;
}

export function LogoutButton() {
  return <a href={`${SERVICE_URI.API_URI}/auth/sign-out`}>Logout</a>;
}

export const Avatar = observer(() => {
  const { authState } = useRoot();

  if (!authState.profilePictureUrl) {
    return null;
  }

  return (
    <div
      className="h-10 w-10 rounded-full bg-gray-200"
      style={{
        backgroundImage: `url(${authState.profilePictureUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    ></div>
  );
});

export const AuthBar = observer(() => {
  const { authState } = useRoot();

  if (authState.assumeAuthenticatedInCosmeticUI === true) {
    return (
      <div className="flex flex-row gap-2 p-6">
        <LogoutButton />
        <Avatar />
      </div>
    );
  }
});
