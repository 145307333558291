import { TreeItem } from '../../components/tree-item';
import { observer } from 'mobx-react-lite';
import { TreeNode } from '../tree/TreeNode';
import { useEditor } from '../editor-context';
import { ExpandAndCollapseButton } from './expand-and-collapse-button';
import { NodeLabel } from './node-label';
import { RowIconButton } from './row-icon-button';

type Props = {
  node: TreeNode;
  indent: number;
  parentIsSelected: boolean;
};

export const LayerTreeRow = observer(({ node, indent, parentIsSelected }: Props) => {
  const { toolState, layerTreeState } = useEditor();

  return (
    <>
      <TreeItem
        data-node-id={node.id}
        highlighted={node.isHovered && toolState.shouldShowHoverHighlight}
        selected={node.isSelected}
        parentSelected={parentIsSelected}
        indent={indent}
      >
        <ExpandAndCollapseButton node={node} />
        <RowIconButton node={node} />
        <NodeLabel className="ml-2" node={node} />
      </TreeItem>

      {/* Only render children if the node is expanded */}
      {(layerTreeState.expandedNodeIds.has(node.id) ? node.children : []).map((child) => (
        <LayerTreeRow
          key={child.id}
          node={child}
          indent={indent + 1}
          parentIsSelected={node.isSelected || parentIsSelected}
        />
      ))}
    </>
  );
});
