import { roundOptimized } from '@paper/models/src/math/round-optimized';
import { formatAlpha, formatChannel } from './color-formatting';
import type { Rgb } from 'culori/fn';

export function getPrettyRgb({ r, g, b, alpha = 1 }: Rgb, explicitAlpha = true) {
  let str = [r, g, b].map((c) => formatChannel(c * 255, 0, 0, 255)).join(' ');

  if (explicitAlpha || roundOptimized(alpha, 3) !== 1) {
    str += ' / ' + formatAlpha(alpha * 100) + '%';
  }

  return str;
}

export function getCssStringRgb(rgb: Rgb) {
  return `rgb(${getPrettyRgb(rgb, false)})`;
}
