import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { SelectBrushGraphic } from './select-brush-graphic';
import { isPointInRect } from '@paper/models/src/math/collision';
import { Tool } from '../toolbar/ToolState';
import { DropTargetHighlight } from './drop-target-highlight';
import { DragGhosts } from './drag-ghosts';
import { cursorIsOverSelectableNode, CursorToNode } from './cursor-is-over-selectable-node';
import { Vec2Utils } from '@paper/models/src/math/vec2';
import { Vec2 } from '@paper/models/src/math/vec2';

/** How far a pointer needs to move with the pointer down to qualify as a drag/brush instead of a click */
export const CLICK_VS_DRAG_THRESHOLD_PX = 5;
/** The ms time we will assume a pointer down is a click, before switching to a drag or, if another pointerdown is within the window, treating another pointerdown as a double click */
export const DOUBLE_CLICK_TIME_MS = 500;

/**
 * Handles the Move Tool logic for pointerDown and then passes the ball to individual states after it determines the intent of the click
 * Requires a canvas overlay element so we can attach our pointer events
 */
export const MoveTool = observer(({ overlayEl }: { overlayEl: HTMLCanvasElement }) => {
  const editorState = useEditor();

  // Select and drag logic
  useEffect(() => {
    const { pointerState, keyState, selectionState, layerTreeState, moveToolState, textTool, toolState, cursorState } =
      editorState;

    let lastPointerDownWorldPos: Vec2 = { x: -Infinity, y: -Infinity };

    function onPointerDown(e: PointerEvent) {
      // For left clicks, defer to handles if one is hovered
      if (e.button === 0 && pointerState.isHoveringHandle) {
        return;
      }

      if (e.button === 2) return; // ignore right clicks

      lastPointerDownWorldPos = pointerState.cursorPosWorld;
      e.stopPropagation();
      e.preventDefault();

      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur(); // blur focused inputs
      }

      // ----- Setup for pointer down -----
      // Keep all pointer events flowing to the canvas until we release
      const pointerIdToRelease = e.pointerId;
      overlayEl.setPointerCapture(pointerIdToRelease);

      // Update the global cursor position (in case the user hasn't moved the pointer yet)
      pointerState.setCursorPos(e.clientX, e.clientY);

      // ----- Figure out what type of pointer down situation this is -----

      // Check for temporary panning
      if (e.button === 1 || e.button === 3 || e.button === 4) {
        // Wheel click or thumb clicks, pan the canvas while they drag
        moveToolState.quickPanState.startQuickPan(pointerIdToRelease);
        return;
      }

      // Check for hover
      const hoveredTreeNode = pointerState.hoveredNode;

      // Check for selection bounds
      // Pointer down inside selection bounds (not meaningful if metaKey is held, which will start a new brush)
      const selectionBoundsRect = selectionState.selectionBoundsRect;
      let pointerDownIsInSelectionBounds = false;
      if (selectionBoundsRect !== null && e.metaKey === false) {
        // We have a selection, check if the pointer is down inside the selection bounds
        pointerDownIsInSelectionBounds = isPointInRect(pointerState.cursorPosWorld, selectionBoundsRect);
      }

      // Figure out if the pointer is over a selectable node
      const isSelectableNode = cursorIsOverSelectableNode(keyState, selectionState, hoveredTreeNode);

      // ----- Intent specific behavior -----

      // Conditions for provisional selection brush
      // (using meta over an unselectable node which selects it... but then starting to drag a selection brush)
      if (isSelectableNode === CursorToNode.SelectableOnlyWithMetaModifier && hoveredTreeNode !== null) {
        selectionState.selectIds([hoveredTreeNode.id], { additive: e.shiftKey });
        layerTreeState.ensureNodesAreVisibleInLayerTree([hoveredTreeNode]);
        moveToolState.selectionBrushState.startProvisionalSelectionBrush(pointerIdToRelease);
        return;
      }

      // Conditions for selection brush
      if (isSelectableNode === CursorToNode.NotSelectable && pointerDownIsInSelectionBounds === false) {
        // Start the brush
        moveToolState.selectionBrushState.startProvisionalSelectionBrush(pointerIdToRelease, true);
        // Clear the selection if there's no shift key
        // IMPORTANT to do this after starting the brush for good undo/redo ordering
        if (e.shiftKey === false) {
          selectionState.selectIds([]);
        }
        return;
      }

      // Conditions for new single node selection and provisional drag
      if (
        isSelectableNode === CursorToNode.Selectable &&
        pointerDownIsInSelectionBounds === false &&
        hoveredTreeNode !== null
      ) {
        selectionState.selectIds([hoveredTreeNode.id], { additive: e.shiftKey });
        layerTreeState.ensureNodesAreVisibleInLayerTree([hoveredTreeNode]);
        moveToolState.dragState.startProvisionalDrag(false, pointerIdToRelease);
        return;
      }

      // Conditions for click within selection bounds, which could turn into a drag, a deselect, a single node select, or (if shift is held) removing a single node from the selection
      if (pointerDownIsInSelectionBounds) {
        // Start a provisional drag (pointerMove can upgrade to a real drag once it has moved far enough)
        moveToolState.dragState.startProvisionalDrag(true, pointerIdToRelease);
        return;
      }
    }

    /** Double click should set things to "auto" size */
    function onDoubleClick(e: MouseEvent) {
      if (pointerState.isHoveringHandle) return;
      if (e.button === 2) return; // ignore right clicks
      e.preventDefault(); // canvas should own pointer events fully
      e.stopPropagation(); // canvas should own pointer events fully

      // Sometimes you can click to select and then click again to drag, which shouldn't be counted as a double click
      // So if the new position is far from the original, just ignore this
      const distanceFromLastPointerDown = Vec2Utils.length(
        Vec2Utils.subtract(pointerState.cursorPosWorld, lastPointerDownWorldPos)
      );
      if (distanceFromLastPointerDown > CLICK_VS_DRAG_THRESHOLD_PX) return;

      const hoveredNode = pointerState.hoveredNode;
      if (hoveredNode && hoveredNode.canEditText) {
        const selectAll = true;
        toolState.setActiveTool(Tool.Text);
        textTool.startEditingTextNode(hoveredNode, selectAll);
      }
    }

    overlayEl.addEventListener('pointerdown', onPointerDown);
    overlayEl.addEventListener('dblclick', onDoubleClick);

    cursorState.setToolCursorClass('cursor-default');

    return () => {
      overlayEl.removeEventListener('pointerdown', onPointerDown);
      overlayEl.removeEventListener('dblclick', onDoubleClick);
      cursorState.setToolCursorClass(null);
    };
  }, [editorState, overlayEl]);

  return (
    <>
      <SelectBrushGraphic />
      <DropTargetHighlight />
      <DragGhosts />
    </>
  );
});
