import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { SELECTION_BOUNDS_COLOR } from '../move-tool/select-brush-graphic';

/**
 * Renders a box around the currently selected node
 */
export const SelectionHighlights = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { selectionState, cameraState, hudState, moveToolState } = editorState;

    function drawSelectionHighlight(ctx: CanvasRenderingContext2D) {
      // Don't do any work if nothing is selected
      if (selectionState.selectedNodes.length === 0) return;
      // Don't draw if we're dragging or temporarily hiding highlights
      if (moveToolState.dragState.isDragging || selectionState.hideHighlights) return;

      // Calculate line width and offset to make the line be on exact pixels
      // In this case we don't care about devicePixelRatio because we want as fine a line as possible
      const baseWidth = 1;
      const borderWidth = baseWidth / cameraState.scale;
      const halfOfBorderWidth = borderWidth / 2;

      // Spec for selection bounds:
      // 1px wide
      // outside of the node stroke
      // (the hover is 2px, centered)
      for (const node of selectionState.selectedNodes) {
        const boundsX = node.xInWorld;
        const boundsY = node.yInWorld;
        const boundsWidth = node.width;
        const boundsHeight = node.height;
        if (boundsWidth === 0 || boundsHeight === 0) {
          continue;
        }

        ctx.strokeStyle = SELECTION_BOUNDS_COLOR;
        ctx.lineWidth = borderWidth;
        ctx.strokeRect(
          boundsX - halfOfBorderWidth,
          boundsY - halfOfBorderWidth,
          boundsWidth + borderWidth,
          boundsHeight + borderWidth
        );
      }
    }

    hudState.worldDrawingFunctions.add(drawSelectionHighlight);
    return () => {
      hudState.worldDrawingFunctions.delete(drawSelectionHighlight);
    };
  }, [editorState]);

  return null;
});
