import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { NumberInput } from '../properties/number-input';
import { Field } from '../../components/field';
import { reducePropertyValues } from '../properties/reduce-property-values';
import { GhostIconButton } from '../../components/ghost-icon-button';
import { Tooltip } from '../../components/tooltip';
import { PaddingIcon } from '../../icons/padding-icon';
import { SplitPaddingIcon } from '../../icons/split-padding-icon';
import { PanelRow } from '../../components/panel';

export const PaddingProperty = observer(() => {
  const { propertiesState } = useEditor();

  const valuesInline = propertiesState.getStyleValues('paddingInline');
  const valuesBlock = propertiesState.getStyleValues('paddingBlock');

  const valuesLeft = propertiesState.getStyleValues('paddingLeft');
  const valuesRight = propertiesState.getStyleValues('paddingRight');
  const valuesTop = propertiesState.getStyleValues('paddingTop');
  const valuesBottom = propertiesState.getStyleValues('paddingBottom');

  // Show split padding controls if at least one node in the selection uses individual padding
  const isSplit =
    hasValue(valuesLeft.keys()) ||
    hasValue(valuesRight.keys()) ||
    hasValue(valuesTop.keys()) ||
    hasValue(valuesBottom.keys());

  if (isSplit) {
    // Current selection may include both individual and logical paddings
    // Retrieve individual paddings with logical paddings as fallbacks
    const valuesComputedLeft = propertiesState.getStyleValues('paddingLeft, paddingInline');
    const valuesComputedRight = propertiesState.getStyleValues('paddingRight, paddingInline');
    const valuesComputedTop = propertiesState.getStyleValues('paddingTop, paddingBlock');
    const valuesComputedBottom = propertiesState.getStyleValues('paddingBottom, paddingBlock');

    const inputValueLeft = reducePropertyValues(valuesComputedLeft.keys(), '0px');
    const inputValueRight = reducePropertyValues(valuesComputedRight.keys(), '0px');
    const inputValueTop = reducePropertyValues(valuesComputedTop.keys(), '0px');
    const inputValueBottom = reducePropertyValues(valuesComputedBottom.keys(), '0px');

    return (
      <>
        <PanelRow>
          <Field.Root>
            <Field.Icon>
              <PaddingIcon side="left" />
            </Field.Icon>
            <Field.Control>
              <NumberInput
                min={0}
                value={inputValueLeft}
                onValueCommit={(value) => {
                  for (const node of propertiesState.allNodes) {
                    if (node.layoutModeForChildren === 'flex') {
                      node.setStyle('paddingLeft', value);

                      // Clean up any inline padding, but don't mess up padding right
                      const paddingInline = node.styles.paddingInline;
                      const paddingRight = node.styles.paddingRight;
                      node.setStyle('paddingInline', undefined);
                      if (paddingInline !== undefined && paddingRight === undefined) {
                        node.setStyle('paddingRight', paddingInline);
                      }
                    }
                  }
                }}
              />
            </Field.Control>
          </Field.Root>

          <Field.Root>
            <Field.Icon>
              <PaddingIcon side="top" />
            </Field.Icon>
            <Field.Control>
              <NumberInput
                min={0}
                value={inputValueTop}
                onValueCommit={(value) => {
                  for (const node of propertiesState.allNodes) {
                    if (node.layoutModeForChildren === 'flex') {
                      node.setStyle('paddingTop', value);

                      // Clean up any block padding, but don't mess up padding bottom
                      const paddingBlock = node.styles.paddingBlock;
                      const paddingBottom = node.styles.paddingBottom;
                      node.setStyle('paddingBlock', undefined);
                      if (paddingBlock !== undefined && paddingBottom === undefined) {
                        node.setStyle('paddingBottom', paddingBlock);
                      }
                    }
                  }
                }}
              />
            </Field.Control>
          </Field.Root>

          <Tooltip.Root>
            <Tooltip.Trigger
              render={<GhostIconButton />}
              onClick={() => {
                let paddingInline: number | string;
                let paddingBlock: number | string;

                // Do two things:
                // - Use logical padding on all nodes
                // - Set the same padding on all nodes in the selection based on the first node we see
                for (const node of propertiesState.allNodes) {
                  if (node.layoutModeForChildren === 'flex') {
                    paddingInline ??= node.styles.paddingLeft ?? node.styles.paddingInline ?? 0;
                    paddingBlock ??= node.styles.paddingTop ?? node.styles.paddingBlock ?? 0;
                    node.setStyle('paddingInline', paddingInline);
                    node.setStyle('paddingBlock', paddingBlock);
                    node.setStyle('paddingLeft', undefined);
                    node.setStyle('paddingRight', undefined);
                    node.setStyle('paddingTop', undefined);
                    node.setStyle('paddingBottom', undefined);
                  }
                }
              }}
            >
              <SplitPaddingIcon />
            </Tooltip.Trigger>
            <Tooltip.Popup>Toggle individual padding</Tooltip.Popup>
          </Tooltip.Root>
        </PanelRow>

        <PanelRow>
          <Field.Root>
            <Field.Icon>
              <PaddingIcon side="right" />
            </Field.Icon>
            <Field.Control>
              <NumberInput
                min={0}
                value={inputValueRight}
                onValueCommit={(value) => {
                  for (const node of propertiesState.allNodes) {
                    if (node.layoutModeForChildren === 'flex') {
                      node.setStyle('paddingRight', value);

                      // Clean up any inline padding, but don't mess up padding left
                      const paddingInline = node.styles.paddingInline;
                      const paddingLeft = node.styles.paddingLeft;
                      node.setStyle('paddingInline', undefined);
                      if (paddingInline !== undefined && paddingLeft === undefined) {
                        node.setStyle('paddingLeft', paddingInline);
                      }
                    }
                  }
                }}
              />
            </Field.Control>
          </Field.Root>

          <Field.Root>
            <Field.Icon>
              <PaddingIcon side="bottom" />
            </Field.Icon>
            <Field.Control>
              <NumberInput
                min={0}
                value={inputValueBottom}
                onValueCommit={(value) => {
                  for (const node of propertiesState.allNodes) {
                    if (node.layoutModeForChildren === 'flex') {
                      node.setStyle('paddingBottom', value);

                      // Clean up any block padding, but don't mess up padding top
                      const paddingBlock = node.styles.paddingBlock;
                      const paddingTop = node.styles.paddingTop;
                      node.setStyle('paddingBlock', undefined);
                      if (paddingBlock !== undefined && paddingTop === undefined) {
                        node.setStyle('paddingTop', paddingBlock);
                      }
                    }
                  }
                }}
              />
            </Field.Control>
          </Field.Root>

          <span className="w-4" />
        </PanelRow>
      </>
    );
  }

  const inputValueInline = reducePropertyValues(valuesInline.keys(), '0px');
  const inputValueBlock = reducePropertyValues(valuesBlock.keys(), '0px');

  return (
    <PanelRow>
      <Field.Root>
        <Field.Icon>
          <PaddingIcon side="x" />
        </Field.Icon>
        <Field.Control>
          <NumberInput
            min={0}
            value={inputValueInline}
            onValueCommit={(value) => {
              for (const node of propertiesState.allNodes) {
                if (node.layoutModeForChildren === 'flex') {
                  node.setStyle('paddingInline', value);
                  node.setStyle('paddingLeft', undefined);
                  node.setStyle('paddingRight', undefined);
                }
              }
            }}
          />
        </Field.Control>
      </Field.Root>

      <Field.Root>
        <Field.Icon>
          <PaddingIcon side="y" />
        </Field.Icon>
        <Field.Control>
          <NumberInput
            min={0}
            value={inputValueBlock}
            onValueCommit={(value) => {
              for (const node of propertiesState.allNodes) {
                if (node.layoutModeForChildren === 'flex') {
                  node.setStyle('paddingBlock', value);
                  node.setStyle('paddingTop', undefined);
                  node.setStyle('paddingRight', undefined);
                }
              }
            }}
          />
        </Field.Control>
      </Field.Root>

      <Tooltip.Root>
        <Tooltip.Trigger
          render={<GhostIconButton />}
          onClick={() => {
            let paddingInline: number | string;
            let paddingBlock: number | string;

            // Do two things:
            // - Split paddings on all nodes
            // - Set the same padding on all nodes in the selection based on the first node we see
            for (const node of propertiesState.allNodes) {
              if (node.layoutModeForChildren === 'flex') {
                paddingInline ??= node.styles.paddingLeft ?? node.styles.paddingInline ?? 0;
                paddingBlock ??= node.styles.paddingTop ?? node.styles.paddingBlock ?? 0;
                node.setStyle('paddingLeft', paddingInline);
                node.setStyle('paddingRight', paddingInline);
                node.setStyle('paddingTop', paddingBlock);
                node.setStyle('paddingBottom', paddingBlock);
                node.setStyle('paddingInline', undefined);
                node.setStyle('paddingBlock', undefined);
              }
            }
          }}
        >
          <PaddingIcon />
        </Tooltip.Trigger>
        <Tooltip.Popup>Toggle individual padding</Tooltip.Popup>
      </Tooltip.Root>
    </PanelRow>
  );
});

function hasValue(values: Iterable<string | undefined>) {
  const arr = Array.from(values);
  if (arr.length === 1) {
    return arr[0] !== undefined;
  }

  return arr.length > 0;
}
