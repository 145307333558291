import { Edit } from '@paper/models/src/delta/edit';
import { EditorState } from '../EditorState';
import { applyEdit } from '@paper/models/src/delta/apply-edit';
import { action, makeObservable } from 'mobx';
import { buildRedo } from './build-redo';

/** Undo/redo instruction */
export type Instruction = {
  edits: Edit[];
  selectedIds?: string[];
  activePageId?: string;
};

/** A single undo/redo, can group multiple changes as patches */
interface UndoRedoEvent {
  undo: Instruction;
  redo?: Instruction;
}

export class UndoManager {
  constructor(private editorState: EditorState) {
    makeObservable(this);

    // Set up initial undo back to nothing selected
    this.addSelectionChange([]);
  }

  /** The number of events to keep in history */
  historyLength = 250;

  /** If changes are made within this time, they will be grouped into one Event and the debounce will start again */
  autoGroupDebounceMs = 250;

  private history: Array<UndoRedoEvent> = [];
  private historyIndex = 0;

  /** When not empty, incoming changes will be ignored and not added to undo/redo events */
  private ignoringChanges = new Set<string>();
  /** Begin ignoring changes, you need to provide a unique ID for this ignore request */
  startIgnoringChanges = (requestorId: string) => {
    this.ignoringChanges.add(requestorId);
  };
  /** Resume listening to changes, you need to provide the same requestor ID as when you started ignoring changes */
  stopIgnoringChanges = (requestorId: string) => {
    this.ignoringChanges.delete(requestorId);
  };

  /** Just stores who requested a manual grouping of undo events, used to warn for unexpected collisions */
  private groupRequestorId: string | null = null;
  /** Begin grouping changes, you need to provide a unique ID for this grouping request */
  startGroupingChanges = (requestorId: string) => {
    // Create a new blank provisional instruction
    this.provisionalInstruction = { edits: [] };
    this.groupRequestorId = requestorId;
  };
  /** Commits the provisional instruction we've built up if it has any changes and resumes auto-commiting changes, you need to provide the same requestor ID as when you started grouping changes */
  commitGroupedChanges = (requestorId: string) => {
    if (this.provisionalInstruction) {
      if (Object.keys(this.provisionalInstruction).length > 0) {
        // If we added anything to the provisional instruction, add it as a new event
        this.addNewUndoRedoEvent(this.provisionalInstruction);
      }
      this.provisionalInstruction = null;
    }

    // Make sure the requestor commiting the group is the same one that started it (just a sanity check or warning)
    if (this.groupRequestorId !== requestorId) {
      console.error(`Unexpected collision of undo grouping: original ${this.groupRequestorId}, new ${requestorId}`);
    }
    this.groupRequestorId = null;
  };
  /** A provisional instruction that is being built up until a commit is made */
  private provisionalInstruction: Instruction | null = null;

  @action
  undo = () => {
    if (this.historyIndex >= 0) {
      const event = this.history[this.historyIndex];
      if (!event) {
        console.warn('No event to undo, historyIndex:', this.historyIndex);
        return;
      }

      // ------ Apply the Undo ----- //
      const ignoreRequestorId = Date.now().toString();
      this.startIgnoringChanges(ignoreRequestorId);

      // Build the redo before we make changes to state
      event.redo = buildRedo(this.editorState.fileState, event.undo);

      // Apply the instructions from the event
      if (event.undo.edits) {
        // For undo we should work backwards through the patches so they apply in reverse order to how they were applied
        for (let i = event.undo.edits.length - 1; i >= 0; i--) {
          const patch = event.undo.edits[i] as Edit;
          applyEdit(this.editorState.fileState.data, patch);
        }
      }

      if (event.undo.activePageId) {
        this.editorState.pageState.setActivePage(event.undo.activePageId);
      }

      if (event.undo.selectedIds) {
        this.editorState.selectionState.selectIds(event.undo.selectedIds);
      }

      this.stopIgnoringChanges(ignoreRequestorId);
      // ----- End apply undo ----- //

      // Go back in history for the next undo
      this.historyIndex--;
      // Redraw the graphics after the undo
      this.editorState.hudState.requestDraw();
    }
  };

  @action
  redo = () => {
    if (this.historyIndex < this.history.length) {
      // Move forward one place in history
      const event = this.history[this.historyIndex + 1];
      if (!event || !event.redo) {
        return;
      }

      // ------ Apply the Redo ----- //
      const ignoreRequestorId = Date.now().toString();
      this.startIgnoringChanges(ignoreRequestorId);

      // Apply the instructions from the event
      if (event.redo.edits) {
        for (const patch of event.redo.edits) {
          applyEdit(this.editorState.fileState.data, patch);
        }
      }

      if (event.redo.activePageId) {
        this.editorState.pageState.setActivePage(event.redo.activePageId);
      }

      if (event.redo.selectedIds) {
        this.editorState.selectionState.selectIds(event.redo.selectedIds);
      }

      this.stopIgnoringChanges(ignoreRequestorId);
      // ----- End apply redo ----- //

      // Go forward in history for the next event
      this.historyIndex++;
      // Redraw the graphics after the redo
      this.editorState.hudState.requestDraw();
    }
  };

  /**
   * Creates and adds a new undo/redo event to the history with the given instruction.
   * If we're not currently at the front of history, will chop the timeline and start a new timeline from here.
   */
  private addNewUndoRedoEvent = (instruction: Instruction) => {
    // Branch into a new timeline by dropping any future redo HistoryEvents after the current index
    if (this.historyIndex < this.history.length - 1) {
      this.history.splice(this.historyIndex + 1);
    }
    // If we've reached the history limit, remove the oldest event
    if (this.historyIndex === this.historyLength) {
      this.history.shift();
      this.historyIndex--;
    }

    // Create the new event and add it to history
    const newEvent: UndoRedoEvent = {
      undo: instruction,
    };

    this.history.push(newEvent);
    this.historyIndex = this.history.length - 1;
  };

  /** Keeps track of the last time we wrote a new file event to the history so we can group edits together into the same event */
  private lastEventTime = 0;
  /**
   * Add an edit to the pending event
   * If transient is true, the edit will not be added to the history at all
   */
  addEdit(edit: Edit, transient?: boolean) {
    if (this.ignoringChanges.size > 0 || transient) {
      return;
    }

    // Update the last event time
    const timeSinceLastEvent = Date.now() - this.lastEventTime;
    this.lastEventTime = Date.now();

    // Manually grouping into a provisional instruction
    if (this.provisionalInstruction !== null) {
      // Add the edit to the provisional instruction
      this.provisionalInstruction.edits ??= [];
      this.provisionalInstruction.edits.push(edit);
      return;
    }

    // Auto-grouping into the existing event in history
    const newestEventInHistory = this.history[this.historyIndex];
    const shouldAutoGroupChanges = timeSinceLastEvent < this.autoGroupDebounceMs;
    if (shouldAutoGroupChanges && newestEventInHistory !== undefined) {
      // Group changes together by adding this edit to the existing event in history
      newestEventInHistory.undo.edits ??= [];
      newestEventInHistory.undo.edits.push(edit);
      return;
    }

    // If we made it this far, it's a new instruction in history
    const newInstruction: Instruction = {
      edits: [edit],
    };
    this.addNewUndoRedoEvent(newInstruction);
  }

  /** Add a selection change to the Event history */
  addSelectionChange(oldNodeIds: string[]) {
    if (this.ignoringChanges.size > 0) {
      return;
    }

    // Update the last event time
    const timeSinceLastEvent = Date.now() - this.lastEventTime;
    this.lastEventTime = Date.now();

    // Manually grouping into a provisional instruction
    if (this.provisionalInstruction !== null) {
      // Keep previously saved selectedIds and add the new ones to create a larger undo group
      const newSet = new Set([...(this.provisionalInstruction.selectedIds ?? []), ...oldNodeIds]);
      this.provisionalInstruction.selectedIds = [...newSet];
      return;
    }

    // Auto-grouping into the existing event in history
    const newestEventInHistory = this.history[this.historyIndex];
    const shouldAutoGroupChanges = timeSinceLastEvent < this.autoGroupDebounceMs;
    if (shouldAutoGroupChanges && newestEventInHistory !== undefined) {
      // Group changes together by adding this edit to the existing event in history
      newestEventInHistory.undo.selectedIds ??= [];
      newestEventInHistory.undo.selectedIds.push(...oldNodeIds);
      return;
    }

    // New instruction in history
    const newInstruction: Instruction = {
      edits: [],
      selectedIds: [...oldNodeIds],
    };
    this.addNewUndoRedoEvent(newInstruction);
  }

  /** Add a page change to the Event history */
  addPageChange(pageId: string) {
    if (this.ignoringChanges.size > 0) {
      return;
    }

    // Update the last event time
    const timeSinceLastEvent = Date.now() - this.lastEventTime;
    this.lastEventTime = Date.now();

    // Manually grouping into a provisional instruction
    if (this.provisionalInstruction !== null) {
      if (this.provisionalInstruction.activePageId) {
        // Do nothing if we already have a saved pageId in the provisional instruction - we will want to undo to the furthest page back in the group
        return;
      } else {
        this.provisionalInstruction.activePageId = pageId;
      }
      return;
    }

    // Auto-grouping into the existing event in history
    const newestEventInHistory = this.history[this.historyIndex];
    const shouldAutoGroupChanges = timeSinceLastEvent < this.autoGroupDebounceMs;
    if (shouldAutoGroupChanges && newestEventInHistory !== undefined) {
      // Group changes together by adding this edit to the existing event in history
      newestEventInHistory.undo.activePageId = pageId;
      return;
    }

    // New instruction in history
    const newInstruction: Instruction = {
      edits: [],
      activePageId: pageId,
    };
    this.addNewUndoRedoEvent(newInstruction);
  }
}
