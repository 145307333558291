import { TreeNode } from '../tree/TreeNode';
import { ComponentMeta } from './built-in-ui';
import { PropertyPanels } from '../properties/property-panel';
import { ColorUtils } from '@paper/models/src/colors/Color';
import { RectangleTreeIcon } from '../../components/tree-icons';

// Built in components
export const Rectangle = ({
  node,
  ref,
  style,
  children,
}: {
  node: TreeNode;
  ref: React.RefObject<HTMLDivElement>;
  style: React.CSSProperties;
  children: React.ReactNode;
}) => {
  return (
    <div
      ref={ref}
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const RectangleMeta: ComponentMeta = {
  label: 'Rectangle',
  component: Rectangle,
  canHaveChildren: false,
  props: {},
  defaultStyles: {
    backgroundColor: '#ddd',
  },
  defaultStyleMeta: {
    fills: [{ type: 'color', color: ColorUtils.new('dddddd')!, isVisible: true }],
  },
  propertyPanels: new Set([
    PropertyPanels.Position,
    PropertyPanels.Layout,
    PropertyPanels.Fill,
    PropertyPanels.Outline,
  ]),
  layerTreeIcon: RectangleTreeIcon,
};
