export function CrossIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        d="M4.46522 4.46447L8.00076 8M11.5363 11.5355L8.00076 8M8.00076 8L11.5363 4.46447M8.00076 8L4.46522 11.5355"
        fill="none"
        stroke="currentcolor"
      />
    </svg>
  );
}
