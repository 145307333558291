import { action, computed, makeObservable, observable } from 'mobx';
import { EditorState } from '../EditorState';
import { Vec2 } from '@paper/models/src/math/vec2';
import { Rect, RectUtils } from '@paper/models/src/math/rect';
import { clamp } from '@paper/models/src/math/clamp';
import { Size } from '@paper/models/src/math/size';
import { lerp } from '@paper/models/src/math/lerp';

/**
 * The zoom levels that the camera will snap to when using keyboard shortcuts
 * The scroll wheel will move smoothly and ignore snaps
 */
export const ZOOM_SNAPS = [0.02, 0.03, 0.06, 0.13, 0.25, 0.5, 1, 2, 4, 8, 16, 32, 64, 128, 256];
export const MIN_ZOOM = ZOOM_SNAPS[0]!;
export const MAX_ZOOM = ZOOM_SNAPS[ZOOM_SNAPS.length - 1]!;

export const CAMERA_LERP = 0.4;
export const CAMERA_ZOOM_WHEEL_SPEED = 0.01;
export const CAMERA_ZOOM_PINCH_SPEED = 0.04;
export const CAMERA_PAN_SPEED = 5;

export class CameraState {
  constructor(public editorState: EditorState) {
    makeObservable(this);

    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize();
  }
  dispose = () => {
    window.removeEventListener('resize', this.setWindowSize);
  };

  /** The current camera element */
  @observable accessor cameraEl: HTMLDivElement | null = null;
  @action setCameraEl = (el: HTMLDivElement) => {
    this.cameraEl = el;
  };

  targetPanX: number = 0;
  targetPanY: number = 0;
  targetScale: number = 1;

  /** Keeps the DOM camera updated to our current settings */
  updateCameraRaf = 0;
  requestCameraDraw = () => {
    // If we already queued an update, do nothing
    if (this.updateCameraRaf !== 0) return;

    // Let the HUD know the camera has moved and it needs to redraw next frame too
    this.editorState.hudState.requestDraw();

    // Cancel any existing animation frame
    cancelAnimationFrame(this.updateCameraRaf);
    // Update the camera transform before the next frame
    this.updateCameraRaf = requestAnimationFrame(this.updateCameraEl);
  };
  updateCameraEl = () => {
    if (!this.cameraEl) {
      // We're ahead of the DOM, keep RAFing until the DOM is ready
      this.updateCameraRaf = 0;
      this.requestCameraDraw();
      return;
    }

    const newPanX = this.panRounded.x;
    const newPanY = this.panRounded.y;
    const newScale = this.scale;
    this.cameraEl.style.transform = `matrix3d(${newScale}, 0, 0, 0, 0, ${newScale}, 0, 0, 0, 0, 1, 0, ${newPanX}, ${newPanY}, 0, 1)`;

    // If we're not at the target pan or scale, keep updating
    if (newPanX !== this.targetPanX || newPanY !== this.targetPanY || newScale !== this.targetScale) {
      this.requestCameraDraw();
    }
    // Note that this appears to not actually finish getting to the target, but it feels good as is

    // If we're past a certain zoom amount, we need to use nearest neighbor scaling
    if (this.scale > 1.5) {
      this.cameraEl.style.imageRendering = 'pixelated';
    } else {
      this.cameraEl.style.imageRendering = 'auto';
    }

    this.updateCameraRaf = 0;
  };

  /** Pan is measured in viewport coordinates */
  @observable accessor pan: Vec2 = { x: 0, y: 0 };
  /** Use this externally to render the camera or measure things against the current pan */
  @computed({ keepAlive: true }) get panRounded() {
    // Floor (or ceil) appears to give slightly less jitter when zooming than .round
    return { x: Math.floor(this.pan.x), y: Math.floor(this.pan.y) };
  }
  @action setPan = (newPanX: number, newPanY: number, skipLerp = false) => {
    if (typeof newPanX === 'number' && Number.isNaN(newPanX) === false) {
      this.targetPanX = newPanX;
      if (skipLerp) {
        this.pan.x = newPanX;
      } else {
        this.pan.x = lerp(this.pan.x, newPanX, CAMERA_LERP);
      }
    }
    if (typeof newPanY === 'number' && Number.isNaN(newPanY) === false) {
      this.targetPanY = newPanY;
      if (skipLerp) {
        this.pan.y = newPanY;
      } else {
        this.pan.y = lerp(this.pan.y, newPanY, CAMERA_LERP);
      }
    }

    this.requestCameraDraw();
  };

  /** The zoom level of the camera */
  @observable accessor scale: number = 1;
  @action setScale = (newScale: number, skipLerp = false) => {
    if (typeof newScale === 'number' && Number.isNaN(newScale) === false) {
      this.targetScale = clamp(newScale, MIN_ZOOM, MAX_ZOOM);
      if (skipLerp) {
        this.scale = this.targetScale;
      } else {
        this.scale = lerp(this.scale, this.targetScale, CAMERA_LERP);
      }
    }

    this.requestCameraDraw();
  };

  /** Resets the zoom to 1 while staying centered at its current pan */
  @action resetZoom = () => {
    this.zoomTowardPoint(1, this.center, true);
  };

  /** Use this to incrementally zoom toward a specific world point */
  @action zoomTowardPoint = (desiredZoom: number, point: Vec2, skipLerpRequested = false) => {
    let newZoom = clamp(desiredZoom, MIN_ZOOM, MAX_ZOOM);

    let skipLerp = skipLerpRequested;
    if (MAX_ZOOM - newZoom < 0.5) {
      // 255.5+ should round up to 256 to avoid wheel/gesture zoom having a million tiny steps at the end
      newZoom = MAX_ZOOM;
      skipLerp = true;
    }

    const panAdjustment = newZoom - this.scale;

    // Adjust the pan to zoom toward the specified point
    this.setPan(this.pan.x - panAdjustment * point.x, this.pan.y - panAdjustment * point.y, skipLerp);
    this.setScale(newZoom, skipLerp);
  };

  /** Zooms and pans to show the specified world rectangle */
  @action zoomToFit = (worldRect: Rect, viewportPadding: number = 50) => {
    const viewport = this.currentViewableAreaViewport;

    /** The area we need to fit content into, basically the visible viewport minus padding */
    const sizeToFitInto: Size = {
      width: viewport.maxX - viewport.minX - viewportPadding * 2,
      height: viewport.maxY - viewport.minY - viewportPadding * 2,
    };

    const contentSize: Size = {
      width: worldRect.maxX - worldRect.minX,
      height: worldRect.maxY - worldRect.minY,
    };
    if (contentSize.width === 0 || contentSize.height === 0) {
      // Bail if there's no content to zoom to
      return;
    }

    /** Find the zoom we'd need to fit the content on each axis */
    const neededZoomToFit = {
      x: sizeToFitInto.width / contentSize.width,
      y: sizeToFitInto.height / contentSize.height,
    };

    // Scale based on the smaller ratio, meaning the other will also fit inside the space
    const smallestZoomNeeded = Math.abs(Math.min(neededZoomToFit.x, neededZoomToFit.y));

    // Adjust the zoom and center on the middle of the content
    this.centerOnPoint(RectUtils.center(worldRect), smallestZoomNeeded, true);
  };

  /** The opposite of the zoom, useful to cancel zoom out for elements that should stay the same size */
  @computed({ keepAlive: true }) get scaleInverse() {
    return 1 / this.scale;
  }

  @observable accessor windowWidth: number = 0;
  @observable accessor windowHeight: number = 0;
  @action setWindowSize = () => {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  };

  @observable accessor lastSettingsPerPage: Partial<Record<string, { pan: Vec2; zoom: number }>> = {};
  @action stashSettingsForPage = (pageId: string) => {
    this.lastSettingsPerPage[pageId] = { pan: { ...this.pan }, zoom: this.scale };
  };
  @action restoreSettingsForPage = (pageId: string) => {
    const lastSettings = this.lastSettingsPerPage[pageId];
    if (lastSettings) {
      this.setPan(lastSettings.pan.x, lastSettings.pan.y, true);
      this.setScale(lastSettings.zoom, true);
    } else {
      // Zoom out to the fit the content of the page, but don't zoom in more than 1x
      this.zoomToFit(this.editorState.treeUtils.totalBounds);
      if (this.scale > 1) {
        this.resetZoom();
      }
    }
  };

  /**
   * Returns the bounds of the viewport space that is canvas
   * TODO: in the future, remove interface elements from this calculation
   */
  @computed get currentViewableAreaViewport(): Rect {
    return { minX: 0, minY: 0, maxX: this.windowWidth, maxY: this.windowHeight };
  }

  /**
   * Returns the bounds of the world space that is currently viewable
   */
  @computed({ keepAlive: true }) get currentViewableAreaWorld(): Rect {
    const topLeft = this.viewportToWorld({ x: 0, y: 0 }, false);
    const bottomRight = this.viewportToWorld({ x: this.windowWidth, y: this.windowHeight }, false);
    return { minX: topLeft.x, minY: topLeft.y, maxX: bottomRight.x, maxY: bottomRight.y };
  }

  /**
   * Takes a window viewport point and converts it to a world point
   */
  viewportToWorld = (point: Vec2, rounded = true): Vec2 => {
    if (rounded) {
      return { x: Math.round((point.x - this.pan.x) / this.scale), y: Math.round((point.y - this.pan.y) / this.scale) };
    } else {
      return { x: (point.x - this.pan.x) / this.scale, y: (point.y - this.pan.y) / this.scale };
    }
  };

  /** Takes a world point and converts it to a window viewport point */
  worldToViewport = (point: Vec2): Vec2 => {
    return { x: point.x * this.scale + this.pan.x, y: point.y * this.scale + this.pan.y };
  };

  /**
   * Converts a viewport rectangle to a world rectangle
   * This is useful for getting the bounds of a viewport rectangle in world coordinates
   */
  viewportToWorldRect = (rect: Rect, rounded = true): Rect => {
    const topLeft = this.viewportToWorld({ x: rect.minX, y: rect.minY }, rounded);
    const bottomRight = this.viewportToWorld({ x: rect.maxX, y: rect.maxY }, rounded);
    return {
      minX: topLeft.x,
      minY: topLeft.y,
      maxX: bottomRight.x,
      maxY: bottomRight.y,
    };
  };

  /**
   * Converts a world rectangle to a viewport rectangle
   * This is useful for getting the bounds of a world rectangle in viewport coordinates
   */
  worldToViewportRect = (rect: Rect): Rect => {
    const topLeft = this.worldToViewport({ x: rect.minX, y: rect.minY });
    const bottomRight = this.worldToViewport({ x: rect.maxX, y: rect.maxY });
    return {
      minX: topLeft.x,
      minY: topLeft.y,
      maxX: bottomRight.x,
      maxY: bottomRight.y,
    };
  };

  /** The current center of the camera based on screen size and interface elements in world coordinates */
  @computed get center(): Vec2 {
    // TODO: Include interface elements in this calculation once they exist
    return this.viewportToWorld({ x: this.windowWidth / 2, y: this.windowHeight / 2 }, false);
  }

  /** Centers the camera on the specified point in world coordinates */
  @action centerOnPoint(point: Vec2, scaleRequested: number, skipLerp = false) {
    const newScale = clamp(scaleRequested, MIN_ZOOM, MAX_ZOOM);

    // The center of the viewport
    const halfViewportWidth = this.windowWidth / 2;
    const halfViewportHeight = this.windowHeight / 2;

    // Figure out how much we need to adjust the pan to keep the point centered after zooming
    const adjustedPanAtNewScaleX = -point.x * newScale;
    const adjustedPanAtNewScaleY = -point.y * newScale;

    this.setPan(halfViewportWidth + adjustedPanAtNewScaleX, halfViewportHeight + adjustedPanAtNewScaleY, skipLerp);
    this.setScale(newScale, skipLerp);
  }

  /**
   * Moves the pan the minimal amount possible to make sure a given rect is fully enclosed in the view
   * Attempts not to adjust the zoom scale, but will call zoomToFit if the rect doesn't fit in the current view
   */
  @action movePanToEnsureRectInView = (worldRect: Rect, viewportPadding: number = 30) => {
    const viewport = this.currentViewableAreaViewport;
    const bounds = this.worldToViewportRect(worldRect);

    // If any of these are negative, we need to move the pan by that amount to bring it into view
    const leftEdgeToRectMinX = bounds.minX - (viewport.minX - viewportPadding);
    const rightEdgeToRectMaxX = viewport.maxX - (bounds.maxX + viewportPadding);
    const topEdgeToRectMinY = bounds.minY - (viewport.minY - viewportPadding);
    const bottomEdgeToRectMaxY = viewport.maxY - (bounds.maxY + viewportPadding);

    // Check if we don't need to do anything
    if (leftEdgeToRectMinX > 0 && rightEdgeToRectMaxX > 0 && topEdgeToRectMinY > 0 && bottomEdgeToRectMaxY > 0) {
      // Already in view
      return;
    }

    // Check if it won't fit at all without adjust the scale (and then do that instead)
    const willNotFitX = leftEdgeToRectMinX < 0 && rightEdgeToRectMaxX < 0;
    const willNotFitY = topEdgeToRectMinY < 0 && bottomEdgeToRectMaxY < 0;
    if (willNotFitX || willNotFitY) {
      // If both of the same axis are negative, we'll need to adjust the scale of the camera too, which we'll outsource to "zoomToFit"
      this.zoomToFit(worldRect);
      return;
    }

    // Otherwise, we need to move the pan
    const panAdjust = { x: 0, y: 0 };
    // Math.min will give us the negative number, but don't go positive if no adjustment is needed on that axis
    panAdjust.x = Math.min(Math.min(leftEdgeToRectMinX, rightEdgeToRectMaxX), 0);
    panAdjust.y = Math.min(Math.min(topEdgeToRectMinY, bottomEdgeToRectMaxY), 0);

    this.setPan(this.pan.x + panAdjust.x, this.pan.y + panAdjust.y, true);
  };

  @action zoomIn = (pointInWorld?: Vec2) => {
    const nextSnapIndex = ZOOM_SNAPS.findIndex((zoom) => zoom > this.scale);
    const nextZoom = ZOOM_SNAPS[nextSnapIndex] ?? MAX_ZOOM;
    this.zoomTowardPoint(nextZoom, pointInWorld ?? this.center, true);
  };

  @action zoomOut = (pointInWorld?: Vec2) => {
    const nextSnapIndex = ZOOM_SNAPS.findLastIndex((zoom) => zoom < this.scale);
    const nextZoom = ZOOM_SNAPS[nextSnapIndex] ?? MIN_ZOOM;
    this.zoomTowardPoint(nextZoom, pointInWorld ?? this.center, true);
  };
}
