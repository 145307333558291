import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useEditor } from '../editor-context';
import { CAMERA_PAN_SPEED, CAMERA_ZOOM_PINCH_SPEED, CAMERA_ZOOM_WHEEL_SPEED } from './CameraState';
import { useAutoPan } from './auto-pan';

export const Camera = observer(function Camera({
  children,
  hudOverlay,
}: {
  children: React.ReactNode;
  hudOverlay: React.ReactNode;
}) {
  const editorState = useEditor();
  const { cameraState, keyState } = editorState;

  /** Wraps both the camera and overlay, useful for camera interactions like pan/zoom */
  const cameraAndOverlayWrapperRef = useRef<HTMLDivElement>(null);

  /** The camera ref is adjusted to match the zoom/pan of the camera */
  const cameraRef = useRef<HTMLDivElement>(null);

  useAutoPan(editorState);

  // Scroll wheel interaction
  useEffect(() => {
    const wrapperEl = cameraAndOverlayWrapperRef.current;
    const cameraEl = cameraRef.current;
    if (!wrapperEl || !cameraEl) return;
    // Register the camera el with CameraState
    cameraState.setCameraEl(cameraEl);

    // We keep track of the previous wheel info so we can tell if we're continuing a scroll or starting a new one
    const millisecondsToContinueScroll = 36;
    let lastWheelTimestamp = 0;
    let lastWheelFunction: typeof handlePan | typeof handleZoom;

    const rollingAveragePanLength = 4;
    let lastNPansX: Array<number> = [];
    let lastNPansY: Array<number> = [];

    function handleWheel(e: WheelEvent) {
      // Prevent scroll wheel from scrolling the page
      e.preventDefault();

      // Some browsers send "pinch" as a wheel event with ctrl set to true – we can test by seeing if ctrl isn't actually down
      const isPinch = e.ctrlKey && keyState.isCtrlDown === false;

      // Check if we're in the long tail of a smooth scroll gesture
      const now = Date.now();
      const timeSinceLastWheel = now - lastWheelTimestamp;

      // If it's very close to the last wheel event, we continue the same function
      const continueLastFunction = timeSinceLastWheel < millisecondsToContinueScroll;
      if (continueLastFunction) {
        // We're in the long tail of a smooth scroll gesture, continue with the previous function we used
        lastWheelFunction(e, isPinch);
      } else if (e.metaKey || e.ctrlKey || isPinch) {
        // New wheel event while holding meta/ctrl or a pinch: zoom
        handleZoom(e, isPinch);
        lastWheelFunction = handleZoom;
      } else {
        // New wheel event while not holding meta/ctrl: pan
        lastNPansX = [];
        lastNPansY = [];
        handlePan(e, isPinch);
        lastWheelFunction = handlePan;
      }

      // Update caches for next time
      lastWheelTimestamp = now;
    }

    const maximumPanDelta = 33; // consider making this per frame, I think some frames can double up
    function handlePan(e: WheelEvent, _isPinch: boolean) {
      // Clamp newXRequested to -30 or 30, or closer to 0
      const clampedDeltaX = Math.max(-maximumPanDelta, Math.min(maximumPanDelta, -e.deltaX));
      const clampedDeltaY = Math.max(-maximumPanDelta, Math.min(maximumPanDelta, -e.deltaY));

      // We build a rolling average of the last N pan events to smooth out the pan
      if (lastNPansX.length > rollingAveragePanLength) {
        lastNPansX.length = rollingAveragePanLength;
      }
      lastNPansX.unshift(clampedDeltaX);
      const avgX = lastNPansX.reduce((a, b) => a + b, 0) / lastNPansX.length;

      if (lastNPansY.length > rollingAveragePanLength) {
        lastNPansY.length = rollingAveragePanLength;
      }
      lastNPansY.unshift(clampedDeltaY);
      const avgY = lastNPansY.reduce((a, b) => a + b, 0) / lastNPansY.length;

      const newPanX = cameraState.pan.x + avgX * CAMERA_PAN_SPEED;
      const newPanY = cameraState.pan.y + avgY * CAMERA_PAN_SPEED;
      cameraState.setPan(newPanX, newPanY);
    }

    function handleZoom(e: WheelEvent, isPinch: boolean) {
      const zoomSpeed = isPinch ? CAMERA_ZOOM_PINCH_SPEED : CAMERA_ZOOM_WHEEL_SPEED;

      /** We multiply the zoom speed by the inverse of the current scale to give a non-linear zoom that feels good */
      const newZoom = cameraState.scale + -e.deltaY * zoomSpeed * (cameraState.scale / 1);
      const newOrigin = cameraState.viewportToWorld({ x: e.clientX, y: e.clientY }, false);

      cameraState.zoomTowardPoint(newZoom, newOrigin);
    }

    function handleGestureZoom(e: { clientX: number; clientY: number; scale: number }) {
      const newScaleRaw = scaleAtStartOfGesture * -e.scale;
      // Boost the zoom increasingly more as we get further from the zoom start to get a non-linear zoom that feels good
      // the constant (2.25) just increases the amount of increase as we get further from the starting zoom
      let zoomMultiplier = newScaleRaw / scaleAtStartOfGesture;
      const newScaleBoosted = newScaleRaw * zoomMultiplier;
      const newOrigin = cameraState.viewportToWorld({ x: e.clientX, y: e.clientY }, false);
      cameraState.zoomTowardPoint(newScaleBoosted, newOrigin);
    }

    let scaleAtStartOfGesture: number = 1;
    function handleGestureStart(e: any) {
      e.preventDefault();
      scaleAtStartOfGesture = cameraState.targetScale;
    }
    function handleGestureChange(e: any) {
      e.preventDefault();
      handleGestureZoom(e);
    }

    wrapperEl.addEventListener('wheel', handleWheel, { passive: false });
    wrapperEl.addEventListener('gesturestart', handleGestureStart, { passive: false });
    wrapperEl.addEventListener('gesturechange', handleGestureChange, { passive: false });

    return () => {
      wrapperEl.removeEventListener('wheel', handleWheel);
      wrapperEl.removeEventListener('gesturestart', handleGestureStart);
      wrapperEl.removeEventListener('gesturechange', handleGestureChange);
    };
  }, [cameraState, keyState]);

  return (
    <div ref={cameraAndOverlayWrapperRef} style={{ width: '100%', height: '100%', contain: 'strict' }}>
      <div
        ref={cameraRef}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative', // new stacking context for children
          transformOrigin: 'top left',
          backfaceVisibility: 'hidden', // performance optimization
          pointerEvents: 'none', // prevent pointer events from being propagated to children
          userSelect: 'none', // prevent text selection
          // willChange: 'transform', // don't use will-change or it will cause blurry zooming caching
        }}
      >
        {children}
      </div>

      {hudOverlay}
    </div>
  );
});
