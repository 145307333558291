import { action, computed, makeObservable, observable } from 'mobx';
import { Root } from '../root/Root';
import { User, UserSchema } from '@paper/models/src/auth/user';
import { SERVICE_URI } from '../root/api-address';
import { Value } from '@sinclair/typebox/value';
import { informLogRocketOfUser } from '../logrocket';
import { parseCookies } from './get-cookie';
import {
  CosmeticPreauthUserInfoCookieName,
  type CosmeticPreauthUserInfoCookie,
  CosmeticPreauthUserInfoCookieSchema,
} from '@paper/models/src/auth/cosmetic-preauth-user-info-cookie';

/**
 * Every 3 minutes we refresh the access token ahead of needing it
 * The AuthKit setting is 5 minute access token expiration
 */
const REFRESH_INTERVAL_MS = 1000 * 60 * 3;

export class AuthState {
  constructor(public readonly root: Root) {
    makeObservable(this);

    // See if we have cosmetic user info stored in a cookie so we can display an avatar instantly
    this.loadCosmeticUserInfo();

    // To get to this app you should be signed in, so just check immediately against the /me endpoint
    // If it fails it will redirect to the sign in page
    this.loadUserInfo();

    // Start a timer to refresh the access token ahead of needing it
    this.refreshIntervalId = window.setInterval(this.refreshSession, REFRESH_INTERVAL_MS);
  }

  dispose = () => {
    this.setUserInfo(null);

    if (this.refreshIntervalId) {
      window.clearInterval(this.refreshIntervalId);
      this.refreshIntervalId = null;
    }
  };

  refreshIntervalId: number | null = null;
  refreshSession = async () => {
    const response = await fetch(`${SERVICE_URI.API_URI}/auth/refresh`, {
      credentials: 'include',
    });

    if (response.status !== 200) {
      // Could not refresh access token, redirect to sign in
      console.warn('Could not refresh access token, redirecting to sign in');
      window.location.href = SERVICE_URI.API_URI + '/auth/sign-in?final_redirect_uri=' + window.location.href;
    }

    // Update the user info if it has changed
    const data = await response.json();
    this.setUserInfo(data.user);
  };

  @computed get isAuthenticated() {
    return this.user !== null;
  }

  /** Whether the UI should show a logged in state before the /me route validates auth state or not */
  @computed get assumeAuthenticatedInCosmeticUI(): boolean {
    return Boolean(this.cosmeticUserInfo || this.user);
  }

  @computed get email(): string | null {
    return this.user?.email || null;
  }

  @computed get firstName(): string | null {
    return this.user?.firstName || this.cosmeticUserInfo?.firstName || null;
  }

  @computed get lastName(): string | null {
    return this.user?.lastName || this.cosmeticUserInfo?.lastName || null;
  }

  @computed get profilePictureUrl(): string | null {
    return this.user?.profilePictureUrl ?? this.cosmeticUserInfo?.profilePictureUrl ?? null;
  }

  @computed get userId(): string | null {
    return this.user?.id || null;
  }

  // ----- Cosmetic pre-auth user info ----- //
  /** Cosmetic-only user info, used to avoid a flash of pre-auth state while /me is loading */
  @observable private accessor cosmeticUserInfo: CosmeticPreauthUserInfoCookie | null = null;
  @action loadCosmeticUserInfo = () => {
    try {
      const cookies = parseCookies(window.document.cookie);
      const cosmeticUserInfoRaw = cookies.get(CosmeticPreauthUserInfoCookieName);
      if (!cosmeticUserInfoRaw) return;
      const cosmeticUserInfo = JSON.parse(cosmeticUserInfoRaw);

      if (Value.Check(CosmeticPreauthUserInfoCookieSchema, cosmeticUserInfo)) {
        this.cosmeticUserInfo = cosmeticUserInfo;
      } else {
        for (const error of Value.Errors(CosmeticPreauthUserInfoCookieSchema, cosmeticUserInfo)) {
          console.error(error);
        }
      }
    } catch (error) {
      // No need to actually throw, just log and let it be null
      console.error('Failed to load cosmetic user info', error);
    }
  };

  // ----- User info ----- //
  @observable private accessor user: User | null = null;
  @action loadUserInfo = async () => {
    try {
      const response = await fetch(`${SERVICE_URI.API_URI}/auth/me`, {
        credentials: 'include',
      });
      if (response.status !== 200) {
        throw new Error('Failed to fetch user info');
      }

      const data = await response.json();
      const userInfo = Value.Parse(UserSchema, data);
      this.setUserInfo(userInfo);
    } catch (error) {
      console.error('Failed to fetch user info', error);
      // Redirect to the sign in page
      window.location.href = SERVICE_URI.API_URI + '/auth/sign-in?final_redirect_uri=' + window.location.href;
    }
  };

  @action
  private setUserInfo = (user: User | null) => {
    this.user = user;

    if (user) {
      informLogRocketOfUser(user);
    }
  };
}
