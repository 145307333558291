import {
  ColorPickerFieldsDisplayP3,
  ColorPickerFieldsMainInputP3,
  ColorPickerFieldsOklab,
  ColorPickerFieldsOklch,
} from './color-picker-fields';
import { ColorPickerCartesianUniform } from './color-picker-cartesian-uniform';
import { ColorPickerSliderHueUniform, ColorPickerSliderOpacity } from './color-picker-sliders';
import { ColorPickerSwatches } from './color-picker-swatches';

export function ColorPickerTabP3(props: React.ComponentProps<'div'>) {
  return (
    <div className="ColorPickerPanel" {...props}>
      <div className="flex gap-2.5">
        <ColorPickerCartesianUniform colorSpace="p3" />
        <ColorPickerSliderOpacity />
        <ColorPickerSliderHueUniform />
      </div>
      <div className="w-55.25">
        <ColorPickerSwatches className="mb-2" />
        <div className="flex flex-col gap-1.5">
          <ColorPickerFieldsOklch />
          <ColorPickerFieldsOklab />
          <ColorPickerFieldsDisplayP3 />
          <ColorPickerFieldsMainInputP3 />
        </div>
      </div>
    </div>
  );
}
