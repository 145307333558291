import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { LayerTreeRow } from './layer-tree-row';
import { useLayerTreePointerEvents } from './use-layer-tree-pointer-events';
import { useKeepSelectionVisibleInLayerTree } from './use-keep-selection-visible-in-layer-tree';
import { LayerTreeContextMenu } from './layer-tree-context-menu';
import { LayerTreeDropIndicators } from './layer-tree-drop-indicators';

export const LayerTree = observer(() => {
  const { treeUtils, selectionState } = useEditor();

  // Wire up pointer events for selection and dragging
  const [containerRef, rowWrapperRef] = useLayerTreePointerEvents();
  // Make sure the selection gets scrolled into view in the layer tree
  useKeepSelectionVisibleInLayerTree(containerRef);

  return (
    <LayerTreeContextMenu containerRef={containerRef}>
      <div
        ref={containerRef}
        className="relative w-80 max-w-160 min-w-50 grow overflow-auto overscroll-none py-1.5"
        style={{ scrollbarWidth: 'thin' }}
        onPointerDown={(event) => {
          if (event.target === event.currentTarget) {
            selectionState.removeSelectedNodeIds(Array.from(selectionState.selectedNodeIds));
          }
        }}
      >
        {/* necessary for children to all stretch fully horizontally if any of them overflow horizontally */}
        <div ref={rowWrapperRef} style={{ width: 'fit-content', minWidth: '100%' }}>
          {treeUtils.rootNode.children.map((node) => (
            <LayerTreeRow key={node.id} node={node} indent={0} parentIsSelected={false} />
          ))}
        </div>

        <LayerTreeDropIndicators containerRef={containerRef} />
      </div>
    </LayerTreeContextMenu>
  );
});
