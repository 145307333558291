import { PanelHeader, PanelTitle } from '../../components/panel';
import { WidthProperty } from '../properties/width-property';
import { HeightProperty } from '../properties/height-property';
import { BorderRadiusProperty } from '../properties/border-radius-property';
import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { PropertyPanels } from '../properties/property-panel';
import { GhostIconButton } from '../../components/ghost-icon-button';
import { MinusIcon } from '../../icons/minus-icon';
import {
  addFlexToNode,
  removeFlexFromNode,
  wrapNodesWithFlex,
  determineFlexAction,
} from '../tree/wrap-nodes-with-flex';
import { FlexDirectionProperty } from './flex-direction-property';
import { GapProperty } from './gap-property';
import { PaddingProperty } from './padding-property';
import { AlignItemsProperty } from './align-items-property';
import { JustifyContentProperty } from './justify-content-property';
import { Tooltip } from '../../components/tooltip';
import { Button } from '../../components/button';
import { PanelRow } from '../../components/panel';

export const LayoutProperties = observer(() => {
  const editorState = useEditor();
  const { propertiesState } = editorState;
  const nodes = propertiesState.allNodes;
  const collapsed = propertiesState.collapsedPanels.has(PropertyPanels.Layout);
  const flexAction = determineFlexAction(nodes);

  return (
    <div>
      <PanelHeader>
        <PanelTitle
          collapsed={collapsed}
          onCollapsedChange={(collapsed) =>
            collapsed
              ? propertiesState.collapsePanel(PropertyPanels.Layout)
              : propertiesState.expandPanel(PropertyPanels.Layout)
          }
        >
          {flexAction === 'remove-flex' ? 'Flex' : 'Layout'}
        </PanelTitle>

        {flexAction === 'remove-flex' && (
          <Tooltip.Root>
            <Tooltip.Trigger
              render={<GhostIconButton />}
              onClick={(event) => {
                event.currentTarget.blur();
                propertiesState.expandPanel(PropertyPanels.Layout);
                removeFlexFromNode(nodes[0]!);
              }}
            >
              <MinusIcon />
            </Tooltip.Trigger>
            <Tooltip.Popup>Remove flex</Tooltip.Popup>
          </Tooltip.Root>
        )}
      </PanelHeader>

      {!collapsed && (
        <>
          <PanelRow>
            <WidthProperty />
            <HeightProperty />
            <BorderRadiusProperty />
          </PanelRow>

          {/* If the layout mode is flex for all selected nodes, show the flex properties */}
          {nodes.every((node) => node.layoutModeForChildren === 'flex') && (
            <>
              {/* Direction and gap make no sense when a node can't have children (e.g. Text) */}
              {nodes.every((node) => node.canHaveChildren) && (
                <PanelRow className="grid grid-cols-3">
                  <FlexDirectionProperty className="col-span-2" />
                  <GapProperty />
                </PanelRow>
              )}
              <PanelRow>
                <AlignItemsProperty />
                <JustifyContentProperty />
              </PanelRow>
              <PaddingProperty />
            </>
          )}

          {flexAction === 'add-flex-to-node' && (
            <PanelRow>
              <Button className="grow" onClick={() => addFlexToNode(editorState, nodes[0]!)}>
                Add flex
              </Button>
            </PanelRow>
          )}

          {flexAction === 'wrap-in-flex' && (
            <PanelRow>
              <Button className="grow" onClick={() => wrapNodesWithFlex(editorState, nodes)}>
                Wrap in flex
              </Button>
            </PanelRow>
          )}
        </>
      )}
    </div>
  );
});
