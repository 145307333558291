import { observer } from 'mobx-react-lite';
import { CreateFileButton } from './create-file-button';
import { FileList } from './file-list';
import { useEffect } from 'react';
import { AuthBar } from './temp-auth-stuff';

export const Home = observer(() => {
  useEffect(() => {
    document.title = makePageTitle('Projects');
  });

  return (
    <>
      <AuthBar />
      <br />
      <br />
      <CreateFileButton />
      <br />
      <br />
      <FileList />
    </>
  );
});

export function makePageTitle(title: string) {
  return `${title} – Paper`;
}
