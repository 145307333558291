import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import Menu from '../../components/context-menu';
import { useState } from 'react';

export const PageContextMenu = observer(({ children }: { children: React.ReactNode }) => {
  const editorState = useEditor();
  const [pageId, setPageId] = useState<string | null>(null);

  /** Since we're using one trigger for the entire Page List, we need to manually figure out which page we're targeting */
  function handleTriggerContextMenu(e: React.MouseEvent) {
    const targetNodeEl = ((e.target as HTMLElement)?.closest('[data-page-id]') as HTMLElement) ?? null;
    const pageId = targetNodeEl?.getAttribute('data-page-id');
    if (pageId) {
      setPageId(pageId);
    } else {
      setPageId(null);
    }
  }

  return (
    <Menu.Root>
      <Menu.Trigger style={{ display: 'contents' }} onContextMenu={handleTriggerContextMenu}>
        {children}
      </Menu.Trigger>

      <Menu.Portal>
        <Menu.Content>
          {pageId !== null && (
            <>
              {/* <Menu.Item style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>Copy link to page</Menu.Item> */}
              {/* <Menu.Separator /> */}
              {/* <Menu.Item style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>Rename page</Menu.Item> */}
              {/* <Menu.Item style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>Duplicate page</Menu.Item> */}
              {/* <Menu.Separator /> */}
              <Menu.Item
                style={{ backgroundColor: 'white', padding: 20, color: 'black' }}
                onSelect={() => editorState.pageState.deletePage(pageId)}
                disabled={editorState.pageState.pageList.length === 1}
              >
                Delete page
              </Menu.Item>
            </>
          )}
        </Menu.Content>
      </Menu.Portal>
    </Menu.Root>
  );
});
