import { createMultipleSortOrderKeys } from '@paper/models/src/file/create-sort-order-key';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { assert } from '../../assert';
import { action } from 'mobx';

export const moveDraggedNodesToRoot = action((editorState: EditorState, draggedNodes: TreeNode[]) => {
  const rootNode = editorState.treeUtils.rootNode;

  // Make a map of container to nodes
  const frameToNodes = new Map<TreeNode, TreeNode[]>();
  draggedNodes.forEach((node) => {
    const frame = node.containerFrame;
    // Skip nodes that are already in the root, we don't need to reparent them
    if (frame && frame.isRoot === false) {
      frameToNodes.set(frame, [...(frameToNodes.get(frame) ?? []), node]);
    }
  });

  // For each container, generate sort keys 1 above it
  const sortKeyToNode = new Map<string, TreeNode>();
  frameToNodes.forEach((nodes, frame) => {
    const frameIndex = frame.indexOfNodeInParent;
    assert(frameIndex !== null);

    const sortKeys = createMultipleSortOrderKeys(
      nodes.length,
      rootNode.children[frameIndex]?.sortKey ?? null,
      rootNode.children[frameIndex + 1]?.sortKey ?? null
    );
    assert(sortKeys.length === nodes.length);
    nodes.forEach((node, index) => {
      sortKeyToNode.set(sortKeys[index]!, node);
    });
  });

  // Move the nodes to the root in one batch
  sortKeyToNode.forEach((node, sortKey) => {
    editorState.treeUtils.moveNodeToNewParent(node, rootNode, sortKey);
  });
});
