import { useEffect } from 'react';
import { UserFocusType } from '@paper/models/src/websocket/multiplayer-user';
import { observer } from 'mobx-react-lite';
import { useEditor } from './editor-context';

/** Tracks the pointer and wires up global logic like no right click outside of inputs */
export const EditorPointerWatcherAndRightClickBlocker = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { pointerState, hudState, keyState } = editorState;

    function handlePointerMove(e: PointerEvent) {
      // Some browsers fire pointermove events for non-pointer devices like keyboards
      if (pointerState.cursorPos.x === e.clientX && pointerState.cursorPos.y === e.clientY) {
        return;
      }
      pointerState.setCursorPos(e.clientX, e.clientY);
      keyState.updateModifiersFromPointerMove(e);
      updateFocus(e);
    }

    function handleBlur() {
      pointerState.setFocus(UserFocusType.Blurred);
    }

    /** Attempts to find the current hovered interface or, if over the canvas, the hovered canvas node */
    function updateFocus(e: PointerEvent | KeyboardEvent) {
      // Check if we're over the canvas
      const isHoveringCanvas = e.target instanceof HTMLElement && e.target === hudState.hudEl;

      if (isHoveringCanvas) {
        pointerState.setFocus(UserFocusType.Canvas);

        // If we're temporarily hiding highlights but the mouse is back over the canvas, stop hiding highlights
        if (editorState.selectionState.hideHighlights) {
          editorState.selectionState.stopHidingHighlights();
        }
      } else {
        pointerState.setFocus(UserFocusType.Interface);
      }
    }

    function handleContextMenu(e: Event) {
      // Prevent the default context menu from appearing unless we're in an input, textarea, or contenteditable
      if (
        e.target instanceof HTMLElement &&
        (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || e.target.isContentEditable)
      ) {
        return;
      }
      e.preventDefault();
    }

    window.addEventListener('pointermove', handlePointerMove);
    window.addEventListener('blur', handleBlur);
    window.addEventListener('contextmenu', handleContextMenu);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [editorState]);

  return null;
});
