/** Renders an HSV hue slice using sRGB colors */
export const hsvFragmentShader = /* glsl */ `#version 300 es
precision highp float;

uniform float u_hue;
uniform vec2 u_resolution;

out vec4 fragColor;

// Standard HSV to RGB conversion
vec3 hsv2rgb(vec3 hsv) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(hsv.xxx + K.xyz) * 6.0 - K.www);
    return hsv.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), hsv.y);
}

void main() {
    vec2 texCoord = gl_FragCoord.xy / u_resolution;

    float saturation = texCoord.x;
    float value = texCoord.y;
    vec3 rgb = hsv2rgb(vec3(u_hue, saturation, value));

    fragColor = vec4(rgb, 1.0);
}
`;
