import { useEffect, useRef } from 'react';
import { assert } from '../../assert';
import { HueSlice } from './hue-slice';
import { uniformFragmentShaderSource } from './frag-shader-uniform-map';
import { toast } from 'sonner';
import { vertexShaderSource } from './vertex-shader';

// Notes for future us:
// Be very careful changing anything
// It's very easy to render incorrect colors as the color pipeline is long
// It's roughly: oklch -> oklab -> xyz -> linear rgb -> display p3 -> canvas itself does gamma rendering
// linear rgb can include values outside of 0-1, do not clamp them!
// any clamping anywhere will lead to incorrect colors

// For p3 mode, the canvas itself must do both of these things:
// const gl = canvas.getContext('webgl2', { colorSpace: 'display-p3' } ...
// gl.drawingBufferColorSpace = 'display-p3';
// or it won't render colors in P3 correctly

// If you change something, you need to test with a color picker like https://sindresorhus.com/system-color-picker
// To make sure rendered colors match input oklch
// Testing requires:
// 1. P3 turned on on a P3 screen
// 2. P3 turned on, on an sRGB screen
// 3. P3 turned off, on a P3 screen
// 4. P3 turned off, on an sRGB screen
// You should measure the colors at the top edge and top right corner first
// as well as a good sampling of colors throughout the gradients
// Everything should be in the same oklch hue
// (except very near black and white there isn't enough info for the rendered measured pixel to give the exact same hue)

export enum UniformColorSpace {
  RGB = 0,
  P3 = 1,
}

export function ColorMapUniform({
  hue,
  hueSlice,
  colorSpace,
  height,
  width,
  className,
}: {
  hue: number;
  hueSlice: HueSlice;
  colorSpace: 'p3' | 'rgb';
  height: number;
  width: number;
  className?: string;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const glRef = useRef<WebGL2RenderingContext | null>(null);
  const programRef = useRef<WebGLProgram | null>(null);
  const uniformsRef = useRef<{
    resolution: WebGLUniformLocation | null;
    hue: WebGLUniformLocation | null;
    cusp_saturation: WebGLUniformLocation | null;
    toe: WebGLUniformLocation | null;
    colorSpace: WebGLUniformLocation | null;
  } | null>(null);

  useEffect(() => {
    // Compile a shader from source
    function createShader(gl: WebGLRenderingContext, type: GLenum, source: string) {
      const shader = gl.createShader(type);
      assert(shader);
      gl.shaderSource(shader, source);
      gl.compileShader(shader);

      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('Shader compile error:', gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
      }
      return shader;
    }

    // Create and link the shader program
    function createProgram(gl: WebGLRenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader) {
      const program = gl.createProgram();
      assert(program);
      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);
      gl.linkProgram(program);

      if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.error('Program link error:', gl.getProgramInfoLog(program));
        return null;
      }
      return program;
    }

    // Initialize WebGL with the canvas
    let vao: WebGLVertexArrayObject | null = null;
    let positionBuffer: WebGLBuffer | null = null;
    function initWebGL(canvas: HTMLCanvasElement, fragmentShaderSource: string, colorSpace: 'p3' | 'rgb') {
      const gl = getGLContextForColorSpace(canvas, colorSpace);

      // Create shaders
      const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
      const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
      assert(vertexShader);
      assert(fragmentShader);

      // Create program
      const program = createProgram(gl, vertexShader, fragmentShader);
      assert(program);
      gl.useProgram(program);

      // Create and bind VAO first
      vao = gl.createVertexArray();
      gl.bindVertexArray(vao);

      // Create a buffer for the position attribute
      positionBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

      // Set up the position attribute
      const positionLoc = gl.getAttribLocation(program, 'position');
      gl.enableVertexAttribArray(positionLoc);
      gl.vertexAttribPointer(positionLoc, 2, gl.FLOAT, false, 0, 0);

      // Set the position buffer data (full-screen quad)
      const positions = new Float32Array([-1, -1, 1, -1, -1, 1, 1, -1, 1, 1, -1, 1]);
      gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);

      return { gl, program };
    }

    // Main setup function
    function setupColorPicker(canvas: HTMLCanvasElement, fragmentShaderSource: string, colorSpace: 'p3' | 'rgb') {
      canvas.width = 400;
      canvas.height = 400;

      // Initialize WebGL
      const { gl, program } = initWebGL(canvas, fragmentShaderSource, colorSpace);

      // Get uniform locations
      const uniforms = {
        resolution: gl.getUniformLocation(program, 'u_resolution'),
        hue: gl.getUniformLocation(program, 'u_hue'),
        toe: gl.getUniformLocation(program, 'u_toe'),
        cusp_saturation: gl.getUniformLocation(program, 'u_cusp_saturation'),
        colorSpace: gl.getUniformLocation(program, 'u_colorSpace'),
      };

      // Return objects needed for updates
      return { gl, program, uniforms };
    }

    try {
      const { gl, program, uniforms } = setupColorPicker(canvasRef.current!, uniformFragmentShaderSource, colorSpace);
      uniformsRef.current = uniforms;
      glRef.current = gl;
      programRef.current = program;
    } catch (e) {
      toast.error('Please use a browser that supports WebGL2');
    }

    return () => {
      const gl = glRef.current;
      if (!gl) {
        return;
      }

      // Delete VAO
      if (vao) {
        gl.deleteVertexArray(vao);
      }

      // Delete buffers
      if (positionBuffer) {
        gl.deleteBuffer(positionBuffer);
      }

      // Delete shaders and program
      if (programRef.current) {
        // Get shaders from program
        const shaders = gl.getAttachedShaders(programRef.current);
        if (shaders) {
          shaders.forEach((shader) => {
            if (programRef.current) {
              gl!.detachShader(programRef.current, shader);
              gl!.deleteShader(shader);
            }
          });
        }
        gl!.deleteProgram(programRef.current);
      }

      // Optional: remove canvas context (some browsers free memory faster)
      const canvas = gl.canvas;
      canvas.width = 1;
      canvas.height = 1;
      gl.viewport(0, 0, 1, 1);
    };
  }, [colorSpace]);

  // Update effect - runs when hue changes
  useEffect(() => {
    const gl = glRef.current;
    const program = programRef.current;
    const uniforms = uniformsRef.current;

    if (!gl || !program || !uniforms) return;
    // Set uniforms
    gl.useProgram(program);

    // Set resolution first
    gl.uniform2f(uniforms.resolution, gl.canvas.width, gl.canvas.height);

    // Set color space
    gl.uniform1i(uniforms.colorSpace, colorSpace === 'p3' ? UniformColorSpace.P3 : UniformColorSpace.RGB);

    // Set other uniforms
    gl.uniform1f(uniforms.hue, hue);
    gl.uniform1f(uniforms.cusp_saturation, hueSlice.cuspSaturation);
    gl.uniform1f(uniforms.toe, hueSlice.toe);

    // Set viewport and draw
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }, [hue, hueSlice, colorSpace]);

  return <canvas ref={canvasRef} style={{ width, height }} className={className} />;
}

/** Gets a WebGL2 context that is set to display-p3 */
export function getGLContextForColorSpace(canvas: HTMLCanvasElement, colorSpace: 'p3' | 'rgb') {
  // The browser will fallback to sRGB if P3 is not supported so we can always set this as display-p3
  // Do not change these settings without a lot of testing in sRGB and P3, they impact how math is done in the shader code
  const gl = canvas.getContext('webgl2', {
    colorSpace: colorSpace === 'p3' ? 'display-p3' : 'srgb',
    alpha: false,
  }) as WebGL2RenderingContext;
  if (!gl) {
    throw new Error('Failed to get WebGL2 context');
  }
  gl.drawingBufferColorSpace = colorSpace === 'p3' ? 'display-p3' : 'srgb';
  return gl;
}
