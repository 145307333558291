import { useEffect, useRef } from 'react';
import { vertexShaderSource } from './vertex-shader';
import { hsvFragmentShader } from './frag-shader-hsv-map';

// The HSV map can only use sRGB colors (no p3)
// because HSV fundamentally does not map to p3, at least in a way that can preserve the hue across p3 colors

export function ColorMapHsv({
  hue,
  width,
  height,
  className,
}: {
  hue: number;
  width: number;
  height: number;
  className?: string;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const glRef = useRef<WebGL2RenderingContext | null>(null);
  const programRef = useRef<WebGLProgram | null>(null);
  const uniformsRef = useRef<{
    resolution: WebGLUniformLocation | null;
    hue: WebGLUniformLocation | null;
  } | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = width;
    canvas.height = height;

    const gl = canvas.getContext('webgl2', { colorSpace: 'srgb', alpha: false }) as WebGL2RenderingContext;
    if (!gl) throw new Error('Failed to get WebGL2 context');

    // Create and compile shaders
    const program = gl.createProgram()!;
    const vShader = gl.createShader(gl.VERTEX_SHADER)!;
    const fShader = gl.createShader(gl.FRAGMENT_SHADER)!;

    gl.shaderSource(vShader, vertexShaderSource);
    gl.shaderSource(fShader, hsvFragmentShader);
    gl.compileShader(vShader);
    gl.compileShader(fShader);
    gl.attachShader(program, vShader);
    gl.attachShader(program, fShader);
    gl.linkProgram(program);
    gl.useProgram(program);

    // Create a square
    const positions = new Float32Array([-1, -1, 1, -1, -1, 1, 1, 1]);

    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);

    // Set up the position attribute
    const positionLoc = gl.getAttribLocation(program, 'position');
    gl.enableVertexAttribArray(positionLoc);
    gl.vertexAttribPointer(positionLoc, 2, gl.FLOAT, false, 0, 0);

    // Set up attributes and uniforms
    const hueLocation = gl.getUniformLocation(program, 'u_hue');
    const resolutionLocation = gl.getUniformLocation(program, 'u_resolution');

    glRef.current = gl;
    programRef.current = program;
    uniformsRef.current = {
      resolution: resolutionLocation,
      hue: hueLocation,
    };

    return () => {
      gl.deleteProgram(program);
      gl.deleteShader(vShader);
      gl.deleteShader(fShader);
      gl.deleteBuffer(positionBuffer);
    };
  }, [height, width]);

  /** Update and draw whenever hue changes */
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const gl = glRef.current;
    const uniforms = uniformsRef.current;
    const program = programRef.current;
    if (!uniforms || !gl || !program) return;

    // Update uniforms
    gl.useProgram(program);
    gl.uniform1f(uniforms.hue, hue / 360.0);
    gl.uniform2f(uniforms.resolution, canvas.width, canvas.height);

    // Draw
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  }, [hue]);

  return <canvas ref={canvasRef} style={{ width, height }} className={className} />;
}
