import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { TreeItem } from '../../components/tree-item';
import { PageType } from '@paper/models/src/file/page-schema';
import { CheckIcon } from '../../icons/check-icon';
import { PageTreeIcon } from '../../components/tree-icons';
import { CollapsibleIndicator } from '../../components/collapsible-indicator';

export const PageListRow = observer(({ page }: { page: PageType }) => {
  const { pageState } = useEditor();

  function handlePageClick(pageId: string) {
    pageState.setActivePage(pageId);
  }

  return (
    <TreeItem key={page.id} data-page-id={page.id} onClick={() => handlePageClick(page.id)}>
      <CollapsibleIndicator.Root />
      <span className="text-gray-1 flex h-3 w-3 items-center justify-center opacity-60 *:shrink-0">
        <PageTreeIcon />
      </span>
      <span className="ml-2 grow">{page.label}</span>
      {page.id === pageState.activePageId && (
        <div className="flex w-4 items-center justify-center">
          <CheckIcon />
        </div>
      )}
    </TreeItem>
  );
});
