import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { Tool } from './ToolState';
import { MoveTool } from '../move-tool/move-tool';
import { PanTool } from '../move-tool/pan-tool';
import { DrawTool } from '../draw-tool/draw-tool';
import { TextTool } from '../text-tool/text-tool';
import { ZoomTool } from '../zoom-tool/zoom-tool';

export const ActiveTool = observer(({ overlayEl }: { overlayEl: HTMLCanvasElement }) => {
  const { toolState } = useEditor();

  if (toolState.activeTool === Tool.Move) {
    return <MoveTool overlayEl={overlayEl} />;
  } else if (toolState.activeTool === Tool.Pan) {
    return <PanTool overlayEl={overlayEl} />;
  } else if (toolState.activeTool === Tool.Draw) {
    return <DrawTool overlayEl={overlayEl} />;
  } else if (toolState.activeTool === Tool.Text) {
    return <TextTool overlayEl={overlayEl} />;
  } else if (toolState.activeTool === Tool.Zoom) {
    return <ZoomTool overlayEl={overlayEl} />;
  } else {
    return null;
  }
});
