import { clamp } from '@paper/models/src/math/clamp';
import type { Hex, Hsl, Oklch } from './Color';
import type { Rgb, P3 } from 'culori/fn';

/**
 * Parses the following hex-like formats:
 * - "a" → `0xAAAAAA`
 * - "ab" → `0xABABAB`
 * - "abc" → `0xAABBCC`
 * - "abcd" → `0xAABBCCDD, alpha: 0.867`
 * - "abcdef" → `0xABCDEF`
 * - "abcdefwhoops" → `0xABCDEF`
 * - "abcdef12" → `0xABCDEF, alpha: 0.07`
 */
function parseHex(rawInput: string): Hex | undefined {
  const hexRegExp = /^#?([0-9a-f]{8}|[0-9a-f]{1,6})/i;
  const matches = rawInput.trim().match(hexRegExp);
  let match = matches?.[1];
  if (!match) return;
  let alpha;

  if (match.length <= 2) {
    const hex = parseInt(match.repeat(6 / match.length), 16);
    return { mode: 'hex', hex, alpha };
  }

  if (match.length <= 4) {
    const [r, g, b, a] = match;
    const hex = parseInt(r! + r! + g! + g! + b! + b!, 16);
    alpha ??= a === undefined ? a : parseInt(a + a, 16) / 255;
    return { mode: 'hex', hex, alpha };
  }

  // Ignore 5-digit hexes, they can't be interpreted predictibly
  if (match.length === 5) return;

  const rgb = match.substring(0, 6);
  const a = match.substring(6, 8);
  const hex = parseInt(rgb, 16);
  alpha ??= a ? parseInt(a, 16) / 255 : undefined;
  return { mode: 'hex', hex, alpha };
}

export function parseColor(rawInput: string): Hsl | Rgb | Hex | Oklch | P3 | undefined {
  const cleaned = rawInput.trim().toLowerCase();

  // We isolate any wrapping mode function (rgb(), hsl(), oklch()...)
  const functionMatch = cleaned.match(/^(rgb|hsl|oklch)a?\s*\((.*)\)$/);
  let mode = functionMatch?.[1] as 'rgb' | 'hsl' | 'oklch' | 'p3' | undefined;
  let content = functionMatch ? functionMatch[2] : cleaned;

  const p3Match = cleaned.match(/^color\(display-p3\s+(.*)\)$/);
  if (p3Match?.[1]) {
    mode = 'p3';
    content = p3Match[1];
  }

  // We remove the slash and special symbols and split the content into strings based on spaces or commas
  const strings =
    content
      ?.replace(/\/|°/g, '')
      .split(/(?:\s*,\s*|\s+)/)
      .filter(Boolean) ?? [];

  let mainStrings: string[] = [];
  let alphaString: string | undefined;

  // ['100', '100', '100', '50%'] or ['#000', '50%'] for example
  if (strings.length === 4 || strings.length === 2) {
    mainStrings = strings.slice(0, -1);
    alphaString = strings[strings.length - 1];
  } else {
    // No alpha found
    mainStrings = strings;
  }

  // We then turn strings into parts, which have isolated value and unit
  const [a, b, c] = mainStrings.map(parsePart);

  const alphaPart = alphaString ? parsePart(alphaString) : undefined;
  let alpha: number | undefined;

  if (alphaPart) {
    if (alphaPart.unit === '' && alphaPart.value <= 1) {
      alpha = alphaPart.value;
    } else {
      alpha = alphaPart.value / 100;
    }
    alpha = clamp(alpha, 0, 1);
  }

  // If only one main part was found, we are in a hex color
  if (!b && !c) {
    const hex = parseHex(rawInput);
    // The hex itself might contain an alpha, like 66 in #ff000066. The outer alpha we parsed takes precedence.
    return hex ? { ...hex, mode: 'hex', alpha: alpha ?? hex.alpha } : undefined;
  }

  // If not all three parts are defined, something went wrong
  if (!a || !b || !c) {
    return;
  }

  // Finally, assignColor is called to determine the color mode and create the color object
  return assignColor(a, b, c, alpha, mode);
}

function assignColor(
  a: { value: number; unit: string },
  b: { value: number; unit: string },
  c: { value: number; unit: string },
  alpha?: number,
  mode?: 'oklch' | 'hsl' | 'rgb' | 'p3'
): Hsl | Rgb | Hex | Oklch | P3 | undefined {
  if (
    (mode === undefined || mode === 'oklch') &&
    ((a.unit === '%' && b.unit === '' && c.unit === '') ||
      (a.unit === '%' && b.unit === '' && c.unit === 'deg') ||
      (a.unit === '%' && b.unit === '%' && c.unit === '') ||
      (a.unit === '%' && b.unit === '%' && c.unit === 'deg') ||
      (a.unit === '' && b.unit === '' && c.unit === 'deg') ||
      (a.unit === '' &&
        b.unit === '' &&
        c.unit === '' &&
        a.value <= 1 &&
        b.value > 0 &&
        b.value <= 0.4 &&
        (c.value === 0 || c.value > 1) &&
        c.value <= 360))
  ) {
    const l = clamp(a.unit === '%' ? a.value / 100 : a.value, 0, 1);
    const c_ = clamp(b.unit === '%' ? (b.value * 0.4) / 100 : b.value, 0, 1);
    const h = c.value;

    return { mode: 'oklch', l, c: c_, h, alpha };
  }

  if (
    (mode === undefined || mode === 'hsl') &&
    ((a.unit === '' && b.unit === '%' && c.unit === '%') ||
      (a.unit === 'deg' && b.unit === '%' && c.unit === '%') ||
      (a.unit === 'deg' && b.unit === '' && c.unit === ''))
  ) {
    const h = a.value;
    const s = clamp(b.unit === '%' ? b.value / 100 : b.value, 0, 1);
    const l = clamp(c.unit === '%' ? c.value / 100 : c.value, 0, 1);

    return { mode: 'hsl', h, s, l, alpha };
  }

  if (
    (mode === undefined || mode === 'p3') &&
    a.unit === '' &&
    b.unit === '' &&
    c.unit === '' &&
    a.value > 0 &&
    a.value <= 1 &&
    b.value > 0 &&
    b.value <= 1 &&
    c.value > 0 &&
    c.value <= 1
  ) {
    const r = clamp(a.value, 0, 1);
    const g = clamp(b.value, 0, 1);
    const b_ = clamp(c.value, 0, 1);

    return { mode: 'p3', r, g, b: b_, alpha };
  }

  if (
    (mode === undefined || mode === 'rgb') &&
    a.unit === '' &&
    b.unit === '' &&
    c.unit === '' &&
    a.value <= 255 &&
    b.value <= 255 &&
    c.value <= 255
  ) {
    const r = clamp(a.value / 255, 0, 1);
    const g = clamp(b.value / 255, 0, 1);
    const b_ = clamp(c.value / 255, 0, 1);

    return { mode: 'rgb', r, g, b: b_, alpha };
  }
}

function parsePart(raw: string) {
  const match = raw.match(/^(-?\d*\.?\d+)(%|deg)?$/);
  if (!match) return undefined;

  const unit = match[2] ?? '';
  const value = parseFloat(match[1] ?? '');

  return { value, unit };
}
