import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { PageListRow } from './page-list-row';
import { PanelHeader, PanelTitle } from '../../components/panel';
import { PageContextMenu } from './page-context-menu';
import { GhostIconButton } from '../../components/ghost-icon-button';
import { PlusIcon } from '../../icons/plus-icon';

export const PageList = observer(() => {
  const editorState = useEditor();
  const pageState = editorState.pageState;

  if (pageState.pageListIsOpen === false) {
    return (
      <div className="flex flex-col py-1.5">
        <PanelHeader>
          <PanelTitle collapsed onCollapsedChange={() => pageState.setPageListIsOpen(true)}>
            {pageState.activePage?.label ?? 'Current page'}
          </PanelTitle>
        </PanelHeader>
      </div>
    );
  }

  return (
    <div className="flex flex-col py-1.5">
      <PanelHeader>
        <PanelTitle onCollapsedChange={() => pageState.setPageListIsOpen(false)}>Pages</PanelTitle>
        <GhostIconButton
          onClick={(event) => {
            pageState.addPage();
            event.currentTarget.blur();
          }}
        >
          <PlusIcon />
        </GhostIconButton>
      </PanelHeader>
      <PageContextMenu>
        {pageState.pageList.map((page) => {
          return <PageListRow key={page.id} page={page} />;
        })}
      </PageContextMenu>
    </div>
  );
});
