import { TempTreeType } from '@paper/models/src/file/temp-tree-schema';
import { findNextSafeAreaForRect } from '../canvas/find-safe-area-for-rect';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { getBoundsForNodes } from '../tree/get-bounds-for-nodes';

/**
 * When copy/pasting top level Boxes inside the same file
 * They should position themselves next to their original source node as closely as possible
 */
export function checkForSpecialFrameBoxPasteCase(
  editorState: EditorState,
  newParentNodes: Map<TreeNode, number>,
  pasteData: TempTreeType,
  newNodes: TreeNode[],
  tempIdToNewIdMap: Map<string, string>
) {
  // If we're pasting into a non-root node, it's not the top level frame box case
  if (newParentNodes.size > 1 || newParentNodes.has(editorState.treeUtils.rootNode) === false) return;

  // Require all source nodes to 1) be in the file 2) be a Box component and 3) be top level nodes
  const allSourceNodesAreValid = Object.keys(pasteData.oldIdToNewIdMap).every((tempId) => {
    const sourceNode = editorState.treeUtils.getNode(tempId);
    const sourceNodeParentIsRootOfActivePage = sourceNode?.parent === editorState.treeUtils.rootNode;
    return sourceNode !== null && sourceNodeParentIsRootOfActivePage && sourceNode.component === 'Box';
  });

  // If any of the source nodes are not valid, return
  if (!allSourceNodesAreValid) return;

  // If we survived the checks, we're in the special case!

  // Loop the originals and position the clones as close to them as possible
  for (const [sourceId, tempTreeId] of Object.entries(pasteData.oldIdToNewIdMap)) {
    const newCloneId = tempIdToNewIdMap.get(tempTreeId) ?? '';
    const sourceNode = editorState.treeUtils.getNode(sourceId);
    const newCloneNode = editorState.treeUtils.getNode(newCloneId);
    if (!sourceNode || !newCloneNode) {
      console.warn(`Unexpected: during frame box paste, could not find nodes for: ${sourceId} - ${newCloneId}`);
      continue;
    }

    const newPos = findNextSafeAreaForRect(editorState, sourceNode.bounds);
    newCloneNode.setXInWorld(newPos.x);
    newCloneNode.setYInWorld(newPos.y);
  }

  // Find the bounds of the new pasted and positioned nodes
  const bounds = getBoundsForNodes(newNodes);

  // Move the camera to ensure the new box is in view
  editorState.cameraState.movePanToEnsureRectInView(bounds);
}
