import { observer } from 'mobx-react-lite';
import { Button } from '../components/button';
import { Link } from 'wouter';
import { SERVICE_URI } from '../root/api-address';
import { useEffect, useState } from 'react';
import { FileType } from '@paper/models/src/file/file-schema';
import { useRoot } from '../root/root-context';

export const FileList = observer(() => {
  const authState = useRoot().authState;
  const [files, setFiles] = useState<FileType[]>([]);

  useEffect(() => {
    // Wait for the user to be known before we try fetching files
    if (!authState.isAuthenticated) return;

    const fetchFiles = async () => {
      const response = await fetch(SERVICE_URI.API_URI + '/files', {
        credentials: 'include',
      });
      const data = await response.json();
      setFiles(data);
    };
    fetchFiles();
  }, [authState.isAuthenticated, authState.userId]);

  if (files.length === 0) {
    return <div className="mt-4 flex flex-col gap-3 p-4">No files yet</div>;
  }

  return (
    <div>
      <div className="mt-4 flex flex-col gap-3 p-4">
        <div>choose a file:</div>
        {files.map((data) => {
          return (
            <Link href={`/design/${data.id}`} key={data.id}>
              <Button>
                {data.name} – {data.id}
              </Button>
            </Link>
          );
        })}
      </div>
    </div>
  );
});
