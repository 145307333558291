import { observer } from 'mobx-react-lite';
import { PanelHeader, PanelTitle } from '../../components/panel';
import { TypefaceProperty } from './typeface-property';
import { FontSizeProperty } from './font-size-property';
import { FontStyleProperty } from './font-style-property';
import { useEditor } from '../editor-context';
import { PropertyPanels } from '../properties/property-panel';
import { LineHeightProperty } from './line-height-property';
import { LetterSpacingProperty } from './letter-spacing-property';
import { TextAlignProperty } from './text-align-property';
import { TextDecorationProperty } from './text-decoration-property';
import { TextColorProperty } from './text-color-property';
import { PanelRow } from '../../components/panel';

export const TypographyProperties = observer(() => {
  const { propertiesState } = useEditor();
  const collapsed = propertiesState.collapsedPanels.has(PropertyPanels.Typography);

  return (
    <div>
      <PanelHeader>
        <PanelTitle
          collapsed={collapsed}
          onCollapsedChange={(collapsed) =>
            collapsed
              ? propertiesState.collapsePanel(PropertyPanels.Typography)
              : propertiesState.expandPanel(PropertyPanels.Typography)
          }
        >
          Text
        </PanelTitle>
      </PanelHeader>

      {!collapsed && (
        <>
          <TextColorProperty />
          <TypefaceProperty />
          <PanelRow>
            <FontStyleProperty />
          </PanelRow>
          <PanelRow>
            <FontSizeProperty />
            <LineHeightProperty />
            <LetterSpacingProperty />
          </PanelRow>
          <PanelRow>
            <TextAlignProperty />
            <TextDecorationProperty />
          </PanelRow>
        </>
      )}
    </div>
  );
});
