import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useEditor } from '../editor-context';
import { Tool } from '../toolbar/ToolState';
import { TextEditHoverHighlight } from './text-edit-hover-highlight';
import { TextIntent } from './TextToolState';
import { startDrawingText } from './start-drawing-text';
import { assert } from '../../assert';
import { Vec2, Vec2Utils } from '@paper/models/src/math/vec2';
import { DOUBLE_CLICK_TIME_MS } from '../move-tool/move-tool';

export const TextTool = observer(({ overlayEl }: { overlayEl: HTMLCanvasElement }) => {
  const editorState = useEditor();
  /** If drawing starts, it will put a dispose function here so we can clean it up if TextTool unmounts */
  const disposeDrawingRef = useRef<null | (() => void)>(null);

  useEffect(() => {
    const { pointerState, textTool, toolState, selectionState, cursorState, tickState } = editorState;

    // ----- State ----- //
    let pointerIsDown = false;
    /** The last time the pointer down handler ran */
    let lastPointerDownTime = 0;
    /** For tracking double clicks, triple clicks, etc */
    let pointerDownClickCount = 0;
    /** Tracks the caret position first returned on the initial pointer down, to compare against as we move the pointer */
    let pointerDownCaretPos: number | null = null;
    /** Tracks the viewport x/y of the pointer down so we can compare distance for double clicks */
    let lastPointerDownPos: Vec2 = { x: 0, y: 0 };

    function handlePointerDown(e: PointerEvent) {
      if (e.button !== 0) return;
      e.preventDefault(); // Don't steal focus from the textarea

      pointerIsDown = true;

      // ----- Double click detection ----- //
      const distanceFromLastPointerDown = Vec2Utils.length(
        Vec2Utils.subtract(pointerState.cursorPosWorld, lastPointerDownPos)
      );
      lastPointerDownPos = { ...pointerState.cursorPosWorld };
      const timingIsWithinDoubleClickWindow = Date.now() - lastPointerDownTime < DOUBLE_CLICK_TIME_MS;
      if (timingIsWithinDoubleClickWindow && pointerDownClickCount < 4 && distanceFromLastPointerDown < 5) {
        pointerDownClickCount++;
      } else {
        pointerDownClickCount = 1;
      }
      lastPointerDownTime = Date.now();

      const hoveredNode = textTool.hoveredTextNode;
      // ----- Click out in open canvas ----- //
      if (!hoveredNode) {
        if (textTool.allowDrawing) {
          textTool.setCurrentIntent(TextIntent.Draw);
          disposeDrawingRef.current = startDrawingText(editorState, overlayEl, e);
          return;
        } else {
          // Clicking outside a text node - end editing and revert to move tool
          textTool.stopEditingTextNode();
          toolState.setActiveTool(Tool.Move);
          return;
        }
      }

      // ----- Focus the textarea if we aren't already focused ----- //
      if (!textTool.isFocused) {
        textTool.textareaEl?.focus();
      }

      // ----- Click in a new text node ----- //
      if (hoveredNode !== textTool.editingTextNode) {
        // This is a different text node than we were editing, start a new editing session
        textTool.setCurrentIntent(TextIntent.Editing);
        selectionState.selectIds([hoveredNode.id]);
        textTool.startEditingTextNode(hoveredNode);
      }

      // ----- Clicking inside a text node, set the caret ----- //
      pointerDownCaretPos = textTool.findIndexForPoint(pointerState.cursorPosWorld);
      if (pointerDownCaretPos === null) return;

      textTool.setCurrentIntent(TextIntent.Editing);

      switch (pointerDownClickCount) {
        case 1: {
          // Single click in a new position, move the caret and anchor
          const moveAnchorToo = e.shiftKey === false;
          textTool.setCaretPosition(pointerDownCaretPos, moveAnchorToo);
          break;
        }
        case 2: {
          // Double click (and every 4th click after)
          textTool.selectContinuousChunks(pointerDownCaretPos, pointerDownCaretPos);
          break;
        }
        case 3: {
          // Triple click (and every 4th click after)
          textTool.selectFullLine(pointerDownCaretPos, pointerDownCaretPos);
          break;
        }
        case 4: {
          // Quad click (and every 4th click after)
          textTool.selectAll();
          break;
        }
        default: {
          console.warn('Invalid click count', pointerDownClickCount);
          pointerDownClickCount = 0;
          break;
        }
      }
    }

    function onTick() {
      // ----- Cursor ----- //
      const node = textTool.hoveredTextNode;
      if (textTool.currentIntent === TextIntent.Draw) {
        cursorState.setToolCursorClass('cursor-crosshair');
      } else if (node) {
        // If we're hovering a text node, show the text cursor
        cursorState.setToolCursorClass('cursor-text');
      } else {
        // Not drawing and not hovering a node, show the default cursor for selecting intent
        if (textTool.allowDrawing) {
          cursorState.setToolCursorClass('cursor-crosshair');
        } else {
          cursorState.setToolCursorClass(null);
        }
      }

      // ----- Handle a drag selection ----- //
      if (pointerIsDown) {
        const characterUnderPointer = textTool.findIndexForPoint(pointerState.cursorPosWorld);
        if (characterUnderPointer === null) return;

        switch (pointerDownClickCount) {
          case 1: {
            // Single click drag, move the caret
            const moveAnchorToo = false;
            textTool.setCaretPosition(characterUnderPointer, moveAnchorToo);
            break;
          }
          case 2: {
            // Double click (and every 4th click after)
            assert(pointerDownCaretPos !== null);
            textTool.selectContinuousChunks(pointerDownCaretPos, characterUnderPointer);
            break;
          }
          case 3: {
            // Triple click (and every 4th click after)
            assert(pointerDownCaretPos !== null);
            textTool.selectFullLine(pointerDownCaretPos, characterUnderPointer);
            break;
          }
          case 4: {
            // Quad click (and every 4th click after)
            textTool.selectAll();
            break;
          }
        }
      }

      // TODO: optimization If we don't have an index of characters for this node yet, we can build it now
    }

    function handlePointerUp(e: PointerEvent) {
      if (e.button !== 0) return;
      pointerIsDown = false;
    }

    overlayEl.addEventListener('pointerdown', handlePointerDown);
    // overlayEl.addEventListener('pointermove', onTick);
    tickState.subToTick(onTick);

    window.addEventListener('pointerup', handlePointerUp);
    return () => {
      overlayEl.removeEventListener('pointerdown', handlePointerDown);
      // overlayEl.removeEventListener('pointermove', onTick);
      tickState.unsubToTick(onTick);
      window.removeEventListener('pointerup', handlePointerUp);
      if (typeof disposeDrawingRef.current === 'function') {
        disposeDrawingRef.current();
      }
    };
  }, [editorState, overlayEl]);

  return <TextEditHoverHighlight />;
});
