import { observer } from 'mobx-react-lite';
import { SocketState } from '../sync/SocketState';
import { useRoot } from '../../root/root-context';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { EditorState } from '../EditorState';
import { Editor } from '../editor';
import { FilePayloadMessage } from '@paper/models/src/websocket/file-payload-message';
import { FileLoadFailedMessage } from '@paper/models/src/websocket/file-load-failed-message';
import { Value } from '@sinclair/typebox/value';
import { FileSchema } from '@paper/models/src/file/file-schema';
import { toast } from 'sonner';

/** Instantiates the websocket connection and an EditorState once we have file data */
export const FileLoader = observer(({ fileId }: { fileId: string }) => {
  const root = useRoot();
  const [editorState, setEditorState] = useState<EditorState>();

  useEffect(() => {
    let editorState: EditorState;
    const socketState = new SocketState(fileId, fileLoadSuccessCallback, fileLoadErrorCallback);

    /** Successful load, create the EditorState and hydrate with file data */
    function fileLoadSuccessCallback(filePayload: FilePayloadMessage) {
      const file = filePayload.file;
      console.info(`File loaded for ${fileId}, sync: ${filePayload.syncMachineId}`);

      // Convert the json date strings back into Dates
      file.createdAt = new Date(file.createdAt);
      file.updatedAt = new Date(file.updatedAt);

      // Validate the file data is in the expected schema
      if (!Value.Check(FileSchema, file)) {
        toast.error('File load failed, unexpected schema.');
        console.error('File load failed - payload does not match expected FileType schema');
        for (const error of Value.Errors(FileSchema, file)) {
          console.error(error);
        }
        return;
      }

      editorState = new EditorState(root, socketState, file);
      setEditorState(editorState);
    }

    function fileLoadErrorCallback(error: FileLoadFailedMessage) {
      console.error('Failed to load file', error);
      console.info('TODO: implement client error handling for failed file loads');
    }

    return () => {
      socketState.dispose();
      editorState?.dispose();
    };
  }, [root, fileId]);

  if (editorState === undefined) {
    return null;
  }

  return <Editor editorState={editorState} />;
});
