import { PanelHeader, PanelTitle } from '../../components/panel';
import { XProperty } from './x-property';
import { YProperty } from './y-property';
import { RotateProperty } from './rotate-property';
import { PropertyPanels } from './property-panel';
import { useEditor } from '../editor-context';
import { observer } from 'mobx-react-lite';
import { PanelRow } from '../../components/panel';

export const PositionProperties = observer(() => {
  const { propertiesState } = useEditor();
  const collapsed = propertiesState.collapsedPanels.has(PropertyPanels.Position);

  return (
    <div>
      <PanelHeader>
        <PanelTitle
          collapsed={collapsed}
          onCollapsedChange={(collapsed) =>
            collapsed
              ? propertiesState.collapsePanel(PropertyPanels.Position)
              : propertiesState.expandPanel(PropertyPanels.Position)
          }
        >
          Position
        </PanelTitle>
      </PanelHeader>

      {!collapsed && (
        <PanelRow>
          <XProperty />
          <YProperty />
          <RotateProperty />
        </PanelRow>
      )}
    </div>
  );
});
