import { action, autorun, computed, makeObservable, observable, transaction } from 'mobx';
import { EditorState } from '../EditorState';
import { RectUtils, RectWithSize } from '@paper/models/src/math/rect';
import { Vec2, Vec2Utils } from '@paper/models/src/math/vec2';
import { Size } from '@paper/models/src/math/size';
import { assert } from '../../assert';
import { createSortOrderKey } from '@paper/models/src/file/create-sort-order-key';
import { makeTreeRelationshipString } from '@paper/models/src/file/tree-node-relationships';
import { cloneTempTreeIntoFile } from '../tree/clone-temp-tree-into-file';
import { cloneNodesIntoTempTree } from '../tree/clone-nodes-into-temp-tree';
import { DropTargetResult, findDropTarget } from './find-drop-target';
import { LayoutDirection } from './calculate-dom-layout-direction';
import { TreeNode } from '../tree/TreeNode';
import { moveDraggedNodesToRoot } from './move-dragged-nodes-to-root';
import { moveDraggedNodesToNewParent } from './move-dragged-nodes-to-new-parent';
import { CLICK_VS_DRAG_THRESHOLD_PX, DOUBLE_CLICK_TIME_MS } from './move-tool';
import { SnapAxis } from '../snaps/find-snaps';
import { roundToPointFive } from '@paper/models/src/math/round-to-point-five';
import { IDisposer } from 'mobx-utils';
export class DragState {
  constructor(public editorState: EditorState) {
    makeObservable(this);

    // Dim and z-index nodes as needed while dragging
    // There are two potential problems:
    // Nodes that don't have ref will not be able to dim
    // If a node has opacity set, this will unset it until they re-render (perhaps it could store original values and restore?)
    this.draggedNodeStylerDisposer = autorun(() => {
      if (this.isDragging) {
        const selectedNodes = this.editorState.selectionState.selectedNodes;

        // Loop all dragged nodes
        // 1. If they're coming from a DOM mode parent, they never pop out, they should dim only
        // 2. If they're free form
        // 2A. over a drop target -> they should dim and go to the front if we're over a parent
        // 2B. over the canvas -> they should revert to normal opacity and z-index

        for (const node of selectedNodes) {
          const domEl = node.domEl;
          const wrapperEl = node.wrapperEl;
          if (!domEl || !wrapperEl) {
            console.warn('No domEl found, cannot properly drag-dim for node', node.label);
            continue;
          }

          if (node.isFixedLayout === false) {
            // Coming from DOM mode, always dim and stay put
            domEl.style.opacity = '0.4';
          } else {
            // Coming from free form
            if (this.dropTarget) {
              // Over a drop target, dim and go to the front
              domEl.style.opacity = '0.4';
              wrapperEl.style.zIndex = '1000000';
            } else {
              // Over the canvas, revert to normal opacity and z-index
              domEl.style.removeProperty('opacity');
              wrapperEl.style.removeProperty('z-index');
            }
          }
        }
      } else {
        // Not dragging anymore, reset drag styles
        for (const node of this.editorState.selectionState.selectedNodes) {
          const domEl = node.domEl;
          const wrapperEl = node.wrapperEl;
          if (!domEl || !wrapperEl) {
            console.warn('No domEl found, cannot reset drag-dim for node', node.label);
            continue;
          }
          domEl.style.removeProperty('opacity');
          wrapperEl.style.removeProperty('z-index');
        }
      }
    });
  }
  dispose = () => {
    this.unbindInputHandlers();
    this.draggedNodeStylerDisposer?.();
  };

  /** When we drag nodes, we dim them depending on state, this holds the autorun disposer */
  draggedNodeStylerDisposer: IDisposer | null = null;

  /** True while dragging nodes, includes provisional drag */
  @observable accessor isDragging = false;
  /** True if we're dragging OR if we're in a provisional drag */
  @observable accessor isAtLeastProvisionalDrag = false;

  /** Maps node ids to their original drag positions */
  originalDragPositions: Map<string, { world: Vec2; inContainer: Vec2 }> = new Map();
  /** Keeps track of the bounds of the selection when the drag starts, useful for projecting a target position and then finding snaps */
  originalDragSelectionBounds: RectWithSize | null = null;
  /** When we start dragging, we generate sort keys between each selected node and its previous sibling, which is where we will insert clones if a clone drag happens. The key is the dragged node ID. */
  potentialCloneRelationships: Map<string, string> = new Map();
  /** Drag ghost positions if we're dragging from a DOM layout */
  dragGhostPositions: Map<string, { size: Size; position: Vec2 }> = new Map();
  /** Whether the drag started in a DOM layout or started as freeform */
  dragMode: DragMode | null = null;

  /** Keeps track of where a pointer was when it was first pressed, in viewport space */
  pointerDownInViewport: Vec2 = { x: 0, y: 0 };
  /** Keeps track of where a pointer was when it was first pressed, in world space */
  pointerDownInWorld: Vec2 = { x: 0, y: 0 };
  /** The time that the pointer went down, to detect drags vs clicks */
  pointerDownTime: number | null = null;
  /** For pointer capture, the pointer that was captured during the pointerDown */
  pointerIdToRelease: number | null = null;

  /** After a pointer down, the mouse must move a certain amount before it qualifies as a drag instead of a messy click */
  clickQualifiesAsDrag = false;

  /** True if the pointer was down inside the selection bounds, which if a drag doesn't happen means we should select/deselect based on the click */
  pointerDownIsInSelectionBounds = false;

  @action startProvisionalDrag = (pointerDownIsInSelectionBounds: boolean, pointerIdToRelease: number | null) => {
    // Keep track of where the pointer was when we started the drag
    this.pointerDownInViewport.x = this.editorState.pointerState.cursorPos.x;
    this.pointerDownInViewport.y = this.editorState.pointerState.cursorPos.y;
    this.pointerDownInWorld = this.editorState.cameraState.viewportToWorld(this.pointerDownInViewport, false);
    this.pointerDownTime = Date.now();
    this.pointerDownIsInSelectionBounds = pointerDownIsInSelectionBounds;
    this.pointerIdToRelease = pointerIdToRelease;
    this.isAtLeastProvisionalDrag = true;

    this.bindInputHandlers();
  };

  /** Called publicly to end a drag, whether its provisional (could still be a click) or committed (we're dragging) */
  @action endDrag = () => {
    this.unbindInputHandlers();

    if (this.clickQualifiesAsDrag) {
      // If we made it to a real drag, end it
      this.endCommittedDrag();
    } else {
      // It's a click, never made it to a drag
      if (this.pointerDownIsInSelectionBounds) {
        // We started inside selection bounds and didn't move far enough to qualify as a drag
        // This counts as a click over top of the selection bounds
        this.handleClickInsideSelectionBounds();
      }
    }

    // ----- Cleanup ----- //
    this.potentialCloneRelationships.clear();
    this.originalDragPositions.clear();
    this.isDragging = false;
    this.clickQualifiesAsDrag = false;
    this.pointerDownTime = null;
    this.pointerDownInViewport = { x: 0, y: 0 };
    this.pointerDownInWorld = { x: 0, y: 0 };
    this.pointerDownIsInSelectionBounds = false;
    this.isAtLeastProvisionalDrag = false;
  };

  @action private commitToDragging(dragMode: DragMode) {
    // Treat file changes as transient until the drag is ended
    this.editorState.fileState.fileDataObserver?.startTreatingChangesAsTransient();

    // Set the drag mode
    this.dragMode = dragMode;

    // Clone the original drag positions from the current state
    this.originalDragPositions.clear();
    for (const node of this.editorState.selectionState.selectedNodes) {
      this.originalDragPositions.set(node.id, {
        world: { x: node.xInWorld, y: node.yInWorld },
        inContainer: { x: node.x, y: node.y },
      });
    }
    // Clone the original selection bounds
    assert(this.editorState.selectionState.selectionBoundsRect);
    this.originalDragSelectionBounds = { ...this.editorState.selectionState.selectionBoundsRect };
    // Generate sort keys for each selected node and its previous sibling
    for (const node of this.editorState.selectionState.selectedNodes) {
      const parent = node.parent;
      assert(parent);
      const prevKey = node.previousSibling?.sortKey ?? null;
      const draggedKey = node.sortKey;
      const potentialCloneKey = createSortOrderKey(prevKey, draggedKey);
      const relationship = makeTreeRelationshipString(parent.id, potentialCloneKey);
      this.potentialCloneRelationships.set(node.id, relationship);
    }

    this.dropTarget = null;
    this.isDragging = true;
  }

  /** If we actually started dragging, this ends it */
  @action private endCommittedDrag() {
    const editorState = this.editorState;
    // Stop treating changes as transient (will send a final non-transient edit to the server)
    editorState.fileState.fileDataObserver?.endTreatingChangesAsTransient();

    // ----- Reparenting or re-arranging within flex ----- //
    const newParent = this.dropTargetNode ?? this.editorState.treeUtils.rootNode;
    if (newParent.isRoot || this.dropTargetNode === null || this.dropTargetIndex === null) {
      // Moving to the root gets special behavior that tries to maintain layer index next to original frame, for multi-frame operations
      moveDraggedNodesToRoot(editorState, editorState.selectionState.selectedNodes);
    } else {
      moveDraggedNodesToNewParent(
        editorState,
        editorState.selectionState.selectedNodes,
        newParent,
        this.dropTargetIndex
      );
    }

    // Moving DOM nodes into free form layout so move their position to the drag ghost position
    if (this.dragMode === DragMode.DOM && newParent.isFixedLayout === true) {
      editorState.selectionState.selectedNodes.forEach((node) => {
        const newPos = this.dragGhostPositions.get(node.id)?.position;
        assert(newPos);
        node.setXInWorld(newPos.x);
        node.setYInWorld(newPos.y);
      });
    }

    // Reset any drag ghosts
    this.dragGhostPositions.clear();
    this.dragMode = null;

    // Clear the snaps
    editorState.snapState.clearSnaps();

    if (this.isCloning) {
      this.stopCloning();
    }

    // Request a redraw
    this.editorState.hudState.requestDraw();
  }

  @action private handleInputWhileDragging = () => {
    const editorState = this.editorState;
    const { pointerState, snapState, selectionState, hudState, keyState } = editorState;

    /** If the original position was not on a whole pixel, we'll round to half pixels to maintain it, useful for working with images from retina screens  */
    const roundToHalfPixel =
      Number.isInteger(this.originalDragSelectionBounds?.minX) === false ||
      Number.isInteger(this.originalDragSelectionBounds?.minY) === false;
    const roundingFunction = roundToHalfPixel ? roundToPointFive : Math.round;

    // Calculate how far we've moved the pointer since we started dragging
    const dragVector = Vec2Utils.subtract(pointerState.cursorPosWorld, this.pointerDownInWorld);
    dragVector.x = roundingFunction(dragVector.x);
    dragVector.y = roundingFunction(dragVector.y);

    // Check if the pointer has moved far enough to qualify as a drag
    if (this.clickQualifiesAsDrag === false) {
      assert(this.pointerDownTime);
      const movementVector = Vec2Utils.subtract(pointerState.cursorPos, this.pointerDownInViewport);
      const movementDistance = Vec2Utils.length(movementVector);
      const timeSincePointerDown = Date.now() - this.pointerDownTime;

      // If we haven't moved far enough and it hasn't hit the timing threshold yet, bail out
      if (movementDistance < CLICK_VS_DRAG_THRESHOLD_PX && timeSincePointerDown < DOUBLE_CLICK_TIME_MS) {
        return;
      }

      // We've moved far enough or waited long enough to qualify as a drag
      this.clickQualifiesAsDrag = true;

      const dragMode = determineDragMode(editorState);
      this.commitToDragging(dragMode);
    }

    if (keyState.isAltDown && !this.isCloning) {
      // If alt is held we should perform a clone drag
      this.startCloning();
    } else if (this.isCloning && !keyState.isAltDown) {
      // If alt is released we should stop cloning
      this.stopCloning(true);
    }

    // Look for drop targets
    const dropTarget = findDropTarget(editorState, pointerState.hoveredNode);
    this.setDropTarget(dropTarget);

    // Lock to a single axis if shift is held
    let snapAxis = SnapAxis.XY;
    if (keyState.isShiftDown) {
      if (Math.abs(dragVector.x) > Math.abs(dragVector.y)) {
        dragVector.y = 0;
        snapAxis = SnapAxis.X;
      } else {
        dragVector.x = 0;
        snapAxis = SnapAxis.Y;
      }
    }

    // Find snaps! if the drop target has free form children
    // Remember that null drop target means we're dragging over top of the canvas, and we'd want snaps for that
    if (dropTarget === null || dropTarget?.node.childrenAreFixed) {
      assert(this.originalDragSelectionBounds);
      const targetBoundsPosition = RectUtils.addVector(this.originalDragSelectionBounds, dragVector);
      const snaps = snapState.findSnapsForRect(targetBoundsPosition, snapAxis);
      // Adjust the drag vector by the snap target
      if (snaps.xSnaps.length > 0) {
        dragVector.x += snaps.xSnaps[0]!.distanceSigned;
      }
      if (snaps.ySnaps.length > 0) {
        dragVector.y += snaps.ySnaps[0]!.distanceSigned;
      }
      // Update the snap state's version of our bounds for drawing snaps
      const updatedBoundsForSnaps = RectUtils.addVector(this.originalDragSelectionBounds, dragVector);
      snapState.setCurrentBounds(updatedBoundsForSnaps);
    } else {
      // Drop target is using DOM layout so we shouldn't show any snaps
      if (snapState.currentSnaps !== null) {
        // Had leftover snaps from previous drop target, clear them
        snapState.clearSnaps();
      }
    }

    // Update the node positions (inside one mobx transaction)
    transaction(() => {
      for (const node of selectionState.selectedNodes) {
        const originalDragPosition = this.originalDragPositions.get(node.id);
        if (!originalDragPosition) {
          continue;
        }

        if (this.dragMode === DragMode.FREEFORM) {
          // Free mode - update the node's position
          node.setXInWorld(roundingFunction(originalDragPosition.world.x + dragVector.x));
          node.setYInWorld(roundingFunction(originalDragPosition.world.y + dragVector.y));
        } else {
          // DOM mode, just move the ghosts
          const existingGhost = this.dragGhostPositions.get(node.id);
          if (existingGhost) {
            // Move existing ghost
            const newPos = Vec2Utils.add(originalDragPosition.world, dragVector);
            existingGhost.position.x = newPos.x;
            existingGhost.position.y = newPos.y;
          } else {
            // Create new ghost since it doesn't exist yet
            this.dragGhostPositions.set(node.id, {
              size: { width: node.width, height: node.height },
              position: Vec2Utils.add(originalDragPosition.world, dragVector),
            });
          }
        }
      }
    });

    // If we move to an Event system, perhaps the HUD could subscribe to this instead of coding it here
    hudState.requestDraw();
  };

  /** Binds pointer handlers to the editor state */
  bindInputHandlers = () => {
    this.editorState.tickState.subToTick(this.handleInputWhileDragging);
    window.addEventListener('pointerup', this.handlePointerUp);
    window.addEventListener('keydown', this.handleKeyDown);
  };
  /** Unbinds pointer handlers from the editor state */
  unbindInputHandlers = () => {
    this.editorState.tickState.unsubToTick(this.handleInputWhileDragging);
    window.removeEventListener('pointerup', this.handlePointerUp);
    window.removeEventListener('keydown', this.handleKeyDown);

    if (this.pointerIdToRelease && this.editorState.hudState.hudEl) {
      // Release pointer capture (the MoveTool uses the hudEl as its pointerDown element)
      this.editorState.hudState.hudEl.releasePointerCapture(this.pointerIdToRelease);
    }
  };
  handlePointerUp = (e: PointerEvent) => {
    e.preventDefault(); // prevent browser behavior for wheel clicks and thumb clicks
    e.stopPropagation(); // pointer events on the canvas should never go anywhere else
    this.endDrag();
  };

  /** Escape key should end the drag */
  handleKeyDown = (e: KeyboardEvent) => {
    if (this.isDragging && e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      this.endDrag();
    }
  };

  // ----- Drop targets during drag ----- //
  @observable accessor dropTarget: DropTargetResult | null = null;
  @computed get dropTargetNode(): TreeNode | null {
    return this.dropTarget?.node ?? null;
  }
  @computed get dropTargetIndex(): number | null {
    return this.dropTarget?.insertAt ?? null;
  }
  @computed get dropTargetLayoutDirection(): LayoutDirection | null {
    return this.dropTarget?.layoutDirection ?? null;
  }
  @action setDropTarget = (dropTarget: DropTargetResult | null) => {
    // Try to avoid making changes if nothing has changed:
    if (this.dropTarget === null && dropTarget === null) return; // no change
    if (this.dropTarget !== null && dropTarget !== null) {
      // Check for no change in node and insert index:
      if (this.dropTarget.node === dropTarget.node && this.dropTarget.insertAt === dropTarget.insertAt) return; // no change
    }

    // ----- PROTOTYPE this is all prototype code, refactor when happy
    // TODO: one more thought here, if the node is currently inside an artboard
    // and it isn't the artboard of the drop target
    // move the node to the very last item in the drop target's artboard
    // or it will be clipped and invisible
    // To decide:
    // if the current parent is the canvas, it's visible if it's lower than the artboard
    // but not visible if it's higher than the artboard.
    // I'd like to not snap it into the artboard as you're moving, so maybe we hack dragged things
    // to have a higher z-index or always render on top? But that's kind of weird because
    // when dragging free form vs free form the dragged item shouldn't go above other things
    // maybe artboards get special treatment to always go below dragged items? hmmm
    // the options are:
    // 1. hack the z-index of dragged things to go over top of artboards always, but not other canvas items
    // -----> Vlad's idea: wait for a drop target and then if there's a drop target, it goes over top
    // 2. snap the node into the artboard immediately if the mouse goes over it (or with a debounce on mouse movement)
    // 3. let things below the artboard just not be visible when dragging them (this sucks not really an option)

    // I guess the act of dragging something over an artboard indicates you want to see it on top of that artboard
    // so the question is really should the item clip (move into the artboard immediately)
    // or not clip (hack the z-index to appear above the artboard while maintaing its free form spot)
    // I think DOM->drags will not clip, so maybe it's good to match that behavior
    // on the other hand, clipping shows me more what the real final result of my drag will be
    const { selectionState, treeUtils } = this.editorState;
    if (dropTarget === null && this.dragMode === DragMode.FREEFORM) {
      // If it's a drag of freeform nodes and we're over the canvas
      // we should pop nodes out to root
      for (const node of selectionState.selectedNodes) {
        if (node.parentId !== treeUtils.rootNode.id) {
          moveDraggedNodesToRoot(this.editorState, [node]);
        }
      }
    }
    // Possible TODO: move nodes back into their original parents if freeform drag goes out into canvas and comes back into the same node
    // --- end prototype code

    if (!dropTarget) {
      this.dropTarget = null;
      return;
    }

    const node = dropTarget.node;

    // Drop targets cannot include anything in the current clones
    if (node && this.isCloning && this.currentCloneNodeIds.has(node.id)) {
      this.dropTarget = null;
      return;
    }

    // Drop targets cannot include anything in the current selection
    if (node && this.editorState.selectionState.selectedNodeIds.has(node.id)) {
      this.dropTarget = null;
      return;
    }

    this.dropTarget = dropTarget;
  };

  // ----- Cloning drag ----- //
  @observable accessor isCloning = false;
  /** Stores the current clones until they're final so we can avoid using them for drop targets */
  @observable accessor currentCloneNodeIds: Set<string> = new Set();
  @action startCloning = () => {
    this.isCloning = true;

    // Queue a cloning of the selected nodes to avoid blocking the UI
    requestIdleCallback(
      action(() => {
        const tempTree = cloneNodesIntoTempTree(
          this.editorState,
          Array.from(this.editorState.selectionState.selectedNodeIds)
        );

        // Build a map of the cloned node IDs to the original node relationships (remember the IDs are changing twice, once when cloned into the temp tree and once when cloned into the file)
        const topLevelNodeRelationships: Record<string, string> = {};
        for (const [originalId, relationship] of this.potentialCloneRelationships) {
          const clonedId = tempTree.oldIdToNewIdMap[originalId];
          assert(typeof clonedId === 'string');
          topLevelNodeRelationships[clonedId] = relationship;
        }

        // Reposition top level clones at their original positions (we're still on the original + first clone IDs here)
        for (const [originalId, dragPosition] of this.originalDragPositions) {
          const clonedId = tempTree.oldIdToNewIdMap[originalId];
          assert(typeof clonedId === 'string');
          const clonedNode = tempTree.nodes[clonedId];
          assert(clonedNode);
          clonedNode.x = dragPosition.inContainer.x;
          clonedNode.y = dragPosition.inContainer.y;
        }

        // Clone the nodes back into the file – this will result in new IDs for all the nodes
        const clonedIdMap = cloneTempTreeIntoFile(this.editorState, tempTree, topLevelNodeRelationships);
        this.currentCloneNodeIds = new Set(clonedIdMap.values());
      })
    );
  };
  /** Stops cloning and deletes any clones that we created */
  @action stopCloning = (deleteNodes: boolean = false) => {
    // Queue a deletion of the clones to avoid blocking the UI
    requestIdleCallback(
      action(() => {
        if (deleteNodes) {
          this.editorState.treeUtils.deleteNodes(Array.from(this.currentCloneNodeIds));
        }
        this.currentCloneNodeIds.clear();
        this.isCloning = false;
      })
    );
  };

  /** Handles a click inside selection bounds that didn't end up dragging */
  @action handleClickInsideSelectionBounds = () => {
    const { pointerState, selectionState, layerTreeState, keyState } = this.editorState;
    if (pointerState.hoveredNode) {
      const hoveredNodeId = pointerState.hoveredNode.id;
      if (keyState.isShiftDown) {
        // If shift is held, we should remove or add the clicked node from the selection depending on whether it's already in the selection
        if (selectionState.selectedNodeIds.has(hoveredNodeId)) {
          selectionState.removeSelectedNodeIds([hoveredNodeId]);
        } else {
          selectionState.selectIds([hoveredNodeId], { additive: true });
          layerTreeState.ensureNodesAreVisibleInLayerTree([pointerState.hoveredNode]);
        }
      } else {
        // Click was on a specific node with no modifier key:
        // if normal node: unselect everything else and select this node
        // if "unselectable" node, this should behave like clicking in blank canvas, so we should deselect everything
        if (pointerState.hoveredNode.isNonSelectableFrame === true) {
          // Clicked on an unselectable node, deselect everything
          selectionState.selectIds([]);
        } else {
          // Normal node, unselect everything else and select this node
          selectionState.selectIds([hoveredNodeId]);
          layerTreeState.ensureNodesAreVisibleInLayerTree([pointerState.hoveredNode]);
        }
      }
    } else {
      // Click was on blank canvas, deselect everything
      selectionState.selectIds([]);
    }
  };
}

export enum DragMode {
  /** When a drag starts inside a DOM layout */
  DOM = 0,
  /** When a drag starts with freeform dragging, not influenced by DOM layout */
  FREEFORM = 1,
}

/** Determines which type of drag mode we should use based on the selected nodes
 * If all of the selected nodes are in a DOM layout, we should use DOM mode
 * Otherwise, we should use FREEFORM mode
 * This means we'll use FREEFORM mode if we have mixed selection types (DOM and freeform)
 * which I think will lead to more desirable behavior (reparenting everything together as a freeform unit)
 */
export function determineDragMode(editorState: EditorState): DragMode {
  const { selectionState } = editorState;
  const useDom = selectionState.selectedNodes.every((node) => node.isFixedLayout === false);
  return useDom ? DragMode.DOM : DragMode.FREEFORM;
}
