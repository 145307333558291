export const ShaderToolIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentcolor" {...props}>
      <path d="M11.025 2.99819C11.3233 2.83556 11.6839 2.83556 11.9822 2.9982L19.4785 7.0844C19.7999 7.25959 19.9999 7.59638 19.9999 7.96243V16.0374C19.9999 16.4034 19.7999 16.7402 19.4785 16.9154L11.9822 21.0016C11.6839 21.1643 11.3233 21.1643 11.025 21.0016L3.52871 16.9154C3.20732 16.7402 3.00732 16.4034 3.00732 16.0374V7.96243C3.00732 7.59638 3.20732 7.25959 3.52871 7.0844L11.025 2.99819Z" />
      <path d="M11.5034 20.7406V14.7234" />
      <path d="M8.92457 10.9126L3.47803 7.76807" />
      <path d="M14.0847 10.9126L19.5312 7.76807" />
      <circle cx="11.5" cy="12.0" r="2.7" />
    </svg>
  );
};
