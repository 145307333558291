import { useContext, useEffect, useRef } from 'react';
import * as Slider from '@radix-ui/react-slider';
import { useEditor } from '../editor-context';
import { ColorPickerContext } from './color-picker';
import { convertHslToOklch, convertOklchToHsl } from '@paper/models/src/colors/color-conversion';
import { roundOptimized } from '@paper/models/src/math/round-optimized';
import { ColorUtils } from '@paper/models/src/colors/Color';
import { findClosestColorInGamut } from './hue-slice';
import clsx from 'clsx';

export function ColorPickerSliderHueHsl() {
  const { fileState } = useEditor();
  const { color, onColorChange } = useContext(ColorPickerContext);
  const hsl = convertOklchToHsl(color.value);

  return (
    <Slider.Root
      inverted
      step={1}
      min={0}
      max={359.99}
      value={[roundOptimized(hsl.h, 2) % 360]} // Flip to 0 after 359.99
      orientation="vertical"
      className="ColorPickerOutline ColorPickerSliderHueHsl"
      onPointerDown={() => fileState.fileDataObserver.startTreatingChangesAsTransient()}
      onPointerUp={() => fileState.fileDataObserver.endTreatingChangesAsTransient()}
      onValueChange={([value]: [number]) => {
        const oklch = convertHslToOklch({ ...hsl, h: value });
        onColorChange({ gamut: 'rgb', value: oklch });
      }}
    >
      <Slider.Thumb className="ColorPickerSliderThumb" tabIndex={-1} />
    </Slider.Root>
  );
}

export function ColorPickerSliderHueUniform() {
  const { fileState } = useEditor();
  const { tab, color, onColorChange } = useContext(ColorPickerContext);
  const offset = 28.95813708570438; // Start at display-p3 1 0 0

  // When using the slider, we'll use the reference color to find the closest color in gamut in the new hue.
  const referenceColor = useRef(color);

  // Track which color the hue slider commits. We'll reset the reference color when another input type is used.
  // (That is, if you keep using just the hue slider, it will attempt to match the original color in chroma/lightness).
  const committedColor = useRef(color);

  useEffect(() => {
    if (ColorUtils.isEqual(committedColor.current, color) === false) {
      referenceColor.current = color;
    }
  }, [color]);

  return (
    <Slider.Root
      value={[(color.value.h + 360 - offset) % 360]}
      max={359.999} // Don't flip to 0 when reaching 360
      min={0}
      step={1}
      inverted
      orientation="vertical"
      className={clsx('ColorPickerOutline', 'ColorPickerSliderHueUniform', {
        ColorPickerSliderHueUniformP3: tab === 'p3',
        ColorPickerSliderHueUniformRgb: tab === 'rgb',
      })}
      onPointerDown={() => {
        // TODO Vlad same for keydown/keyup for all sliders?
        fileState.fileDataObserver.startTreatingChangesAsTransient();
      }}
      onPointerUp={() => {
        fileState.fileDataObserver.endTreatingChangesAsTransient();
      }}
      onValueChange={([value]: [number]) => {
        committedColor.current = {
          gamut: tab,
          value: findClosestColorInGamut({ ...referenceColor.current.value, h: (value + offset) % 360 }, tab),
        };
        onColorChange(committedColor.current);
      }}
    >
      <Slider.Thumb className="ColorPickerSliderThumb" tabIndex={-1} />
    </Slider.Root>
  );
}

export function ColorPickerSliderOpacity() {
  const { fileState } = useEditor();
  const { tab, color, onColorChange } = useContext(ColorPickerContext);

  return (
    <Slider.Root
      value={[color.value.alpha ?? 1]}
      max={1}
      min={0}
      step={0.01}
      orientation="vertical"
      className="ColorPickerSliderOpacity"
      onPointerDown={() => fileState.fileDataObserver.startTreatingChangesAsTransient()}
      onPointerUp={() => fileState.fileDataObserver.endTreatingChangesAsTransient()}
      onValueChange={([value]: [number]) => {
        onColorChange({ gamut: tab, value: { ...color.value, alpha: value } });
      }}
    >
      <Slider.Thumb className="ColorPickerSliderThumb" tabIndex={-1} />
    </Slider.Root>
  );
}
