import { observable, makeObservable, action } from 'mobx';
import { EditorState } from '../EditorState';
import { FileType } from '@paper/models/src/file/file-schema';
import { assert } from '../../assert';
import { FileEditMessage } from '@paper/models/src/websocket/file-edit-message';
import { MessageType, typeKey } from '@paper/models/src/websocket/socket-message';
import { FileDataObserver } from './FileDataObserver';
import { Edit } from '@paper/models/src/delta/edit';
import { MeasureNodePositionAndSize } from './MeasureNodePositionAndSize';

export class FileState {
  constructor(
    public editorState: EditorState,
    fileData: FileType
  ) {
    makeObservable(this);
    // Hydrate the file data
    this.data = observable(fileData);

    // Wire up File Observer for undo/redo and persistence
    this.fileDataObserver = new FileDataObserver(this);

    this.measureNodePositionAndSize = new MeasureNodePositionAndSize(this);
  }
  dispose = () => {
    if (this.fileDataObserver !== null) {
      this.fileDataObserver.dispose();
    }
  };

  /** Watches the file data for changes */
  fileDataObserver: FileDataObserver;

  /** Measures the size and position of nodes as needed */
  measureNodePositionAndSize: MeasureNodePositionAndSize;

  /**
   * This is the main file data in plain JSON format.
   */
  @observable accessor data: FileType;

  @action
  makeLabelForComponent = (component: string) => {
    this.data.labelCounter[component] ??= 0;
    this.data.labelCounter[component]! += 1;
    return `${component} ${this.data.labelCounter[component]}`;
  };

  /**
   * Sends a file edit message to the server.
   *
   * If transient is true, the edit will only be rebroadcast to other clients, it will not be saved to the file (useful for dragging and resizing)
   */
  sendFileEdit = (edit: Edit, transient?: boolean) => {
    if (transient === true && this.editorState.multiplayerState.isSinglePlayer) {
      // No need to send transient edits in single player mode
      return;
    }

    requestIdleCallback(() => {
      const message: FileEditMessage = {
        [typeKey]: MessageType.FileEdit,
        fileId: this.data.id,
        edit,
      };

      if (transient === true) {
        message.transient = true;
      }

      this.editorState.socketState.socket?.send(JSON.stringify(message));
    });
  };
}
