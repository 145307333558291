import { computed, makeObservable } from 'mobx';
import { Vec2Utils } from '@paper/models/src/math/vec2';
import { action } from 'mobx';
import { observable } from 'mobx';
import { EditorState } from '../EditorState';
import { Vec2 } from '@paper/models/src/math/vec2';
import { RectUtils } from '@paper/models/src/math/rect';
import { chooseSelectionFromBrush } from './choose-selection-from-brush';
import { assert } from '@paper/models/src/assert';
import { CLICK_VS_DRAG_THRESHOLD_PX } from './move-tool';

export class SelectionBrushState {
  constructor(private editorState: EditorState) {
    makeObservable(this);
  }
  dispose = () => {
    this.unbindInputHandlers();
  };

  /** True if we're currently in a selection brush, whether provisional or committed */
  @observable accessor isSelectBrushing = false;
  /** The first point of the selection brush */
  @observable accessor brushPoint1: Vec2 | null = null;
  /** The second point of the selection brush */
  @observable accessor brushPoint2: Vec2 | null = null;

  /** The set of node IDs that were selected before the selection brush started */
  selectionIdsBeforeBrush: Set<string> = new Set();
  /** True if the click that started the selection brush has committed to the selection brush */
  clickCountsAsBrush: boolean = false;
  /** The pointer ID that we'll release when the selection brush ends */
  pointerIdForCaptureRelease: number | null = null;
  /** The pointer down position in world space */
  pointerDownInViewport: Vec2 = { x: 0, y: 0 };

  /** Start a new selection brush, either provisional, which means a click that might turn into a brush with movement, or immediately committed to the brush */
  @action startProvisionalSelectionBrush(
    pointerIdForCaptureRelease: number,
    commitToBrushingImmediately: boolean = false
  ) {
    this.pointerIdForCaptureRelease = pointerIdForCaptureRelease;
    this.isSelectBrushing = true;
    this.pointerDownInViewport = { ...this.editorState.pointerState.cursorPos };
    if (commitToBrushingImmediately) {
      this.commitToSelectionBrush();
    }

    this.bindInputHandlers();
  }

  /** The public function to end a brush, whether provisional or committed */
  @action endSelectionBrush() {
    if (this.isSelectBrushing) {
      this.stopCommittedSelectionBrush();
    }

    // Clean up
    this.unbindInputHandlers();
    this.isSelectBrushing = false;
    this.brushPoint1 = null;
    this.brushPoint2 = null;
    this.clickCountsAsBrush = false;

    // Redraw the lack of selection brush
    this.editorState.hudState.requestDraw();
  }

  /** We're starting a full selection brush */
  @action private commitToSelectionBrush() {
    const { selectionState, pointerState } = this.editorState;

    this.clickCountsAsBrush = true;
    this.brushPoint1 = { ...pointerState.cursorPosWorld };
    this.brushPoint2 = { ...pointerState.cursorPosWorld };
    this.selectionIdsBeforeBrush = new Set(selectionState.selectedNodeIds);
    this.editorState.undoManager.startIgnoringChanges('selection-brush');
  }

  /** We're ending a full selection brush */
  @action private stopCommittedSelectionBrush = () => {
    const { selectionState, layerTreeState, undoManager } = this.editorState;

    // We ignore Undo changes while brushing so we need to add the selection change manually
    undoManager.stopIgnoringChanges('selection-brush');
    const hadNoSelectionChanges =
      this.selectionIdsBeforeBrush.size === selectionState.selectedNodeIds.size &&
      [...this.selectionIdsBeforeBrush].every((id) => selectionState.selectedNodeIds.has(id));
    if (hadNoSelectionChanges === false) {
      // Selection brush resulted in changes, add the old selection to the Undo history
      undoManager.addSelectionChange(Array.from(this.selectionIdsBeforeBrush));
    }
    this.selectionIdsBeforeBrush.clear();

    // Make sure selected nodes are visible in the layer tree
    if (selectionState.selectedNodeIds.size > 0) {
      layerTreeState.ensureNodesAreVisibleInLayerTree(selectionState.selectedNodes);
    }
  };

  @action private handleInputWhileBrushing = () => {
    const { pointerState, keyState, treeUtils, selectionState } = this.editorState;

    // Check if the pointer has moved far enough to qualify as a drag
    if (this.clickCountsAsBrush === false) {
      // We only care about movement for commiting to brush (not time like drag)
      const movementVector = Vec2Utils.subtract(pointerState.cursorPos, this.pointerDownInViewport);
      const movementDistance = Vec2Utils.length(movementVector);
      // If we haven't moved far enough and it hasn't hit the timing threshold yet, bail out
      if (movementDistance < CLICK_VS_DRAG_THRESHOLD_PX) {
        return;
      }

      // Remove the item that was selected by the click
      // NOTE: I don't know if this is necessary since the selected unselectable node requires enclosure, which can't happen from
      // the initial click point, so it will deselect iself anyway... commenting out for now, but if we need it, here's where it goes
      //   if (selectionState.mostRecentlySelectedNode) {
      //     selectionState.removeSelectedNodeIds([selectionState.mostRecentlySelectedNode.id]);
      //   }

      // We've moved far enough or waited long enough to qualify as a selection brush instead of a selection click
      this.clickCountsAsBrush = true;
      this.commitToSelectionBrush();
    }

    // If space is held, we move the brush instead of resizing it
    const moveInsteadOfResize = keyState.keyCodeDown.has('Space');

    if (moveInsteadOfResize && this.brushPoint2 && this.brushPoint1) {
      // Update point 1 by the same amount we're moving point 2
      const vectorOfMovement = Vec2Utils.subtract(pointerState.cursorPosWorld, this.brushPoint2);
      this.brushPoint1 = Vec2Utils.add(this.brushPoint1, vectorOfMovement);
    }
    // Update point 2
    this.brushPoint2 = { ...pointerState.cursorPosWorld };

    // Get all the nodes that intersect with the brush rect
    const matchingNodes = treeUtils.treeNodesFromRect(this.brushRect);
    const newSelection = chooseSelectionFromBrush(matchingNodes, keyState.isMetaDown);

    // Update the selection state if we've found anything or if we currently have a previous selection
    if (newSelection.length > 0 || selectionState.selectedNodeIds.size > 0 || this.selectionIdsBeforeBrush.size > 0) {
      const newSelectionIds = new Set<string>(newSelection);

      // Shift key behavior
      if (keyState.isShiftDown) {
        // Any IDs that were selected pre-brush should be removed if they're found inside the brush
        // And we should persist the IDs that were selected pre-brush if they're not found inside the brush
        for (const nodeId of this.selectionIdsBeforeBrush) {
          newSelectionIds.has(nodeId) ? newSelectionIds.delete(nodeId) : newSelectionIds.add(nodeId);
        }
      }

      // We're pre-computing additive behavior so we don't pass an additive option here
      selectionState.selectIds(newSelectionIds);
    }
    // Redraw the new selection brush
    this.editorState.hudState.requestDraw();
  };

  /** Binds pointer handlers to the editor state */
  bindInputHandlers = () => {
    this.editorState.tickState.subToTick(this.handleInputWhileBrushing);
    window.addEventListener('pointerup', this.handlePointerUp);
  };
  /** Unbinds pointer handlers from the editor state */
  unbindInputHandlers = () => {
    this.editorState.tickState.unsubToTick(this.handleInputWhileBrushing);
    window.removeEventListener('pointerup', this.handlePointerUp);

    if (this.pointerIdForCaptureRelease && this.editorState.hudState.hudEl) {
      // Release pointer capture (the MoveTool uses the hudEl as its pointerDown element)
      this.editorState.hudState.hudEl.releasePointerCapture(this.pointerIdForCaptureRelease);
    }
  };
  handlePointerUp = (e: PointerEvent) => {
    e.preventDefault(); // prevent browser behavior for wheel clicks and thumb clicks
    e.stopPropagation(); // pointer events on the canvas should never go anywhere else
    this.endSelectionBrush();
  };

  /** The current size of the selection brush */
  @computed({ keepAlive: true }) get brushRect() {
    if (!this.brushPoint1 || !this.brushPoint2) {
      return RectUtils.empty();
    }
    return RectUtils.fromPoints([this.brushPoint1, this.brushPoint2]);
  }
}
