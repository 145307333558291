import { EditorState, GRID_SIZE } from '../EditorState';
import { TreeNode } from './TreeNode';
import { assert } from '../../assert';
import { RectUtils, RectWithSize } from '@paper/models/src/math/rect';
import { createMultipleSortOrderKeys } from '@paper/models/src/file/create-sort-order-key';
import { createTreeNodeData, TreeNodeType } from '@paper/models/src/file/tree-node-schema';
import { action, transaction } from 'mobx';
import { median } from '@paper/models/src/math/median';
import { getBoundsForNodes } from './get-bounds-for-nodes';

export const componentsThatOfferFlex = new Set(['Box', 'Text']);

/**
 * Wraps the selected nodes in a new node with the provided component ID
 * If the provided nodes were previouly in separate parents, we'll make multiple new wrappers, one per parent-grouping
 */
export const wrapNodesWithFlex = action((editorState: EditorState, nodes: Array<TreeNode>) => {
  // Group the nodes by their parent
  const nodesByParentId: Record<string, Array<TreeNode>> = {};
  for (const node of nodes) {
    const parent = node.parent;
    if (parent) {
      nodesByParentId[parent.id] ??= [];
      nodesByParentId[parent.id]!.push(node);
    }
  }

  // For each parent group, create a new wrapper node and put the children into it
  transaction(() => {
    for (const originalParentId of Object.keys(nodesByParentId)) {
      wrapSameParentNodes(editorState, nodesByParentId[originalParentId]!);
    }
  });
});

/** Wraps a specific set of same-parent nodes with a new wrapper node */
const wrapSameParentNodes = action((editorState: EditorState, nodes: Array<TreeNode>) => {
  const { fileState, treeUtils, selectionState, layerTreeState } = editorState;

  // Grab the old parentNode
  const originalParentNode = nodes[0]!.parent!;

  // Create a new wrapper node
  const newNodeData = createTreeNodeData();
  newNodeData.component = 'Box';
  newNodeData.label = fileState.makeLabelForComponent('Box');
  const newWrapper = treeUtils.addNode(newNodeData, originalParentNode.id);

  // Find the highest sort key of the children, we'll use it for the new wrapper node
  let highestSortKey = null;
  for (const node of nodes) {
    if (highestSortKey === null || node.sortKey! > highestSortKey) {
      highestSortKey = node.sortKey!;
    }
  }
  assert(highestSortKey !== null, 'Unexpected: highestSortKey should be found.');

  // Analyze the layout of the children to determine the best layout settings
  const layoutAnalysis = analyzeLayoutForFlexSettings(nodes, 'wrapping-children-in-new-flex');

  // Move the children into the new wrapper
  const sortKeys = createMultipleSortOrderKeys(layoutAnalysis.sortedNodes.length, null, null);
  for (let i = 0; i < sortKeys.length; i++) {
    treeUtils.moveNodeToNewParent(layoutAnalysis.sortedNodes[i]!, newWrapper, sortKeys[i]!);
  }

  // Apply the flex settings to the new wrapper
  const reorderChildren = false; // we already reordered the children when we moved them into the new wrapper so we can skip it here
  addFlexToNode(editorState, newWrapper, layoutAnalysis, reorderChildren);

  // Update the sort key of the new wrapper node to place it where the last child previously was
  treeUtils.moveNodeWithinParent(newWrapper, highestSortKey);

  // If the new wrapper is inserting into fixed layout, it will need explicit position
  // (if it's inserting into DOM layout, we can let the flex layout engine handle this and then measure it)
  if (newWrapper.isFixedLayout) {
    // Match the wrapper to the children's bounds
    newWrapper.setXInWorld(layoutAnalysis.newBounds.minX);
    newWrapper.setYInWorld(layoutAnalysis.newBounds.minY);
  }

  // Select the new wrapper node
  selectionState.selectIds([newWrapper.id]);
  // Make sure the layer tree is visible for the wrapper node
  layerTreeState.ensureNodesAreVisibleInLayerTree([newWrapper]);
});

/**
 * Takes a target node and an analysis of its children and converts it to flex while maintaining as much layout as possible
 * - Will do a layout analysis to try and maintain the general shape and order of the free form nodes
 * - You can optionally pass a layout analysis if you already did one, like if you created a new wrapper and needs to insert the children in order
 * - If reorderChildren is true, the children will be reordered in the sorted order from the layout analysis
 */
export function addFlexToNode(
  editorState: EditorState,
  node: TreeNode,
  existingLayoutAnalysis?: LayoutAnalysisForFlex,
  reorderChildren: boolean = true
) {
  // Do a layout analysis if we need one
  const layoutAnalysis = existingLayoutAnalysis ?? analyzeLayoutForFlexSettings(node.children, 'adding-flex-to-parent');

  transaction(() => {
    // Apply the flex settings to the node
    node.setStyle('display', 'flex');
    node.setStyle('flexDirection', layoutAnalysis.direction);
    node.setStyle('justifyContent', 'start');
    node.setStyle('alignItems', layoutAnalysis.alignItems);
    node.setStyle('gap', layoutAnalysis.gap);
    node.setStyle('paddingInline', layoutAnalysis.paddingX);
    node.setStyle('paddingBlock', layoutAnalysis.paddingY);

    // If node is not in DOM layout, set it to auto size
    // This helps it adjust to new padding potentially making it larger
    if (node.isFixedLayout === true && node.children.length > 0) {
      node.setStyle('width', 'auto');
      node.setStyle('height', 'auto');
    }

    // Set children to flex-shrink 0, flex-grow 0, and flex-basis auto
    // so they don't resize when resizing the wrapper
    for (const child of node.children) {
      child.setStyle('flexShrink', '0');
      child.setStyle('flexGrow', '0');
      child.setStyle('flexBasis', 'auto');
    }

    // Reorder the children in the sorted order from the layout analysis
    if (reorderChildren === true) {
      // Reorder the children to match the layout analysis
      const sortKeys = createMultipleSortOrderKeys(layoutAnalysis.sortedNodes.length, null, null);
      for (let i = 0; i < sortKeys.length; i++) {
        editorState.treeUtils.moveNodeToNewParent(layoutAnalysis.sortedNodes[i]!, node, sortKeys[i]!);
      }
    }
  });
}

/** Removes flex from a node while maintaining the layout of the node and its children */
export function removeFlexFromNode(node: TreeNode) {
  // Explicitly set children positions so they don't move when removing the flex
  for (const child of node.children) {
    child.setXInWorld(child.xInWorld);
    child.setYInWorld(child.yInWorld);
  }

  // If the node width or height auto, change it to hard set width so it does not collapse without children contents
  if (node.styles?.width === 'auto') {
    node.setStyle('width', node.width);
  }
  if (node.styles?.height === 'auto') {
    node.setStyle('height', node.height);
  }

  // Clear out flex settings
  node.setStyle('display', undefined);
  node.setStyle('flexDirection', undefined);
  node.setStyle('justifyContent', undefined);
  node.setStyle('alignItems', undefined);
  node.setStyle('gap', undefined);
  node.setStyle('paddingInline', undefined);
  node.setStyle('paddingBlock', undefined);
  node.setStyle('paddingTop', undefined);
  node.setStyle('paddingBottom', undefined);
  node.setStyle('paddingLeft', undefined);
  node.setStyle('paddingRight', undefined);
}

/**
 * Studies a group of free form positioned nodes and tries to determine the best matching settings for flex layout:
 * - direction: row or column
 * - alignItems: how to align the nodes within the group
 * - gap: the average gap between nodes
 * - paddingX: additional padding on the left or right side of the group
 * - paddingY: additional padding on the top or bottom of the group
 * - sortedNodes: the nodes sorted in the best matching direction
 *
 * Note: it is expected that all nodes share a common parent
 */
export function analyzeLayoutForFlexSettings(
  nodes: Array<TreeNode>,
  addOrWrap: 'adding-flex-to-parent' | 'wrapping-children-in-new-flex'
): LayoutAnalysisForFlex {
  // Ensure all nodes share a common parent
  let parent: TreeNode | null = null;
  for (const node of nodes) {
    if (parent === null) {
      parent = node.parent!;
    } else if (node.parent !== parent) {
      throw new Error('Unexpected: all nodes must share a common parent in analyzeFreeFormLayout');
    }
  }

  if (nodes.length === 0 || !parent) {
    // There are no nodes to analyze, return defaults
    return {
      direction: 'row',
      alignItems: 'start',
      newBounds: { minX: 0, minY: 0, maxX: 0, maxY: 0, width: 0, height: 0 },
      gap: 0,
      paddingX: 0,
      paddingY: 0,
      sortedNodes: [],
    };
  }

  // Determine the direction and sort the nodes:
  const { direction, alignItems } = getBestDirectionAndAlignment(nodes, 'row');

  // Find the existing bounds of all the children
  const childrenBounds = getBoundsForNodes(nodes);

  // Sort the nodes in the best direction
  const sortedNodes = [...nodes];
  if (direction === 'row') {
    sortedNodes.sort((a, b) => a.xInWorld - b.xInWorld);
  } else {
    sortedNodes.sort((a, b) => a.yInWorld - b.yInWorld);
  }

  // Determine the gap:
  const gap = Math.max(getBestGap(sortedNodes, direction), 0);

  // Determine the padding.
  // Wrapping in a new flex should always have 0 padding, but adding to a new parent should analyze the children to parent positions
  let paddingX = 0;
  let paddingY = 0;
  if (addOrWrap === 'adding-flex-to-parent') {
    const paddingResults = getBestPadding(parent!, childrenBounds);
    paddingX = paddingResults.paddingX;
    paddingY = paddingResults.paddingY;
  }

  // Determine the total bounds
  const newBounds = getNewTotalBounds(sortedNodes, childrenBounds, gap, paddingX, paddingY, direction);

  return {
    direction,
    alignItems,
    newBounds,
    gap,
    paddingX,
    paddingY,
    sortedNodes,
  };
}

/** Takes an array of nodes and determines whether their centers are more like a row or a column */
function getBestDirectionAndAlignment(
  nodes: Array<TreeNode>,
  defaultIfUndetermined: 'row' | 'column'
): {
  direction: 'row' | 'column';
  alignItems: 'center' | 'start' | 'end';
} {
  if (nodes.length < 2) return { direction: defaultIfUndetermined, alignItems: 'start' };

  // One easy fairly accurate way to determine layout direction is to add the total delta of centers of each rectangle on each axis
  // and then see which axis has the most delta... a row will have a lot of travel on the x-axis and a column will have a lot of travel on the y-axis
  let xDeltas = {
    left: 0,
    center: 0,
    right: 0,
  };
  let yDeltas = {
    top: 0,
    center: 0,
    bottom: 0,
  };
  for (let i = 1; i < nodes.length; i++) {
    const prev = nodes[i - 1]!;
    const curr = nodes[i]!;
    xDeltas.left += Math.abs(curr.bounds.minX - prev.bounds.minX);
    xDeltas.center += Math.abs(RectUtils.center(curr.bounds).x - RectUtils.center(prev.bounds).x);
    xDeltas.right += Math.abs(curr.bounds.maxX - prev.bounds.maxX);
    yDeltas.top += Math.abs(curr.bounds.minY - prev.bounds.minY);
    yDeltas.center += Math.abs(RectUtils.center(curr.bounds).y - RectUtils.center(prev.bounds).y);
    yDeltas.bottom += Math.abs(curr.bounds.maxY - prev.bounds.maxY);
  }

  // To determine direction, compare the deltas for the centers – imagine drawing a line through whichever has less variation to determine overall direction
  const direction = xDeltas.center > yDeltas.center ? 'row' : 'column';

  // To determine alignment, pick the smallest delta between the starting, center, and ending edges
  let alignItems: 'start' | 'center' | 'end';
  if (direction === 'row') {
    // Row direction so check top/center/bottom edges for alignment
    if (yDeltas.top < yDeltas.center && yDeltas.top < yDeltas.bottom) {
      alignItems = 'start';
    } else if (yDeltas.bottom < yDeltas.center && yDeltas.bottom < yDeltas.top) {
      alignItems = 'end';
    } else {
      alignItems = 'center';
    }
  } else {
    // Column direction so check left/center/right edges for alignment
    if (xDeltas.left < xDeltas.center && xDeltas.left < xDeltas.right) {
      alignItems = 'start';
    } else if (xDeltas.right < xDeltas.center && xDeltas.right < xDeltas.left) {
      alignItems = 'end';
    } else {
      alignItems = 'center';
    }
  }

  return { direction, alignItems };
}

/**
 * Returns the gap to use for flex layout
 * - Uses the most common gap if there is a dominant choice
 * - Otherwise uses the average gap and rounds it to the nearest multiple of GRID_SIZE
 */
function getBestGap(sortedNodes: Array<TreeNode>, direction: 'row' | 'column') {
  if (sortedNodes.length === 0) return 0;

  // Build a map of gap sizes to their counts
  const gaps: number[] = [];
  let gapsWeCounted = 0;
  for (let i = 1; i < sortedNodes.length; i++) {
    const prev = sortedNodes[i - 1]!;
    const curr = sortedNodes[i]!;
    // Note: we do want to allow negatives so that the total width/height of the new wrapper is around the same as the current bounds of the nodes
    const gap = curr.bounds[direction === 'row' ? 'minX' : 'minY'] - prev.bounds[direction === 'row' ? 'maxX' : 'maxY'];
    // Track the gap
    gaps.push(gap);
    gapsWeCounted++;
  }

  // Find the median gap
  const medianGap = Math.round(median(gaps));
  // Note: we made the decision to allow negative gaps to include cases like avatar or card stacks
  // Note: we tried rounding to nearest snap increment and it felt surprising, so just use the median
  return medianGap;
}

/**
 * Returns the padding to use for flex layout
 * - If parent is root, no padding
 * - Checks the padding between the parent and its children and prefers the larger of the two for each axis
 */
function getBestPadding(parentNode: TreeNode, childrenBounds: RectWithSize): { paddingX: number; paddingY: number } {
  // No padding if the old parent is the root/canvas
  if (parentNode.isRoot) {
    return { paddingX: 0, paddingY: 0 };
  }

  // Padding spec:
  // We tried using the smaller of the existing space between parent bounds and children but we think we can do better
  // Most often, you want the padding from the left and top as you build down, so we're going to try that out
  // Feel free to tinker with this in the future if we think of something better
  const parentBounds = parentNode.bounds;
  const leftDelta = childrenBounds.minX - parentBounds.minX;
  const topDelta = childrenBounds.minY - parentBounds.minY;

  const paddingX = Math.max(0, leftDelta);
  const paddingY = Math.max(0, topDelta);
  return { paddingX, paddingY };
}

/** Takes the nodes, gap, padding, direction, and returns the total size of the new wrapper */
function getNewTotalBounds(
  sortedNodes: Array<TreeNode>,
  childrenBounds: RectWithSize,
  gap: number,
  paddingX: number,
  paddingY: number,
  direction: 'row' | 'column'
): RectWithSize {
  let newHeight;
  let newWidth;
  let totalChildWidthEndToEnd = 0;
  let totalChildHeightEndToEnd = 0;
  let largestChildWidth = 0;
  let largestChildHeight = 0;
  // Add up the width and height of all the nodes
  for (const node of sortedNodes) {
    totalChildWidthEndToEnd += node.width;
    totalChildHeightEndToEnd += node.height;
    largestChildWidth = Math.max(largestChildWidth, node.width);
    largestChildHeight = Math.max(largestChildHeight, node.height);
  }
  if (direction === 'row') {
    // Row: height is the largest child, width is the total width of all the children end-to-end
    newHeight = largestChildHeight;
    newWidth = totalChildWidthEndToEnd;
  } else {
    // Column: width is the largest child, height is the total height of all the children end-to-end
    newWidth = largestChildWidth;
    newHeight = totalChildHeightEndToEnd;
  }

  // Add padding
  newWidth += paddingX * 2;
  newHeight += paddingY * 2;
  // Add gap
  if (direction === 'row') {
    newWidth += gap * (sortedNodes.length - 1);
  } else {
    newHeight += gap * (sortedNodes.length - 1);
  }

  return {
    minX: childrenBounds.minX - paddingX,
    minY: childrenBounds.minY - paddingY,
    maxX: newWidth,
    maxY: newHeight,
    width: newWidth,
    height: newHeight,
  };
}

type LayoutAnalysisForFlex = {
  direction: 'row' | 'column';
  alignItems: 'center' | 'start' | 'end';
  newBounds: RectWithSize;
  gap: number;
  paddingX: number;
  paddingY: number;
  sortedNodes: Array<TreeNode>;
};

// Things that can be selected:
// 1. single component that supports flex layout selected
//   a. using autolayout: show option to remove
//   b. not using autolayout: show option to add
// 2. multiple things selected: show option to add
//   a. everything in same parent: wrap it all in a flex
//   b. not in same parent: each thing in a common parent gets wrapped in its own flex
// 3. single non-supporting component selected: show option to "wrap in flex"
type PossibleActions = 'wrap-in-flex' | 'remove-flex' | 'add-flex-to-node';

export function determineFlexAction(nodes: Array<TreeNode>): PossibleActions {
  if (nodes.length === 1) {
    const singleNode = nodes[0]!;
    if (componentsThatOfferFlex.has(singleNode.component)) {
      // single component that supports flex layout selected, show option to add or remove flex layout depending on whether it's already turned on
      return singleNode.layoutModeForChildren === 'flex' ? 'remove-flex' : 'add-flex-to-node';
    } else {
      // Single non-supporting component selected, show option to "wrap in flex"
      return 'wrap-in-flex';
    }
  } else if (nodes.length > 1) {
    // Multiple things selected: show option to wrap in flex layout
    return 'wrap-in-flex';
  } else {
    // 0 things selected, unexpected case
    console.warn('Unexpected: 0 nodes passed to determineFlexAction');
    return 'wrap-in-flex';
  }
}
