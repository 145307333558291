import { Font } from './FontState';
import { assert } from '../../assert';
import { BUILT_IN_FONTS } from './built-in-fonts';
import cloneDeep from 'lodash-es/cloneDeep';
import { unreachable } from '../../unreachable';

interface FontPartial {
  family: string;
  style: string;
}

export class FontUtils {
  static clone(font: Font) {
    return cloneDeep(font);
  }

  static isEqual(a: FontPartial, b: FontPartial) {
    return a.family === b.family && a.style === b.style;
  }

  static isBuiltIn(family: string): family is 'UI Sans-Serif' | 'UI Monospace' {
    return BUILT_IN_FONTS.some((font) => font.family === family);
  }

  static createId(family: string, style: string) {
    return family + ID_DELIMITER + style;
  }

  static breakId(id: string) {
    const parts = id.split(ID_DELIMITER);
    assert(parts.length === 2, `Expected a valid font ID, got "${id}"`);
    return { family: parts[0]!, style: parts[1]! };
  }

  static css(font: Font): Record<string, string | number | undefined> {
    const fontStyle = font.isItalic ? 'italic' : undefined;
    const fontWeight = font.weight;

    if (this.isBuiltIn(font.family)) {
      switch (font.family) {
        case 'UI Sans-Serif':
          return {
            fontFamily: 'system-ui, sans-serif',
            fontWeight,
            fontStyle,
          };
        case 'UI Monospace':
          return {
            fontFamily: 'ui-monospace, "SFMono-Regular", "SF Mono", "Menlo", "Consolas", "Liberation Mono", monospace',
            fontWeight,
            fontStyle,
          };
        default:
          unreachable(font.family);
      }
    }

    let fontFamily: string[] = [`"${font.family}"`, 'system-ui', 'sans-serif'];

    if (font.postscriptName && font.postscriptName !== font.family) {
      fontFamily.unshift(`"${font.postscriptName}"`);
    }

    return {
      fontFamily: fontFamily.join(', '),
      fontWeight,
      fontStyle,
    };
  }
}

const ID_DELIMITER = ' $$$ ';
