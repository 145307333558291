import { MessageType, SocketMessage, typeKey } from '@paper/models/src/websocket/socket-message';
import { EditorState } from '../EditorState';
import { handleCursorMoveMessage } from './handle-cursor-move-message';
import { handleClientJoinedFileMessage } from './handle-client-joined-file-message';
import { handleClientLeftFileMessage } from './handle-client-left-file-message';
import { handleClientsAlreadyInFileMessage } from './handle-clients-already-in-file-message';
import { handleFileEditMessage } from './handle-file-event-message';
import { handleUserPageChangeMessage } from './handle-user-page-change-message';
import { handleUserFocusChangeMessage } from './handle-user-focus-change-message';
import { handleUserSelectNodeMessage } from './handle-user-select-node-message';

/** The main incoming message handler on the client that routes messages to the appropriate handler */
export function handleMessageOnClient(editorState: EditorState, messageData: SocketMessage) {
  // Using a switch statement gives us better type narrowing and safety than a map of handler functions
  switch (messageData[typeKey]) {
    case MessageType.CursorMove: {
      handleCursorMoveMessage(editorState, messageData);
      break;
    }
    case MessageType.FileEdit: {
      handleFileEditMessage(editorState, messageData);
      break;
    }
    case MessageType.UserJoinedFile: {
      handleClientJoinedFileMessage(editorState, messageData);
      break;
    }
    case MessageType.UserLeftFile: {
      handleClientLeftFileMessage(editorState, messageData);
      break;
    }
    case MessageType.UsersAlreadyInFile: {
      handleClientsAlreadyInFileMessage(editorState, messageData);
      break;
    }
    case MessageType.UserPageChange: {
      handleUserPageChangeMessage(editorState, messageData);
      break;
    }
    case MessageType.UserFocusChange: {
      handleUserFocusChangeMessage(editorState, messageData);
      break;
    }
    case MessageType.UserSelectNode: {
      handleUserSelectNodeMessage(editorState, messageData);
      break;
    }
  }
}
