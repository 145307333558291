import { Tooltip as BaseTooltip } from '@base-ui-components/react/tooltip';
import clsx from 'clsx';
import { useState } from 'react';

function TooltipRoot({ hoverable = false, ...props }: BaseTooltip.Root.Props) {
  const [open, setOpen] = useState(false);
  return (
    <BaseTooltip.Root
      open={open}
      onOpenChange={(open, event, reason) => {
        // Don't open tooltips on focus (it's noisy)
        if (reason?.includes('focus')) {
          return;
        }

        setOpen(open);
      }}
      hoverable={hoverable}
      {...props}
    />
  );
}

interface TooltipPopupProps {
  children: React.ReactNode;
  className?: string;
}

function TooltipPopup({ className, children }: TooltipPopupProps) {
  return (
    <BaseTooltip.Portal>
      {/* TODO Vlad fix the offset against ghost buttons */}
      <BaseTooltip.Positioner side="bottom" sideOffset={4}>
        <BaseTooltip.Popup className={clsx('TooltipPopup', className)}>{children}</BaseTooltip.Popup>
      </BaseTooltip.Positioner>
    </BaseTooltip.Portal>
  );
}

export const Tooltip = {
  Provider: BaseTooltip.Provider,
  Root: TooltipRoot,
  Trigger: BaseTooltip.Trigger,
  Popup: TooltipPopup,
};
