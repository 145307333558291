import clsx from 'clsx';

function CollapsibleIndicatorRoot({ className, ...props }: React.ComponentProps<'span'>) {
  return <span className={clsx('CollapsibleIndicator', className)} {...props} />;
}

interface CollapsibleIndicatorIconProps extends React.ComponentProps<'svg'> {
  collapsed?: boolean;
}

function CollapsibleIndicatorIcon({ collapsed, ...props }: CollapsibleIndicatorIconProps) {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" {...props}>
      <path transform={collapsed ? 'rotate(-90, 4, 4)' : undefined} d="M1 2.5L4 5.5L7 2.5" stroke="currentcolor" />
    </svg>
  );
}

export const CollapsibleIndicator = {
  Root: CollapsibleIndicatorRoot,
  Icon: CollapsibleIndicatorIcon,
};
