export function EyeIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor" {...props}>
      <g className="EyeIconLight">
        <path
          d="M14 8C14 8 12.5 4.5 8 4.5C3.5 4.5 2 8 2 8C2 8 3.5 11.5 8 11.5C12.5 11.5 14 8 14 8Z"
          fill="none"
          stroke="currentcolor"
        />
        <circle cx="8" cy="8" r="2" />
      </g>

      <g className="EyeIconDark">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.45508 8.00012C1.45508 8.00012 3.01499 3.99036 8.00293 3.99036C12.9909 3.99036 14.5502 8.00012 14.5502 8.00012C14.5502 8.00012 13.0102 12.0001 8.00293 12.0001C2.99562 12.0001 1.45508 8.00012 1.45508 8.00012ZM11.0026 8C11.0026 9.65685 9.6595 11 8.00265 11C6.34579 11 5.00265 9.65685 5.00265 8C5.00265 6.34315 6.34579 5 8.00265 5C9.6595 5 11.0026 6.34315 11.0026 8Z"
        />
        <circle cx="9.52661" cy="6.52808" r="1.18677" />
      </g>
    </svg>
  );
}
