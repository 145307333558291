import clsx from 'clsx';
import { useContext } from 'react';
import { ColorSpace, ColorUtils } from '@paper/models/src/colors/Color';
import { ColorPickerContext } from './color-picker';
import { Tooltip } from '../../components/tooltip';
import { useForceRender } from '../../components/use-force-render';
import { findClosestColorInGamut } from './hue-slice';

export function ColorPickerSwatches(props: React.ComponentProps<'div'>) {
  const { tab, initialColor, prevColor, color, onColorChange } = useContext(ColorPickerContext);
  const isClipped = ColorUtils.isClipped(color, tab);
  const forceRender = useForceRender();

  function toggleNewColor(event: React.MouseEvent) {
    if (event.buttons !== 1) {
      return;
    }

    // Make this color the initial color
    if (ColorUtils.isEqual(color, initialColor.current)) {
      initialColor.current = prevColor.current;
    } else {
      prevColor.current = initialColor.current;
      initialColor.current = color;
    }

    // Force render self after updating the refs
    forceRender();
  }

  function toggleInitialColor(event: React.MouseEvent) {
    if (event.buttons !== 1) {
      return;
    }

    // Toggle the initial color back and forth
    if (ColorUtils.isEqual(color, initialColor.current)) {
      onColorChange(prevColor.current);
    } else {
      onColorChange(initialColor.current);
    }

    prevColor.current = color;
  }

  return (
    <div {...props}>
      <div className="ColorPickerSwatches ColorPickerOutline">
        <div
          style={{ backgroundColor: ColorUtils.cssString(initialColor.current) }}
          onPointerDown={toggleInitialColor}
        />
        <div
          className="flex items-end justify-end"
          style={{ backgroundColor: 'var(--color)' }}
          onPointerDown={toggleNewColor}
        />

        {isClipped && (
          <div
            className="absolute right-0 bottom-0 pt-2 pr-1 pb-1 pl-2"
            onPointerDown={(event) => {
              if (event.buttons !== 1) {
                return;
              }

              onColorChange({ ...color, value: findClosestColorInGamut(color.value, tab) });
            }}
          >
            <GamutWarning colorSpace={tab}>Clipped</GamutWarning>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 justify-items-center">
        <Tooltip.Root>
          <Tooltip.Trigger
            className="text-gray-2 px-1 pt-0.5 text-sm font-medium select-none"
            render={(props) => <div {...props} />}
            onPointerDown={toggleInitialColor}
          >
            Previous
          </Tooltip.Trigger>
          <Tooltip.Popup>Click to recover previous color</Tooltip.Popup>
        </Tooltip.Root>
        <Tooltip.Root>
          <Tooltip.Trigger
            className="text-gray-2 px-1 pt-0.5 text-sm font-medium select-none"
            render={(props) => <div {...props} />}
            onPointerDown={toggleNewColor}
          >
            New
          </Tooltip.Trigger>
          <Tooltip.Popup>Click to replace previous color</Tooltip.Popup>
        </Tooltip.Root>
      </div>
    </div>
  );
}

function GamutWarning({ colorSpace, className, ...props }: React.ComponentProps<'div'> & { colorSpace: ColorSpace }) {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger
        // line-height override because of Safari alignment issues
        className={clsx('rounded-2 bg-black/65 px-1 text-sm leading-[15px] text-white', className)}
        render={(props) => <div {...props} />}
        {...props}
      />
      <Tooltip.Popup>
        This color may be displayed incorrectly.
        <br />
        Click to use a fallback for {colorSpace === 'p3' ? 'Display P3' : 'sRGB'}.
      </Tooltip.Popup>
    </Tooltip.Root>
  );
}
