import { clamp } from '@paper/models/src/math/clamp';
import { useCallback, useEffect, useRef, useState } from 'react';

// Constrain the thumb inside a smaller space on the canvas.
// It feels better if it's not right on the edges. Clicking exactly 1 pixel
// from the edges will result in the thumb being at the same position as clicking
// exactly on the edge though. But with 1px it is barely noticeable.
const THUMB_MARGIN = 1;

interface CartesianProps extends Omit<React.ComponentProps<'div'>, 'onChange'> {
  /** Current X coordinate value, 0 to 1 */
  x: number;
  /** Current Y coordinate value, 0 to 1 */
  y: number;
  /** Width of the cartesian area */
  width: number;
  /** Height of the cartesian area */
  height: number;
  /** Called when the coordinates change */
  onChange: (x: number, y: number) => void;
  /** Called when the user grabs the thumb and starts dragging */
  onChangeStart?: () => void;
  /** Called when the user releases the thumb and stops dragging */
  onChangeEnd?: () => void;
  /**
   * When used, makes it possible to ignore a mismatch between the component's
   * internal x/y state and the external x/y props in order to keep the thumb in place
   * after the user finishes dragging.
   *
   * Returns `true` by default.
   */
  shouldSyncCoordinates?: (x: number, y: number) => boolean;
}

function CartesianRoot({
  x: propX,
  y: propY,
  width,
  height,
  onChange,
  onChangeStart,
  onChangeEnd,
  shouldSyncCoordinates = () => true,
  ...props
}: CartesianProps) {
  // The internal coordinates that are updated immediately during pointer move
  // (`onChange` is called in a RAF to always keep the UI movement smooth)
  const [state, setState] = useState({ x: propX, y: propY });
  const isControlled = useRef(true);

  // Track current coords in a stable object to use in persistent event handlers
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  // Create and track a RAF id and clean it up when done
  const raf = useRef(0);
  const createRaf = useCallback((callback: Function) => {
    cancelAnimationFrame(raf.current);
    raf.current = requestAnimationFrame(() => {
      callback();
      raf.current = 0;
    });
  }, []);

  function handleChangeStart() {
    isControlled.current = false;
    onChangeStart?.();
  }

  function handleChangeEnd() {
    isControlled.current = true;
    onChangeEnd?.();
  }

  if (isControlled.current && (propX !== state.x || propY !== state.y)) {
    if (shouldSyncCoordinates(state.x, state.y)) {
      setState({ x: propX, y: propY });
    }
  }

  return (
    <div
      {...props}
      tabIndex={-1}
      style={
        {
          '--cartesian-thumb-x': clamp(state.x * width, THUMB_MARGIN, width - THUMB_MARGIN) + 'px',
          '--cartesian-thumb-y': clamp(state.y * height, THUMB_MARGIN, height - THUMB_MARGIN) + 'px',
          'position': 'relative',
          'width': width,
          'height': height,
          'outline': 0,
          ...props.style,
        } as React.CSSProperties
      }
      onKeyDown={(event) => {
        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key) === false) {
          return;
        }

        event.preventDefault();
        const stepX = event.shiftKey ? 10 / width : 1 / width;
        const stepY = event.shiftKey ? 10 / height : 1 / height;
        const dirX = event.key === 'ArrowLeft' ? -1 : event.key === 'ArrowRight' ? 1 : 0;
        const dirY = event.key === 'ArrowDown' ? -1 : event.key === 'ArrowUp' ? 1 : 0;
        const x = clamp(state.x + dirX * stepX, 0, 1);
        const y = clamp(state.y + dirY * stepY, 0, 1);
        setState({ x, y });
        handleChangeStart();
        createRaf(() => onChange(x, y));
      }}
      onKeyUp={(event) => {
        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key) === false) {
          return;
        }
        // Schedule a change end raf that can't be cancelled
        requestAnimationFrame(() => handleChangeEnd());
      }}
      onPointerDown={(event) => {
        if (event.buttons !== 1) {
          return;
        }

        // Prevent long press drag in Chrome
        event.preventDefault();

        // Focus self to catch keyboard events
        event.currentTarget.focus();

        const root = event.currentTarget;
        const rootRect = root.getBoundingClientRect();
        const isThumbEvent = event.target instanceof HTMLElement && event.target.hasAttribute('data-cartesian-thumb');

        // A slight initial offset to make the puck feel more centered to the pointer
        let X_OFFSET = -1;
        let Y_OFFSET = -1;

        // If the thumb is clicked, add the offset from pointer to the thumb
        // center so that the thumb stays still relative to the pointer
        if (isThumbEvent) {
          const thumb = event.target as HTMLElement;
          const thumbRect = thumb.getBoundingClientRect();
          X_OFFSET -= X_OFFSET + event.clientX - thumbRect.x - thumbRect.width / 2;
          Y_OFFSET -= Y_OFFSET + event.clientY - thumbRect.y - thumbRect.height / 2;
        }

        root.setPointerCapture(event.pointerId);
        handleChangeStart();

        // Update the coords if the click didn't land on the thumb
        if (isThumbEvent === false) {
          const x = clamp((event.clientX - rootRect.left + X_OFFSET) / width, 0, 1);
          const y = 1 - clamp((event.clientY - rootRect.top + Y_OFFSET) / height, 0, 1);
          setState({ x, y });
          createRaf(() => onChange(x, y));
        }
        window.addEventListener('pointermove', handlePointerMove);
        window.addEventListener('pointerup', handlePointerUp);

        function handlePointerMove(event: PointerEvent) {
          event.preventDefault(); // Prevent text selection
          const x = clamp((event.clientX - rootRect.left + X_OFFSET) / width, 0, 1);
          const y = 1 - clamp((event.clientY - rootRect.top + Y_OFFSET) / height, 0, 1);

          // Don't blast `onChange` unless the values actually change
          if (stateRef.current.x !== x || stateRef.current.y !== y) {
            setState({ x, y });
            createRaf(() => onChange(x, y));
          }
        }

        function handlePointerUp() {
          root.releasePointerCapture(event.pointerId);
          window.removeEventListener('pointermove', handlePointerMove);
          window.removeEventListener('pointerup', handlePointerUp);
          // Schedule a change end raf that can't be cancelled
          requestAnimationFrame(() => handleChangeEnd());
        }
      }}
    />
  );
}

function CartesianThumb({ style, ...props }: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      data-cartesian-thumb=""
      style={{
        ...style,
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 'auto',
        bottom: 'auto',
        translate: `calc(var(--cartesian-thumb-x) - 50%) calc(-1 * var(--cartesian-thumb-y) - 50%)`,
      }}
    />
  );
}

export const Cartesian = {
  Root: CartesianRoot,
  Thumb: CartesianThumb,
};
