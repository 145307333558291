import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { Field } from '../../components/field';
import { reducePropertyValues } from '../properties/reduce-property-values';
import { AlignStartIcon } from '../../icons/align-start-icon';
import { AlignCenterIcon } from '../../icons/align-center-icon';
import { AlignStretchIcon } from '../../icons/align-stretch-icon';

export const AlignItemsProperty = observer((props: React.ComponentProps<typeof Field.Root>) => {
  const { propertiesState } = useEditor();

  const flexDirectionValues = propertiesState.getStyleValues('flexDirection');
  const flexDirectionInputValue = reducePropertyValues(flexDirectionValues.keys(), 'row');

  const values = propertiesState.getStyleValues('alignItems');
  const inputValue = reducePropertyValues(values.keys(), 'start');

  const iconRotation = +(flexDirectionInputValue === 'column') * -90 + +(inputValue === 'end') * 180;
  const iconTransform = `rotate(${iconRotation})`;

  function commitValue(value: string) {
    for (const node of propertiesState.allNodes) {
      if (node.layoutModeForChildren === 'flex') {
        node.setStyle('alignItems', value);
      }
    }
  }

  return (
    <Field.Root {...props}>
      <Field.Icon>
        {['start', 'end', 'Mixed'].includes(inputValue) && <AlignStartIcon transform={iconTransform} />}
        {inputValue === 'center' && <AlignCenterIcon transform={iconTransform} />}
        {inputValue === 'stretch' && <AlignStretchIcon transform={iconTransform} />}
      </Field.Icon>
      <Field.Control>
        <select
          value={inputValue}
          onChange={(event) => {
            if (event.target.value !== 'Mixed') {
              commitValue(event.target.value);
            }
          }}
        >
          {inputValue === 'Mixed' && (
            <>
              <option value="Mixed">Mixed</option>
              <hr />
            </>
          )}
          <option value="start">Start</option>
          <option value="center">Center</option>
          <option value="end">End</option>
          {propertiesState.allNodes.every((node) => node.canHaveChildren) && <option value="stretch">Stretch</option>}
        </select>
      </Field.Control>
      <Field.Caret />
    </Field.Root>
  );
});
