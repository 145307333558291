import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { flushSync } from 'react-dom';

export type InputHandle = HTMLInputElement & {
  /** A handle to directly set the input's internal value */
  setValue: (value: string) => void;

  /** A handle to save the value: parses, validates, and calls `onValueCommit` */
  commitValue: (value: string) => void;
};

export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  ref?: React.Ref<InputHandle | null>;

  value: string;
  defaultValue?: never;
  onValueCommit?: (value: string) => void;

  /**
   * A function to visually transform the incoming value when it is displayed in the input.
   */
  format?: (value: string) => string;

  /**
   * A function to parse the value that user entered into the input.
   * By default, collapses consecutive whitespace and trims the value.
   *
   * Should return `null` when the value can't be parsed; the input will
   * revert to the previously committed value in this case.
   *
   * Checks for `!!value` by default.
   */
  parse?: (value: string) => string | null;

  /**
   * A function used to customise how input contents are selected when clicking into the input.
   * Defaults to `(input) => input?.select()`
   */
  select?: (input: HTMLInputElement | null) => void;
}

/**
 * Base text input component used in the editor.
 *
 * Does:
 * - Autoselects the value on click
 * - Reverts the value and blurs on Escape keypress
 * - Returns the value via the `onValueCommit` handler on blur and Enter keypress
 * - Provides a way to transform how the incoming value is displayed via the `format` handler
 * - Provides a way to transform the value user submits via the `parse` handler. (Cleans up whitespace by default).
 * - Provides a way to reject the value user submits via the `validate` handler. (Checks for `!!value` by default).
 * - Disables autocompletion, autocorrection, spellcheck, password managers etc.
 *
 * Should be extended by inputs designated for specific use-cases, such as color input or number input.
 * Exposes `input.setValue()` handler to imperatively change its internal value.
 */
export function Input({
  onValueCommit,
  format = defaultFormatter,
  parse = defaultParser,
  select = defaultSelect,
  ...props
}: InputProps) {
  const sourceValue = format(props.value);
  const [value, setValue] = useState(sourceValue);
  const [input, setInput] = useState<HTMLInputElement | null>(null);
  const isDirty = useRef(false);

  // Track the external value prop if the input isn't focused.
  // Makes sure that the internal value doesn't get stale if props don't change.
  const shouldResetValue = input && document.activeElement !== input && sourceValue !== value;
  if (shouldResetValue) {
    setValue(sourceValue);
  }

  function commitValue(value: string) {
    const parsed = parse(value);

    if (parsed === null) {
      setValue(sourceValue);
      return;
    }

    const formatted = format(parsed);
    setValue(formatted);
    onValueCommit?.(parsed);
  }

  const commitValueRef = useRef(commitValue);
  commitValueRef.current = commitValue;

  useImperativeHandle(
    props.ref,
    function () {
      if (input) {
        return Object.assign(input, {
          setValue,
          commitValue: function (value: string) {
            commitValueRef.current(value);
          },
        });
      }
      return null;
    },
    [input]
  );

  function handleBlur() {
    if (isDirty.current) {
      commitValue(value);
    }

    isDirty.current = false;
  }

  // Run the blur handler on unmount
  const handleBlurRef = useRef(handleBlur);
  handleBlurRef.current = handleBlur;
  useEffect(() => {
    return () => handleBlurRef.current();
  }, []);

  return (
    <input
      {...baseInputProps}
      {...props}
      ref={setInput}
      value={value}
      onBlur={(event) => {
        handleBlur();
        props.onBlur?.(event);
      }}
      onFocus={(event) => {
        // Select on focus with our custom selection function (when the label is clicked or with `autoFocus`)
        // Note: use `event.target` over `input` since the latter won't work with `autoFocus` in popups
        select(event.target);
        props.onFocus?.(event);
      }}
      onChange={(event) => {
        isDirty.current = true;
        setValue(event.target.value);
        props.onChange?.(event);
      }}
      onBeforeInput={() => {
        // Catch input that doesn't result in `onChange` callback
        // (Note: `onChange` is still needed to catch character removal)
        isDirty.current = true;
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          if (value === sourceValue) {
            input?.blur();
          } else {
            flushSync(() => setValue(sourceValue));
            select(input);
          }
        }

        if (event.key === 'Enter') {
          // Treat the value as dirty when the Enter key is pressed
          isDirty.current = true;
          input?.blur();
        }

        props.onKeyDown?.(event);
      }}
      onPointerDown={(event) => {
        defaultPointerDownHandler(input, select);
        props.onPointerDown?.(event);
      }}
    />
  );
}

function defaultFormatter(value: string) {
  return value;
}

function defaultParser(value: string) {
  return value.trim().replace(/\s+/g, ' ') || null;
}

function defaultSelect(input: HTMLInputElement | null) {
  input?.select();
}

/** Autoselects input contents on click */
export function defaultPointerDownHandler(input: HTMLInputElement | null, select = defaultSelect) {
  if (document.activeElement !== input) {
    if (input) {
      // (1) Firefox restores previous selection on focus
      // (2) Chrome also restores previous selection, but only when it spans the entire value
      // This is at odds with autoselection on click; we want a clear initial state every time.
      input.focus(); // Required for Chrome only, as it won't modify selection unless focused.
      input.selectionStart = null;
      input.selectionEnd = null;
    }

    // If input selection changes at any point after pointerdown, we want to cancel autoselection
    // on pointerup (even if the user ends up with no selection after the cursor movement).
    const handleSelectionChange = () => {
      if (input?.selectionStart !== input?.selectionEnd) {
        document.removeEventListener('selectionchange', handleSelectionChange);
        document.removeEventListener('pointerup', handlePointerUp);
      }
    };

    const handlePointerUp = (event: Event) => {
      document.removeEventListener('selectionchange', handleSelectionChange);
      if (event.target && event.target === input) {
        select(input);
      }
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    document.addEventListener('pointerup', handlePointerUp, { once: true, passive: true });
  }
}

export const baseInputProps = {
  'type': 'text',
  'autoCapitalize': 'none',
  'autoComplete': 'off',
  'autoCorrect': 'off',
  'spellCheck': 'false',
  // Turn off common password managers
  // https://www.stefanjudis.com/snippets/turn-off-password-managers/
  'data-1p-ignore': 'true',
  'data-lpignore': 'true',
  'data-bwignore': 'true',
  'data-form-type': 'other',
} as const;
