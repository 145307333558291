import { roundOptimized } from '@paper/models/src/math/round-optimized';
import { formatAlpha, formatChannel, formatHue, prettySymbolsRegexp } from './color-formatting';
import { convertHslToRgb } from 'culori/fn';
import type { Hsl } from './Color';

export function getPrettyHsl(hsl: Hsl, explicitAlpha = true) {
  const { h, s, l, alpha = 1 } = hsl;

  // Collapse hue and saturation to 0 if the actually rendered RGB color is gray
  const { r, g, b } = convertHslToRgb(hsl);
  const isGray = [r, g, b].map((n) => Math.round(n * 255)).every((n, i, a) => n === a[0]);

  let H = isGray ? '0' : formatHue(h, false);
  let S = isGray ? '0' : formatChannel(s * 100, 1, 0, 100);
  let L = formatChannel(l * 100, 1, 0, 100);

  H += '°';
  S += '%';
  L += '%';

  let str = [H, S, L].join(' ');

  if (explicitAlpha || roundOptimized(alpha, 3) !== 1) {
    str += ' / ' + formatAlpha(alpha * 100) + '%';
  }

  return str;
}

export function getCssStringHsl(hsl: Hsl) {
  return `hsl(${getPrettyHsl(hsl, false).replace(prettySymbolsRegexp, '')})`;
}
