import { useContext, useMemo, useRef } from 'react';
import { useEditor } from '../editor-context';
import { COLOR_PICKER_HEIGHT, COLOR_PICKER_WIDTH, ColorPickerContext } from './color-picker';
import { Cartesian } from '../../components/cartesian';
import { ColorMapUniform } from './color-map-uniform';
import { getHueSlice, getOklchFromXY, getXYFromOklch } from './hue-slice';
import { ColorSpace, ColorUtils } from '@paper/models/src/colors/Color';

interface ColorPickerCartesianUniformProps {
  colorSpace: ColorSpace;
}

export function ColorPickerCartesianUniform({ colorSpace }: ColorPickerCartesianUniformProps) {
  const { fileState } = useEditor();
  const { tab, color, onColorChange } = useContext(ColorPickerContext);
  const colorAtCoords = useRef(color);

  const hueSlice = useMemo(() => getHueSlice(color.value.h, colorSpace), [colorSpace, color.value.h]);
  const [x, y] = getXYFromOklch(color.value, colorSpace, hueSlice);

  return (
    <Cartesian.Root
      x={x}
      y={y}
      width={COLOR_PICKER_WIDTH}
      height={COLOR_PICKER_HEIGHT}
      onChange={(x, y) => {
        colorAtCoords.current = {
          gamut: colorSpace,
          value: getOklchFromXY(x, y, colorSpace, hueSlice),
        };

        onColorChange(colorAtCoords.current);
      }}
      onChangeStart={() => fileState.fileDataObserver.startTreatingChangesAsTransient()}
      onChangeEnd={() => fileState.fileDataObserver.endTreatingChangesAsTransient()}
      shouldSyncCoordinates={() => ColorUtils.isEqual(colorAtCoords.current, color) === false}
    >
      <ColorMapUniform
        hue={color.value.h}
        hueSlice={hueSlice}
        className="ColorPickerMap"
        colorSpace={colorSpace}
        width={COLOR_PICKER_WIDTH}
        height={COLOR_PICKER_HEIGHT}
      />
      <Cartesian.Thumb
        data-clipped-color={ColorUtils.isClipped(color, tab) ? '' : undefined}
        className="ColorPickerThumb"
      />
    </Cartesian.Root>
  );
}
