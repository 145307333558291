import { useCallback, useEffect, useState } from 'react';

export function useLocalStorage<T>(key: string, defaultValue: T) {
  const [value, setValue] = useState<T>(() => {
    try {
      return JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue));
    } catch (error) {
      console.warn(error);
      return defaultValue;
    }
  });

  const saveValue = useCallback(
    function (value: T) {
      setValue(value);

      const eventType = getEventType(key);
      const event = new CustomEvent<T>(eventType, { detail: value });
      dispatchEvent(event);

      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {}
    },
    [key]
  );

  useEffect(() => {
    function handleCustomEvent(event: CustomEventInit<T>) {
      if (event.detail) {
        setValue(event.detail);
      }
    }

    const eventType = getEventType(key);
    addEventListener(eventType, handleCustomEvent);
    return () => removeEventListener(eventType, handleCustomEvent);
  }, [key]);

  return [value, saveValue] as const;
}

function getEventType(key: string) {
  return `change-${key}`;
}
