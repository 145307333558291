import stringSimilarity from 'string-similarity-js';
import { googleKeyToStyle } from './google-font-styles';
import { Font, FONTS_SORT_OPTIONS, FontState } from './FontState';
import { FontUtils } from './FontUtils';
import { BUILT_IN_FONTS } from './built-in-fonts';

export class FontFamily {
  private fontState: FontState;
  name: string;
  size: number;
  styles: string[];

  constructor(name: string, fontState: FontState) {
    this.name = name;
    this.fontState = fontState;
    this.styles = this.getStyles();
    this.size = this.styles.length;
  }

  /**
   * Returns all fonts that belong to this family.
   *
   * A family may be interweaved from fonts that exist in different sources.
   * For example, a family may have a few fonts sourced locally and a few sourced from Google.
   */
  async getFonts() {
    const promises = this.styles.map((style) => this.fontState.getFont(this.name, style));
    const fonts = await Promise.all(promises);
    return fonts.filter(Boolean) as Font[];
  }

  private getStyles() {
    const styles: string[] = [];

    if (FontUtils.isBuiltIn(this.name)) {
      for (const font of BUILT_IN_FONTS) {
        if (font.family === this.name) {
          styles.push(font.style);
        }
      }

      return styles;
    }

    const googleFontKeys = this.fontState.googleFontData[this.name];
    if (googleFontKeys) {
      for (const key of googleFontKeys) {
        styles.push(googleKeyToStyle[key]!);
      }
    }

    for (const [id] of this.fontState.fontToNodeIndex) {
      const { family, style } = FontUtils.breakId(id);
      if (family === this.name) {
        styles.push(style);
      }
    }

    for (const fontData of this.fontState.localFontData) {
      if (fontData.family === this.name) {
        styles.push(fontData.style);
      }
    }

    return Array.from(new Set(styles));
  }

  /**
   * Retrieve family styles, grouped together by italicisation, width, and fvar axes.
   * Asynchronous because we might need to parse the local font data.
   */
  async getStyleGroups(): Promise<string[][]> {
    type GroupKey = {
      width: number;
      subfamily?: string;
      isItalic?: boolean;
      coordinates?: {
        [axisTag: string]: number;
      };
    };

    let groups: [GroupKey, Font[]][] = [];
    const fonts = await this.getFonts();

    for (const font of fonts) {
      // Assemble the group key
      const key: GroupKey = {
        width: font.width || 100,
        isItalic: font.isItalic,
        subfamily: font.subfamily,
      };

      const group = groups.find(([{ width, isItalic, subfamily }]) => {
        return width === key.width && isItalic === key.isItalic && subfamily === key.subfamily;
      });

      if (group) {
        group[1].push(font);
      } else {
        groups.push([key, [font]]);
      }
    }

    // Sort by weight and name within each group
    groups.forEach(([, fonts]) => {
      fonts.sort((a, b) => {
        if (a.weight !== b.weight) {
          return a.weight! - b.weight!;
        }
        return a.style.localeCompare(b.style, 'en', FONTS_SORT_OPTIONS);
      });
    });

    // Sort groups between themselves by: subfamily, width, fvar coordinates, and italicisation
    const opticalSizes = ['Caption', 'Text', 'Book', 'Subhead', 'Display'];
    groups.sort(([a], [b]) => {
      if (a.subfamily !== b.subfamily) {
        // If it's a superfamily, fonts without the subfamily go first.
        // (These would be the common members like "Regular", "Italic", "Bold").
        // (Subfamilies could be "Caption", "Display", "Compressed", etc.)
        if (!a.subfamily || !b.subfamily) {
          return a.subfamily ? 1 : -1;
        }

        // Most commonly, Adobe uses subfamilies for optical variations in a superfamily
        // Notable examples: Arno, Warnock, Jenson fonts.
        const optIndexA = opticalSizes.findIndex((name) => a.subfamily?.includes(name));
        const optIndexB = opticalSizes.findIndex((name) => b.subfamily?.includes(name));
        if (optIndexA !== optIndexB) {
          return optIndexA - optIndexB;
        }
      }

      // Normal width goes first. Otherwise, sort by width from condensed to expanded
      if (a.width !== b.width) {
        if (a.width === 100) return -1;
        if (b.width === 100) return 1;
        return a.width - b.width;
      }

      // Group by variable font instance coordinates
      for (const tag in a.coordinates) {
        // We've already handled weight and width
        if (tag !== 'wdth' && tag !== 'wght' && b.coordinates?.[tag]) {
          if (a.coordinates[tag] !== b.coordinates[tag]) {
            return a.coordinates[tag]! - b.coordinates[tag];
          }
        }
      }

      if (a.isItalic != b.isItalic) {
        return a.isItalic ? 1 : -1;
      }

      return 0;
    });

    // If each group has one member, dissolve the groups (e.g. a family has just "Regular" and "Italic" styles)
    if (groups.every(([, fonts]) => fonts.length === 1)) {
      return [groups.flatMap(([, fonts]) => fonts.map((font) => font.style))];
    }

    return groups.map(([, fonts]) => fonts.map((font) => font.style));
  }

  /** Find the best match to the target font */
  async match(target: Font | null | undefined): Promise<Font> {
    let candidates = await this.getFonts();

    if (!target || candidates.length === 1) {
      return candidates[0]!;
    }

    let bestScore = Number.NEGATIVE_INFINITY;
    let bestMatch: Font = target;

    // Reduce our options to the matching italicization
    if (candidates.some((item) => item.isItalic === target.isItalic)) {
      candidates = candidates.filter((item) => item.isItalic === target.isItalic);
    }

    // console.log('***');
    // console.log('Picking a match for', target.family, target.style, { ...target });
    // const debugScores = [];

    for (const item of candidates) {
      let widthScore = 0;

      if (target.width && item.width) {
        // Value a width mismatch at 150% of weight mismatch
        // (Width usually goes in steps of 12.5)
        // https://learn.microsoft.com/en-us/typography/opentype/spec/os2#uswidthclass
        widthScore = (-100 / 12.5) * 1.5 * Math.abs(item.width - target.width);
      }

      if (!item.weight || !target.weight) {
        console.warn('Expected a numeric weight when comparing fonts', item.family, target.family);
      }

      const weightScore = -1 * Math.abs((item.weight ?? 400) - (target.weight ?? 400));
      const nameScore = stringSimilarity(item.style, target.style) * 300;

      // In some cases, there are multiple matches with exactly the same score.
      // Give a tiny preference to the base style of the family (or the base italic style).
      const regexp = /^(regular|normal|roman|((regular |normal )?(italic|oblique|sloped|slanted)))$/i;
      const isBaseFont =
        regexp.test(item.style) ||
        item.family === item.fullName ||
        item.family.replace(/\s/g, '') === item.postscriptName;
      const baseFontScore = +isBaseFont;

      const score = weightScore + widthScore + nameScore + baseFontScore;

      if (score > bestScore) {
        bestScore = score;
        bestMatch = item;
      }

      // const debug = { score, weightScore, widthScore, nameScore, font: `${item.family} ${item.style}`, data: item };
      // debugScores.push(debug);
    }

    // console.log(debugScores.sort((a, b) => b.score - a.score));
    return bestMatch;
  }
}
