import { convertNumberToHex } from './color-conversion';
import { formatAlpha } from './color-formatting';
import type { Hex } from './Color';

export function getPrettyHex({ hex, alpha = 1 }: Hex, explicitAlpha = true) {
  let str = convertNumberToHex(hex, 6);

  if (explicitAlpha || alpha !== 1) {
    str += ' / ' + formatAlpha(alpha * 100) + '%';
  }

  return str;
}

export function getCssStringHex({ hex, alpha = 1 }: Hex) {
  if (alpha === 1) {
    return '#' + convertNumberToHex(hex, 6);
  }

  return '#' + convertNumberToHex(hex, 6) + convertNumberToHex(Math.round(alpha * 255), 2);
}
