// Get the current URL of the page
const currentUrl = window.location.origin;
/** The production domain for the client */
const PROD_DOMAINS = new Set(['https://app.paper.design']);

const isProd = PROD_DOMAINS.has(currentUrl);
// Check if we're using local servers (for development)
const useLocalServers = import.meta.env.VITE_LOCAL_SERVERS === 'true';
// Sanity check that we're not using local servers in production
if (isProd && useLocalServers) {
  throw new Error('VITE_LOCAL_SERVERS cannot be true in production');
}

// STAGING
export const SERVICE_URI = {
  SYNC_URI: 'wss://sync.paper-staging.dev',
  API_URI: 'https://api.paper-staging.dev',
  ASSETS_URI: 'https://assets.paper-staging.dev',
  WORKERS_URI: 'https://workers.paper-staging.dev',
};

if (isProd) {
  // PRODUCTION
  SERVICE_URI.SYNC_URI = 'wss://sync.paper.design';
  SERVICE_URI.API_URI = 'https://api.paper.design';
  SERVICE_URI.ASSETS_URI = 'https://assets.paper.design';
  SERVICE_URI.WORKERS_URI = 'https://workers.paper.design';
} else if (useLocalServers) {
  // LOCAL SERVERS
  SERVICE_URI.SYNC_URI = 'ws://localhost:8080';
  SERVICE_URI.API_URI = 'http://localhost:8080';
  SERVICE_URI.ASSETS_URI = 'https://assets.paper.design'; // use production assets for local dev
  SERVICE_URI.WORKERS_URI = 'http://localhost:8079';
}
