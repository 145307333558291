import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { CaretIcon } from '../icons/caret-icon';

function FieldRoot({ className, ...props }: React.ComponentProps<'div'>) {
  return <div className={clsx('FieldRoot', className)} {...props} />;
}

interface FieldControlProps extends React.ComponentProps<'div'> {
  align?: 'left' | 'center';
  children: React.ReactElement;
}

function FieldControl({ align = 'left', className, ...props }: FieldControlProps) {
  return <Slot data-align={align} className={clsx('FieldControl', className)} {...props} />;
}

function FieldIcon({ className, ...props }: React.ComponentProps<'div'>) {
  return <div className={clsx('FieldIcon', className)} {...props} />;
}

function FieldCaret({ className, ...props }: React.ComponentProps<'div'> & { children?: never }) {
  return (
    <div className={clsx('FieldCaret', className)} {...props}>
      <CaretIcon />
    </div>
  );
}

export const Field = {
  Root: FieldRoot,
  Control: FieldControl,
  Icon: FieldIcon,
  Caret: FieldCaret,
};

export function FieldGroup({ className, ...props }: React.ComponentProps<'div'>) {
  return <div className={clsx('FieldGroup', className)} {...props} />;
}
