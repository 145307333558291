import * as MenuPrim from '@radix-ui/react-context-menu';
import { EditorState } from '../EditorState';
import { observer } from 'mobx-react-lite';
import { TreeNode } from '../tree/TreeNode';
import {
  addFlexToNode,
  determineFlexAction,
  removeFlexFromNode,
  wrapNodesWithFlex,
} from '../tree/wrap-nodes-with-flex';
import { transaction } from 'mobx';

export const WrapInFlexOrRemoveFlex = observer(
  ({ editorState, nodes }: { editorState: EditorState; nodes: Array<TreeNode> }) => {
    // Figure out which flex options we should offer
    const action = determineFlexAction(nodes);

    if (action === 'add-flex-to-node') {
      return (
        <MenuPrim.Item
          onSelect={() => {
            transaction(() => {
              // Apply the flex settings to the Box
              nodes.forEach((node) => addFlexToNode(editorState, node));
            });
          }}
        >
          <div style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>Add flex layout</div>
        </MenuPrim.Item>
      );
    } else if (action === 'remove-flex') {
      return (
        <MenuPrim.Item
          onSelect={() => {
            transaction(() => {
              nodes.forEach((node) => removeFlexFromNode(node));
            });
          }}
        >
          <div style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>Remove flex</div>
        </MenuPrim.Item>
      );
    } else {
      return (
        <MenuPrim.Item onSelect={() => wrapNodesWithFlex(editorState, nodes)}>
          <div style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>Wrap in flex</div>
        </MenuPrim.Item>
      );
    }
  }
);
