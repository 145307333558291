import { composeRefs } from '@radix-ui/react-compose-refs';
import React, { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { baseInputProps, defaultPointerDownHandler } from './input';
import { parseNumberAndUnit, splitValueAndUnit } from '../editor/properties/parse-number-and-unit';
import { useColorMode } from './use-color-mode';
import { ColorMode, ColorUtils, type Color } from '@paper/models/src/colors/Color';
import { convertHexToRgb, convertRgbToOklch } from '@paper/models/src/colors/color-conversion';
import { clamp } from '@paper/models/src/math/clamp';

export interface ColorInputProps extends Omit<React.ComponentProps<'input'>, 'color' | 'value'> {
  color: Color;
  mode?: ColorMode;
  onColorCommit?: (value: Color) => void;
}

export function ColorInput({ color, mode: modeProp, onColorCommit, ...props }: ColorInputProps) {
  const gamut = color.gamut;
  const [mode] = useColorMode(gamut, modeProp);
  const sourceValue = ColorUtils.pretty(color, mode);
  const [value, setValue] = useState(sourceValue);
  const [input, setInput] = useState<HTMLInputElement | null>(null);
  const isDirty = useRef(false);

  // Track the external value prop if the input isn't focused.
  // Makes sure that the internal value doesn't get stale in the
  // case that the props don't change after the value is committed.
  const shouldResetValue = input && document.activeElement !== input && sourceValue !== value;
  if (shouldResetValue) {
    setValue(sourceValue);
  }

  function commitValue(value: string) {
    const color = ColorUtils.new(value);

    if (color === null) {
      setValue(sourceValue);
      return;
    }

    // Preserve the original gamut if the parser had no opinion
    // TODO Vlad there is a bug when pasting a CSS P3 string doesn't display back P3 in the input
    color.gamut ??= gamut;
    const formatted = ColorUtils.pretty(color, mode);
    setValue(formatted);
    onColorCommit?.(color);
  }

  function handleBlur() {
    if (isDirty.current) {
      commitValue(value);
    }

    isDirty.current = false;
  }

  function handleCopy(event: React.ClipboardEvent<HTMLInputElement>) {
    const { selectionStart, selectionEnd, value } = event.currentTarget;

    if (selectionStart !== 0 || selectionEnd !== value.length) {
      return;
    }

    const color = ColorUtils.new(value);

    if (color === null) {
      return;
    }

    event.preventDefault();
    event.clipboardData.setData('text/plain', ColorUtils.cssString(color, mode));
  }

  // Run the blur handler on unmount
  const handleBlurRef = useRef(handleBlur);
  handleBlurRef.current = handleBlur;
  useEffect(() => {
    return () => handleBlurRef.current();
  }, []);

  return (
    <input
      {...baseInputProps}
      {...props}
      ref={(node) => {
        setInput(node);
        if (typeof props.ref === 'object') {
          composeRefs(props.ref, { current: node });
        }
      }}
      value={value}
      onCopy={handleCopy}
      onCut={handleCopy}
      onBlur={(event) => {
        handleBlur();
        props.onBlur?.(event);
      }}
      onFocus={(event) => {
        // Select on focus (when the label is clicked or with `autoFocus`)
        // Note: use `event.target` over `input` since the latter won't work with `autoFocus` in popups
        event.target.select();
        props.onFocus?.(event);
      }}
      onChange={(event) => {
        isDirty.current = true;
        setValue(event.target.value);
        props.onChange?.(event);
      }}
      onBeforeInput={() => {
        // Catch input that doesn't result in `onChange`
        // (Note: `onChange` is still needed to catch character removal)
        isDirty.current = true;
      }}
      onKeyDown={(event) => {
        if (!input) {
          return;
        }

        if (event.key === 'Escape') {
          if (value === sourceValue) {
            input?.blur();
          } else {
            flushSync(() => setValue(sourceValue));
            input?.select();
          }
        }

        if (event.key === 'Enter') {
          // Treat the value as dirty when the Enter key is pressed
          isDirty.current = true;
          input?.blur();
        }

        const start = input.selectionStart;
        const end = input.selectionEnd;

        if (!event.key.startsWith('Arrow') || start === null || end === null || event.metaKey) {
          return;
        }

        let groups = splitValueIntoGroups(input.value);

        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
          const pos = event.key === 'ArrowDown' ? start : end;
          let group = getGroupAt(groups, pos);

          if (group) {
            event.preventDefault();
            const sub = nudgeColor({
              value: input.value,
              start: group.start,
              end: group.end,
              dir: event.key === 'ArrowDown' ? -1 : 1,
              bigNudge: event.shiftKey,
            });

            // console.log({ sub });

            const newValue = input.value.substring(0, group.start) + sub + input.value.substring(group.end);
            flushSync(() => commitValue(newValue));

            // Find new selection boundaries after the new color is written into the input
            groups = splitValueIntoGroups(input.value);
            group = getGroupAt(groups, group.start);

            if (group) {
              input.selectionStart = group.start;
              input.selectionEnd = group.end;
            }
          }
        }

        // TODO Vlad multi-group selection and nudging
        if (event.shiftKey) {
          return;
        }

        if (event.key === 'ArrowLeft' && event.altKey) {
          const group = getGroupBefore(groups, start, end);
          if (group) {
            event.preventDefault();
            input.selectionStart = group.start;
            input.selectionEnd = group.end;
          }
        }

        if (event.key === 'ArrowRight' && event.altKey) {
          const group = getGroupAfter(groups, start, end);
          if (group) {
            event.preventDefault();
            input.selectionStart = group.start;
            input.selectionEnd = group.end;
          }
        }
      }}
      onPointerDown={(event) => {
        defaultPointerDownHandler(input);
        props.onPointerDown?.(event);
      }}
    />
  );
}

// Split the input value into distinct groups that are navigated via arrow keys
function splitValueIntoGroups(value: string) {
  let pos = 0;
  return value
    .trim()
    .split(/\s*,\s*|\s*\/\s*|\s+/gi)
    .map((sub) => {
      const index = value.indexOf(sub, pos);
      pos = index + sub.length;
      return { sub, start: index, end: index + sub.length };
    });
}

interface NudgeColorArgs {
  value: string;
  start: number;
  end: number;
  dir: -1 | 1;
  bigNudge: boolean;
}

function nudgeColor({ value, start, end, dir, bigNudge }: NudgeColorArgs) {
  const sub = value.substring(start, end);
  const colorFromSubstring = ColorUtils.parse(sub);
  const colorFromString = ColorUtils.parse(value);

  let min = 0;
  let max = 100;
  let amount = 1;
  const mode = colorFromString?.mode ?? 'hex';

  // TODO Vlad this is not sturdy
  // nudge amounts and min/max should be determined based
  // on the channel under the cursor and not the whole color mode

  // Check if we are nudging the alpha channel (after the slash)

  if (sub.includes('°')) {
    min = -Infinity;
    max = Infinity;
  } else if (!sub.includes('%')) {
    switch (mode) {
      case 'rgb': {
        max = 255;
        break;
      }
      case 'p3': {
        max = 1;
        amount = 0.001;
        break;
      }
      case 'hsl': {
        max = 100;
        break;
      }
      case 'oklch': {
        amount = 0.001;
        max = 0.4;
        break;
      }
    }
  }

  amount *= bigNudge ? dir * 10 : dir;

  // We are nudging the hex part of the hex if both substring and full value are parsed as an equivalent hex
  if (
    colorFromSubstring?.mode === 'hex' &&
    colorFromString?.mode === 'hex' &&
    ColorUtils.isEqual(
      { value: { ...ColorUtils.toOklch(colorFromSubstring), alpha: 1 } },
      { value: { ...ColorUtils.toOklch(colorFromString), alpha: 1 } }
    )
  ) {
    const rgb = convertHexToRgb(colorFromString);
    rgb.r = clamp((rgb.r * 255 + amount) / 255, 0, 255);
    rgb.g = clamp((rgb.g * 255 + amount) / 255, 0, 255);
    rgb.b = clamp((rgb.b * 255 + amount) / 255, 0, 255);
    const color: Color = { value: convertRgbToOklch(rgb) };
    return ColorUtils.cssString(color).substring(1);
  }

  let [channel, unit] = splitValueAndUnit(sub);

  if (Number.isNaN(channel)) {
    return sub;
  }

  return clamp(parseFloat(channel) + amount, min, max) + unit;
}

function getGroupAt(groups: ReturnType<typeof splitValueIntoGroups>, pos: number) {
  return groups.find((group) => group.start <= pos && group.end >= pos);
}

function getGroupBefore(groups: ReturnType<typeof splitValueIntoGroups>, start: number, end: number) {
  return (
    groups.toReversed().find((group) => (group.start === start ? group.end < end : group.start < start)) || groups[0]
  );
}

function getGroupAfter(groups: ReturnType<typeof splitValueIntoGroups>, start: number, end: number) {
  return (
    groups.find((group) => (group.end === end ? group.start > start : group.end > end)) || groups[groups.length - 1]
  );
}
