import { ColorMode, ColorUtils } from '@paper/models/src/colors/Color';
import { ColorPickerContext } from './color-picker';
import { Field, FieldGroup } from '../../components/field';
import { NumberInput } from '../properties/number-input';
import { CopyButton } from '../../components/copy-button';
import { roundOptimized } from '@paper/models/src/math/round-optimized';
import {
  convertOklchToOklab,
  convertOklabToOklch,
  convertOklchToRgb,
  convertRgbToOklch,
  convertOklchToP3,
  convertP3ToOklch,
  convertOklchToHsl,
  convertHslToOklch,
} from '@paper/models/src/colors/color-conversion';
import { formatAlpha, formatChannel, formatHue, formatOkChannel } from '@paper/models/src/colors/color-formatting';
import { ColorInput } from '../../components/color-input';
import { CaretIcon } from '../../icons/caret-icon';
import { useColorMode } from '../../components/use-color-mode';
import { useId } from 'react';
import { useContext } from 'react';

export function ColorPickerFieldsOklch() {
  const { tab, color, onColorChange } = useContext(ColorPickerContext);
  const { l, c, h, alpha = 1 } = color.value;

  const lightnessId = useId();
  const chromaId = useId();
  const hueId = useId();
  const alphaId = useId();

  return (
    <div>
      <div className="flex gap-2">
        <FieldGroup>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={lightnessId}
                units={[]}
                min={0}
                max={100}
                value={String(l * 100)}
                format={(value) => formatChannel(value, 1, 0, 100)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: { ...color.value, l: parseFloat(value) / 100 },
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={chromaId}
                units={[]}
                min={0}
                max={0.4}
                increments={[0.001, 0.01]}
                value={String(c)}
                format={(value) => formatOkChannel(value, 0)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: { ...color.value, c: parseFloat(value) },
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={hueId}
                units={[]}
                value={String(h)}
                format={(value) => {
                  // Collapse hue to 0 if chroma is going to be presented as 0 too
                  if (roundOptimized(color.value.c, 4) < 0.0001) {
                    return '0';
                  }

                  return formatHue(value, true);
                }}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: { ...color.value, h: parseFloat(value) },
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={alphaId}
                min={0}
                max={100}
                units={[]}
                value={String(alpha * 100)}
                format={(value) => formatAlpha(value)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: { ...color.value, alpha: parseFloat(value) / 100 },
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
        </FieldGroup>
        <CopyButton tooltip="Copy OKLCH" getValue={() => ColorUtils.cssString(color, 'oklch')} />
      </div>

      <div className="grid grid-cols-4 justify-items-center pr-8">
        <label htmlFor={lightnessId} className={label}>
          L
        </label>
        <label htmlFor={chromaId} className={label}>
          C
        </label>
        <label htmlFor={hueId} className={label}>
          H
        </label>
        <label htmlFor={alphaId} className={label}>
          A
        </label>
      </div>
    </div>
  );
}

export function ColorPickerFieldsOklab() {
  const { tab, color, onColorChange } = useContext(ColorPickerContext);
  const oklab = convertOklchToOklab(color.value);
  const { l, a, b, alpha = 1 } = oklab;

  const lightnessId = useId();
  const aId = useId();
  const bId = useId();
  const alphaId = useId();

  return (
    <div>
      <div className="flex gap-2">
        <FieldGroup>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={lightnessId}
                units={[]}
                min={0}
                max={100}
                value={String(l * 100)}
                format={(value) => formatChannel(value, 1, 0, 100)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: { ...color.value, l: parseFloat(value) / 100 },
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={aId}
                units={[]}
                min={-0.4}
                max={0.4}
                increments={[0.001, 0.01]}
                value={String(a)}
                format={(value) => formatOkChannel(value)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertOklabToOklch({ ...oklab, a: parseFloat(value) }, color.value.h),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={bId}
                units={[]}
                min={-0.4}
                max={0.4}
                increments={[0.001, 0.01]}
                value={String(b)}
                format={(value) => formatOkChannel(value)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertOklabToOklch({ ...oklab, b: parseFloat(value) }, color.value.h),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={alphaId}
                min={0}
                max={100}
                units={[]}
                value={String(alpha * 100)}
                format={(value) => formatAlpha(value)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: { ...color.value, alpha: parseFloat(value) / 100 },
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
        </FieldGroup>
        <CopyButton tooltip="Copy OKLAB" getValue={() => ColorUtils.cssString(color, 'oklab')} />
      </div>

      <div className="grid grid-cols-4 justify-items-center pr-8">
        <label htmlFor={lightnessId} className={label}>
          L
        </label>
        <label htmlFor={aId} className={label}>
          a
        </label>
        <label htmlFor={bId} className={label}>
          b
        </label>
        <label htmlFor={alphaId} className={label}>
          A
        </label>
      </div>
    </div>
  );
}

export function ColorPickerFieldsRgb() {
  const { tab, color, onColorChange } = useContext(ColorPickerContext);
  const rgb = convertOklchToRgb(color.value);
  const { r, g, b, alpha = 1 } = rgb;

  const redId = useId();
  const greenId = useId();
  const blueId = useId();
  const alphaId = useId();

  return (
    <div>
      <div className="flex gap-2">
        <FieldGroup>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={redId}
                units={[]}
                min={0}
                max={255}
                value={String(r * 255)}
                format={(value) => formatChannel(value, 0, 0, 255)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertRgbToOklch({ ...rgb, r: parseFloat(value) / 255 }, color.value.h),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={greenId}
                units={[]}
                min={0}
                max={255}
                value={String(g * 255)}
                format={(value) => formatChannel(value, 0, 0, 255)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertRgbToOklch({ ...rgb, g: parseFloat(value) / 255 }, color.value.h),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={blueId}
                units={[]}
                min={0}
                max={255}
                value={String(b * 255)}
                format={(value) => formatChannel(value, 0, 0, 255)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertRgbToOklch({ ...rgb, b: parseFloat(value) / 255 }, color.value.h),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={alphaId}
                min={0}
                max={100}
                units={[]}
                value={String(alpha * 100)}
                format={(value) => formatAlpha(value)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: { ...color.value, alpha: parseFloat(value) / 100 },
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
        </FieldGroup>
        <CopyButton tooltip="Copy RGB" getValue={() => ColorUtils.cssString(color, 'rgb')} />
      </div>

      <div className="grid grid-cols-4 justify-items-center pr-8">
        <label htmlFor={redId} className={label}>
          R
        </label>
        <label htmlFor={greenId} className={label}>
          G
        </label>
        <label htmlFor={blueId} className={label}>
          B
        </label>
        <label htmlFor={alphaId} className={label}>
          A
        </label>
      </div>
    </div>
  );
}

export function ColorPickerFieldsDisplayP3() {
  const { tab, color, onColorChange } = useContext(ColorPickerContext);
  const p3 = convertOklchToP3(color.value);
  const { r, g, b, alpha = 1 } = p3;

  const redId = useId();
  const greenId = useId();
  const blueId = useId();
  const alphaId = useId();

  return (
    <div>
      <div className="flex gap-2">
        <FieldGroup>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={redId}
                units={[]}
                min={0}
                max={1}
                increments={[0.001, 0.01]}
                value={String(r)}
                format={(value) => formatChannel(value, 3)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertP3ToOklch({ ...p3, r: parseFloat(value) }, color.value.h),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={greenId}
                units={[]}
                min={0}
                max={1}
                increments={[0.001, 0.01]}
                value={String(g)}
                format={(value) => formatChannel(value, 3)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertP3ToOklch({ ...p3, g: parseFloat(value) }, color.value.h),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={blueId}
                units={[]}
                min={0}
                max={1}
                increments={[0.001, 0.01]}
                value={String(b)}
                format={(value) => formatChannel(value, 3)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertP3ToOklch({ ...p3, b: parseFloat(value) }, color.value.h),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={alphaId}
                min={0}
                max={100}
                units={[]}
                value={String(alpha * 100)}
                format={(value) => formatAlpha(value)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: { ...color.value, alpha: parseFloat(value) / 100 },
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
        </FieldGroup>
        <CopyButton tooltip="Copy Display P3" getValue={() => ColorUtils.cssString(color, 'p3')} />
      </div>

      <div className="grid grid-cols-4 justify-items-center pr-8">
        <label htmlFor={redId} className={label}>
          R
        </label>
        <label htmlFor={greenId} className={label}>
          G
        </label>
        <label htmlFor={blueId} className={label}>
          B
        </label>
        <label htmlFor={alphaId} className={label}>
          A
        </label>
      </div>
    </div>
  );
}

export function ColorPickerFieldsHsl() {
  const { tab, color, onColorChange } = useContext(ColorPickerContext);
  const hsl = convertOklchToHsl(color.value);
  const { h, s, l, alpha = 1 } = hsl;

  // Collapse hue and saturation to 0 if the actually rendered RGB color is gray
  const { r, g, b } = convertOklchToRgb(color.value);
  const isGray = [r, g, b].map((n) => Math.round(n * 255)).every((n, i, a) => n === a[0]);

  const hueId = useId();
  const saturationId = useId();
  const lightnessId = useId();
  const alphaId = useId();

  return (
    <div>
      <div className="flex gap-2">
        <FieldGroup>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={hueId}
                units={[]}
                value={String(h)}
                format={(value) => (isGray ? '0' : formatHue(value, true))}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertHslToOklch({ ...hsl, h: parseFloat(value) }),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={saturationId}
                units={[]}
                min={0}
                max={100}
                value={String(s * 100)}
                format={(value) => (isGray ? '0' : formatChannel(value, 1, 0, 100))}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertHslToOklch({ ...hsl, s: parseFloat(value) / 100 }),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={lightnessId}
                units={[]}
                min={0}
                max={100}
                value={String(l * 100)}
                format={(value) => formatChannel(value, 1, 0, 100)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: convertHslToOklch({ ...hsl, l: parseFloat(value) / 100 }),
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control align="center">
              <NumberInput
                id={alphaId}
                min={0}
                max={100}
                units={[]}
                value={String(alpha * 100)}
                format={(value) => formatAlpha(value)}
                onValueCommit={(value) => {
                  onColorChange({
                    gamut: tab,
                    value: { ...color.value, alpha: parseFloat(value) / 100 },
                  });
                }}
              />
            </Field.Control>
          </Field.Root>
        </FieldGroup>
        <CopyButton tooltip="Copy HSL" getValue={() => ColorUtils.cssString(color, 'hsl')} />
      </div>

      <div className="grid grid-cols-4 justify-items-center pr-8">
        <label htmlFor={hueId} className={label}>
          H
        </label>
        <label htmlFor={saturationId} className={label}>
          S
        </label>
        <label htmlFor={lightnessId} className={label}>
          L
        </label>
        <label htmlFor={alphaId} className={label}>
          A
        </label>
      </div>
    </div>
  );
}

export function ColorPickerFieldsMainInputRgb() {
  const { color, onColorChange } = useContext(ColorPickerContext);
  const [mode, saveMode] = useColorMode('rgb');
  const inputId = useId();

  return (
    <div>
      <div className="flex gap-2">
        <Field.Root>
          <Field.Control align="center">
            <ColorInput data-autofocus id={inputId} color={color} onColorCommit={onColorChange} mode={mode} />
          </Field.Control>
        </Field.Root>
        <div className="IconButton relative">
          <select
            value={mode}
            onChange={(event) => saveMode(event.target.value as ColorMode, 'rgb')}
            className="absolute inset-0 overflow-hidden opacity-0"
          >
            <option value="oklch">OKLCH</option>
            <option value="hsl">HSL</option>
            <option value="rgb">RGB</option>
            <option value="hex">Hex</option>
          </select>
          <CaretIcon />
        </div>
      </div>
    </div>
  );
}
export function ColorPickerFieldsMainInputP3() {
  const { color, onColorChange } = useContext(ColorPickerContext);
  const [mode, saveMode] = useColorMode('p3');
  const inputId = useId();

  return (
    <div>
      <div className="flex gap-2">
        <Field.Root>
          <Field.Control align="center">
            <ColorInput data-autofocus id={inputId} color={color} onColorCommit={onColorChange} mode={mode} />
          </Field.Control>
        </Field.Root>
        <div className="IconButton relative">
          <select
            value={mode}
            onChange={(event) => saveMode(event.target.value as ColorMode, 'p3')}
            className="absolute inset-0 overflow-hidden opacity-0"
          >
            <option value="oklch">OKLCH</option>
            <option value="p3">Display P3</option>
          </select>
          <CaretIcon />
        </div>
      </div>
    </div>
  );
}

const label = 'text-gray-2 pt-0.5 px-1 text-sm font-medium select-none';
