import { useEffect } from 'react';
import { EditorState } from '../EditorState';
import { Vec2 } from '@paper/models/src/math/vec2';

/** Moves the pan of the canvas if the cursor is near the edge of the viewable area during a drag, selection, etc */
export const useAutoPan = (editorState: EditorState) => {
  useEffect(() => {
    const { cameraState, moveToolState, pointerState, drawToolState, hudState, textTool } = editorState;

    /** Pan speed, multiplied by delta time, just pick a number that feels good */
    const panSpeed = 2000;
    /** How close the cursor needs to be to the edge of the visible area to start auto panning */
    const cursorProximityThreshold = 30;
    /** The distance in pixels over which the pan speed ramps up – so if you're 2 pixels into the threshold, it's 2/this in. Note this continues on past the bounds of the window */
    const panSpeedRampUpSizePx = 40;

    /** Stores the RAF for the auto-pan */
    let panAnimationFrame: number | null = null;
    /** Stores the direction and magnitude for the next auto-pan raf */
    let panVector: Vec2 = { x: 0, y: 0 };

    function handlePointerMove(e: PointerEvent) {
      if (shouldAutoPan() === false) {
        return;
      }

      const { currentViewableAreaViewport } = cameraState;

      const distanceToLeft = pointerState.cursorPos.x - currentViewableAreaViewport.minX;
      const distanceToRight = currentViewableAreaViewport.maxX - pointerState.cursorPos.x;
      const distanceToBottom = currentViewableAreaViewport.maxY - pointerState.cursorPos.y;
      const distanceToTop = pointerState.cursorPos.y - currentViewableAreaViewport.minY;

      if (distanceToLeft < cursorProximityThreshold) {
        const howFarIntoPanZone =
          currentViewableAreaViewport.minX + cursorProximityThreshold - pointerState.cursorPos.x;
        const speedMultiplier = Math.min(howFarIntoPanZone / panSpeedRampUpSizePx, 1);
        panVector.x = speedMultiplier;
      } else if (distanceToRight < cursorProximityThreshold) {
        const howFarIntoPanZone =
          pointerState.cursorPos.x - (currentViewableAreaViewport.maxX - cursorProximityThreshold);
        const speedMultiplier = Math.min(howFarIntoPanZone / panSpeedRampUpSizePx, 1);
        panVector.x = -speedMultiplier;
      } else {
        panVector.x = 0;
      }

      if (distanceToTop < cursorProximityThreshold) {
        const howFarIntoPanZone =
          currentViewableAreaViewport.minY + cursorProximityThreshold - pointerState.cursorPos.y;
        const speedMultiplier = Math.min(howFarIntoPanZone / panSpeedRampUpSizePx, 1);
        panVector.y = speedMultiplier;
      } else if (distanceToBottom < cursorProximityThreshold) {
        const howFarIntoPanZone =
          pointerState.cursorPos.y - (currentViewableAreaViewport.maxY - cursorProximityThreshold);
        const speedMultiplier = Math.min(howFarIntoPanZone / panSpeedRampUpSizePx, 1);
        panVector.y = -speedMultiplier;
      } else {
        panVector.y = 0;
      }

      if (panVector.x !== 0 || panVector.y !== 0) {
        schedulePanNextFrame();
      }
    }

    /** Returns true if we should be looking for auto-pan cursor intent */
    function shouldAutoPan() {
      return (
        moveToolState.dragState.isDragging === true || // dragging
        moveToolState.selectionBrushState.isSelectBrushing === true || // selection brushing
        pointerState.isControlledByHandle !== null || // moving a handle or resizing
        drawToolState.isDrawing === true || // drawing
        textTool.isDrawingText === true // drawing text
      );
    }

    /** Schedules a pan before the next frame */
    function schedulePanNextFrame() {
      // bail if it's already scheduled
      if (panAnimationFrame !== null) return;
      panAnimationFrame = requestAnimationFrame(performPan);
    }

    let lastFrameTime = 0;
    const maxDeltaTime = 1 / 30;
    /** Moves the camera pan by whatever the camera vector is and reschedules itself until we shouldn't auto-pan anymore */
    function performPan() {
      // Reset the animation frame request
      panAnimationFrame = null;
      // Calculate delta time
      const currentTime = Date.now();
      const deltaTime = Math.min((currentTime - lastFrameTime) / 1000, maxDeltaTime);
      lastFrameTime = currentTime;

      // We check again for shouldAutoPan in the RAF in case conditions have changed – timings can get weird with escape key presses and such before the RAF
      if (shouldAutoPan()) {
        const panAmountX = panVector.x * panSpeed * deltaTime;
        const panAmountY = panVector.y * panSpeed * deltaTime;
        cameraState.setPan(cameraState.pan.x + panAmountX, cameraState.pan.y + panAmountY);
        schedulePanNextFrame();
      } else {
        // Conditions changed before the frame and we don't need to autopan anymore - go ahead and clear out the pan vector
        panVector.x = 0;
        panVector.y = 0;
      }
    }

    if (!hudState.hudEl) return;

    hudState.hudEl.addEventListener('pointermove', handlePointerMove);
    return () => {
      hudState.hudEl?.removeEventListener('pointermove', handlePointerMove);
    };
  }, [editorState]);
};
