import {
  type TableEntry,
  getFontFileData,
  parseFvarTable,
  parseLtagTable,
  parseNameTable,
  parseOS2Table,
  uncompressTable,
} from '@paper-design/opentype.js/fn';
import { Font } from './FontState';

/*
 * Parses additional metadata from the local font file,
 * such as the exact weight, width, variable axes, etc.,
 * that we use for grouping fonts in the font style select
 * and for matching one style to another.
 */
export async function parseLocalFont(fontData: FontData) {
  try {
    const font: Font = {
      family: fontData.family,
      style: fontData.style,
      weight: 400,
      isItalic: false,
      fullName: fontData.fullName,
      postscriptName: fontData.postscriptName,
    };

    const blob = await fontData.blob();
    const buffer = await blob.arrayBuffer();
    const { data, tableEntries } = getFontFileData(buffer);

    let fvarTableEntry: TableEntry | undefined;
    let ltagTableEntry: TableEntry | undefined;
    let nameTableEntry: TableEntry | undefined;
    let os2TableEntry: TableEntry | undefined;

    for (const tableEntry of tableEntries) {
      switch (tableEntry.tag) {
        case 'fvar':
          fvarTableEntry = tableEntry;
          break;
        case 'ltag':
          ltagTableEntry = tableEntry;
          break;
        case 'name':
          nameTableEntry = tableEntry;
          break;
        case 'OS/2':
          os2TableEntry = tableEntry;
          break;
      }
    }

    // Determining whether a font is italic:
    //
    // In theory, font files must follow the OpenType standard and
    // set certain metadata about whether a font is italicised.
    // In practice, many fonts don't set all the metadata bits correctly,
    // or don't set any at all.
    //
    // Notable offender: "Helvetica Neue Medium Italic" on macOS doesn't mention
    // anything about its italicisation anywhere except the style name.
    //
    // As a quick initial heuristic, check the style name
    font.isItalic = /italic|oblique|cursive|slant|slope/i.test(font.style);

    if (os2TableEntry) {
      const table = uncompressTable(data, os2TableEntry);
      const os2 = parseOS2Table(table.data, table.offset);
      font.weight = os2.usWeightClass;

      // Check the "fsSelection" field – this is the canonical source of info about font italicisation:
      font.isItalic ||= (os2.fsSelection & 1) === 1;

      // Previous strategies.
      // Not needed in practice, the name check + fsSelection check seem to cover things:
      //
      // Some fonts don't set "fsSelection" field correctly
      // Notable offender: some of the Apple’s "New York" subfamilies
      // Check post table's italic angle as a fallback
      // if (!font.isItalic && postTableEntry) {
      //   const table = uncompressTable(data, postTableEntry);
      //   const post = parsePostTable(table.data, table.offset);
      //   font.isItalic = post.italicAngle !== 0;
      // }
      //
      // Some fonts set neither "fsSelection" nor "italicAngle" entries correctly
      // Notable offender: the "Helvetica" styles on macOS
      // Check head table's macStyle as a fallback
      // if (!font.isItalic && headTableEntry) {
      //   const table = uncompressTable(data, headTableEntry);
      //   const head = parseHeadTable(table.data, table.offset);
      //   font.isItalic = (head.macStyle & 2) === 2;
      // }

      os2.usWidthClass ||= 5;
      if (os2.usWidthClass !== 5) {
        // Record width if it's non-standard (we assume standard width if this field is unavailable anyway)
        // Values are normalize to 'wdth' axis values (100 is 5, each step is 12.5)
        font.width = 37.5 + os2.usWidthClass * 12.5;
      }
    }

    if (nameTableEntry) {
      let ltag: string[] = [];

      if (ltagTableEntry) {
        const ltagTable = uncompressTable(data, ltagTableEntry);
        ltag = parseLtagTable(ltagTable.data, ltagTable.offset);
      }

      const nameTable = uncompressTable(data, nameTableEntry);
      const names = parseNameTable(nameTable.data, nameTable.offset, ltag);

      if (names.macintosh?.wwsFamily) {
        font.subfamily = names.macintosh.wwsFamily.en || Object.values(names.macintosh.wwsFamily)[0];
      }

      if (fvarTableEntry) {
        const fvarTable = uncompressTable(data, fvarTableEntry);
        const fvar = parseFvarTable(fvarTable.data, fvarTable.offset, names);
        font.axes = fvar.axes;

        const instance = fvar.instances.find((instance) => {
          return Object.values(instance.name).find((name) => name === font.style);
        });

        if (instance) {
          font.coordinates = instance.coordinates;
          for (const axis in instance.coordinates) {
            switch (axis) {
              case 'wght':
                font.weight = instance.coordinates.wght!;
                break;
              case 'wdth':
                font.width = instance.coordinates.wdth;
                break;
              case 'ital':
                font.isItalic ||= instance.coordinates.ital !== 0;
                break;
              case 'slnt':
                font.isItalic ||= instance.coordinates.slnt !== 0;
                break;
            }
          }
        }
      }
    }

    // Some fonts report 0 weight, like Apple Chancery (invalid according to the spec, harmful for our purposes).
    font.weight ||= 400;
    return font;

    // In some cases, the browser may fail to return a local font.
    // This may happen when a font file is corrupt or assembled incorrectly.
  } catch (error) {
    console.warn(error, fontData);
    return null;
  }
}
