import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { UserFocusType } from '@paper/models/src/websocket/multiplayer-user';
import { assert } from '../../assert';
import { useEffect } from 'react';
import { getBoundsForNodes } from '../tree/get-bounds-for-nodes';

// Fade opacity if the user is off the tab
const opacityPerFocus = {
  [UserFocusType.Canvas]: 1,
  [UserFocusType.Interface]: 1,
  [UserFocusType.Blurred]: 0.25,
};

type Props = {
  clientId: string;
};
export const MultiplayerSelectionHighlight = observer(({ clientId }: Props) => {
  const editorState = useEditor();

  useEffect(() => {
    const { hudState, cameraState, multiplayerState, treeUtils, pageState } = editorState;
    const user = multiplayerState.userData[clientId];

    function drawSelectionHighlight(ctx: CanvasRenderingContext2D) {
      assert(user);
      const userPageId = user.pageId;

      // Don't render if they're on a different page
      if (userPageId !== pageState.activePageId) {
        return null;
      }
      // Don't render if the user has no selected nodes
      if (user.selectedNodes.length === 0) {
        return null;
      }

      const opacity = opacityPerFocus[user.focus];
      const color = user.color;

      // Calculate the bounds of the selected nodes
      // TODO: consider having a max like 30-50 range
      const nodes = user.selectedNodes.map((id) => treeUtils.getNode(id)).filter((node) => node !== null);
      const bounds = getBoundsForNodes(nodes);

      // Calculate line width and offset to make the line be on exact pixels
      const lineWidth = 2 * cameraState.scaleInverse;
      const offset = lineWidth / 2;

      const originalAlpha = ctx.globalAlpha;
      ctx.strokeStyle = color;
      ctx.globalAlpha = opacity;
      ctx.lineWidth = lineWidth;
      ctx.strokeRect(bounds.minX - offset, bounds.minY - offset, bounds.width + lineWidth, bounds.height + lineWidth);
      ctx.globalAlpha = originalAlpha;
    }

    hudState.worldDrawingFunctions.add(drawSelectionHighlight);
    return () => {
      hudState.worldDrawingFunctions.delete(drawSelectionHighlight);
    };
  }, [editorState, clientId]);

  return null;
});
