import { roundOptimized } from '@paper/models/src/math/round-optimized';
import { formatAlpha, formatChannel, formatHue, formatOkChannel, prettySymbolsRegexp } from './color-formatting';
import type { Oklch } from './Color';

export function getPrettyOklch({ l, c, h, alpha = 1 }: Oklch, explicitAlpha = true) {
  let str = formatChannel(l * 100, 1, 0, 100) + '% ';
  str += formatOkChannel(c, 0) + ' ';
  // Collapse hue to 0 if chroma is going to be presented as 0 too
  str += roundOptimized(c, 4) < 0.0001 ? '0°' : formatHue(h, false) + '°';

  if (explicitAlpha || roundOptimized(alpha, 3) !== 1) {
    str += ' / ' + formatAlpha(alpha * 100) + '%';
  }

  return str;
}

export function getCssStringOklch(oklch: Oklch) {
  return `oklch(${getPrettyOklch(oklch, false).replace(prettySymbolsRegexp, '')})`;
}
