import { useCallback, useEffect, useState } from 'react';
import { ColorMode, colorModeSupportsP3, ColorSpace, isColorMode } from '@paper/models/src/colors/Color';

const KEYS = {
  rgb: 'color-mode-rgb',
  p3: 'color-mode-p3',
} as const;

export function useColorMode(gamut: ColorSpace = 'rgb', override?: ColorMode) {
  const [value, setValue] = useState<ColorMode>(() => getValue(gamut));

  const saveMode = useCallback(function (value: ColorMode, gamut: ColorSpace) {
    setValue(value);

    const eventType = getEventType(gamut);
    const event = new CustomEvent<ColorMode>(eventType, { detail: value });
    dispatchEvent(event);

    try {
      localStorage.setItem(KEYS[gamut], JSON.stringify(value));
    } catch (error) {}
  }, []);

  useEffect(() => {
    const value = getValue(gamut);
    setValue(value);

    function handleCustomEvent(event: CustomEventInit<ColorMode>) {
      if (event.detail) {
        setValue(event.detail);
      }
    }

    const eventType = getEventType(gamut);
    addEventListener(eventType, handleCustomEvent);
    return () => removeEventListener(eventType, handleCustomEvent);
  }, [gamut]);

  if (override !== undefined) {
    return [override, saveMode] as const;
  }

  return [value, saveMode] as const;
}

function getEventType(gamut: ColorSpace) {
  return `change-${KEYS[gamut]}`;
}

function getValue(gamut: ColorSpace) {
  const key = KEYS[gamut];

  try {
    const value = JSON.parse(localStorage.getItem(key) || 'null');

    if (!value) {
      return gamut === 'p3' ? 'p3' : 'hex';
    }

    if (gamut === 'p3' && colorModeSupportsP3(value) === false) {
      console.warn('Unrecognized color mode in local storage:', value);
      localStorage.removeItem(key);
      return 'p3';
    }

    if (isColorMode(value) === false) {
      console.warn('Unrecognized color mode in local storage:', value);
      localStorage.removeItem(key);
      return 'hex';
    }

    return value;
  } catch (error) {
    console.warn(error);
    return 'hex';
  }
}
