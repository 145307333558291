import { makeObservable, observable, computed, action } from 'mobx';
import { EditorState } from '../EditorState';
import { RectWithSize } from '@paper/models/src/math/rect';
import { Tool } from '../toolbar/ToolState';

export class ZoomToolState {
  constructor(public editorState: EditorState) {
    makeObservable(this);
  }
  dispose = () => {
    // This shouldn't get stuck subbed, but just in case, cannot hurt to clear it
    this.editorState.tickState.unsubToTick(this.watchForZoomEndConditions);
  };

  startTool = () => {
    // Start watching for zoom ending conditions
    this.trustingZKey = true;
    this.editorState.tickState.subToTick(this.watchForZoomEndConditions);
  };

  /** Bounds of the zoom draw in progress */
  @computed({ keepAlive: true }) get isDrawingZoomBrush() {
    return this.zoomBounds !== null;
  }

  /** Bounds of the zoom draw in progress */
  @observable
  accessor zoomBounds: RectWithSize | null = null;
  @action
  setZoomBounds(bounds: RectWithSize) {
    this.zoomBounds = bounds;
  }

  @action
  clearZoomBounds() {
    this.zoomBounds = null;
  }

  /** We can't reliably get keyup event for Z since it's common to use cmd+z and cmd blocks keyup events, so we stop trusting the z key after we see cmd pressed */
  trustingZKey = true;
  /**
   * We can't reliably get a keyup event for Z, because cmd+z for redo and MacOS blocks keyups in some situations.
   * So instead we check for the conditions that would end zooming on each tick and end zoom if they are met
   */
  watchForZoomEndConditions = () => {
    const editorState = this.editorState;
    const toolState = editorState.toolState;
    const keyState = editorState.keyState;
    const tickState = editorState.tickState;

    if (this.editorState.keyState.isMetaDown) {
      this.trustingZKey = false;
    }

    const drawingBrush = this.isDrawingZoomBrush;
    const zIsDown = this.trustingZKey && keyState.keyCodeDown.has('KeyZ');
    const zoomIsActiveTool = toolState.activeTool === Tool.Zoom;

    if (zoomIsActiveTool && !drawingBrush && zIsDown === false) {
      toolState.setActiveTool(Tool.Move);
      // No need to watch anymore
      tickState.unsubToTick(this.watchForZoomEndConditions);
    }
  };
}
