import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { cloneNodesIntoTempTree } from '../tree/clone-nodes-into-temp-tree';
import { makeEmbeddedHtmlForClipboard } from './clipboard-embedded-html';

export const HandleCopy = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { selectionState } = editorState;

    function handleCopy(event: ClipboardEvent) {
      // Ignore copy events from inputs, textareas, and elements that are editable by the user
      if (event.target instanceof HTMLElement) {
        const targetTagName = event.target.tagName.toLowerCase();
        if (targetTagName === 'input' || targetTagName === 'textarea' || event.target.isContentEditable) {
          return;
        }
      }

      event.preventDefault();
      event.stopPropagation();

      const selectedNodeIds = selectionState.selectedNodeIds;
      if (selectedNodeIds.size === 0) {
        return;
      }

      // Build the temp tree JSON to embed into html
      const tempTree = cloneNodesIntoTempTree(editorState, Array.from(selectedNodeIds));
      const tempTreeString = JSON.stringify(tempTree);

      // TODO: if selection is SVG

      // Build the plain text paste in case any of the selected nodes are text nodes
      let plainTextPaste = '';
      for (let i = 0; i < selectionState.selectedNodes.length; i++) {
        const node = selectionState.selectedNodes[i]!;
        if (i > 0) {
          // Insert a line break between nodes
          plainTextPaste += '\n';
        }
        plainTextPaste += node.textValue;
      }

      const embeddedHtml = makeEmbeddedHtmlForClipboard(tempTreeString, plainTextPaste);
      event.clipboardData?.setData('text/html', embeddedHtml);
      event.clipboardData?.setData('text/plain', plainTextPaste);
    }

    window.addEventListener('copy', handleCopy);

    return () => {
      window.removeEventListener('copy', handleCopy);
    };
  }, [editorState]);

  return null;
});
