import { useId } from 'react';
import { CheckIcon } from '../icons/check-icon';
import { CopyIcon } from '../icons/copy-icon';
import { Tooltip } from './tooltip';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';

// Copy buttons share a global state so that clicking
// a copy button clears the check icon on another one

class CopyButtonState {
  private timeout = 0;
  @observable accessor id: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setId(value: string | null) {
    clearTimeout(this.timeout);
    this.id = value;
    this.timeout = window.setTimeout(() => {
      this.setId(null);
    }, 1500);
  }
}

const state = new CopyButtonState();

interface CopyButtonProps {
  tooltip: string;
  getValue: () => string;
}

export const CopyButton = observer(({ tooltip, getValue }: CopyButtonProps) => {
  const id = useId();

  return (
    <Tooltip.Root>
      <Tooltip.Trigger
        className="IconButton"
        onClick={() => {
          const value = getValue();
          navigator.clipboard.writeText(value);
          state.setId(id);
        }}
      >
        {state.id === id ? <CheckIcon className="text-gray-1" strokeWidth="1.1" /> : <CopyIcon />}
      </Tooltip.Trigger>
      {state.id !== id && <Tooltip.Popup>{tooltip}</Tooltip.Popup>}
    </Tooltip.Root>
  );
});
