import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { autorun } from 'mobx';
import { FontUtils } from './FontUtils';
import { googleStyleToWeight } from './google-font-styles';

// Possible TODO:
// - When nodes are deleted, their fonts are not removed from the in memory index (only matters for the duration of the current page load)
// - There is a FOUT when font style is being loaded from Google
//   - Before setting node.style, need to wait for its fonts to load (could use listeners on document.fonts)
//   - Before unloading a font, wait for pending style updates to complete

/** Loads fonts into the HTML document as they're needed */
export const FontLoader = observer(function FontLoaderImpl() {
  const { fontState } = useEditor();

  useEffect(() => {
    const loadedFamilies = new Set<string>();

    function loadFamily(familyName: string) {
      if (loadedFamilies.has(familyName)) {
        return;
      }

      loadedFamilies.add(familyName);

      if (FontUtils.isBuiltIn(familyName)) {
        return;
      }

      const family = fontState.getFamily(familyName);
      const stylesToLoad: string[] = [];

      family?.styles.forEach((style) => {
        // Add a <link> to Google Fonts for the family styles
        // that are available on Google and not available locally
        if (
          fontState.isAvailableFromGoogle(familyName, style) &&
          fontState.isAvailableLocally(familyName, style) === false
        ) {
          stylesToLoad.push(style);
        }
      });

      if (stylesToLoad.length > 0) {
        const link = document.createElement('link');
        link.href = getGoogleFontsLink(familyName, stylesToLoad);
        link.rel = 'stylesheet';
        link.setAttribute('data-family', familyName);
        document.head.appendChild(link);
      }
    }

    function unloadFamily(familyName: string) {
      if (loadedFamilies.has(familyName) === false) {
        return;
      }

      loadedFamilies.delete(familyName);
      document.querySelector(`link[data-family="${familyName}"]`)?.remove();
    }

    /** Watch the font index and load/unload fonts as needed */
    const fontWatcherDispose = autorun(() => {
      // Check each font style and load the corresponding font if it's not among the loaded fonts
      for (const [id] of fontState.fontToNodeIndex) {
        const { family } = FontUtils.breakId(id);
        loadFamily(family);
      }

      // Remove any loaded fonts that are no longer needed
      for (const family of loadedFamilies) {
        const fontIds = Array.from(fontState.fontToNodeIndex.keys());
        if (fontIds.some((id) => FontUtils.breakId(id).family === family) === false) {
          unloadFamily(family);
        }
      }
    });

    return () => {
      for (const family of loadedFamilies) {
        unloadFamily(family);
      }

      fontWatcherDispose();
    };
  }, [fontState]);

  return null;
});

function getGoogleFontsLink(familyName: string, styles: string[]) {
  const familyQuery = familyName.replaceAll(' ', '+');

  if (!familyQuery) {
    console.warn('Expected at least a single font to load');
  }

  let query: string[] = [];

  for (const style of styles) {
    const isItalic = style.includes('Italic');
    const weight = googleStyleToWeight[style]!;
    query.push(`${+isItalic},${weight}`);
  }

  // Google Fonts requires a certain order in the query:
  // grouped by italicisation and sorted by weight
  query.sort((a, b) => +a[0]! - +b[0]! || +a.slice(2) - +b.slice(2));

  return `https://fonts.googleapis.com/css2?family=${familyQuery}:ital,wght@${query.join(';')}&display=block`;
}
