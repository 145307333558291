import { assert } from '@paper/models/src/assert';
import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { autorun } from 'mobx';

export function useKeepSelectionVisibleInLayerTree(treeRef: React.RefObject<HTMLDivElement>) {
  const { selectionState } = useEditor();

  useEffect(() => {
    const containerEl = treeRef.current;
    assert(containerEl);

    const disposer = autorun(async () => {
      const mostRecentlySelectedNodeId = selectionState.mostRecentlySelectedNode?.id ?? null;
      if (!mostRecentlySelectedNodeId) return;

      // Schedule to avoid blocking the main thread
      await new Promise((resolve) => requestIdleCallback(resolve));

      const selectedNodeEl = containerEl.querySelector(`[data-node-id="${mostRecentlySelectedNodeId}"]`);

      if (selectedNodeEl) {
        const containerRect = containerEl.getBoundingClientRect();
        const selectedNodeRect = selectedNodeEl.getBoundingClientRect();

        // We need to check if the node is COMPLETELY out of view to adjust it, or else clicking on a partially hidden node will jump its position too much
        const needToScrollUp = selectedNodeRect.top - containerRect.top < 0;
        const needToScrollDown = containerRect.bottom - selectedNodeRect.bottom < 0;

        if (needToScrollUp) {
          // The node bottom is above the top of the container, scroll up
          const distanceToScroll = selectedNodeRect.y - containerRect.y;
          const isCompletelyOutOfView = selectedNodeRect.bottom < containerRect.y;
          // For scrolling up, add a small buffer if it's completely out of view
          const buffer = isCompletelyOutOfView ? selectedNodeRect.height * -2 : 0;
          containerEl.scrollBy(0, distanceToScroll + buffer);
        } else if (needToScrollDown) {
          // The top of the node is below the bottom of the container, scroll down
          const distanceToScroll = selectedNodeRect.bottom - containerRect.bottom;
          const isCompletelyOutOfView = selectedNodeRect.top > containerRect.bottom;
          // For scrolling down, add a large buffer if it's completely out of view
          const buffer = isCompletelyOutOfView ? selectedNodeRect.height * 4 : 0;
          containerEl.scrollBy(0, distanceToScroll + buffer);
        }
      }
    });
    return () => {
      disposer();
    };
  }, [treeRef, selectionState]);
}
