import { Rect, RectUtils, RectWithSize } from '@paper/models/src/math/rect';
import { Vec2 } from '@paper/models/src/math/vec2';
import { ResizeHandlePosition } from '../selection/resize-handles-logic';

/**
 * Generates a Rect from a starting point and a new point, with optional mirroring and aspect ratio forcing
 * Purposefully does NOT round the results to preserve accuracy – make sure to round when applying the result
 *
 * @param anchorPoint - An anchor for the Rect that will not move (unless mirroring)
 * @param controlPoint - The new point of the Rect which represents the desired movement
 * @param originalRect - The rect before any adjustments have been made, used to calculate deltas for mirroring and aspect ratio
 * @param mirror - Whether to mirror the changes to the Rect on both sides
 * @param forceAspectRatio - Whether to force the aspect ratio of the Rect to match the original aspect ratio
 * @returns The new Rect, rounded to the nearest pixel
 */
export function makeResizeRect(
  anchorPoint: Vec2,
  controlPoint: Vec2,
  /** The rect before any adjustments have been made, used to calculate deltas for mirroring and aspect ratio */
  originalRect: RectWithSize,
  mirror?: boolean,
  forceAspectRatio?: boolean,
  /** Adjusts how proportional resizing is applied, if it's an axis is locked it's distributed on both sides */
  handle?: ResizeHandlePosition
): RectWithSize {
  let newRect = RectUtils.fromPoints([anchorPoint, controlPoint]);

  // Note to future developers: don't round any values until the end

  // ----- Mirror ----- //
  // Mirror strategy is to make sure the new center point is the same as the old center point
  if (mirror === true) {
    const originalCenter = RectUtils.center(originalRect);

    const distanceToCenterX = controlPoint.x - originalCenter.x;
    const distanceToCenterY = controlPoint.y - originalCenter.y;

    newRect = RectUtils.fromPoints([
      { x: originalCenter.x - distanceToCenterX, y: originalCenter.y - distanceToCenterY },
      { x: originalCenter.x + distanceToCenterX, y: originalCenter.y + distanceToCenterY },
    ]);
  }

  // ----- Force aspect ratio ----- //
  if (forceAspectRatio === true) {
    // Make new rect the same ratio as original ratio
    // by adding or subtracting on the edge that's closest to our pointer position
    // or, if we're mirroring or axis-locked, split the difference on both sides
    const newRatio = Math.abs(newRect.width / newRect.height);
    const originalRatio = Math.abs(originalRect.width / originalRect.height);
    let widthPolarity = Math.sign(controlPoint.x - anchorPoint.x);
    let heightPolarity = Math.sign(controlPoint.y - anchorPoint.y);

    // Determine which dimension to adjust
    let adjustOnAxis: 'width' | 'height';
    // If resize handle is specified and is axis-locked, use the other dimension
    if (handle === ResizeHandlePosition.Left || handle === ResizeHandlePosition.Right) {
      // Mouse is controlling the width, so adjust height
      adjustOnAxis = 'height';
    } else if (handle === ResizeHandlePosition.Top || handle === ResizeHandlePosition.Bottom) {
      // Mouse is controlling the height, so adjust width
      adjustOnAxis = 'width';
    } else {
      // Mouse is controlling both dimensions, determine which one to adjust based on the ratio
      adjustOnAxis = newRatio > originalRatio ? 'height' : 'width';
    }

    if (adjustOnAxis === 'height') {
      // Wider than the original, adjust height
      const adjustedHeight = newRect.width / originalRatio;
      const heightDelta = adjustedHeight - newRect.height;
      if (mirror || handle === ResizeHandlePosition.Left || handle === ResizeHandlePosition.Right) {
        const adjustmentAmount = heightDelta / 2;
        newRect.minY -= adjustmentAmount;
        newRect.maxY += adjustmentAmount;
      } else if (heightPolarity === 1) {
        newRect.maxY = newRect.minY + adjustedHeight;
      } else if (heightPolarity === -1) {
        newRect.minY = newRect.maxY - adjustedHeight;
      }
    } else if (adjustOnAxis === 'width') {
      // Taller than the original, adjust width
      const adjustedWidth = newRect.height * originalRatio;
      const widthDelta = adjustedWidth - newRect.width;
      if (mirror || handle === ResizeHandlePosition.Top || handle === ResizeHandlePosition.Bottom) {
        const adjustmentAmount = widthDelta / 2;
        newRect.minX -= adjustmentAmount;
        newRect.maxX += adjustmentAmount;
      } else if (widthPolarity === 1) {
        newRect.maxX = newRect.minX + adjustedWidth;
      } else if (widthPolarity === -1) {
        newRect.minX = newRect.maxX - adjustedWidth;
      }
    }
  }

  // Round at the end (important to not round before this to preserve accuracy)
  if (originalRect.minX % 1 !== 0) {
    // If the original rect had non-integer x, round to nearest 0.5 instead of integer
    newRect.minX = Math.round(newRect.minX * 2) / 2;
    newRect.maxX = Math.round(newRect.maxX * 2) / 2;
  } else {
    // Working with integers, round to the nearest integer
    newRect.minX = Math.round(newRect.minX);
    newRect.maxX = Math.round(newRect.maxX);
  }
  if (originalRect.minY % 1 !== 0) {
    // If the original rect had non-integer y, round to nearest 0.5 instead of integer
    newRect.minY = Math.round(newRect.minY * 2) / 2;
    newRect.maxY = Math.round(newRect.maxY * 2) / 2;
  } else {
    // Working with integers, round to the nearest integer
    newRect.minY = Math.round(newRect.minY);
    newRect.maxY = Math.round(newRect.maxY);
  }

  const width = newRect.maxX - newRect.minX;
  const height = newRect.maxY - newRect.minY;

  return { minX: newRect.minX, minY: newRect.minY, maxX: newRect.maxX, maxY: newRect.maxY, width, height };
}
