import { useContext, useRef } from 'react';
import { useEditor } from '../editor-context';
import { ColorMapHsv } from './color-map-hsv';
import { COLOR_PICKER_HEIGHT, COLOR_PICKER_WIDTH, ColorPickerContext } from './color-picker';
import { Cartesian } from '../../components/cartesian';
import { convertHsvToOklch, convertOklchToHsv } from '@paper/models/src/colors/color-conversion';
import { ColorUtils } from '@paper/models/src/colors/Color';

export function ColorPickerCartesianHsv() {
  const { fileState } = useEditor();
  const { color, onColorChange } = useContext(ColorPickerContext);
  const colorAtCoords = useRef(color);

  const hsv = convertOklchToHsv(color.value);
  const { s: x, v: y } = hsv;

  return (
    <Cartesian.Root
      x={x}
      y={y}
      width={COLOR_PICKER_WIDTH}
      height={COLOR_PICKER_HEIGHT}
      onChange={(x, y) => {
        colorAtCoords.current = {
          gamut: 'rgb',
          value: convertHsvToOklch({ ...hsv, s: x, v: y }),
        };

        onColorChange(colorAtCoords.current);
      }}
      onChangeStart={() => fileState.fileDataObserver.startTreatingChangesAsTransient()}
      onChangeEnd={() => fileState.fileDataObserver.endTreatingChangesAsTransient()}
      shouldSyncCoordinates={() => ColorUtils.isEqual(colorAtCoords.current, color) === false}
    >
      <ColorMapHsv
        hue={hsv.h ?? 0}
        className="ColorPickerMap"
        width={COLOR_PICKER_WIDTH}
        height={COLOR_PICKER_HEIGHT}
      />
      <Cartesian.Thumb
        data-clipped-color={ColorUtils.isClipped(color, 'rgb') ? '' : undefined}
        className="ColorPickerThumb"
      />
    </Cartesian.Root>
  );
}
