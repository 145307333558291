import { EditorState } from '../EditorState';
import { action, makeObservable, observable } from 'mobx';
import { TreeNode } from './TreeNode';
import { Size } from '@paper/models/src/math/size';
import { Vec2 } from '@paper/models/src/math/vec2';

/** Stores observed sizes for every node in the current page, set by MeasureNodePositionAndSize */
export class SizeIndex {
  constructor(private editorState: EditorState) {
    makeObservable(this);
  }

  @observable
  accessor sizeMap = new Map<string, Size>();
  @action
  setSize = (nodeId: string, size: Size) => {
    this.sizeMap.set(nodeId, size);
  };

  @observable
  accessor positionInWorld: Record<string, Vec2> = {};
  @action
  setNodePositionInWorld = (nodeId: string, x: number, y: number) => {
    // Avoids creating new objects to save on garbage collection since these happen a ton
    if (this.positionInWorld[nodeId] === undefined) {
      this.positionInWorld[nodeId] = { x, y };
    } else {
      if (this.positionInWorld[nodeId].x !== x || this.positionInWorld[nodeId].y !== y) {
        this.positionInWorld[nodeId].x = x;
        this.positionInWorld[nodeId].y = y;
      }
    }
  };
}
