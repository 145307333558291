export function parseNumberAndUnit(value: string, integer?: boolean) {
  // Throw away leading zeroes
  const cleanValue = value.trim().replace(/^0+(?=\d)/, '');
  const number = integer ? parseInt(cleanValue) : parseFloat(cleanValue);

  // Match the full number including decimal places
  const unit = Number.isNaN(number) ? '' : cleanValue.replace(/^-?\d*\.?\d+/, '');
  return [number, unit] as const;
}

export function splitValueAndUnit(value: string) {
  const cleanValue = value.trim();
  const number = parseFloat(cleanValue);
  const unit = Number.isNaN(number) ? '' : cleanValue.replace(/^-?\d*\.?\d+/, '');
  return [cleanValue.substring(0, cleanValue.length - unit.length), unit] as const;
}
