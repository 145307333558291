import * as Popover from '@radix-ui/react-popover';
import { Tabs } from '@base-ui-components/react';
import { createContext, useMemo, useRef, useState } from 'react';
import { Popup } from '../../components/popup';
import { Color, ColorSpace, ColorUtils } from '@paper/models/src/colors/Color';
import { ColorPickerTabSrgb } from './color-picker-tab-srgb';
import { CrossIcon } from '../../icons/cross-icon';
import { ColorPickerTabP3 } from './color-picker-tab-p3';
import { useLocalStorage } from '../../components/use-local-storage';
import { createIgnoreZone } from '../hotkeys';
import { WarningIcon } from '../../icons/warning-icon';
import { Tooltip } from '../../components/tooltip';

interface ColorPickerProps {
  color: Color;
  onColorChange: (color: Color) => void;
}

interface ColorPickerContextValue extends ColorPickerProps {
  tab: ColorSpace;
  initialColor: React.MutableRefObject<Color>;
  prevColor: React.MutableRefObject<Color>;
}

export const ColorPickerContext = createContext<ColorPickerContextValue>(null as any);

export function ColorPicker(props: ColorPickerProps) {
  const [open, setOpen] = useState(false);

  const cssColor = {
    '--color': ColorUtils.cssString(props.color),
    '--color-solid': ColorUtils.cssString({
      value: { ...props.color.value, alpha: 1 },
    }),
  } as React.CSSProperties;

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger
        className="ColorPickerButton"
        style={cssColor}
        // Open on pointerdown instead of on click
        onClick={(event) => event.preventDefault()}
        onPointerDown={(event) => {
          event.preventDefault();
          setOpen((value) => !value);
        }}
        onKeyDown={(event) => {
          if (event.key === ' ' || event.key === 'Enter') {
            setOpen((value) => !value);
          }
        }}
      />
      <Popover.Portal>
        <Popover.Content
          asChild
          align="start"
          alignOffset={-16}
          side="left"
          sideOffset={8}
          collisionPadding={16}
          onOpenAutoFocus={(event) => {
            if (event.target instanceof HTMLElement) {
              const input = event.target.querySelector('[data-autofocus]');
              if (input instanceof HTMLInputElement) {
                setTimeout(() => input.focus());
              }
            }
          }}
        >
          <Popup style={cssColor}>
            <ColorPickerPopupContent {...props} />
          </Popup>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

const ColorPickerIgnoreZone = createIgnoreZone({
  ignoredHotkeys: [
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'Shift ArrowUp',
    'Shift ArrowDown',
    'Shift ArrowLeft',
    'Shift ArrowRight',
  ],
});

// A separate component is used for the states to avoid
// wasting state renders on things used only inside the popover

function ColorPickerPopupContent({ color, onColorChange }: ColorPickerProps) {
  const [lastClickedTab, saveLastClickedTab] = useLocalStorage<ColorSpace>('color-picker-tab', 'rgb');
  const [initialTab] = useState(color.gamut ?? lastClickedTab);
  const [tab, setTab] = useState<ColorSpace>(initialTab);
  const initialColor = useRef(color);
  const prevColor = useRef(color);
  const context = useMemo(() => ({ tab, initialColor, prevColor, color, onColorChange }), [tab, color, onColorChange]);

  return (
    <ColorPickerContext.Provider value={context}>
      <ColorPickerIgnoreZone>
        <Tabs.Root
          value={tab}
          onValueChange={(tab: ColorSpace) => {
            setTab(tab);
            saveLastClickedTab(tab);
          }}
        >
          <div className="ColorPickerHeader">
            <Tabs.List className="flex">
              <Tabs.Tab className="ColorPickerTab" value="rgb">
                <span className="ColorPickerTabInner">sRGB</span>
              </Tabs.Tab>
              <Tabs.Tab className="ColorPickerTab" value="p3">
                <span className="ColorPickerTabInner">
                  Display P3
                  {tab === 'p3' && matchMedia('(color-gamut: p3)').matches === false && (
                    <Tooltip.Root>
                      <Tooltip.Trigger render={(props) => <div className="-my-1 -mr-0.25 py-1 pl-1.25" {...props} />}>
                        <WarningIcon className="mt-px" />
                      </Tooltip.Trigger>
                      <Tooltip.Popup>
                        <div className="mb-1.5">Your display doesn’t support P3 colors.</div>
                        <div>
                          Colors in this tab may appear oversaturated,
                          <br />
                          but you can still edit them.
                        </div>
                      </Tooltip.Popup>
                    </Tooltip.Root>
                  )}
                </span>
              </Tabs.Tab>
            </Tabs.List>

            <div className="ml-auto">
              <Popover.Close className="ColorPickerCloseButton">
                <CrossIcon />
              </Popover.Close>
            </div>
          </div>
          <Tabs.Panel className="outline-none" value="rgb" tabIndex={-1}>
            <ColorPickerTabSrgb />
          </Tabs.Panel>
          <Tabs.Panel className="outline-none" value="p3" tabIndex={-1}>
            <ColorPickerTabP3 />
          </Tabs.Panel>
        </Tabs.Root>
      </ColorPickerIgnoreZone>
    </ColorPickerContext.Provider>
  );
}

export const COLOR_PICKER_WIDTH = 240;
export const COLOR_PICKER_HEIGHT = 254;
