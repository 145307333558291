export const US_LAYOUT_QWERTY_DARWIN = {
  KeyA: 'aAåÅ',
  KeyB: 'bB∫ı',
  KeyC: 'cCçÇ',
  KeyD: 'dD∂Î',
  KeyE: 'eE´´',
  KeyF: 'fFƒÏ',
  KeyG: 'gG©˝',
  KeyH: 'hH˙Ó',
  KeyI: 'iIˆˆ',
  KeyJ: 'jJ∆Ô',
  KeyK: 'kK˚',
  KeyL: 'lL¬Ò',
  KeyM: 'mMµÂ',
  KeyN: 'nN˜˜',
  KeyO: 'oOøØ',
  KeyP: 'pPπ∏',
  KeyQ: 'qQœŒ',
  KeyR: 'rR®‰',
  KeyS: 'sSßÍ',
  KeyT: 'tT†ˇ',
  KeyU: 'uU¨¨',
  KeyV: 'vV√◊',
  KeyW: 'wW∑„',
  KeyX: 'xX≈˛',
  KeyY: 'yY¥Á',
  KeyZ: 'zZΩ¸',
  Digit1: '1!¡⁄',
  Digit2: '2@™€',
  Digit3: '3#£‹',
  Digit4: '4$¢›',
  Digit5: '5%∞ﬁ',
  Digit6: '6^§ﬂ',
  Digit7: '7&¶‡',
  Digit8: '8*•°',
  Digit9: '9(ª·',
  Digit0: '0)º‚',
  Space: '    ',
  Minus: '-_–—',
  Equal: '=+≠±',
  BracketLeft: '[{“”',
  BracketRight: ']}‘’',
  Backslash: '\\|«»',
  Semicolon: ';:…Ú',
  Quote: '\'"æÆ',
  Backquote: '`~``',
  Comma: ',<≤¯',
  Period: '.>≥˘',
  Slash: '/?÷¿',
  IntlBackslash: '§±§±',
} as const;

export const AZERTY_FR_DARWIN = {
  KeyA: 'qQ‡Ω',
  KeyQ: 'aAæÆ',
  KeyM: ',?∞¿',
  KeyW: 'zZÂÅ',
  KeyZ: 'wW‹›',
  Semicolon: 'mMµÓ',
  Slash: '=+≠±',
  Equal: '-_—–',
};

export const QWERTZ_DE_DARWIN = {
  KeyY: 'zZΩˇ',
  KeyZ: 'yY¥‡',
  Slash: '-_—–',
};

export const defaultMappingLayout = US_LAYOUT_QWERTY_DARWIN;
export type DefaultMappingLayout = typeof defaultMappingLayout;
export type ReversedDefaultMappingLayout = ReverseInterface<{
  [K in keyof DefaultMappingLayout]: ExtractFirstChar<DefaultMappingLayout[K]>;
}>;

export type ModifiersKeyInEvent = 'ctrlKey' | 'altKey' | 'shiftKey' | 'metaKey';

export type DigitKey = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
export type ArrowKey = 'ArrowUp' | 'ArrowDown' | 'ArrowLeft' | 'ArrowRight';
export type NamedKey =
  | 'Enter'
  | 'Tab'
  | 'F1'
  | 'F2'
  | 'F3'
  | 'F4'
  | 'F5'
  | 'F6'
  | 'F7'
  | 'F8'
  | 'F9'
  | 'F10'
  | 'F11'
  | 'F12'
  | 'ArrowLeft'
  | 'ArrowUp'
  | 'ArrowRight'
  | 'ArrowDown'
  | 'Escape'
  | 'Backspace'
  | 'Delete'
  | 'Home'
  | 'End'
  | 'PageUp'
  | 'PageDown';

export type LetterKey =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z';

export type SymbolKey = ',' | '.' | '/' | ';' | "'" | '[' | ']' | '-' | '=' | '\\' | '`' | '§';
// export type SpecialKey = 'Enter' | 'Space' | 'Backspace' | 'Escape' | 'Tab'
// export type ArrowKey = 'ArrowRight' | 'ArrowLeft' | 'ArrowUp' | 'ArrowDown'
export type ModifierKey = 'Ctrl' | 'Shift' | 'Alt' | 'Meta' | 'Mod';

// Combined basic keys
export type Key = DigitKey | LetterKey | SymbolKey | ModifierKey | NamedKey;

// Clean keys (excluding certain modifier keys)
export type CleanKey = Exclude<Key, ModifierKey>;

// Helper type for modifier combinations
type ModifierCombination =
  | 'Mod'
  | 'Mod Shift'
  | 'Mod Alt'
  | 'Mod Shift Alt'
  | 'Ctrl'
  | 'Ctrl Shift'
  | 'Ctrl Alt'
  | 'Ctrl Meta'
  | 'Ctrl Shift Alt'
  | 'Ctrl Shift Meta'
  | 'Ctrl Alt Meta'
  | 'Ctrl Shift Alt Meta'
  | 'Meta'
  | 'Meta Shift'
  | 'Meta Alt'
  | 'Meta Ctrl'
  | 'Meta Shift Alt'
  | 'Meta Shift Ctrl'
  | 'Meta Ctrl Alt'
  | 'Meta Ctrl Shift Alt';

// Hotkey type
export type Hotkey = Key | `${ModifierCombination} ${Key}` | (string & {});

export interface HotkeysZoneConfig<ContextState> {
  id: string;
  name?: string;
  description?: string;
  isGlobal?: boolean;
  shouldIgnoreEvent?: (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => boolean;
  shouldPreventDefault?: (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => boolean;
  shouldStopPropagation?: (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => boolean;
  hotkeys: Record<string, HotkeyConfig<ContextState>>;
}

export type HotkeyConfig<ContextState> = {
  hotkey: Hotkey | Hotkey[];
  name?: string;
  description?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent, contextState: ContextState) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent, contextState: ContextState) => void;
};

export interface NormalizedHotkeyConfig<ContextState>
  extends Partial<Record<ModifiersKeyInEvent, boolean>>,
    HotkeyConfig<ContextState> {
  isCustom?: boolean;
  hotkey: Hotkey;
  key: string;
  id: string;
}

type ExtractFirstChar<T> = T extends `${infer First}${string}` ? First : never;
type ReverseInterface<T> = {
  [K in keyof T as T[K] extends PropertyKey ? T[K] : never]: K;
};
