import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { SELECTION_BOUNDS_COLOR } from '../move-tool/select-brush-graphic';

/**
 * Renders a box around the currently hovered node
 */
export const HoverHighlight = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { pointerState, cameraState, hudState, toolState, selectionState } = editorState;

    function drawHighlight(ctx: CanvasRenderingContext2D) {
      // Don't draw if we're drawing, dragging, or selecting, or not using the move tool
      if (!toolState.shouldShowHoverHighlight) return;

      const node = pointerState.hoveredNode;
      if (!node) {
        return;
      }

      // Don't draw if the node is already selected
      if (selectionState.selectedNodeIds.has(node.id)) return;

      ctx.strokeStyle = SELECTION_BOUNDS_COLOR;

      // Spec for hover line:
      // 2px wide
      // outside stroke feels better
      // (the selection bounds will be 1px outside the node)

      const baseWidth = 2;
      const borderWidth = baseWidth / cameraState.scale;
      const halfWidth = borderWidth / 2;
      ctx.lineWidth = borderWidth;
      ctx.strokeRect(
        node.xInWorld - halfWidth,
        node.yInWorld - halfWidth,
        node.width + borderWidth,
        node.height + borderWidth
      );
    }

    // Register our drawing function
    hudState.worldDrawingFunctions.add(drawHighlight);
    return () => {
      hudState.worldDrawingFunctions.delete(drawHighlight);
    };
  }, [editorState]);

  return null;
});
