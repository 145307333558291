import { EditorState } from '../EditorState';
import { createMultipleSortOrderKeys } from '@paper/models/src/file/create-sort-order-key';
import { createTreeNodeData } from '@paper/models/src/file/tree-node-schema';
import { parseEmbeddedHtmlFromClipboard } from './clipboard-embedded-html';
import { TempTreeSchema, TempTreeType } from '@paper/models/src/file/temp-tree-schema';
import { Value } from '@sinclair/typebox/value';
import { cloneTempTreeIntoFile } from '../tree/clone-temp-tree-into-file';
import { makeTreeRelationshipString } from '@paper/models/src/file/tree-node-relationships';
import { htmlToPlainText } from './parse-html-to-text';
import { checkForSpecialFrameBoxPasteCase } from './check-for-special-frame-box-paste-case.ts';
import { isSVGInPlaintext } from './is-svg-in-plaintext';
import { createNewImageNodeFromFiles } from './create-new-image-node-from-paste';
import { TreeNode } from '../tree/TreeNode';
import { RectWithSize } from '@paper/models/src/math/rect';
import { assert } from '@paper/models/src/assert';
import { intersects } from '@paper/models/src/math/collision';
import { action } from 'mobx';
import { getBoundsForNodes } from '../tree/get-bounds-for-nodes.ts';

export async function handleDataTransferItems(editorState: EditorState, event: ClipboardEvent | DragEvent) {
  const data = event instanceof ClipboardEvent ? event.clipboardData : event.dataTransfer;
  if (!data) {
    return;
  }

  // If we have files, we'll only process the files (the rest is likely just metadata)
  if (data.files.length > 0) {
    handleFilesPaste(editorState, event);
  } else {
    const htmlFromClipboard = data.getData('text/html');
    const paperJsonFromHtmlEmbed = parseEmbeddedHtmlFromClipboard(htmlFromClipboard);
    if (paperJsonFromHtmlEmbed !== null) {
      // It's JSON from our app
      handlePaperJSONPaste(editorState, paperJsonFromHtmlEmbed);
    } else {
      // It's HTML or plaintext
      handleTextPaste(editorState, event);
    }
  }
}

/**
 * Sequence goes:
 * 1. Extract file sizes, temp URLs from images
 * 2. Transaction
 *   2A. Create nodes for images in one batch
 *   2B. Position nodes
 *   2C. Select nodes
 * 3. Upload image files to server (if they fail, revert by nodeId)
 */
async function handleFilesPaste(editorState: EditorState, event: ClipboardEvent | DragEvent) {
  // File paste
  const data = event instanceof ClipboardEvent ? event.clipboardData : event.dataTransfer;
  if (!data) {
    return;
  }

  // Pull out the image files
  const imageFiles = Array.from(data.files).filter((file) => file.type.includes('image'));

  // Create the new image nodes and upload them to R2
  const positionAt = event instanceof DragEvent ? 'cursor' : 'selection';
  await createNewImageNodeFromFiles(editorState, imageFiles, positionAt);
}

const handlePaperJSONPaste = action((editorState: EditorState, jsonContentFromClipboard: string) => {
  const pasteData = JSON.parse(jsonContentFromClipboard);

  // Validate it's a TempTree
  const isTempTree = Value.Check(TempTreeSchema, pasteData);
  if (!isTempTree) return;

  // ----- Find potential parents ----- //
  // - If selection is empty, paste into the root node
  // - Loop through the selected nodes:
  // --- If the node is one of the source nodes in the clipboard, add its parent to the potential parents, insert after the node
  // --- If the node doesn't allow children, add its parent to the potential parents, insert after the node
  // --- If the node allows children, add it to the potential parents, insert at the end of the children
  // - Loop each potential parent and paste into each one (no more than once)
  const newTargetParents: Map<TreeNode, number> = new Map();
  const selectedNodes = editorState.selectionState.selectedNodes;
  if (selectedNodes.length === 0) {
    // If selection is empty, paste into the root node
    newTargetParents.set(editorState.treeUtils.rootNode, editorState.treeUtils.rootNode.children.length);
  } else {
    // Loop through the selected nodes
    for (const selectedNode of selectedNodes) {
      const shouldTargetSelectionsParent =
        selectedNode.canHaveChildren === false || // This selected node doesn't allow children
        selectedNode.id in pasteData.oldIdToNewIdMap; // This selected node was a source node in the clipboard

      if (shouldTargetSelectionsParent) {
        // This selected node was a source node in the clipboard, so use its parent as a target
        // and insert after the source node
        if (selectedNode.parent && selectedNode.indexOfNodeInParent !== null) {
          newTargetParents.set(selectedNode.parent!, selectedNode.indexOfNodeInParent + 1);
        }
      } else {
        // This selected node allows children, so use it as a target
        // and insert at the end of the children
        newTargetParents.set(selectedNode, selectedNode.children.length);
      }
    }
  }

  // ----- Clone the tree into each new parent ----- //
  const tempIdToNewIdMap: Map<string, string> = new Map();
  // For each new parent, generate the right sorts keys and clone into the parent
  for (const [newParent, insertAtIndex] of newTargetParents.entries()) {
    const prevSortKey = newParent.children[insertAtIndex - 1]?.sortKey ?? null;
    const nextSortKey = newParent.children[insertAtIndex]?.sortKey ?? null;
    const sortKeys = createMultipleSortOrderKeys(pasteData.topLevelNodeIds.length, prevSortKey, nextSortKey);

    // Set up relationships for each of the top level nodes in the clipboard
    const topLevelNodeRelationships: Record<string, string> = {};
    for (let i = 0; i < pasteData.topLevelNodeIds.length; i++) {
      const tempNode = pasteData.nodes[pasteData.topLevelNodeIds[i]!]!;
      const relationship = makeTreeRelationshipString(newParent.id, sortKeys[i]!);
      topLevelNodeRelationships[tempNode.id] = relationship;
    }
    // Clone the tree into the file and keep track of both the tempTree ID and the new cloned ID
    const thisTempIdToNewIdMap = cloneTempTreeIntoFile(editorState, pasteData, topLevelNodeRelationships);
    // Append these to our map
    thisTempIdToNewIdMap.forEach((newId, tempId) => {
      tempIdToNewIdMap.set(tempId, newId);
    });

    // Build a map of final new ids to the original copied node IDs
    // Reminder: there are 3 sets of IDs:
    // 1. Original ID of the copied node
    // 2. The temporary ID of the clone in the clipboard
    // 3. The new ID of the clone of the clone after pasting
    // pasteData.oldIdToNewIdMap gives you the original -> temp clone
    // tempIdToNewIdMap gives you the temp clone -> new clone
    const newIdToSourceIdMap: Map<string, string> = new Map();
    for (const [tempId, newId] of tempIdToNewIdMap.entries()) {
      Object.entries(pasteData.oldIdToNewIdMap).forEach(([origId, tempIdFromPasteData]) => {
        if (tempIdFromPasteData === tempId) {
          newIdToSourceIdMap.set(newId, origId);
          return;
        }
      });
    }

    // Position the new nodes in the new parent
    positionNodesInNewParent(editorState, newParent, pasteData, tempIdToNewIdMap, newIdToSourceIdMap);
  }

  // ----- Select the new nodes and make them visible in the layer tree ----- //
  const newNodeIds = Array.from(tempIdToNewIdMap.values());
  const newNodes = newNodeIds.map((id) => editorState.treeUtils.getNode(id)!);
  editorState.selectionState.selectIds(newNodeIds);
  editorState.layerTreeState.ensureNodesAreVisibleInLayerTree(newNodes);

  // ----- Check for special case, pasting all "Frame" top level boxes into their own source file ----- //
  checkForSpecialFrameBoxPasteCase(editorState, newTargetParents, pasteData, newNodes, tempIdToNewIdMap);
});

const handleTextPaste = action(async (editorState: EditorState, event: ClipboardEvent | DragEvent) => {
  const data = event instanceof ClipboardEvent ? event.clipboardData : event.dataTransfer;
  if (!data) {
    return;
  }

  // Parse the HTML if there's formatted text, otherwise use the plain text
  const htmlFromClipboard = data.getData('text/html');
  const textToInsert = htmlFromClipboard ? await htmlToPlainText(htmlFromClipboard) : data.getData('text/plain');

  // Test if it's an SVG in text format and, if so, treat it as an image paste
  const isSVGText = isSVGInPlaintext(textToInsert);
  if (isSVGText) {
    // Convert SVG text to a Blob
    const blob = new Blob([textToInsert], { type: 'image/svg+xml' });
    // Create a FormData object
    const svgFile = new File([blob], `${Date.now()}.svg`, { type: 'image/svg+xml' });
    // Create the new image nodes and upload them to R2
    const positionAt = event instanceof DragEvent ? 'cursor' : 'selection';
    createNewImageNodeFromFiles(editorState, [svgFile], positionAt);
    // We're done with this paste
    return;
  }

  // If we made it this far, it's just plain text
  // Insert the text into a new node and position it
  if (textToInsert) {
    // Create a new Text node with the textToInsert
    const newNodeData = createTreeNodeData();
    newNodeData.textValue = textToInsert;
    newNodeData.component = 'Text';
    newNodeData.label = textToInsert.slice(0, 50);

    const newNode = editorState.treeUtils.addNode(newNodeData, editorState.treeUtils.rootNode.id);
    if (newNode) {
      newNode.setStyle('width', 'fit-content');
      newNode.setStyle('height', 'fit-content');
      newNode.setXInWorld(editorState.cameraState.center.x);
      newNode.setYInWorld(editorState.cameraState.center.y);
    }
  }
});

/**
 * Takes some newly pasted nodes and positions them to the best of our abilities to be useful in their new parent
 *
 * - If pasting into the root, leave the nodes where they naturally pasted
 * - If pasting into another node:
 * --- If any part of the pasted nodes are visible, leave them as they pasted
 * --- If they aren't visible: if they fit within the parent, move them to the center of the parent
 * --- If they don't fit within the parent, move them to the top left of the parent
 */
const positionNodesInNewParent = action(
  (
    editorState: EditorState,
    newParent: TreeNode,
    pasteData: TempTreeType,
    tempIdToNewIdMap: Map<string, string>,
    newIdToSourceIdMap: Map<string, string>
  ) => {
    // Find the newly pasted top level nodes
    const topLevelNodes: Array<TreeNode> = [];
    for (const nodeId of pasteData.topLevelNodeIds) {
      const newNodeId = tempIdToNewIdMap.get(nodeId) ?? '';
      const node = editorState.treeUtils.getNode(newNodeId);
      assert(node, `Could not find top level node: ${newNodeId}`);
      topLevelNodes.push(node);
    }

    // If the source of the node is still available and in the same parent as the paste, position the node directly over top its source
    for (const node of topLevelNodes) {
      const sourceId = newIdToSourceIdMap.get(node.id);
      if (sourceId) {
        const sourceNode = editorState.treeUtils.getNode(sourceId);
        if (sourceNode && sourceNode.parent === node.parent) {
          node.setXInLocal(sourceNode.x);
          node.setYInLocal(sourceNode.y);
          return;
        }
      }
    }

    // Calculate the bounds of the new pasted nodes
    const pastedBounds = getBoundsForNodes(topLevelNodes);

    // If we're pasting into the canvas, adjust to be camera centered
    if (newParent.isRoot) {
      const adjustmentX = editorState.cameraState.center.x - pastedBounds.minX - pastedBounds.width / 2;
      const adjustmentY = editorState.cameraState.center.y - pastedBounds.minY - pastedBounds.height / 2;
      for (const node of topLevelNodes) {
        node.setXInWorld(Math.round(node.x + adjustmentX));
        node.setYInWorld(Math.round(node.y + adjustmentY));
      }
      return;
    }

    // Check if the bounds are visible within the parent – if so, we just leave them where they are
    if (intersects(pastedBounds, newParent.bounds)) return;

    // If the pasted nodes aren't visible, check if they fit within the parent period
    if (pastedBounds.width <= newParent.width && pastedBounds.height <= newParent.height) {
      // They fit within the parent, so move them to the center of the parent
      const centerX = newParent.x + newParent.width / 2;
      const centerY = newParent.y + newParent.height / 2;
      const adjustmentX = centerX - pastedBounds.minX - pastedBounds.width / 2;
      const adjustmentY = centerY - pastedBounds.minY - pastedBounds.height / 2;

      for (const node of topLevelNodes) {
        node.setXInWorld(Math.round(node.x + adjustmentX));
        node.setYInWorld(Math.round(node.y + adjustmentY));
      }
      return;
    }

    // If the pasted nodes don't fit within the parent, move them to the top left of the parent to fit as much as possible
    const adjustmentX = newParent.x - pastedBounds.minX;
    const adjustmentY = newParent.y - pastedBounds.minY;
    for (const node of topLevelNodes) {
      node.setXInWorld(Math.round(node.x + adjustmentX));
      node.setYInWorld(Math.round(node.y + adjustmentY));
    }
    return;
  }
);
