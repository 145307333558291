import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { SELECTION_BOUNDS_COLOR } from '../move-tool/select-brush-graphic';
import { drawRoundedRect } from '../hud/gfx-draw-rounded-rectangle';
import { observer } from 'mobx-react-lite';
import { ResizeHandlesLogic, shouldShowResizeHandles } from './resize-handles-logic';
import { Tool } from '../toolbar/ToolState';
import { roundOptimized } from '@paper/models/src/math/round-optimized';

/** Renders the entire selection bounds as a rectangle */
export const SelectionBoundsGraphic = observer(function SelectionBoundsGraphic() {
  const { selectionState, cameraState, hudState, moveToolState, textTool: textTool, toolState } = useEditor();

  useEffect(() => {
    function drawHighlight(ctx: CanvasRenderingContext2D) {
      // Don't draw if we're dragging, temporarily hiding highlights, or editing text
      if (moveToolState.dragState.isDragging || selectionState.hideHighlights || textTool.editingTextNode !== null) {
        return;
      }
      // Don't draw if there is no selection
      const selectionBoundsRect = selectionState.selectionBoundsRect;
      if (!selectionBoundsRect) return;

      // Grab the selection bounds rect
      const rect = { ...selectionBoundsRect };

      // ----- Border around the selection ----- //
      // In this case we don't care about devicePixelRatio because we want as fine a line as possible
      const baseSize = 1;
      const borderSize = baseSize / cameraState.scale;
      const halfOfBorderSize = borderSize / 2;

      // Spec for selection bounds:
      // 1px wide
      // outside of the node stroke
      // (the hover is 2px, centered)
      ctx.strokeStyle = SELECTION_BOUNDS_COLOR;
      ctx.lineWidth = borderSize;
      ctx.strokeRect(
        rect.minX - halfOfBorderSize,
        rect.minY - halfOfBorderSize,
        rect.width + borderSize,
        rect.height + borderSize
      );

      // --- Resize handles --- //
      const showHandle = shouldShowResizeHandles(rect.width * cameraState.scale, rect.height * cameraState.scale);
      if (showHandle) {
        const handleSize = 8 * cameraState.scaleInverse;
        const halfHandleSize = handleSize / 2;
        for (let i = 0; i < 4; i++) {
          const handleX = rect.minX + (i % 2 === 0 ? 0 : rect.width) - halfHandleSize;
          const handleY = rect.minY + (i < 2 ? 0 : rect.height) - halfHandleSize;
          ctx.fillStyle = 'white';
          ctx.fillRect(
            handleX + halfOfBorderSize,
            handleY + halfOfBorderSize,
            handleSize - borderSize,
            handleSize - borderSize
          );
          ctx.strokeRect(
            handleX + halfOfBorderSize,
            handleY + halfOfBorderSize,
            handleSize - borderSize,
            handleSize - borderSize
          );
        }
      }

      // ----- Dimensions text below the selection ----- //
      const offsetFromBottom = 15 * cameraState.scaleInverse;
      // Prep the text
      const width = roundOptimized(selectionBoundsRect.width, 2);
      const height = roundOptimized(selectionBoundsRect.height, 2);

      let xText = String(width);
      let yText = String(height);
      if (selectionState.selectedNodes.length === 1) {
        const singleSelectedNode = selectionState.selectedNodes[0]!;
        if (singleSelectedNode.styles.width === 'auto') {
          xText = `Auto (${width})`;
        }

        if (singleSelectedNode.styles.height === 'auto') {
          yText = `Auto (${height})`;
        }
      }

      const text = `${xText} × ${yText}`;
      ctx.fillStyle = 'white';
      const fontSize = 12 * cameraState.scaleInverse;
      ctx.font = `${fontSize}px system-ui`;
      const textMetrics = ctx.measureText(text);
      // Note that text metrics WILL be scaled by the canvas's adjustments
      const textWidth = textMetrics.width;
      const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;

      // Draw the rounded rect
      const rectWidth = textWidth + 9 * cameraState.scaleInverse;
      const rectHeight = textHeight + 9 * cameraState.scaleInverse;
      const rectRadius = 3 * cameraState.scaleInverse;

      // Calculate position and draw
      const positionX = rect.minX + rect.width / 2;
      const positionY = rect.minY + rect.height + offsetFromBottom;
      drawRoundedRect(ctx, positionX - rectWidth / 2, positionY - rectHeight / 2, rectWidth, rectHeight, rectRadius);
      ctx.fillStyle = SELECTION_BOUNDS_COLOR;
      ctx.fill();

      ctx.fillStyle = 'white';
      // Adjust for font line height
      // const fontAdjustmentY = 0 * cameraState.scaleInverse;
      const textY = positionY + rectHeight / 2 - textHeight / 2;
      ctx.fillText(text, positionX - textWidth / 2, textY);
    }

    // Register our drawing function
    hudState.worldDrawingFunctions.add(drawHighlight);
    return () => {
      hudState.worldDrawingFunctions.delete(drawHighlight);
    };
  }, [hudState, cameraState, selectionState, moveToolState, textTool, toolState]);

  // Hide if there's no selection or if we're editing text
  if (!selectionState.selectionBoundsRect || textTool.editingTextNode !== null) {
    return null;
  }

  // If we're not in move tool, don't render the resize handle logic (they will still draw, as the draw is done in this component)
  if (
    toolState.activeTool !== Tool.Move ||
    moveToolState.dragState.isDragging ||
    moveToolState.selectionBrushState.isSelectBrushing
  ) {
    return null;
  }

  // Render the logic and handlers for resizing
  return (
    <>
      <ResizeHandlesLogic />
    </>
  );
});
