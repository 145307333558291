import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { MIXED_STYLE_VALUE } from '../properties/reduce-property-values';
import { TypefacePopover } from './typeface-popover';
import { Field } from '../../components/field';
import * as Popover from '@radix-ui/react-popover';
import { PropertyPanels } from '../properties/property-panel';
import { TreeNode } from '../tree/TreeNode';
import { Popup } from '../../components/popup';
import { PanelRow } from '../../components/panel';

export const TypefaceProperty = observer(() => {
  const { propertiesState } = useEditor();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const nodes = propertiesState.nodesPerProperty[PropertyPanels.Typography];
  const isMixed = isMixedFontFamily(nodes);
  const displayValue = isMixed ? MIXED_STYLE_VALUE : nodes[0]?.styleMeta.font?.family;
  const value = isMixed ? '' : nodes[0]?.styleMeta.font?.family;

  return (
    <PanelRow>
      <Popover.Root open={popoverOpen} onOpenChange={setPopoverOpen}>
        <Field.Root>
          <Field.Icon>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="currentcolor">
              <path d="M2.5 4.5C2.5 3.09886 3.59886 2 5 2H12.499C12.7752 2 13 2.22386 13 2.5C13 2.77614 12.7761 3 12.5 3H8.69244L8.40509 3.85458C8.18869 4.49752 7.89401 5.37197 7.58091 6.29794C7.50259 6.52956 7.42308 6.76453 7.34332 7H8.5C8.77614 7 9 7.22386 9 7.5C9 7.77614 8.77614 8 8.5 8H7.00407C6.56724 9.28543 6.16435 10.4613 5.95799 11.0386C5.63627 11.9386 5.20712 12.4857 4.66741 12.7778C4.16335 13.0507 3.64154 13.0503 3.28378 13.05H3.25C2.94624 13.05 2.7 12.8037 2.7 12.5C2.7 12.1962 2.94624 11.95 3.25 11.95C3.64182 11.95 3.9035 11.9405 4.14374 11.8105C4.36443 11.691 4.65532 11.4148 4.92217 10.6683C5.10695 10.1514 5.45375 9.14134 5.8422 8H4.5C4.22386 8 4 7.77614 4 7.5C4 7.22386 4.22386 7 4.5 7H6.18187C6.30127 6.64785 6.42132 6.29323 6.53887 5.94559C6.85175 5.02025 7.14627 4.14631 7.36256 3.50368L7.53192 3H5C4.15114 3 3.5 3.65114 3.5 4.5C3.5 4.77614 3.27614 5 3 5C2.72386 5 2.5 4.77614 2.5 4.5Z" />
            </svg>
          </Field.Icon>
          <Field.Control>
            <Popover.Trigger asChild>
              <button>{displayValue || 'Select font…'}</button>
            </Popover.Trigger>
          </Field.Control>
        </Field.Root>
        <Popover.Portal>
          <Popover.Content
            asChild
            align="start"
            alignOffset={-8}
            side="left"
            sideOffset={8}
            collisionPadding={16}
            onCloseAutoFocus={(event) => event.preventDefault()}
            // Prevent scrollbars from snatching input focus
            onFocus={(event) => event.currentTarget.querySelector('input')?.focus()}
          >
            <Popup className="flex h-110 max-h-[calc(100vh-40px)] flex-col">
              <TypefacePopover value={value ?? ''} close={() => setPopoverOpen(false)} />
            </Popup>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </PanelRow>
  );
});

export function isMixedFontFamily(nodes: TreeNode[]) {
  let previous: TreeNode;

  for (const current of nodes) {
    previous ??= current;
    if (previous === current) continue;

    if (previous.styleMeta.font?.family !== current.styleMeta.font?.family) {
      return true;
    }

    previous = current;
  }

  return false;
}
