import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { TreeNode } from '../tree/TreeNode';
import { CollapsibleIndicator } from '../../components/collapsible-indicator';

type Props = {
  node: TreeNode;
};

export const ExpandAndCollapseButton = observer(({ node }: Props) => {
  const { layerTreeState } = useEditor();
  const isCollapsible = node.children.length > 0;

  return (
    <CollapsibleIndicator.Root
      onPointerDownCapture={(event) => {
        if (isCollapsible) {
          // Prevent selecting this node on the canvas
          event.stopPropagation();
          layerTreeState.toggleExpandedOrCollapsed(node.id);
        }
      }}
    >
      {isCollapsible && <CollapsibleIndicator.Icon collapsed={layerTreeState.expandedNodeIds.has(node.id) === false} />}
    </CollapsibleIndicator.Root>
  );
});
