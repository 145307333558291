import { roundOptimized } from '@paper/models/src/math/round-optimized';
import { formatAlpha, formatChannel } from './color-formatting';
import type { P3 } from 'culori/fn';

export function getPrettyP3({ r, g, b, alpha = 1 }: P3, explicitAlpha = true) {
  let str = [r, g, b].map((c) => formatChannel(c, 3)).join(' ');

  if (explicitAlpha || roundOptimized(alpha, 3) !== 1) {
    str += ' / ' + formatAlpha(alpha * 100) + '%';
  }

  return str;
}

export function getCssStringP3(p3: P3) {
  return `color(display-p3 ${getPrettyP3(p3, false)})`;
}
