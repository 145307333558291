import { TreeIconProps } from '../../components/tree-icons';
import { EditorState } from '../EditorState';
import { PropertyPanels } from '../properties/property-panel';
import { StyleMeta, TreeNode } from '../tree/TreeNode';
import { BoxMeta } from './Box';
import { ImageMeta } from './Image';
import { RectangleMeta } from './Rectangle';
import { ShaderMeta } from './Shader';
import { TextMeta } from './Text';

export const BuiltInComponentMap: Record<string, ComponentMeta> = {
  Box: BoxMeta,
  Image: ImageMeta,
  Text: TextMeta,
  Shader: ShaderMeta,
  Rectangle: RectangleMeta,
};

export type ComponentMeta = {
  label: string;
  component: React.FC<any>;
  canHaveChildren: boolean;
  props: PropConfigMap;
  layerTreeIcon: React.FC<TreeIconProps>;
  /** Runs after the DrawTool inserts this component into the tree */
  postDrawHook?: (editorState: EditorState, node: TreeNode) => void;
  /** Which standard property panels to show and accept for this component */
  propertyPanels?: Set<PropertyPanels>;
  /** Custom JSX that can injected at the bottom of the property panels for this component */
  customPanel?: React.FC<{ editorState: EditorState }>;
  /** Generates JSX for code generation and copy-as-jsx... returns [imports string, jsx string] maybe a temporary solution if we can automate more of this */
  generateJsx?: (editorState: EditorState, node: TreeNode) => [imports: string, jsx: string];
  /** Default styles */
  defaultStyles?: React.CSSProperties;
  /** Default style meta */
  defaultStyleMeta?: StyleMeta;
};

/** A single prop config */
export type PropInputType = 'color' | 'text' | 'number' | 'switch' | 'select' | 'slider';

// The common set of properties for all prop configs
type PropConfigBase = {
  propName: string;
  label: string;
  inputType: PropInputType;
  defaultValue?: string | number | boolean;
};

// The set of properties for select props
export type PropConfigSelect = PropConfigBase & {
  inputType: 'select';
  options: string[];
};

export type PropConfigSlider = PropConfigBase & {
  inputType: 'slider';
  min?: number;
  max?: number;
  step?: number;
};

// The set of properties for all prop configs
export type PropConfig = PropConfigBase | PropConfigSelect | PropConfigSlider;

/** A map of prop names to prop configs */
export type PropConfigMap = Record<string, PropConfig>;
