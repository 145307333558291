import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { SELECTION_BOUNDS_COLOR } from '../move-tool/select-brush-graphic';
import { TreeNode } from '../tree/TreeNode';
import { LayoutDirection } from './calculate-dom-layout-direction';
import { CameraState } from '../camera/CameraState';
import { clamp } from '@paper/models/src/math/clamp';
import { Size } from '@paper/models/src/math/size';
import { Vec2 } from '@paper/models/src/math/vec2';

/** How many pixels wide should the drop line be on the secondary axis? */
const HIGHLIGHT_SIZE = 4;

export const DROP_PREVIEW_COLOR = SELECTION_BOUNDS_COLOR;

/**
 * Renders a box around the currently selected node
 */
export const DropTargetHighlight = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { moveToolState, cameraState, hudState, selectionState } = editorState;
    const dragState = moveToolState.dragState;

    let cachedDropTargetNode: TreeNode | null = null;
    let cachedTargetStyles: CSSStyleDeclaration | null = null;
    let childIndicatorSize: Size | null = null;
    let selectedNodeTimestamp: number | null = null;

    /** Draws the drop target highlight */
    function drawDropTargetHighlight(ctx: CanvasRenderingContext2D) {
      // Don't draw if we're not dragging
      if (dragState.isDragging === false) return;

      const dropTargetNode = dragState.dropTargetNode;
      const dropIndex = dragState.dropTargetIndex;
      const layoutDirection = dragState.dropTargetLayoutDirection;

      // Don't do any work if there is no drop target
      if (dropTargetNode === null || dropIndex === null || layoutDirection === null) {
        cachedDropTargetNode = null;
        return;
      }

      // If the drop target or dragged selection changes, perform any expensive calculations we can cache on subsequent draws
      if (dropTargetNode !== cachedDropTargetNode || selectedNodeTimestamp !== selectionState.timestamp) {
        // New drop target! Calc it up and cache its things
        cachedDropTargetNode = dropTargetNode;
        selectedNodeTimestamp = selectionState.timestamp;

        const domElement = dropTargetNode.domEl;
        if (domElement === null) return;
        cachedTargetStyles = window.getComputedStyle(domElement);
        // Calculate the biggest size to show for a drop indicator based on dragged node sizes
        const biggestDraggedNodeHeight = Math.max(...selectionState.selectedNodes.map((node) => node.bounds.height));
        const biggestDraggedNodeWidth = Math.max(...selectionState.selectedNodes.map((node) => node.bounds.width));
        childIndicatorSize = { width: biggestDraggedNodeWidth, height: biggestDraggedNodeHeight };
      }

      if (cachedTargetStyles === null || childIndicatorSize === null) return;
      drawParentHighlight(ctx, cameraState, dropTargetNode);
      drawChildrenIndexIndicator(
        ctx,
        cameraState,
        childIndicatorSize,
        dropTargetNode,
        dropIndex,
        layoutDirection,
        cachedTargetStyles
      );
    }

    hudState.worldDrawingFunctions.add(drawDropTargetHighlight);
    return () => {
      hudState.worldDrawingFunctions.delete(drawDropTargetHighlight);
    };
  }, [editorState]);

  return null;
});

// ----- Drop target parent highlight ----- //
function drawParentHighlight(ctx: CanvasRenderingContext2D, cameraState: CameraState, dropTarget: TreeNode) {
  // Calculate line width and offset to make the line be on exact pixels
  const lineWidth = 2 * cameraState.scaleInverse;

  ctx.strokeStyle = SELECTION_BOUNDS_COLOR;
  ctx.lineWidth = lineWidth;
  ctx.strokeRect(dropTarget.xInWorld, dropTarget.yInWorld, dropTarget.width, dropTarget.height);
}

// ----- Children index indicator ----- //
function drawChildrenIndexIndicator(
  ctx: CanvasRenderingContext2D,
  cameraState: CameraState,
  childIndicatorSize: Size,
  dropTarget: TreeNode,
  dropIndex: number,
  layoutDirection: LayoutDirection,
  targetStyles: CSSStyleDeclaration
) {
  // No child indicator if the drop target is in free form layout mode
  if (dropTarget.childrenAreFixed) return;

  // If all children of the drop target are selected, and nothing else is selected we don't draw a drop index
  if (dropTarget.children.length > 0 && dropTarget.children.every((child) => child.isSelected)) return;

  // Find the previous and next children
  const prevChild = dropTarget.children[dropIndex - 1] ?? null;
  const nextChild = dropTarget.children[dropIndex] ?? null;

  // Build the position and size for the actual drawing of the highlight
  let highlightStartX: number = 0;
  let highlightStartY: number = 0;
  let highlightEndX: number = 0;
  let highlightEndY: number = 0;

  /****************/
  /* Primary axis */
  /****************/

  if (layoutDirection === LayoutDirection.LTR) {
    highlightStartX = calculateChildIndicatorOnPrimaryAxis(dropTarget, prevChild, nextChild, 'x', targetStyles);
    highlightEndX = highlightStartX;
  } else if (layoutDirection === LayoutDirection.RTL) {
    highlightStartX = calculateChildIndicatorOnPrimaryAxis(dropTarget, nextChild, prevChild, 'x', targetStyles);
    highlightEndX = highlightStartX;
  } else if (layoutDirection === LayoutDirection.TTB) {
    highlightStartY = calculateChildIndicatorOnPrimaryAxis(dropTarget, prevChild, nextChild, 'y', targetStyles);
    highlightEndY = highlightStartY;
  } else if (layoutDirection === LayoutDirection.BTT) {
    highlightStartY = calculateChildIndicatorOnPrimaryAxis(dropTarget, nextChild, prevChild, 'y', targetStyles);
    highlightEndY = highlightStartY;
  } else {
    // Free form leaked into this drawing function somehow, shouldn't happen
    console.warn(
      'Unexpected: trying to draw DOM drop line for freeform layout drop target',
      dropTarget.label,
      layoutDirection
    );
    return;
  }

  /******************/
  /* Secondary axis */
  /******************/

  if (layoutDirection === LayoutDirection.LTR || layoutDirection === LayoutDirection.RTL) {
    const secondaryAxisDimensions = calculateChildIndicatorOnSecondaryAxis(
      dropTarget,
      childIndicatorSize.height, // requested size
      'y',
      targetStyles
    );
    highlightStartY = secondaryAxisDimensions.startPos;
    highlightEndY = secondaryAxisDimensions.endPos;
  } else {
    // TTB or BTT
    const secondaryAxisDimensions = calculateChildIndicatorOnSecondaryAxis(
      dropTarget,
      childIndicatorSize.width, // requested size
      'x',
      targetStyles
    );
    highlightStartX = secondaryAxisDimensions.startPos;
    highlightEndX = secondaryAxisDimensions.endPos;
  }

  /**********************/
  /* Draw the indicator */
  /**********************/

  ctx.strokeStyle = DROP_PREVIEW_COLOR;
  ctx.fillStyle = DROP_PREVIEW_COLOR;
  ctx.lineWidth = HIGHLIGHT_SIZE * cameraState.scaleInverse;
  ctx.beginPath();
  ctx.moveTo(highlightStartX, highlightStartY);
  ctx.lineTo(highlightEndX, highlightEndY);
  ctx.stroke();
  ctx.closePath();
}

/** a small distance to leave between the highlight and the drop target edge so it doesn't mix with the target's own parent highlight */
const minimumDistanceFromTargetEdge = 6;

/** Figures out where to draw the child indicator on the primary axis of the flex parent (so x if it's row and y if it's column) */
function calculateChildIndicatorOnPrimaryAxis(
  dropTarget: TreeNode,
  prevChild: TreeNode | null,
  nextChild: TreeNode | null,
  axis: 'x' | 'y',
  targetStyles: CSSStyleDeclaration
) {
  const minProperty = axis === 'x' ? 'minX' : 'minY';
  const maxProperty = axis === 'x' ? 'maxX' : 'maxY';
  const minimumPos = dropTarget.bounds[minProperty] + minimumDistanceFromTargetEdge;
  const maximumPos = dropTarget.bounds[maxProperty] - minimumDistanceFromTargetEdge;
  let pos = -Infinity;
  const gap = parseFloat(targetStyles.gap);

  if (prevChild && nextChild) {
    // Pos should be the spot between them
    pos = (prevChild.bounds[maxProperty] + nextChild.bounds[minProperty]) / 2;
  } else if (prevChild && !nextChild) {
    // Pos should be the prevChild's maxX + parent's gap setting,
    pos = prevChild.bounds[maxProperty] + gap;
  } else if (!prevChild && nextChild) {
    // Pos should be the nextChild's minX - parent's gap setting
    pos = nextChild.bounds[minProperty] - gap;
  } else {
    // Empty parent, use AI/JC to determine X (start, center, or end)
    const justifyContent = targetStyles.justifyContent;
    if (justifyContent === 'flex-start' || justifyContent === 'start' || !justifyContent) {
      pos = minimumPos;
    } else if (justifyContent === 'flex-end' || justifyContent === 'end') {
      pos = maximumPos;
    } else if (justifyContent === 'center') {
      pos = (minimumPos + maximumPos) / 2;
    }
  }
  pos = clamp(pos, minimumPos, maximumPos);

  return pos;
}

/** Figures out where to draw the child indicator on the secondary axis of the flex parent (so y if it's row and x if it's column) */
function calculateChildIndicatorOnSecondaryAxis(
  dropTarget: TreeNode,
  requestedSize: number,
  axis: 'x' | 'y',
  targetStyles: CSSStyleDeclaration
): { startPos: number; endPos: number } {
  /** The smallest we'll draw the drop indicator on the secondary axis */
  const minimumSize = 10;

  const minProperty = axis === 'x' ? 'minX' : 'minY';
  const maxProperty = axis === 'x' ? 'maxX' : 'maxY';
  const sizeProperty = axis === 'x' ? 'width' : 'height';

  // Calculate the height based on the largest height child in the drag group
  const alignItems = targetStyles.alignItems;

  // Figure out the largest size we can draw based on padding and parent's size
  const paddingStart = axis === 'x' ? parseFloat(targetStyles.paddingLeft) : parseFloat(targetStyles.paddingTop);
  const paddingEnd = axis === 'x' ? parseFloat(targetStyles.paddingRight) : parseFloat(targetStyles.paddingBottom);

  let maxSize: number;
  const maxSizeWithinParentPercentage = 0.76;
  if (paddingEnd > 0 || paddingStart > 0) {
    // If there's padding, only draw the line within the content area of the parent
    maxSize = dropTarget.bounds[sizeProperty] - paddingStart - paddingEnd;
  } else {
    // If there's no padding, we add a little bit of space around the edges of the parent, it feels cleaner
    maxSize = dropTarget.bounds[sizeProperty] * maxSizeWithinParentPercentage;
  }
  const size = clamp(requestedSize, minimumSize, maxSize);

  // Find the position based on how the flex parent is aligning its children
  let pos = dropTarget.bounds[minProperty];
  if (alignItems === 'flex-start' || alignItems === 'start') {
    pos = dropTarget.bounds[minProperty] + paddingStart;
  } else if (alignItems === 'flex-end' || alignItems === 'end') {
    pos = dropTarget.bounds[maxProperty] - size - paddingEnd;
  } else if (alignItems === 'center') {
    pos = dropTarget.bounds[minProperty] + dropTarget.bounds[sizeProperty] / 2 - size / 2 + paddingStart - paddingEnd;
  }

  // Clamp the position for minimumDistanceFromTargetEdge if there are no children
  // (note: if there are children along the edge, minimumDistanceFromTargetEdge doesn't feel good so we skip the clamp)
  if (dropTarget.children.length === 0) {
    pos = clamp(
      pos,
      dropTarget.bounds[minProperty] + minimumDistanceFromTargetEdge,
      dropTarget.bounds[maxProperty] - size - minimumDistanceFromTargetEdge
    );
  }

  return { startPos: pos, endPos: pos + size };
}
