import { SERVICE_URI } from '../../root/api-address';
import { EditorState } from '../EditorState';

/** Uploads an image to the server and returns the final URL. */
export async function postImageToServer(editorState: EditorState, imageFile: File): Promise<string | null> {
  let finalUrl: string | null = null;

  try {
    const res = await fetch(`${SERVICE_URI.WORKERS_URI}/user-images`, {
      method: 'POST',
      headers: {
        'X-FileId': editorState.fileState.data.id,
        'Content-Type': imageFile.type,
      },
      body: imageFile,
    });

    if (res.ok) {
      const data = await res.json();
      finalUrl = `${SERVICE_URI.WORKERS_URI}/user-images/${data.filename}`;
    } else {
      throw new Error('Upload failed');
    }
  } catch (error) {
    console.error('Error uploading image:', error);
  }

  return finalUrl;
}
