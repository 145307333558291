import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { EditorState } from '../EditorState';
import { RectWithSize } from '@paper/models/src/math/rect';
import { TreeNode } from '../tree/TreeNode';

export class SelectionState {
  constructor(public editor: EditorState) {
    makeObservable(this);
  }

  @observable accessor selectedNodeIds: Set<string> = new Set();
  @observable accessor timestamp = Date.now();

  @action selectIds = (
    rawNodeIds: string[] | IterableIterator<string> | Set<string>,
    options?: { additive?: boolean }
  ) => {
    const addNodeIds = rawNodeIds instanceof Set ? rawNodeIds : new Set(rawNodeIds);
    // Check if there's no change and bail early to avoid triggering observers with no actual changes
    let isIdentical = true;
    if (this.selectedNodeIds.size !== addNodeIds.size) {
      isIdentical = false;
    } else {
      for (const nodeId of this.selectedNodeIds) {
        if (addNodeIds.has(nodeId) === false) {
          isIdentical = false;
          break;
        }
      }
    }
    if (isIdentical) {
      return;
    }

    // Update the timestamp, used as a key in react, since we're changing the selection
    this.timestamp = Date.now();

    // Inform the undo manager of the selection change, giving it the old IDs
    this.editor.undoManager.addSelectionChange(Array.from(this.selectedNodeIds));

    // Remove existing selections unless this is an additive selection
    if (options?.additive !== true) {
      this.selectedNodeIds.clear();
    } else {
      // Make sure to remove any descendants of already selected nodes from the set to add
      for (const nodeId of this.selectedNodeIds) {
        const node = this.editor.treeUtils.getNode(nodeId);
        if (node) {
          for (const descendant of node.descendants) {
            addNodeIds.delete(descendant.id);
          }
        }
      }
    }

    // Select the new nodes
    for (const nodeId of rawNodeIds) {
      const node = this.editor.treeUtils.getNode(nodeId);

      if (!node || node.isRoot) {
        // Ignore the root node or if we can't find the node
        addNodeIds.delete(nodeId);
        continue;
      }

      // Remove any descendants from the set
      for (const descendant of node.descendants) {
        this.selectedNodeIds.delete(descendant.id);
        addNodeIds.delete(descendant.id);
      }
    }

    // Add the filtered list of nodes to the selection
    for (const nodeId of addNodeIds) {
      this.selectedNodeIds.add(nodeId);
    }

    // Inform the server of the selection change
    this.editor.multiplayerState.sendSelectionChange();
  };

  @action removeSelectedNodeIds(nodeIds: string[]) {
    this.timestamp = Date.now();
    // Inform the undo manager of the selection change, giving it the old IDs
    this.editor.undoManager.addSelectionChange(Array.from(this.selectedNodeIds));

    for (const nodeId of nodeIds) {
      this.selectedNodeIds.delete(nodeId);
    }
    // Inform the server of the selection change
    this.editor.multiplayerState.sendSelectionChange();
  }

  @computed({ keepAlive: true }) get selectedNodes(): TreeNode[] {
    const activePage = this.editor.pageState?.activePage;
    if (!activePage) {
      return [];
    }

    const selectedNodes: TreeNode[] = [];
    for (const id of this.selectedNodeIds) {
      const node = this.editor.treeUtils.getNode(id);
      if (node) {
        selectedNodes.push(node);
      }
    }
    return selectedNodes;
  }

  @computed({ keepAlive: true }) get mostRecentlySelectedNode(): TreeNode | null {
    if (this.selectedNodes.length === 0) {
      return null;
    }
    return this.selectedNodes[this.selectedNodes.length - 1]!;
  }

  @computed({ keepAlive: true }) get selectionBoundsRect(): RectWithSize | null {
    if (this.selectedNodes.length === 0) {
      return null;
    }

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    for (const node of this.selectedNodes) {
      minX = Math.min(minX, node.xInWorld);
      minY = Math.min(minY, node.yInWorld);
      maxX = Math.max(maxX, node.xInWorld + node.width);
      maxY = Math.max(maxY, node.yInWorld + node.height);
    }
    return { minX, minY, maxX, maxY, width: maxX - minX, height: maxY - minY };
  }

  @observable accessor lastSettingsPerPage: Partial<Record<string, string[]>> = {};
  @action stashSettingsForPage = (page: string) => {
    this.lastSettingsPerPage[page] = Array.from(this.selectedNodeIds);
  };
  @action restoreSettingsForPage = (page: string) => {
    const selectedNodeIds = this.lastSettingsPerPage[page];
    if (!selectedNodeIds) {
      this.selectIds([]);
    } else {
      this.selectIds(selectedNodeIds);
    }
  };

  /**
   * When a style changes or the user nudges, we want to hide the selection bounds for a few seconds so you can see your changes
   * Moving the mouse over the canvas will instantly show the highlights again
   */
  @observable accessor hideHighlights = false;
  /** The timeout ID for hiding highlights */
  hideHighlightsTimeout: number = 0;
  /** How long to hide highlights when a style changes */
  hideHighlightsTimeoutDuration = 1500;

  /** When this set is not empty, we will ignore style changes for purposes of hiding the selection highlights */
  @observable accessor ignoreStyleChanges: Set<string> = new Set();
  @action addIgnoreStyleChange = (nodeId: string) => {
    this.ignoreStyleChanges.add(nodeId);
  };
  @action removeIgnoreStyleChange = (nodeId: string) => {
    this.ignoreStyleChanges.delete(nodeId);
  };

  /** When a style changes, we may want to hide highlights for a few seconds */
  @action notifyOfStyleChange = () => {
    // Ignore changes from from mouse dragging/resizing/handles
    if (this.editor.pointerState.pointerIsBusy || this.ignoreStyleChanges.size > 0) {
      return;
    }

    this.hideHighlights = true;
    clearTimeout(this.hideHighlightsTimeout);
    // Show the highlights again after a few seconds
    this.hideHighlightsTimeout = window.setTimeout(() => {
      runInAction(() => {
        this.hideHighlights = false;
        this.hideHighlightsTimeout = 0;
        this.editor.hudState.requestDraw();
      });
    }, this.hideHighlightsTimeoutDuration);
  };

  /** Stops hiding highlights immediately */
  @action stopHidingHighlights = () => {
    if (this.hideHighlights === true || this.hideHighlightsTimeout !== 0) {
      clearTimeout(this.hideHighlightsTimeout);
      this.hideHighlights = false;
      this.editor.hudState.requestDraw();
    }
  };
}
