import {
  convertHexToOklch,
  convertRgbToOklch,
  convertHslToOklch,
  convertP3ToOklch,
  convertOklchToHex,
  convertOklchToRgb,
  convertOklchToHsl,
  convertOklchToP3,
  convertOklchToOklab,
  convertOklabToOklch,
  convertOklabToXyz65,
} from './color-conversion';
import { parseColor } from './color-parsing';
import { getCssStringHex, getPrettyHex } from './color-formatting-hex';
import { getCssStringRgb, getPrettyRgb } from './color-formatting-rgb';
import { getCssStringP3, getPrettyP3 } from './color-formatting-p3';
import { getCssStringHsl, getPrettyHsl } from './color-formatting-hsl';
import { getCssStringOklab } from './color-formatting-oklab';
import { getCssStringOklch, getPrettyOklch } from './color-formatting-oklch';
import { convertOklabToLrgb, convertXyz65ToP3, type Oklab, type P3, type Rgb, type Lrgb } from 'culori/fn';
import { roundOptimized } from '../math/round-optimized';
import { unreachable } from '../unreachable';
import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';

export const HexSchema = Type.Object({
  mode: Type.Literal('hex'),
  hex: Type.Number(),
  alpha: Type.Optional(Type.Number()),
});
export type Hex = Static<typeof HexSchema>;

export const HslSchema = Type.Object({
  mode: Type.Literal('hsl'),
  h: Type.Number(),
  s: Type.Number(),
  l: Type.Number(),
  alpha: Type.Optional(Type.Number()),
});
export type Hsl = Static<typeof HslSchema>;

export const OklchSchema = Type.Object({
  mode: Type.Literal('oklch'),
  l: Type.Number(),
  c: Type.Number(),
  h: Type.Number(),
  alpha: Type.Optional(Type.Number()),
});
export type Oklch = Static<typeof OklchSchema>;

export const ColorSchema = Type.Object({
  value: OklchSchema,
  /**
   * Corresponds to the tab that the color was picked in,
   * or undefined if the color was parsed from string and could
   * belong either to sRGB or P3.
   */
  gamut: Type.Optional(Type.Union([Type.Literal('p3'), Type.Literal('rgb')])),
});
export type Color = Static<typeof ColorSchema>;

export type ColorMode = 'hex' | 'rgb' | 'hsl' | 'oklch' | 'oklab' | 'p3';
export type ColorSpace = 'p3' | 'rgb';

export class ColorUtils {
  static new(rawInput: string, alpha?: number) {
    if (!rawInput) {
      return null;
    }

    const parsed = this.parse(rawInput);
    if (!parsed) {
      return null;
    }
    const parsedOklch = this.toOklch(parsed);
    if (!parsedOklch) {
      return null;
    }

    const color: Color = { value: parsedOklch };

    if (alpha !== undefined) {
      color.value.alpha = alpha;
    }

    if (parsed.mode === 'p3' || (this.isClipped(color, 'rgb') && this.isClipped(color, 'p3') === false)) {
      color.gamut === 'p3';
    }

    return color;
  }

  static parse(rawInput: string) {
    return parseColor(rawInput) ?? null;
  }

  static toOklch(value: Hex | Hsl | Oklch | Oklab | Rgb | P3) {
    const { mode } = value;

    switch (mode) {
      case 'oklch':
        return value;
      case 'oklab':
        return convertOklabToOklch(value);
      case 'hex':
        return convertHexToOklch(value);
      case 'rgb':
        return convertRgbToOklch(value);
      case 'hsl':
        return convertHslToOklch(value);
      case 'p3':
        return convertP3ToOklch(value);
      default:
        unreachable(mode);
    }
  }

  static clone(color: Color, alpha?: number): Color {
    const cloned = { ...color, value: { ...color.value } };

    if (alpha !== undefined) {
      cloned.value.alpha = alpha;
    }

    return cloned;
  }

  static isEqual(a: Color, b: Color) {
    // Colors are considered equal if they produce the same CSS values

    if (a.gamut === 'p3' || b.gamut === 'p3') {
      return this.cssString(a, 'p3') === this.cssString(b, 'p3');
    }

    return this.cssString(a, 'rgb') === this.cssString(b, 'rgb');
  }

  static pretty(color: Color, mode: ColorMode) {
    if (mode === 'hex') return getPrettyHex(convertOklchToHex(color.value));
    if (mode === 'rgb') return getPrettyRgb(convertOklchToRgb(color.value));
    if (mode === 'hsl') return getPrettyHsl(convertOklchToHsl(color.value));
    if (mode === 'oklch') return getPrettyOklch(color.value);
    // Oklab is pretty-printed as Oklch (we don't care about it enough
    // yet to parse some own shorthanded format with a roundtrip)
    if (mode === 'oklab') return getPrettyOklch(color.value);
    if (mode === 'p3') return getPrettyP3(convertOklchToP3(color.value));
    unreachable(mode);
  }

  static cssString(color: Color, mode: ColorMode = color.gamut === 'p3' ? 'p3' : 'hex') {
    if (mode === 'hex') return getCssStringHex(convertOklchToHex(color.value));
    if (mode === 'rgb') return getCssStringRgb(convertOklchToRgb(color.value));
    if (mode === 'hsl') return getCssStringHsl(convertOklchToHsl(color.value));
    if (mode === 'oklch') return getCssStringOklch(color.value);
    if (mode === 'oklab') return getCssStringOklab(convertOklchToOklab(color.value));
    if (mode === 'p3') return getCssStringP3(convertOklchToP3(color.value));
    unreachable(mode);
  }

  static isClipped(color: Color, colorSpace: ColorSpace) {
    return isInGamut(color.value, colorSpace) === false;
  }
}

export function isColorMode(candidate: string): candidate is ColorMode {
  let mode = candidate as ColorMode;
  switch (mode) {
    case 'hex':
    case 'rgb':
    case 'hsl':
    case 'oklch':
    case 'oklab':
    case 'p3':
      return true;
  }
  const unreachable: never = mode;
  return false;
}

export function colorModeSupportsP3(candidate: string): candidate is 'oklch' | 'oklab' | 'p3' {
  let mode = candidate as ColorMode;
  switch (mode) {
    case 'oklch':
    case 'oklab':
    case 'p3':
      return true;
  }
  return false;
}

export function isInGamut(color: Oklch, colorSpace: 'p3' | 'rgb') {
  let rgb: P3 | Lrgb;
  const oklab = convertOklchToOklab(color);

  if (colorSpace === 'rgb') {
    rgb = convertOklabToLrgb(oklab);
  } else {
    rgb = convertXyz65ToP3(convertOklabToXyz65(oklab));
  }

  const roundedRed = roundOptimized(rgb.r, 4);
  const roundedGreen = roundOptimized(rgb.g, 4);
  const roundedBlue = roundOptimized(rgb.b, 4);

  const redIsInGamut = roundedRed >= 0 && roundedRed <= 1;
  const greenIsInGamut = roundedGreen >= 0 && roundedGreen <= 1;
  const blueIsInGamut = roundedBlue >= 0 && roundedBlue <= 1;

  return redIsInGamut && greenIsInGamut && blueIsInGamut;
}
