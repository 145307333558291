import { action, makeObservable, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { EditorState } from '../EditorState';
import { googleKeyToStyle } from './google-font-styles';
import { BUILT_IN_FONTS } from './built-in-fonts';
import { binaryInsert } from 'binary-insert';
import { IMobxChange } from '../file-and-observers/FileDataObserver';
import { FontUtils } from './FontUtils';
import { parseLocalFont } from './parse-local-font';
import { FontFamily } from './FontFamily';
import { FvarAxis } from '@paper-design/opentype.js/fn';

/**
 * README
 *
 * We do not use "font" and "font family" interchangeably.
 * A "font" is a specific font variant, a "family" is a collection of fonts.
 *
 * Dictionary:
 * - Font is an object that represents a particular variant of a typeface
 * - Font style represents the corresponding field in the font metadata, like "Bold", "Compressed Light", "Black Italic"
 * - FontFamily is a class that represents a collection of related Font objects
 *
 * For example:
 * - "SF Pro Text Compressed Light" is the full name of a font.
 * - "Compressed Light" is its style name
 * - "SF Pro Text" is its family name
 *
 * Individual fonts are identified by their style name within a family.
 *
 * We assemble our fonts from:
 * - Built-in fonts - always available in the app for all users
 * - Google fonts - available on Google Fonts
 * - Local fonts - available locally on a user's computer
 * - File fonts - what's used in this file.
 *
 * File fonts aren't necessarily present in any other category.
 *
 * Fonts present on Google may be also available locally, and vice versa. For example,
 * "IBM Plex Mono" is a family that may be both installed locally and be on Google Fonts.
 * However, "IBM Plex Mono Book" is a particular style from that family that is not on Google Fonts.
 */

export type Font = {
  family: string;
  style: string;
  weight: number;
  isItalic: boolean;

  /**
   * Full font name for a local font.
   * https://learn.microsoft.com/en-us/typography/opentype/spec/name#nid4
   */
  fullName?: string;

  /**
   * PostScript name for a local font.
   * https://learn.microsoft.com/en-us/typography/opentype/spec/name#nid6
   */
  postscriptName?: string;

  /**
   * Numeric width based on the "usWidthClass" value or the "wdth" axis of the font.
   * The values are in the range of 50 to 200, normalised to the "wdth" axis values.
   * https://learn.microsoft.com/en-us/typography/opentype/spec/os2#uswidthclass
   */
  width?: number;

  /**
   * General axes data if the font is variable
   * https://learn.microsoft.com/en-us/typography/opentype/spec/fvar#variationaxisrecord
   */
  axes?: FvarAxis[];

  /**
   * The subfamily name based on the "wwsFamily" field
   * Commonly used by large non-variable superfamilies, like many of Adobe's fonts
   * https://learn.microsoft.com/en-us/windows/win32/directwrite/font-selection#weight-stretch-style-font-family-model
   */
  subfamily?: string;

  /**
   * Coordinates of the variable font instance, if the font is a variable font instance
   * https://learn.microsoft.com/en-us/typography/opentype/spec/fvar#instancerecord
   */
  coordinates?: Record<string, number>;
};

/** Maintains an index of actively needed fonts and loads/stores google font data*/
export class FontState {
  /** Stores font instances, instantiated on demand.
   *
   * Priorities:
   * 1. Built-in fonts
   * 2. Local fonts
   * 3. Google Fonts
   * 4. File fonts (when unavailable above)
   */
  private fonts = createTransformer(
    async (id: string): Promise<Font | null> => {
      const fontPartial = FontUtils.breakId(id);
      const { family, style } = fontPartial;

      const builtInFont = BUILT_IN_FONTS.find((item) => FontUtils.isEqual(item, fontPartial));
      if (builtInFont) {
        return builtInFont;
      }

      const localData = this.localFontData.find((item) => FontUtils.isEqual(item, fontPartial));
      if (localData) {
        return parseLocalFont(localData);
      }

      const googleFontKey = this.googleFontData[family]?.find((key) => googleKeyToStyle[key] === style);
      if (googleFontKey) {
        return {
          family,
          style,
          weight: parseInt(googleFontKey),
          isItalic: googleFontKey.includes('i'),
        };
      }

      const nodeId = this.fontToNodeIndex.get(id)?.values().next().value;
      if (nodeId) {
        return this.editorState.treeUtils.getNode(nodeId)?.styleMeta?.font || null;
      }

      return null;
    },
    { keepAlive: true }
  );

  /** Returns a font instance for the given id, or null if the font is not found */
  async getFont(family: string, style: string) {
    const id = FontUtils.createId(family, style);
    return this.fonts(id);
  }

  /**
   * All family names (Google, local, built-in, document) sorted alphabetically
   * This list is maintained manually as a performance optimization
   * Use `updateSortedFamilyNames` to regenerate it entirely
   * Use `addToSortedFamilyNames` to add one family
   * Use `removeFromSortedFamilyNames` to remove one family
   */
  @observable accessor sortedFamilyNames: string[] = [];

  @action addToSortedFamilyNames = (family: string) => {
    if (this.sortedFamilyNames.some((value) => value === family) === false) {
      // Insert the family into its sorted position (much cheaper than sorting the whole thing)
      binaryInsert(this.sortedFamilyNames, family, (a, b) => a.localeCompare(b, 'en', FONTS_SORT_OPTIONS));
    }
  };

  @action removeFromSortedFamilyNames = (family: string) => {
    const index = this.sortedFamilyNames.indexOf(family);
    if (index > -1) {
      this.sortedFamilyNames.splice(index, 1);
    }
  };

  /** When updating family lists, call this to fully regenerate sorted family names */
  @action buildSortedFamilyNames = () => {
    let result: string[] = [];

    for (const font of BUILT_IN_FONTS) {
      result.push(font.family);
    }
    for (const family in this.googleFontData) {
      result.push(family);
    }
    for (const fontData of this.localFontData) {
      result.push(fontData.family);
    }
    for (const [id] of this.fontToNodeIndex) {
      const { family } = FontUtils.breakId(id);
      result.push(family);
    }

    result = Array.from(new Set(result));
    result.sort((a, b) => a.localeCompare(b, 'en', FONTS_SORT_OPTIONS));
    this.sortedFamilyNames = result;
  };

  /**
   * Google Fonts are an object keyed by `family => shorthandStyle[]`.
   * `shorthandStyle` is a string like "400", "400i", "500", "500i", etc.
   */
  googleFontData: Record<string, string[]> = {};
  localFontData: FontData[] = [];
  builtInFonts: Font[] = BUILT_IN_FONTS;

  /** In the browser the user must explicitly allow us to gather info about their local fonts  */
  localFontsPermissionState: PermissionState | 'unsupported' = 'unsupported';

  constructor(readonly editorState: EditorState) {
    makeObservable(this);

    // Schedule async loading of fonts
    requestIdleCallback(async () => {
      await this.loadGoogleFonts();

      // Get local fonts. As of 2024, this is supported in Chrome only
      if ('queryLocalFonts' in window) {
        // Check whether the permission had been granted before
        const permission = 'local-fonts' as PermissionName;
        const { state } = await window.navigator.permissions.query({ name: permission });
        this.localFontsPermissionState = state;

        if (state === 'granted') {
          await this.loadLocalFonts();
        }
      }

      // Discover families used in the document
      this.generateFontToNodeIndexForFirstLoad();

      // Build a sorted family name index
      this.buildSortedFamilyNames();
    });
  }

  /** Loads fonts available on Google */
  loadGoogleFonts = async () => {
    const response = await fetch('/static/google-fonts/generated-font-data.json');
    this.googleFontData = await response.json();
  };

  /**
   * Attempts to load local fonts available locally.
   * Will display a local fonts permission request if it hadn't been granted before.
   * @returns success: boolean
   */
  loadLocalFonts = async () => {
    if (this.localFontData.length) {
      return true;
    }

    // As of 2024, this is supported in Chrome only
    this.localFontData = await window.queryLocalFonts();

    // Update permission state after the request
    const permission = 'local-fonts' as PermissionName;
    const { state } = await window.navigator.permissions.query({ name: permission });
    this.localFontsPermissionState = state;

    if (this.localFontData.length) {
      for (const familyName of FONTS_TO_PARSE_WHEN_IDLE) {
        requestIdleCallback(() => this.getFamily(familyName)?.getFonts());
      }

      this.buildSortedFamilyNames();
      return true;
    }

    return false;
  };

  /**
   * Whether a font is available locally.
   *
   * Notes:
   * - A font may be present both among local fonts and among Google Fonts.
   * - Built-in families aren't considered to be among local fonts because
   *   we can't reliably verify whether a particular font style exists for them.
   */
  isAvailableLocally = (family: string, style: string) => {
    return this.localFontData.some((fontData) => FontUtils.isEqual(fontData, { family, style }));
  };

  /**
   * Whether a font is available on Google Fonts.
   *
   * Note: a font may be present both among local fonts and among Google Fonts.
   */
  isAvailableFromGoogle = (family: string, style: string) => {
    return Boolean(
      this.googleFontData[family]?.some((key) => {
        return googleKeyToStyle[key] === style;
      })
    );
  };

  isUnavailable = (family: string, style: string) => {
    return !(
      FontUtils.isBuiltIn(family) ||
      this.isAvailableLocally(family, style) ||
      this.isAvailableFromGoogle(family, style)
    );
  };

  getFamily = createTransformer(
    (familyName: string) => {
      const family = new FontFamily(familyName, this);
      return family.size ? family : null;
    },
    { keepAlive: true }
  );

  /** An index of fonts to nodes */
  @observable accessor fontToNodeIndex: Map<string, Set<string>> = new Map();

  /** Loops every node in the file and maps fonts to nodes that use them */
  private generateFontToNodeIndexForFirstLoad = () => {
    for (const node of this.editorState.treeUtils.rootNode.descendants) {
      if (node.styleMeta.font) {
        this.addToFontToNodeIndex(node.id);
      }
    }
  };

  /**
   * This is called by the FileDataObserver if it sees a change to a property named "font"
   * Possible TODO: consider registering these as callbacks to the FileDataObserver rather than hard coding calls in
   */
  handleFontChange = (path: string, change: IMobxChange) => {
    const [pathname, nodeId] = path.split('/');
    if (pathname !== 'nodes' || !nodeId) {
      console.warn('Unexpected: received non-node path for a font change', path);
      return;
    }
    if (change.type === 'remove' || change.type === 'update') {
      this.removeFromFontToNodeIndex(nodeId);
    }
    if (change.type === 'add' || change.type === 'update') {
      this.addToFontToNodeIndex(nodeId);
    }
  };

  /** When adding a font to a node, call this to add it to the index */
  @action addToFontToNodeIndex = async (nodeId: string) => {
    const font = this.editorState.treeUtils.getNode(nodeId)?.styleMeta?.font;

    if (!font) {
      return;
    }

    const fontId = FontUtils.createId(font.family, font.style);

    if (this.fontToNodeIndex.has(fontId) === false) {
      const { family } = FontUtils.breakId(fontId);
      this.fontToNodeIndex.set(fontId, new Set());
      this.addToSortedFamilyNames(family);
    }

    this.fontToNodeIndex.get(fontId)!.add(nodeId);
  };

  /** When removing a font from a node, call this to remove it from the index */
  @action removeFromFontToNodeIndex = (nodeId: string) => {
    const entry = Array.from(this.fontToNodeIndex.entries()).find((entry) => entry[1].has(nodeId)) ?? [];

    if (!entry?.[0]) {
      return;
    }

    const [fontId] = entry;
    const nodeIds = this.fontToNodeIndex.get(fontId);
    nodeIds?.delete(nodeId);

    if (nodeIds === undefined || nodeIds.size === 0) {
      this.fontToNodeIndex.delete(fontId);

      // Remove the font family from the list of all families if it's not available anymore
      const { family } = FontUtils.breakId(fontId);
      if (this.getFamily(family) === null) {
        this.removeFromSortedFamilyNames(family);
      }
    }
  };
}

/** These font files are large and take a while to retrieve the font blob */
const FONTS_TO_PARSE_WHEN_IDLE = ['Apple Color Emoji', 'SF Pro', 'SF Compact'];

export const FONTS_SORT_OPTIONS: Intl.CollatorOptions = { numeric: true, ignorePunctuation: true };
