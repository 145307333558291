import { Vec2 } from '@paper/models/src/math/vec2';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { calculateDomLayoutDirection, LayoutDirection } from './calculate-dom-layout-direction';
import { RectUtils } from '@paper/models/src/math/rect';

export type DropTargetResult = { node: TreeNode; insertAt: number; layoutDirection: LayoutDirection };

/**
 * Provided with a hovered node, this will find the best drop target for the drag
 * Which could be the hovered node itself, its parent if it doesn't allow children, or potentially a sibling to the hovered node if we're near its edge and there isn't much gap between the hovered node and its siblings
 * If the best target is in DOM layout mode, will find the child index to drop at
 *
 * This is designed to be as efficient as possible, anticipating it will be called every frame while dragging
 */
export function findDropTarget(editorState: EditorState, hoveredNode: TreeNode | null): DropTargetResult | null {
  if (hoveredNode === null) {
    return null;
  }

  // If the hovered node doesn't allow children, we'll check its container instead
  let hoverTarget: TreeNode | null = hoveredNode;
  if (hoveredNode.canHaveChildren === false) {
    hoverTarget = hoveredNode.container;
  }

  // Bail if the target is null, which is unexpected as container should always exist
  if (hoverTarget === null) {
    return null;
  }

  // If the target has fixed layout, we'll insert at the end
  if (hoverTarget.childrenAreFixed) {
    return { node: hoverTarget, insertAt: hoverTarget.children.length, layoutDirection: LayoutDirection.Free };
  }

  // Find the layout direction of the target
  const layoutDirection = calculateDomLayoutDirection(hoverTarget);

  // Check if we should instead drop as a sibling to the target because the cursor is near its edge
  const siblingDrop = checkForSiblingDrop(hoverTarget, editorState.pointerState.cursorPosWorld);
  if (siblingDrop !== null) {
    return siblingDrop;
  }

  // If the target has children already, find the insert index within the target based on cursor position relative to children
  if (hoverTarget.children.length > 0) {
    const index = determineInsertOrderWithinParent(
      editorState,
      hoverTarget,
      editorState.pointerState.cursorPosWorld,
      layoutDirection
    );
    return { node: hoverTarget, insertAt: index, layoutDirection };
  }

  // If we made it this far, the target doesn't have children yet, so just insert at the beginning
  return { node: hoverTarget, insertAt: 0, layoutDirection: layoutDirection };
}

// ----- Sibling drop ----- //

/** The ratio of a hovered node's size that will count as a sibling drop, on each side of it */
const SIBLING_DROP_THRESHOLD = 0.2;

/** Checks a potential drop target to see if we should instead drop as a sibling to it because the cursor is near its edge */
function checkForSiblingDrop(hoveredNode: TreeNode, cursorPos: Vec2): DropTargetResult | null {
  const parent = hoveredNode.parent;
  if (parent === null) return null;

  // Find the parent's layout direction
  const parentLayoutDirection = calculateDomLayoutDirection(parent);

  // Find the cursor's position relative to the hovered node
  let cursorRatio: number;
  if (parentLayoutDirection === LayoutDirection.LTR) {
    cursorRatio = (cursorPos.x - hoveredNode.bounds.minX) / hoveredNode.bounds.width;
  } else if (parentLayoutDirection === LayoutDirection.RTL) {
    cursorRatio = (hoveredNode.bounds.maxX - cursorPos.x) / hoveredNode.bounds.width;
  } else if (parentLayoutDirection === LayoutDirection.TTB) {
    cursorRatio = (cursorPos.y - hoveredNode.bounds.minY) / hoveredNode.bounds.height;
  } else if (parentLayoutDirection === LayoutDirection.BTT) {
    cursorRatio = (hoveredNode.bounds.maxY - cursorPos.y) / hoveredNode.bounds.height;
  } else {
    // The node's parent is in free form layout mode, so sibling drop would not make sense
    return null;
  }

  if (cursorRatio < SIBLING_DROP_THRESHOLD) {
    // Cursor is indicating a previous sibling drop
    const insertAt = hoveredNode.indexOfNodeInParent!;
    return { node: hoveredNode.parent!, insertAt, layoutDirection: parentLayoutDirection };
  } else if (cursorRatio > 1 - SIBLING_DROP_THRESHOLD) {
    // Cursor is indicating a next sibling drop
    const insertAt = hoveredNode.indexOfNodeInParent! + 1;
    return { node: hoveredNode.parent!, insertAt, layoutDirection: parentLayoutDirection };
  }

  // Cursor did not indicate a sibling drop
  return null;
}

// ----- Determining intent within a parent based on cursor position relative to its children ----- //

function determineInsertOrderWithinParent(
  editorState: EditorState,
  parent: TreeNode,
  cursorPos: Vec2,
  layoutDirection: LayoutDirection
): number {
  const axis = layoutDirection === LayoutDirection.LTR || layoutDirection === LayoutDirection.RTL ? 'x' : 'y';
  const minProperty = axis === 'x' ? 'minX' : 'minY';
  const maxProperty = axis === 'x' ? 'maxX' : 'maxY';

  // We don't want to drop "between" selected children, which makes no sense, so we chunk any selected nodes into one group
  // and the drop position can be on either side of the selected-node-group
  type chunkOfNodes = {
    startIndex: number;
    endIndex: number;
    min: number;
    max: number;
  };
  const chunks: chunkOfNodes[] = [];
  let buildingChunk = false;
  for (let i = 0; i < parent.children.length; i++) {
    const child = parent.children[i]!;

    if (editorState.selectionState.selectedNodeIds.has(child.id)) {
      if (buildingChunk === false) {
        // Start of a new chunk of selected nodes
        buildingChunk = true;
        chunks.push({ startIndex: i, endIndex: i, min: child.bounds[minProperty], max: child.bounds[maxProperty] });
      } else {
        // Already in a chunk of selected nodes, continue building it out
        chunks[chunks.length - 1]!.endIndex = i;
        chunks[chunks.length - 1]!.max = child.bounds[maxProperty];
      }
    } else {
      if (buildingChunk === true) {
        // No longer building a chunk, perform end-of-chunk logic
        buildingChunk = false;
      }

      // Push this new single node as a chunk
      chunks.push({ startIndex: i, endIndex: i, min: child.bounds[minProperty], max: child.bounds[maxProperty] });
    }
  }

  // Find the closest chunk center to the cursor position
  let closestChunkDistance: number = Infinity;
  let closestChunkIndex: number = -1;
  for (let chunkI = 0; chunkI < chunks.length; chunkI++) {
    const chunk = chunks[chunkI]!;
    const chunkCenter = chunk.min + (chunk.max - chunk.min) / 2;
    const distance = cursorPos[axis] - chunkCenter;
    if (Math.abs(distance) < Math.abs(closestChunkDistance)) {
      closestChunkDistance = distance;
      closestChunkIndex = chunkI;
    } else {
      // Increasing the distance now, we've passed the closest chunk, so we can stop searching
      break;
    }
  }

  if (closestChunkIndex === -1) {
    // We didn't find a place to drop, which can happen if all children are selected within the parent
    return -1;
  }

  if (layoutDirection === LayoutDirection.RTL || layoutDirection === LayoutDirection.BTT) {
    // For flipped layout directions, remember we still return the insert index in our TreeNode, in normal order
    // So all we need to do is flip side of the index we are on
    return closestChunkDistance < 0 ? chunks[closestChunkIndex]!.endIndex + 1 : chunks[closestChunkIndex]!.startIndex;
  }

  return closestChunkDistance < 0 ? chunks[closestChunkIndex]!.startIndex : chunks[closestChunkIndex]!.endIndex + 1;
}
