export function CaretIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.1"
      {...props}
    >
      <path d="M1.25 4.25L4 1.5L6.75 4.25" />
      <path d="M1.25 7.75L4 10.5L6.75 7.75" />
    </svg>
  );
}
