/**
 * Parses a cookie string into a Map of key-value pairs.
 *
 * @param cookieStr - The cookie string to parse.
 * @returns A Map containing the parsed cookie key-value pairs.
 */
export function parseCookies(cookieStr: string) {
  // Initialize a new Map to store cookie key-value pairs
  const cookieMap = new Map();

  // If cookie string is empty, return empty Map
  if (!cookieStr || cookieStr.trim() === '') {
    return cookieMap;
  }

  // Split the cookie string into individual cookies
  const cookies = cookieStr.split(';');

  // Process each cookie
  cookies.forEach((cookie) => {
    // Remove leading/trailing whitespace
    const trimmedCookie = cookie.trim();

    // Find the position of the first '=' character
    const separatorIndex = trimmedCookie.indexOf('=');

    // Skip invalid cookies that don't have a '=' separator
    if (separatorIndex === -1) {
      return;
    }

    // Extract and decode the key and value
    const key = decodeURIComponent(trimmedCookie.slice(0, separatorIndex));
    const value = decodeURIComponent(trimmedCookie.slice(separatorIndex + 1));

    // Add to Map
    cookieMap.set(key, value);
  });

  return cookieMap;
}
