import { makeObservable, observable, action } from 'mobx';
import { TreeNode } from '../tree/TreeNode';
import { EditorState } from '../EditorState';

export class DrawToolState {
  constructor(private readonly editorState: EditorState) {
    makeObservable(this);
  }

  @observable accessor componentToDraw = 'Box';
  @action setComponentToDraw(component: string) {
    this.componentToDraw = component;
  }

  /** Invoked by the ToolState if another tool takes over */
  endTool = () => {
    if (this.isDrawing) {
      this.stopDrawing();
    }
  };

  /** True when the draw tool is drawing a node, set to true even before we have a drawingNode */
  @observable accessor isDrawing: boolean = false;
  @action setIsDrawing(isDrawing: boolean) {
    this.isDrawing = isDrawing;
  }

  /** The node that is currently being drawn */
  @observable accessor drawingNode: TreeNode | null = null;
  @action setDrawingNode(node: TreeNode | null) {
    this.drawingNode = node;
  }

  /** Stops drawing and clears the drawing node */
  @action stopDrawing() {
    this.drawingNode = null;
    this.isDrawing = false;
  }
}
