import { Vec2 } from '@paper/models/src/math/vec2';
import { EditorState } from '../EditorState';
import { contains, intersects, isPointInRect } from '@paper/models/src/math/collision';
import { Rect, RectUtils } from '@paper/models/src/math/rect';
import { action, computed, makeObservable } from 'mobx';
import { TreeNode } from './TreeNode';
import { createTransformer } from 'mobx-utils';
import { createTreeNodeData, makeRootNodeId, TreeNodeType } from '@paper/models/src/file/tree-node-schema';
import { makeTreeRelationshipString } from '@paper/models/src/file/tree-node-relationships';
import { createSortOrderKey } from '@paper/models/src/file/create-sort-order-key';
import { assert } from '../../assert';
import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';
import { MARKED_FOR_REMOVAL_KEY } from '@paper/models/src/file/marked-for-removal-key';

export class TreeUtils {
  constructor(readonly editorState: EditorState) {
    makeObservable(this);
  }

  /**
   * Stores TreeNode instances, instantiated on demand
   */
  private treeNodes = createTransformer(
    (id: string): TreeNode | null => {
      const data = this.editorState.fileState.data.nodes[id];
      if (!data) {
        return null;
      }

      return new TreeNode(this.editorState, id);
    },
    { keepAlive: true }
  );

  /**
   * Returns a TreeNode instance for the given id, or null if the node is not found
   */
  getNode(id: string): TreeNode | null {
    const node = this.treeNodes(id);
    // If we can't find the node or it's marked for removal, return null
    if (!node || node.data[MARKED_FOR_REMOVAL_KEY]) {
      return null;
    }

    return node;
  }

  /** Finds the root node for the active page */
  @computed({ keepAlive: true })
  get rootNode(): TreeNode {
    const activePageId = this.editorState.pageState.activePageId;
    const rootId = makeRootNodeId(activePageId);
    const rootNode = this.getNode(rootId);
    assert(rootNode, 'Missing root node');
    return rootNode;
  }

  /** Returns the very first node in the tree, useful when you want to loop around from the end */
  get firstNodeInTree(): TreeNode | null {
    return this.rootNode.children[0] ?? null;
  }

  /** Returns the very last node in the tree, useful when you want to loop around from the beginning */
  get lastNodeInTree(): TreeNode | null {
    return this.rootNode.descendants[this.rootNode.descendants.length - 1] ?? null;
  }

  /**
   * Adds a new node to the tree inside the specified parent
   * If sortKey is not specified, it will be added to the end of the parent's children
   */
  @action
  addNode = (node: TreeNodeType, parentId: string, withSortKey?: string): TreeNode => {
    let sortKey: string | null = null;
    if (withSortKey) {
      sortKey = withSortKey;
    } else {
      const highestPreviousSortKey = this.editorState.treeIndex.getHighestChildSortKeyForParent(parentId);
      sortKey = createSortOrderKey(highestPreviousSortKey, null);
    }

    const newRelationship = makeTreeRelationshipString(parentId, sortKey);
    this.editorState.fileState.data.nodeRelationships[node.id] = newRelationship;
    this.editorState.fileState.data.nodes[node.id] = node;
    this.editorState.fontState.addToFontToNodeIndex(node.id);

    const newNode = this.getNode(node.id);
    assert(newNode, 'Missing new node');
    return newNode;
  };

  /** Deletes a node and all of its children */
  @action
  deleteNodes = (nodeIds: string[] | string) => {
    const nodeIdsToDelete = Array.isArray(nodeIds) ? nodeIds : [nodeIds];
    // Remove nodes from the selection
    this.editorState.selectionState.removeSelectedNodeIds(nodeIdsToDelete);
    // Find all the descendants of the nodes to delete as well
    const nodesToDelete: TreeNode[] = [];
    for (const nodeId of nodeIdsToDelete) {
      const node = this.getNode(nodeId);
      if (!node) {
        console.warn(`Unexpected: Missing node to delete for id: ${nodeId}`);
        continue;
      }

      nodesToDelete.push(node, ...node.descendants);
    }

    for (const node of nodesToDelete) {
      // Remove the node from indexes
      this.editorState.fontState.removeFromFontToNodeIndex(node.id);
      delete this.editorState.fileState.data.nodeRelationships[node.id];
      // Mark the node for removal
      node.data[MARKED_FOR_REMOVAL_KEY] = true;
    }
  };

  /** Moves the node to a new parent */
  @action
  moveNodeToNewParent = (node: TreeNode, newParent: TreeNode, insertAtSortKey?: string) => {
    if (node.id === newParent?.id) {
      console.warn(`Unexpected: trying to move node into itself`, node.id, node.label);
      return;
    }

    // Grab our position before reparenting so we can restore it after
    const cachedX = node.xInWorld;
    const cachedY = node.yInWorld;

    // Reparent the node
    let sortKey = insertAtSortKey;
    if (sortKey === undefined) {
      const highestPreviousSortKey = this.editorState.treeIndex.getHighestChildSortKeyForParent(newParent.id);
      sortKey = createSortOrderKey(highestPreviousSortKey, null);
    }
    const newRelationship = makeTreeRelationshipString(newParent.id, sortKey);
    this.editorState.fileState.data.nodeRelationships[node.id] = newRelationship;

    // Restore our position to how it was before the reparent
    node.setXInWorld(cachedX);
    node.setYInWorld(cachedY);

    // Make sure the parent is open in the layer tree
    this.editorState.layerTreeState.ensureNodesAreVisibleInLayerTree([node]);
  };

  /** Moves the node to a new sort key within its current parent */
  @action
  moveNodeWithinParent = (node: TreeNode, newSortKey: string) => {
    if (node.parent === null) {
      console.warn('Unexpected: trying to move node within parent that has no parent', node.id, node.label);
      return;
    }

    const newRelationship = makeTreeRelationshipString(node.parentId!, newSortKey);
    this.editorState.fileState.data.nodeRelationships[node.id] = newRelationship;
  };

  /**
   * Returns the total bounds of all top level children of the tree in the active page
   */
  @computed({ keepAlive: true })
  get totalBounds(): Rect {
    return RectUtils.union(this.rootNode.children.map((node) => node.bounds));
  }

  /** Gets the highest node from a given point. If padPointBy is a number it will add padding around the point, useful for hover detection which benefits from a little grace area around bounds */
  getNodeFromPoint(point: Vec2, padPointBy: number = 0): TreeNode | null {
    let nodes: Array<TreeNode> = [];

    if (padPointBy > 0) {
      // Add a little padding around the point to make hover detection easier
      const paddedPoint: Rect = {
        minX: point.x - padPointBy,
        minY: point.y - padPointBy,
        maxX: point.x + padPointBy,
        maxY: point.y + padPointBy,
      };

      nodes = this.treeNodesFromRect(paddedPoint).map((node) => node.node);
    } else {
      // No padding, pass the point through
      nodes = this.treeNodesFromPoint(point);
    }

    if (nodes.length === 0) {
      return null;
    }
    return nodes[nodes.length - 1]!;
  }

  /**
   * Returns any tree nodes that intersects with the given point
   */
  treeNodesFromPoint(point: Vec2): Array<TreeNode> {
    const matchingNodes: Set<TreeNode> = new Set();

    // Start by checking top level nodes in the tree and go down if one of them matches
    // Possible TODO: check if frame is clipping and, if it's not, check its top level children
    for (const node of this.rootNode.children) {
      this.checkNodeAndDescendantsForIntersectionPoint(node, point, matchingNodes);
    }

    return Array.from(matchingNodes);
  }

  /** Recursively checks down the tree to see if nodes interact this point, mutates matchingNodeIds */
  checkNodeAndDescendantsForIntersectionPoint = (node: TreeNode, point: Vec2, matchingNodes: Set<TreeNode>) => {
    // Don't include nodes that are being dragged since they're temporarily "above" the world
    if (
      this.editorState.moveToolState.dragState.isDragging &&
      this.editorState.selectionState.selectedNodeIds.has(node.id)
    ) {
      return;
    }

    if (isPointInRect(point, node.bounds)) {
      matchingNodes.add(node);
      for (const child of node.children) {
        this.checkNodeAndDescendantsForIntersectionPoint(child, point, matchingNodes);
      }
    }
  };

  /**
   * Returns an array of objects with the node and a boolean indicating if it's fully enclosed by the rect
   */
  treeNodesFromRect = (rect: Rect): Array<{ node: TreeNode; isEnclosed: boolean }> => {
    const matchingNodes: Array<{ node: TreeNode; isEnclosed: boolean }> = [];

    // Loop through top level children and check for hits
    // In the future, we might consider checking for children of frames that aren't clipping, even if the top frame isn't a hit
    for (const node of this.rootNode.children) {
      this.checkNodeAndDescendantsForIntersectionRect(node, rect, matchingNodes);
    }

    return matchingNodes;
  };

  /** Recursively checks down the tree to see if nodes interact this point, mutates matchingNodeIds */
  checkNodeAndDescendantsForIntersectionRect = (
    node: TreeNode,
    rect: Rect,
    matchingNodes: Array<{ node: TreeNode; isEnclosed: boolean }>
  ) => {
    // Don't include nodes that are being dragged since they're temporarily "above" the world
    if (
      this.editorState.moveToolState.dragState.isDragging &&
      this.editorState.selectionState.selectedNodeIds.has(node.id)
    ) {
      return;
    }

    if (intersects(rect, node.bounds)) {
      if (contains(rect, node.bounds)) {
        matchingNodes.push({ node, isEnclosed: true });
      } else {
        matchingNodes.push({ node, isEnclosed: false });
      }

      for (const child of node.children) {
        this.checkNodeAndDescendantsForIntersectionRect(child, rect, matchingNodes);
      }
    }
  };

  sortNodesByTreeOrderInPlace(nodes: TreeNode[]) {
    nodes.sort((a, b) => {
      // Quick time saver if the nodes share a parent, it's just their sort keys
      if (a.parent === b.parent) {
        return a.sortKey! < b.sortKey! ? -1 : 1;
      }

      // We'll loop down through the ancestor chains to find the first difference, then compare the sort keys
      // The max loop length is the longest ancesor chain +1 to account for the nodes' own sortKeys if the two ancestor chains are tied in length
      const maxAncestorChainLength = Math.max(a.ancestors.length, b.ancestors.length) + 1;
      // Skip the root node, which is always shared between the two nodes
      let ancestorIndex = 1;

      // Allow the loop to run to -1, which can happen if the ancestor chains are tied in length and we need to compare nodes directly
      while (ancestorIndex < maxAncestorChainLength) {
        const aAncestor = a.ancestors[ancestorIndex];
        const bAncestor = b.ancestors[ancestorIndex];
        if (aAncestor && bAncestor && aAncestor === bAncestor) {
          // They share this ancestor, dig deeper
          ancestorIndex += 1;
          continue;
        }

        // We have a difference in the ancestor chains or we ran out of ancestors to compare, return the sort key difference
        // Note that one ancestor chain may run out first, in which case the node's own sortKey is the right thing to compare against the other ancestor
        // If the chains ended up exactly the same, it's just node sortKey vs node sortKey
        const aSortKey = aAncestor?.sortKey ?? a.sortKey!;
        const bSortKey = bAncestor?.sortKey ?? b.sortKey!;

        return aSortKey < bSortKey ? -1 : 1;
      }

      console.warn('Unexpected: could not sort nodes by tree order', a.label, b.label);
      return 0;
    });
  }

  /** Creates a new node from a component, assigns defaults, and adds it to the target parent */
  createNode(component: string, targetParent: TreeNode): TreeNode | null {
    const componentMeta = BuiltInComponentMap[component];
    if (!componentMeta) {
      console.warn(`Unexpected: missing component meta for ${component}`);
      return null;
    }

    const blankNode = createTreeNodeData();
    blankNode.component = component;
    blankNode.label = this.editorState.fileState.makeLabelForComponent(component);

    const defaultStyles = componentMeta.defaultStyles;
    if (defaultStyles) {
      Object.entries(defaultStyles).forEach(([key, value]) => {
        blankNode.styles[key] = value;
      });
    }
    const defaultStyleMeta = componentMeta.defaultStyleMeta;
    if (defaultStyleMeta) {
      blankNode.styleMeta = {};
      Object.entries(defaultStyleMeta).forEach(([key, value]) => {
        blankNode.styleMeta![key] = value;
      });
    }

    this.addNode(blankNode, targetParent.id);

    const newNode = this.getNode(blankNode.id);
    if (!newNode) {
      console.warn(`Unexpected: missing new node for ${blankNode.id}`);
      return null;
    }
    return newNode;
  }
}
