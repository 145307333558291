import { Rect, RectUtils } from '@paper/models/src/math/rect';
import { EditorState } from '../EditorState';
import { Vec2 } from '@paper/models/src/math/vec2';
import { TreeNode } from '../tree/TreeNode';

// snaps are based on bounding box, even for odd-sized shapes
// points that can snap are:
// topLeft, topRight, center, bottomLeft, bottomRight
// even if an element is rotated, snaps still work based on its unrotated bounding box

// objects for snaps are culled to the viewport, so if they're not in the viewport, they don't count for snapping

// resizing snaps:
// resize snaps are based on the cursor position
// when proportional resizing, it's single-axis and based on whichever axis the cursor is closest to (matching both axis creates undesirable results)

// rotation interactions do not have any snapping

// centers are always Math.floored to prevent random center snapping

export type SnapResult = {
  xSnaps: FoundSnap[];
  ySnaps: FoundSnap[];
};

export enum SnapAxis {
  X = 0,
  Y = 1,
  XY = 2,
}

export type FoundSnap = {
  /** The axis that found a snap match */
  axis: SnapAxis;
  /** How far away the matching snap is, positive or negative */
  distanceSigned: number; // positive if to the right, negative if to the left
  /** The position of the matching snap on the primary axis */
  primaryAxisValue: number;
  /** All of the matching secondary axis positions AFTER adjusting to the snap point */
  secondaryAxisValues: Set<number>;
};

/** Find the closest matching snaps for a test rectangle against a set of snappable nodes */
export function findSnapsForRect(
  editorState: EditorState,
  self: Rect,
  snappableNodes: TreeNode[],
  snapDistance: number,
  searchAxis: SnapAxis
): SnapResult {
  // ----- Build out the external points we need to check ----- //
  const pointsToCheck: Array<Vec2> = [];
  for (const node of snappableNodes) {
    const nodeBounds = node.bounds;
    const centerPoint = RectUtils.center(nodeBounds);
    pointsToCheck.push({ x: Math.round(centerPoint.x), y: Math.round(centerPoint.y) }); // center
    pointsToCheck.push({ x: Math.round(nodeBounds.minX), y: Math.round(nodeBounds.minY) }); // top left
    pointsToCheck.push({ x: Math.round(nodeBounds.maxX), y: Math.round(nodeBounds.minY) }); // top right
    pointsToCheck.push({ x: Math.round(nodeBounds.maxX), y: Math.round(nodeBounds.maxY) }); // bottom right
    pointsToCheck.push({ x: Math.round(nodeBounds.minX), y: Math.round(nodeBounds.maxY) }); // bottom left
  }

  // ----- For each of our corners and our center, check against the external points to see if they snap ----- //
  const distance = snapDistance * editorState.cameraState.scaleInverse;
  const centerPointUnrounded = RectUtils.center(self);
  const centerPoint = { x: Math.round(centerPointUnrounded.x), y: Math.round(centerPointUnrounded.y) };

  const minX = findSnapForAxis(distance, self.minX, 'x', pointsToCheck);
  const maxX = findSnapForAxis(distance, self.maxX, 'x', pointsToCheck);
  const centerX = findSnapForAxis(distance, centerPoint.x, 'x', pointsToCheck);
  const rawSnapsX: Array<SingleSnapResult> = [minX, maxX, centerX].filter((snap) => snap !== null);
  const minY = findSnapForAxis(distance, self.minY, 'y', pointsToCheck);
  const maxY = findSnapForAxis(distance, self.maxY, 'y', pointsToCheck);
  const centerY = findSnapForAxis(distance, centerPoint.y, 'y', pointsToCheck);
  const rawSnapsY: Array<SingleSnapResult> = [minY, maxY, centerY].filter((snap) => snap !== null);

  // Find the closest, winning, snap distance (if there's a tie between, say, -3 and 3, it will choose whichever it runs into first)
  // x-axis
  let closestXDistanceSigned: number | null = null;
  let closestXSnapDistanceAbs = Infinity;
  for (const snap of rawSnapsX) {
    if (snap.distanceAbs < closestXSnapDistanceAbs) {
      closestXSnapDistanceAbs = snap.distanceAbs;
      closestXDistanceSigned = snap.distanceSigned;
    }
  }

  // y-axis
  let closestYDistanceSigned: number | null = null;
  let closestYSnapDistanceAbs = Infinity;
  for (const snap of rawSnapsY) {
    if (snap.distanceAbs < closestYSnapDistanceAbs) {
      closestYSnapDistanceAbs = snap.distanceAbs;
      closestYDistanceSigned = snap.distanceSigned;
    }
  }

  let finalXSnapsResult: FoundSnap[] = [];
  let finalYSnapsResult: FoundSnap[] = [];

  if (searchAxis === SnapAxis.XY || searchAxis === SnapAxis.X) {
    // Loop again and pick out only the ones that match the closest snap distance and grab their secondary matches
    // and combine any that are on the same primary axis value so we don't end up with more than one snap for the same primary value
    const filteredSnapsX: Record<number, FoundSnap> = {};
    for (const snap of rawSnapsX) {
      if (snap.distanceSigned === closestXDistanceSigned) {
        if (filteredSnapsX[snap.newValue] === undefined) {
          // Add the snap to our filtered list
          filteredSnapsX[snap.newValue] = {
            axis: SnapAxis.X,
            distanceSigned: snap.distanceSigned,
            primaryAxisValue: snap.newValue,
            secondaryAxisValues: snap.secondaryAxisValues,
          };
        }
      }
    }

    // Convert back to an array
    finalXSnapsResult = Object.values(filteredSnapsX);
  }

  if (searchAxis === SnapAxis.XY || searchAxis === SnapAxis.Y) {
    const filteredSnapsY: Record<number, FoundSnap> = {};
    for (const snap of rawSnapsY) {
      if (snap.distanceSigned === closestYDistanceSigned) {
        if (filteredSnapsY[snap.newValue] === undefined) {
          filteredSnapsY[snap.newValue] = {
            axis: SnapAxis.Y,
            distanceSigned: snap.distanceSigned,
            primaryAxisValue: snap.newValue,
            secondaryAxisValues: snap.secondaryAxisValues,
          };
        }
      }
    }
    // Convert back to an array
    finalYSnapsResult = Object.values(filteredSnapsY);
  }

  // Start building the final snap result
  const finalSnapResult: SnapResult = { xSnaps: finalXSnapsResult, ySnaps: finalYSnapsResult };
  return finalSnapResult;
}

/** Use this as a standalone function to find snaps for a single point (findSnapsForRect does not use this) */
export function findSnapsForPoint(
  editorState: EditorState,
  axis: SnapAxis,
  self: Vec2,
  snappableNodes: TreeNode[],
  snapDistance: number
): SnapResult {
  // ----- Build out a list of all the points we need to check for snaps ----- //
  const pointsToCheck: Array<Vec2> = [];
  for (const node of snappableNodes) {
    const nodeBounds = node.bounds;
    const centerPoint = RectUtils.center(nodeBounds);
    pointsToCheck.push({ x: Math.round(centerPoint.x), y: Math.round(centerPoint.y) }); // center
    pointsToCheck.push({ x: Math.round(nodeBounds.minX), y: Math.round(nodeBounds.minY) }); // top left
    pointsToCheck.push({ x: Math.round(nodeBounds.maxX), y: Math.round(nodeBounds.minY) }); // top right
    pointsToCheck.push({ x: Math.round(nodeBounds.maxX), y: Math.round(nodeBounds.maxY) }); // bottom right
    pointsToCheck.push({ x: Math.round(nodeBounds.minX), y: Math.round(nodeBounds.maxY) }); // bottom left
  }

  // ----- Find the closet snap for each axis ----- //
  let xAxisSnap: SingleSnapResult | null = null;
  let yAxisSnap: SingleSnapResult | null = null;
  const distance = snapDistance * editorState.cameraState.scaleInverse;
  if (axis === SnapAxis.X || axis === SnapAxis.XY) {
    xAxisSnap = findSnapForAxis(distance, self.x, 'x', pointsToCheck);
  }
  if (axis === SnapAxis.Y || axis === SnapAxis.XY) {
    yAxisSnap = findSnapForAxis(distance, self.y, 'y', pointsToCheck);
  }

  // ----- Adjust self to match the new snap if we found one ----- //
  const selfAdjustedForSnap = {
    x: (self.x ?? 0) + (xAxisSnap?.distanceSigned ?? 0),
    y: (self.y ?? 0) + (yAxisSnap?.distanceSigned ?? 0),
  };

  // ----- Turn the closest snaps we found into the final result ----- //
  const foundSnaps: SnapResult = { xSnaps: [], ySnaps: [] };
  if (xAxisSnap !== null) {
    foundSnaps.xSnaps.push({
      axis: SnapAxis.X,
      distanceSigned: xAxisSnap.distanceSigned,
      primaryAxisValue: xAxisSnap.newValue,
      secondaryAxisValues: xAxisSnap.secondaryAxisValues,
    });
  }

  if (yAxisSnap !== null) {
    foundSnaps.ySnaps.push({
      axis: SnapAxis.Y,
      distanceSigned: yAxisSnap.distanceSigned,
      primaryAxisValue: yAxisSnap.newValue,
      secondaryAxisValues: yAxisSnap.secondaryAxisValues,
    });
  }

  return foundSnaps;
}

type SingleSnapResult = {
  distanceSigned: number;
  distanceAbs: number;
  originalValue: number;
  newValue: number;
  secondaryAxisValues: Set<number>;
};
/** Finds the single best snap for a given axis value */
function findSnapForAxis(
  snapDistance: number,
  value: number,
  axis: 'x' | 'y',
  pointsToCheck: Array<Vec2>
): SingleSnapResult | null {
  let smallestDeltaSoFarAbs = Math.ceil(snapDistance) + 1; // we add +1 to the first check number since we require "less than" (not equal) to find a snap
  let deltaSigned: number | null = null;
  let newValue: number | null = null;
  const secondaryAxisValues = new Set<number>();
  const otherAxis = axis === 'x' ? 'y' : 'x';

  // ----- Check each point to see if it's a snap ----- //
  for (const point of pointsToCheck) {
    const delta = point[axis] - value;
    const deltaAbs = Math.abs(delta);

    if (deltaAbs < smallestDeltaSoFarAbs) {
      smallestDeltaSoFarAbs = deltaAbs;
      deltaSigned = delta;
      newValue = point[axis];
      secondaryAxisValues.clear();
      secondaryAxisValues.add(point[otherAxis]);
    } else if (delta === deltaSigned) {
      secondaryAxisValues.add(point[otherAxis]);
    }
  }

  if (newValue === null || deltaSigned === null) {
    return null;
  }

  return {
    distanceSigned: deltaSigned,
    distanceAbs: smallestDeltaSoFarAbs,
    originalValue: value,
    newValue,
    secondaryAxisValues,
  };
}
