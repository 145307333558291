import { roundOptimized } from '@paper/models/src/math/round-optimized';
import { formatAlpha, formatChannel, formatOkChannel, prettySymbolsRegexp } from './color-formatting';
import type { Oklab } from 'culori/fn';

export function getCssStringOklab({ l, a, b, alpha = 1 }: Oklab) {
  let str = formatChannel(l * 100, 1, 0, 100) + '% ';
  str += formatOkChannel(a) + ' ';
  str += formatOkChannel(b);

  if (roundOptimized(alpha, 3) !== 1) {
    str += ' / ' + formatAlpha(alpha * 100) + '%';
  }

  return 'oklab(' + str.replace(prettySymbolsRegexp, '') + ')';
}
