import clsx from 'clsx';
import { CollapsibleIndicator } from './collapsible-indicator';

interface PanelHeaderProps extends React.ComponentProps<'div'> {
  inactive?: boolean;
}

export function PanelHeader({ className, inactive, ...props }: PanelHeaderProps) {
  return <div data-inactive={inactive ? '' : undefined} className="PanelHeader" {...props} />;
}

interface PanelTitleProps extends React.ComponentProps<'div'> {
  collapsible?: boolean;
  collapsed?: boolean;
  onCollapsedChange?: (collapsed: boolean) => void;
}

export function PanelTitle({
  children,
  className,
  collapsible = true,
  collapsed,
  onCollapsedChange,
  onPointerDown,
  ...props
}: PanelTitleProps) {
  return (
    <div
      className={clsx('PanelTitle', className)}
      onPointerDown={(event) => {
        if (collapsible) {
          onCollapsedChange?.(!collapsed);
        }
        onPointerDown?.(event);
      }}
      {...props}
    >
      <CollapsibleIndicator.Root>
        {collapsible && <CollapsibleIndicator.Icon collapsed={collapsed} />}
      </CollapsibleIndicator.Root>
      <span>{children}</span>
    </div>
  );
}

export function PanelRow({ className, ...props }: React.ComponentProps<'div'>) {
  return <div className={clsx('PanelRow', className)} {...props} />;
}

export function PanelCaption({ className, ...props }: React.ComponentProps<'div'>) {
  return <div className={clsx('PanelCaption', className)} {...props} />;
}
