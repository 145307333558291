import { createMultipleSortOrderKeys } from '@paper/models/src/file/create-sort-order-key';
import { assert } from '../../assert';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { action } from 'mobx';

export const moveDraggedNodesToNewParent = action(
  (editorState: EditorState, draggedNodes: TreeNode[], newParent: TreeNode, index: number) => {
    // Generate sort keys inside the new drop target
    const sortedDraggedNodes = editorState.selectionState.selectedNodes.slice();
    editorState.treeUtils.sortNodesByTreeOrderInPlace(sortedDraggedNodes);
    assert(sortedDraggedNodes.length > 0);

    const prevSiblingSortKey = newParent.children[index - 1]?.sortKey ?? null;
    const nextSiblingSortKey = newParent.children[index]?.sortKey ?? null;
    const newSortKeys = createMultipleSortOrderKeys(sortedDraggedNodes.length, prevSiblingSortKey, nextSiblingSortKey);
    assert(newSortKeys.length === sortedDraggedNodes.length);

    // Quick check to see if everything is already in the same place, in which case we'll skip any movement
    let foundNodeToMove = false;
    for (let i = 0; i < sortedDraggedNodes.length; i++) {
      const node = sortedDraggedNodes[i]!;
      // If node isn't in the same parent, or it's in DOM layout mode and not already in the right place, we've found a reason to perform node moves
      if (node.parent !== newParent || (node.isFixedLayout === false && node.indexOfNodeInParent !== index + i)) {
        foundNodeToMove = true;
        break;
      }
    }
    if (foundNodeToMove === false) {
      return;
    }

    // Otherwise, we need to move the nodes
    for (let i = 0; i < sortedDraggedNodes.length; i++) {
      const node = sortedDraggedNodes[i]!;
      // Skip nodes that are already in the same parent and freeform, but go ahead and move anything in a flex layout,
      // as the logic to check if it'll be in the right place after other things move is more complex than just moving it
      if (node.parent === newParent && node.isFixedLayout === true) {
        // We don't need to move it
        continue;
      }

      editorState.treeUtils.moveNodeToNewParent(node, newParent, newSortKeys[i]!);
    }
  }
);
