import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { Field } from '../../components/field';
import { reducePropertyValues } from '../properties/reduce-property-values';

export const FlexDirectionProperty = observer((props: React.ComponentProps<typeof Field.Root>) => {
  const { propertiesState } = useEditor();

  const values = propertiesState.getStyleValues('flexDirection');
  const inputValue = reducePropertyValues(values.keys(), 'row');

  function commitValue(value: string) {
    for (const node of propertiesState.allNodes) {
      if (node.layoutModeForChildren === 'flex') {
        node.setStyle('flexDirection', value);
      }
    }
  }

  return (
    <Field.Root {...props}>
      <Field.Icon>{inputValue === 'row' ? '→' : '↓'}</Field.Icon>
      <Field.Control>
        <select value={inputValue} onChange={(e) => commitValue(e.target.value)}>
          <option value="row">Row</option>
          <option value="column">Column</option>
        </select>
      </Field.Control>
      <Field.Caret />
    </Field.Root>
  );
});
