import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { PropertyPanels } from '../properties/property-panel';
import { useEditor } from '../editor-context';
import { Field } from '../../components/field';
import { TreeNode } from '../tree/TreeNode';
import { isMixedFontFamily } from './typeface-property';
import { assert } from '../../assert';
import { FontUtils } from '../fonts/FontUtils';
import { FontFamily } from '../fonts/FontFamily';

export const FontStyleProperty = observer(() => {
  const { fontState, propertiesState } = useEditor();
  const nodes = propertiesState.nodesPerProperty[PropertyPanels.Typography] ?? [];
  const isMixedStyle = isMixedFontStyle(nodes);
  const isMixedFamily = isMixedFontFamily(nodes);
  const font = nodes[0]?.styleMeta.font;

  if (isMixedFamily) {
    if (isMixedStyle) {
      return <DisabledField>Mixed</DisabledField>;
    }

    if (font) {
      return <DisabledField>{font.style}</DisabledField>;
    }
  }

  if (font) {
    const family = fontState.getFamily(font.family);

    if (family) {
      if (family.size === 1) {
        return <DisabledField>{family.styles[0]}</DisabledField>;
      }

      return <FontStylePropertyImpl nodes={nodes} style={font.style} family={family} isMixed={isMixedStyle} />;
    }
  }

  return <DisabledField>Not available</DisabledField>;
});

interface FontStylePropertyImpl {
  nodes: TreeNode[];
  family: FontFamily;
  isMixed: boolean;
  style: string;
}

function FontStylePropertyImpl({ nodes, family, isMixed, style }: FontStylePropertyImpl) {
  const { fontState } = useEditor();

  async function commitValue(styleName: string) {
    for (const node of nodes) {
      const font = await fontState.getFont(family.name, styleName);
      assert(font, `Expected to find "${styleName}" style in "${font?.family}" family.`);

      // Set styles
      node.setStyleMeta('font', FontUtils.clone(font));
      const styles = FontUtils.css(font);
      for (const property in styles) {
        node.setStyle(property, styles[property]);
      }
    }
  }

  const value = isMixed ? 'Mixed' : style;

  // All styles of the font, grouped logically.
  // We retrieve this as a side-effect because retrieving grouped font styles has to be async.
  // While we wait for that to compute, the currently selected font style is rendered in place.
  const [styleGroups, setStyleGroups] = React.useState<string[][] | null>(null);
  React.useEffect(() => {
    let onFulfilled = (styles: string[][]) => {
      setStyleGroups((current) => current ?? styles);
    };

    family.getStyleGroups().then(onFulfilled);

    return () => {
      // Short-circuit the promise callback
      onFulfilled = () => null;
      setStyleGroups(null);
    };
  }, [family, fontState]);

  return (
    <Field.Root>
      <Field.Icon>
        <FontWidthIcon />
      </Field.Icon>
      <Field.Control>
        <select
          // Assembling the grouped styles isusually fast, but some families may take a couple seconds to compute
          // Notable offender: SF Pro. Its file size is ~20 MB, while most fonts are <1 MB
          // (Although many larger fonts are much faster)
          disabled={!styleGroups}
          className="disabled:cursor-wait"
          value={value}
          onChange={(event) => commitValue(event.currentTarget.value)}
        >
          {isMixed ? (
            <React.Fragment>
              <option disabled value="Mixed">
                Mixed
              </option>
              <hr />
            </React.Fragment>
          ) : null}

          {/* Fall back to the current font style if the full grouped styles list isn't available yet */}
          {(styleGroups ?? [[style]]).map((group, index, arr) => {
            return (
              <React.Fragment key={index}>
                {group.map((style) => (
                  <option key={style} value={style}>
                    {style}
                    {fontState.isUnavailable(family.name, style) && ' (Not available)'}
                  </option>
                ))}

                {index < arr.length - 1 && <hr />}
              </React.Fragment>
            );
          })}
        </select>
      </Field.Control>
      {family.size > 1 && <Field.Caret />}
    </Field.Root>
  );
}

function DisabledField({ children }: React.PropsWithChildren) {
  return (
    <Field.Root>
      <Field.Icon>
        <FontWidthIcon />
      </Field.Icon>
      <Field.Control>
        <div>{children}</div>
      </Field.Control>
    </Field.Root>
  );
}

function isMixedFontStyle(nodes: TreeNode[]) {
  let previous: TreeNode;

  for (const current of nodes) {
    previous ??= current;
    if (previous === current) continue;

    if (previous.styleMeta.font?.style !== current.styleMeta.font?.style) {
      return true;
    }

    previous = current;
  }

  return false;
}

function FontWidthIcon() {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="currentcolor">
      <path
        fillRule="evenodd"
        d="M3.44189 10L6.32927 2.00293H7.67043L10.5578 10H9.2499L8.58323 8H5.41607L4.74426 10H3.44189ZM6.94998 3.40505H7.04419L8.24989 7H5.74981L6.94998 3.40505Z"
      />
      <path d="M1.25 13.5L4 11.25L4 13L10 13L10 11.25L12.75 13.5L10 15.75L10 14L4 14L4 15.75L1.25 13.5Z" />
    </svg>
  );
}
