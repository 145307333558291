import { deepObserve, IDisposer } from 'mobx-utils';
import { FileState } from './FileState';
import { IArrayDidChange, IMapDidChange, IObjectDidChange } from 'mobx';
import type { Edit } from '@paper/models/src/delta/edit';
import { NodeRelationshipsKeys } from '@paper/models/src/file/file-schema';

export type IMobxChange = IObjectDidChange | IArrayDidChange | IMapDidChange;

export class FileDataObserver {
  constructor(private fileState: FileState) {
    this.fileObserverDisposer = deepObserve(this.fileState.data, this.handleMobxChange);
  }
  dispose = () => {
    if (this.fileObserverDisposer !== null) {
      this.fileObserverDisposer();
    }
  };
  /** Stores the disposer for watching the file data */
  private fileObserverDisposer: IDisposer | null = null;

  /** Changes will be ignored if this set is not empty */
  private pauseObservingIfNotEmpty = new Set<string>();
  /** Add an ignore. You need to provide a unique ID for this requestor so we can safely resume it later */
  pauseObserving = (requestorId: string) => {
    this.pauseObservingIfNotEmpty.add(requestorId);
  };
  /** Removes an ignore request. You need to provide the same requestor ID as when you started the block pause */
  resumeObserving = (requestorId: string) => {
    this.pauseObservingIfNotEmpty.delete(requestorId);
  };

  /**
   * If true, changes will be treated as transient (not saved to the file and not sent in single-player mode at all)
   * When we exit trasient mode, we re-send the final edits per path that were queued while in transient mode
   */
  private treatChangesAsTransient = false;
  /** Public accessor to see if we're treating changes as transient (used by the node mutation observer to batch changes) */
  get isTreatingChangesAsTransient(): boolean {
    return this.treatChangesAsTransient;
  }
  /** Starts treating changes as transient (not saved to the file and only sent in multiplayer mode) */
  startTreatingChangesAsTransient = () => {
    this.treatChangesAsTransient = true;
    this.cachedTransientEdits = new Map();
  };
  /** Ends transient mode and re-sends final Edits per path as non-transient */
  endTreatingChangesAsTransient = () => {
    this.treatChangesAsTransient = false;
    for (const edit of this.cachedTransientEdits.values()) {
      this.fileState.editorState.fileState.sendFileEdit(edit, false);
    }
    for (const undoEdit of this.cachedTransientUndos.values()) {
      this.fileState.editorState.undoManager.addEdit(undoEdit, false);
    }
    this.cachedTransientEdits = new Map();
    this.cachedTransientUndos = new Map();
  };
  /** Once we come out of transient mode, we re-send the final edits that were queued while in transient mode. These are cached by path. */
  cachedTransientEdits: Map<string, Edit> = new Map();
  cachedTransientUndos: Map<string, Edit> = new Map();

  handleMobxChange = (change: IMobxChange, path: string, root: any) => {
    // Update the tree index if node relationships change
    if (path === NodeRelationshipsKeys) {
      if (change.observableKind === 'object' && typeof change.name === 'string') {
        if (change.type === 'add' || change.type === 'update') {
          // name is the nodeId and newValue is the relationship string
          this.fileState.editorState.treeIndex.updateIndex(change.name, change.newValue);
        } else if (change.type === 'remove') {
          this.fileState.editorState.treeIndex.deleteFromIndex(change.name);
        }
      }
    }

    // Update the font index if the fontFamily changes
    if ('name' in change && change.name === 'font') {
      this.fileState.editorState.fontState.handleFontChange(path, change);
    }

    // If we're paused, don't do anything else
    if (this.pauseObservingIfNotEmpty.size > 0) {
      return;
    }

    // Convert the mobx change to an Edit
    const edit = makeFileEditFromMobxChange(change, path);
    const undoEdit = makeUndoEditFromMobxChange(change, path);

    // Add the edit to the undo manager
    this.fileState.editorState.undoManager.addEdit(undoEdit, this.treatChangesAsTransient);

    // Tell the server about the edit
    this.fileState.editorState.fileState.sendFileEdit(edit, this.treatChangesAsTransient);

    // Cache transient edits
    if (this.treatChangesAsTransient) {
      // Cache the edit so we can re-send it as non-transient once we're done with transient mode
      this.cachedTransientEdits.set(edit.path, edit);

      // Only cache the first undo edit for each path
      if (this.cachedTransientUndos.has(undoEdit.path) === false) {
        this.cachedTransientUndos.set(undoEdit.path, undoEdit);
      }
    }

    return;
  };
}

/** Converts the verbose mobx change info into a smaller Edit to transmit over the network */
function makeFileEditFromMobxChange(change: IMobxChange, path: string): Edit {
  let edit: Edit;

  // From MobX:
  // Objects can have: add, update, remove
  // Arrays can have: update, splice
  // Maps can have: add, update, delete

  let editPath = path;
  if (change.observableKind === 'object' || change.observableKind === 'map') {
    editPath = `${path}/${change.name}`;
  } else {
    editPath = `${path}/${change.index}`;
  }

  switch (change.type) {
    case 'add':
      // MobX: Add can be from Objects or Maps
      edit = {
        path: editPath,
        type: 'add',
        value: change.newValue,
      };
      break;
    case 'update':
      // MobX: Update is from any type
      edit = {
        path: editPath,
        type: 'update',
        value: change.newValue,
      };
      break;
    case 'splice':
      // MobX: Splice is array only and can contain both adds and deletes
      edit = {
        path: editPath,
        type: 'splice',
        added: change.added,
        removedCount: change.removedCount,
      };
      break;
    case 'delete':
    case 'remove':
      // MobX: Delete is from Maps, Remove is from Objects
      edit = {
        path: editPath,
        type: 'remove',
      };
      break;
  }

  return edit;
}

/** Converts the verbose mobx change info into the Edit that would Undo it */
function makeUndoEditFromMobxChange(change: IMobxChange, path: string): Edit {
  let edit: Edit;

  // From MobX:
  // Objects can have: add, update, remove
  // Arrays can have: update, splice
  // Maps can have: add, update, delete

  let editPath = path;
  if (change.observableKind === 'object' || change.observableKind === 'map') {
    editPath = `${path}/${change.name}`;
  } else {
    editPath = `${path}/${change.index}`;
  }

  switch (change.type) {
    case 'add':
      // MobX: Add can be from Objects or Maps
      edit = {
        path: editPath,
        type: 'remove',
      };
      break;
    case 'update':
      // MobX: Update is from any type
      edit = {
        path: editPath,
        type: 'update',
        value: change.oldValue,
      };
      break;
    case 'splice':
      // MobX: Splice is array only and can contain both adds and deletes
      edit = {
        path: editPath,
        type: 'splice',
        added: change.removed,
        removedCount: change.addedCount,
      };
      break;
    case 'delete':
    case 'remove':
      // MobX: Delete is from Maps, Remove is from Objects
      edit = {
        path: editPath,
        type: 'add',
        value: change.oldValue,
      };
      break;
  }

  return edit;
}

// ----- MobX ----- //
// export type IObjectDidChange<T = any> = {
//   observableKind: "object";
//   name: PropertyKey;
//   object: T;
//   debugObjectName: string;
// } & ({
//   type: "add";
//   newValue: any;
// } | {
//   type: "update";
//   oldValue: any;
//   newValue: any;
// } | {
//   type: "remove";
//   oldValue: any;
// });

// export interface IArrayUpdate<T = any> extends IArrayBaseChange<T> {
//   type: "update";
//   newValue: T;
//   oldValue: T;
// }
// export interface IArraySplice<T = any> extends IArrayBaseChange<T> {
//   type: "splice";
//   added: T[];
//   addedCount: number;
//   removed: T[];
//   removedCount: number;
// }

// export type IMapDidChange<K = any, V = any> = {
//   observableKind: "map";
//   debugObjectName: string;
// } & ({
//   object: ObservableMap<K, V>;
//   name: K;
//   type: "update";
//   newValue: V;
//   oldValue: V;
// } | {
//   object: ObservableMap<K, V>;
//   name: K;
//   type: "add";
//   newValue: V;
// } | {
//   object: ObservableMap<K, V>;
//   name: K;
//   type: "delete";
//   oldValue: V;
// });
