import { clamp } from '@paper/models/src/math/clamp';
import { roundOptimized } from '@paper/models/src/math/round-optimized';

export function formatChannel(value: string | number, precision: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 3, min = 0, max = 1) {
  let n = typeof value === 'string' ? parseFloat(value) : value;
  n = clamp(roundOptimized(n, precision), min, max);

  if (n === 0) {
    return '0';
  }

  if (n === min) {
    return min.toString();
  }

  if (n === max) {
    return max.toString();
  }

  return n.toFixed(precision);
}

export function formatOkChannel(value: string | number, min = -0.4, max = 0.4) {
  let n = typeof value === 'string' ? parseFloat(value) : value;
  n = roundOptimized(n, 4);

  if (n === 0) {
    return '0';
  }

  // Print numbers between 0.0001 and 0.0009 without a leading zero (to save space)
  if (Math.abs(n) < 0.001) {
    return String(n).replace('0.', '.');
  }

  return formatChannel(value, 3, min, max);
}

export function formatAlpha(value: string | number = 100) {
  let n = typeof value === 'string' ? parseFloat(value) : value;
  n = clamp(roundOptimized(n, 1), 0, 100);

  if (n === 0) {
    return '0';
  }

  if (n === 100) {
    return '100';
  }

  return n.toString();
}

export function formatHue(value: number | string, trailingZero: boolean) {
  let hue = typeof value === 'string' ? parseFloat(value) : value;

  // Rounded in such a way that hues between 359.95 and 359.99 are displayed as 360
  hue = roundOptimized(moduloAbs(roundOptimized(hue, 2), 360), 1);
  return hue === 0 || hue === 360 ? hue.toString() : trailingZero ? hue.toFixed(1) : roundOptimized(hue, 1).toString();
}

function moduloAbs(n: number, m: number) {
  return ((n % m) + m) % m;
}

export const prettySymbolsRegexp = /°|\.0+\b|\.0+(?=%)/g;
