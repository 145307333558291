import { Value } from '@sinclair/typebox/value';
import { type Static, Type } from '@sinclair/typebox';
import { ulid } from 'ulid';
import { MARKED_FOR_REMOVAL_KEY } from './marked-for-removal-key';
import { ColorSchema } from '../colors/Color';

/** The schema for a page */
export const PageSchema = Type.Object({
  id: Type.String(),
  label: Type.String(),
  createdDate: Type.Number(),
  lastModifiedDate: Type.Number(),
  canvasColor: Type.Optional(ColorSchema),
  /** This page will act deleted in the client and will be fully deleted once all clients disconnect from the file (allows good undo/redo behavior) */
  [MARKED_FOR_REMOVAL_KEY]: Type.Optional(Type.Boolean()),
});
export type PageType = Static<typeof PageSchema>;

/** Temporary function to create a blank page, may move to the client later */
export function createPage(label: string): PageType {
  const blankPage = Value.Create(PageSchema);
  blankPage.id = ulid();
  blankPage.label = label;

  blankPage.createdDate = Date.now();
  blankPage.lastModifiedDate = Date.now();

  return blankPage;
}
