import { action, makeObservable, observable, runInAction } from 'mobx';
import { EditorState } from './EditorState';

export class KeyState {
  constructor(public editorState: EditorState) {
    makeObservable(this);

    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
    window.addEventListener('blur', this.handleBlur);
  }
  dispose = () => {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('blur', this.handleBlur);
  };

  @action handleBlur = () => {
    // Clear out all keys if the window loses focus so that they don't get stuck down
    this.isShiftDown = false;
    this.isAltDown = false;
    this.isCtrlDown = false;
    this.isMetaDown = false;
    this.keyCodeDown.clear();
  };

  @action handleKeyDown = (e: KeyboardEvent) => {
    this.isShiftDown = e.shiftKey;
    this.isAltDown = e.altKey;
    this.isCtrlDown = e.ctrlKey;
    this.isMetaDown = e.metaKey;

    this.keyCodeDown.add(e.code);

    // Modifier keys often change state and require a new draw
    this.editorState.hudState.requestDraw();
  };

  @action handleKeyUp = (e: KeyboardEvent) => {
    this.isShiftDown = e.shiftKey;
    this.isAltDown = e.altKey;
    this.isCtrlDown = e.ctrlKey;
    this.isMetaDown = e.metaKey;

    this.keyCodeDown.delete(e.code);

    // Modifier keys often change state and require a new draw
    this.editorState.hudState.requestDraw();
  };

  @action updateModifiersFromPointerMove = (e: PointerEvent) => {
    this.isShiftDown = e.shiftKey;
    this.isAltDown = e.altKey;
    this.isCtrlDown = e.ctrlKey;
    this.isMetaDown = e.metaKey;
  };

  @observable accessor isShiftDown = false;
  @observable accessor isAltDown = false;
  @observable accessor isCtrlDown = false;
  @observable accessor isMetaDown = false;
  @observable accessor keyCodeDown = new Set<string>();
}
