import { Vec2, Vec2Utils } from '@paper/models/src/math/vec2';
import { RectUtils, RectWithSize } from '@paper/models/src/math/rect';
import { TreeNode } from '../tree/TreeNode';
import { action } from 'mobx';
import { ulid } from 'ulid';
import { makeResizeRect } from '../move-tool/make-resize-rect';
import { EditorState } from '../EditorState';
import { assert } from '@paper/models/src/assert';
import { CLICK_VS_DRAG_THRESHOLD_PX, DOUBLE_CLICK_TIME_MS } from '../move-tool/move-tool';
import { DEFAULT_FONT } from '../fonts/built-in-fonts';
import { FontUtils } from '../fonts/FontUtils';
import { ColorUtils } from '@paper/models/src/colors/Color';

export function startDrawingText(editorState: EditorState, overlayEl: HTMLCanvasElement, e: PointerEvent): () => void {
  const {
    treeUtils,
    cameraState,
    pointerState,
    fileState,
    selectionState,
    textTool,
    tickState,
    snapState,
    hudState,
    layerTreeState,
    keyState,
  } = editorState;

  // ----- State ----- //
  let pointerDownPos: Vec2 | null = null;
  let anchorPoint: Vec2 | null = null;
  let drawBounds: RectWithSize | null = null;
  let qualifiesAsDraw = false;
  let drawingNode: TreeNode | null = null;
  // ----- Begin the drawing ----- //
  // Figure out if we should just insert or start a brush draw
  let targetParentId = treeUtils.rootNode.id;

  // Keep all pointer events flowing to the canvas until we release
  overlayEl.setPointerCapture(e.pointerId);

  let shouldInsertIntoParent = false; // hoverNode can have children + is in DOM layout mode
  const canHaveChildren = pointerState.hoveredNode?.canHaveChildren;
  if (canHaveChildren) {
    targetParentId = pointerState.hoveredNode.id;
  }

  pointerDownPos = { x: e.clientX, y: e.clientY };
  anchorPoint = cameraState.viewportToWorld(pointerDownPos);
  qualifiesAsDraw = false;

  // Treat drawing as transient changes until we're done
  fileState.fileDataObserver?.startTreatingChangesAsTransient();
  // Bind the handlers
  bindTextDrawHandlers();

  // ----- Stop Drawing ----- //
  function stopDrawing() {
    assert(anchorPoint);
    // Unbind the handlers
    unbindTextDrawHandlers();

    // If it's a click, set a sane default size
    if (!qualifiesAsDraw) {
      drawingNode = createNodeAndAddToTree(anchorPoint);
      if (drawingNode) {
        // Reset x/y to get parent container translations
        drawingNode.setXInWorld(anchorPoint.x);
        drawingNode.setYInWorld(anchorPoint.y);
      }
    }

    // Clear snaps
    snapState.clearSnaps();

    // Clear local variables
    let newTextNode = drawingNode;
    pointerDownPos = null;
    anchorPoint = null;
    drawBounds = null;
    drawingNode = null;

    // Stop treating changes as transient (will send a final non-transient edit to the server)
    fileState.fileDataObserver?.endTreatingChangesAsTransient();

    // Inform the state that we're notdrawing text
    textTool.setIsDrawingText(false);

    requestIdleCallback(() => {
      if (newTextNode) {
        textTool.startEditingTextNode(newTextNode, true);

        // Select the new node
        selectionState.selectIds([newTextNode.id]);
        layerTreeState.ensureNodesAreVisibleInLayerTree([newTextNode]);
      }
    });
  }

  // ----- Draw ----- //
  const draw = action(() => {
    if (!pointerDownPos || !anchorPoint || !drawingNode) {
      return;
    }

    const forceAspectRatio = keyState.isShiftDown ? true : false;
    const mirror = keyState.isAltDown ? true : false;
    const originalRect = {
      minX: anchorPoint.x,
      minY: anchorPoint.y,
      maxX: anchorPoint.x + 1,
      maxY: anchorPoint.y + 1,
      width: 1,
      height: 1,
    };

    const controlPoint = snapState.makeSnappedControlPointForDrawingTools(
      pointerState.cursorPosWorld,
      drawingNode.bounds,
      forceAspectRatio,
      null,
      null
    );

    drawBounds = makeResizeRect(anchorPoint, controlPoint, originalRect, mirror, forceAspectRatio);
    RectUtils.roundInPlace(drawBounds);
    drawingNode.setXInWorld(drawBounds.minX);
    drawingNode.setYInWorld(drawBounds.minY);
    drawingNode.setWidth(drawBounds.width || 1);
    drawingNode.setHeight(drawBounds.height || 1);

    // Ask for graphics redraw
    hudState.requestDraw();
  });

  // ----- Check for modifier and escape ----- //
  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      // Escape ends drawing
      stopDrawing();
    }
  }

  function handlePointerUp(e: PointerEvent) {
    assert(anchorPoint);

    // Release pointer capture
    overlayEl.releasePointerCapture(e.pointerId);

    // Stop drawing and reset caches and such
    stopDrawing();
  }

  function createNodeAndAddToTree(position: Vec2, width?: number, height?: number): TreeNode | null {
    // Figure out if we should just insert or start a brush draw
    let targetParentId = treeUtils.rootNode.id;

    let shouldInsertIntoParent = false; // hoverNode can have children + is in DOM layout mode
    const canHaveChildren = pointerState.hoveredNode?.canHaveChildren;
    if (canHaveChildren) {
      targetParentId = pointerState.hoveredNode.id;
    }

    const componentName = 'Text';
    const newNodeData = {
      id: ulid(),
      x: position.x,
      y: position.y,
      width: width ?? 'auto',
      height: height ?? 'auto',
      label: fileState.makeLabelForComponent(componentName),
      textValue: '',
      component: componentName,
      styles: { color: '#000000', fontSize: '12px', lineHeight: '150%', ...FontUtils.css(DEFAULT_FONT) },
      styleMeta: { color: ColorUtils.new('000000'), font: FontUtils.clone(DEFAULT_FONT) },
      props: {},
    };
    const newNode = treeUtils.addNode(newNodeData, targetParentId);
    if (!newNode) {
      console.warn('Failed to create drawing node');
      stopDrawing();
      return null;
    }

    return newNode;
  }

  function onTickWithPointerDown() {
    if (!pointerDownPos) {
      return;
    }

    // Check if we're actually dragging/drawing yet
    if (qualifiesAsDraw === false) {
      const movementVector = Vec2Utils.subtract(pointerState.cursorPos, pointerDownPos);
      const movementDistance = Vec2Utils.length(movementVector);

      // If we haven't moved far enough and it hasn't hit the timing threshold yet, bail out
      // Note: we don't use time here since drawing a tiny 1px by 2px text box would never be something you'd want
      if (movementDistance < CLICK_VS_DRAG_THRESHOLD_PX) {
        return;
      }

      // Inform the state that we're drawing text
      textTool.setIsDrawingText(true);

      // Create the new node
      assert(anchorPoint);
      drawBounds = RectUtils.fromPoints([anchorPoint, anchorPoint]);
      drawingNode = createNodeAndAddToTree(anchorPoint, RectUtils.width(drawBounds), RectUtils.height(drawBounds));
      if (drawingNode) {
        // Select the new node while we're drawing
        selectionState.selectIds([drawingNode.id]);
        layerTreeState.ensureNodesAreVisibleInLayerTree([drawingNode]);
      }
      qualifiesAsDraw = true;
    } else {
      draw();
    }
  }
  // ----- Bind Handlers ----- //
  function bindTextDrawHandlers() {
    window.addEventListener('blur', stopDrawing);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('pointerup', handlePointerUp);
    tickState.subToTick(onTickWithPointerDown);
  }

  // ----- Unbind Handlers ----- //
  function unbindTextDrawHandlers() {
    window.removeEventListener('blur', stopDrawing);
    window.removeEventListener('pointerup', handlePointerUp);
    window.removeEventListener('keydown', handleKeyDown);
    tickState.unsubToTick(onTickWithPointerDown);
  }

  return () => {
    // Unbind handlers
    unbindTextDrawHandlers();
    // Release pointer capture
    overlayEl.releasePointerCapture(e.pointerId);
  };
}
