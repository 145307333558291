import { Type, type Static } from '@sinclair/typebox';
import { Nullable } from '../typebox-helpers';

/**
 * This cookie data is used to render initial UI state before the /me route can complete
 * We're assuming the user is still logged in and showing their info, since that's the most common case
 *
 * DO NOT use this data for anything other than cosmetic purposes, and replace it in the UI as soon as /me loads
 */
export const CosmeticPreauthUserInfoCookieSchema = Type.Object({
  firstName: Nullable(Type.String()),
  lastName: Nullable(Type.String()),
  profilePictureUrl: Nullable(Type.String()),
});
export type CosmeticPreauthUserInfoCookie = Static<typeof CosmeticPreauthUserInfoCookieSchema>;

export const CosmeticPreauthUserInfoCookieName = 'paper-preauth-user-info';
