import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PanToolIcon } from './pan-tool-icon';
import { Tool } from './ToolState';
import { useEditor } from '../editor-context';
import { BoxToolIcon } from './box-tool-icon';
import { MoveToolIcon } from './move-tool-icon';
import { TextToolIcon } from './text-tool-icon';
import { RectangleToolIcon } from './rectangle-tool-icon';
import { ShaderToolIcon } from './shader-tool-icon';

export const Toolbar = observer(() => {
  const editorState = useEditor();
  const { toolState, drawToolState } = editorState;

  const activeTool = toolState.activeTool;
  return (
    <div className="Toolbar">
      <ToolbarButton active={activeTool === Tool.Move} onPointerDown={() => toolState.setActiveTool(Tool.Move)}>
        <MoveToolIcon />
      </ToolbarButton>
      <ToolbarButton active={activeTool === Tool.Pan} onPointerDown={() => toolState.setActiveTool(Tool.Pan)}>
        <PanToolIcon />
      </ToolbarButton>
      <ToolbarButton
        active={activeTool === Tool.Draw && drawToolState.componentToDraw === 'Rectangle'}
        onPointerDown={() => {
          toolState.setActiveTool(Tool.Draw);
          drawToolState.setComponentToDraw('Rectangle');
        }}
      >
        <RectangleToolIcon />
      </ToolbarButton>
      <ToolbarButton
        active={activeTool === Tool.Draw && drawToolState.componentToDraw === 'Box'}
        onPointerDown={() => {
          toolState.setActiveTool(Tool.Draw);
          drawToolState.setComponentToDraw('Box');
        }}
      >
        <BoxToolIcon />
      </ToolbarButton>
      <ToolbarButton
        active={activeTool === Tool.Text}
        onPointerDown={() => {
          toolState.setActiveTool(Tool.Text);
          editorState.textTool.setAllowDrawing(true);
          editorState.textTool.startEditingIfSelectionIsSoloTextNode();
        }}
      >
        <TextToolIcon />
      </ToolbarButton>
      <ToolbarButton
        active={activeTool === Tool.Draw && drawToolState.componentToDraw === 'Shader'}
        onPointerDown={() => {
          toolState.setActiveTool(Tool.Draw);
          drawToolState.setComponentToDraw('Shader');
        }}
      >
        <ShaderToolIcon />
      </ToolbarButton>
    </div>
  );
});

interface ToolbarButtonProps extends React.ComponentProps<'div'> {
  active?: boolean;
}

const ToolbarButton = ({ active, className, ...props }: ToolbarButtonProps) => {
  return <div data-active={active ? '' : undefined} className={clsx('ToolbarButton', className)} {...props} />;
};
