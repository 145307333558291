import { action, makeObservable, runInAction } from 'mobx';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { assert } from '../../assert';
import { Vec2 } from '@paper/models/src/math/vec2';
import { FileState } from './FileState';
import { Size } from '@paper/models/src/math/size';
import { roundOptimized } from '@paper/models/src/math/round-optimized';

// Notes about observing DOM and trying to keep canvas in sync:
// See tldr/measuruing-dom.tldr for diagram
// TLDR: TickState has a RO callback that happens after layout but before paint every frame
// where we can measure after layout and draw canvas before paint
// There's a MutationObserver that marks nodes that need measuring
// and this MeasureNodePositionAndSize class finds all potentially impacted nodes and measures them

/** Grabs dimensions and positions from the DOM for tree nodes that need to be recalculated */
export class MeasureNodePositionAndSize {
  constructor(private fileState: FileState) {
    makeObservable(this);
  }

  /** Nodes that need to have their dimensions and positions updated next frame*/
  pendingNodesToUpdate: Set<TreeNode> = new Set();

  /** Add a node to get a dimension/position measurement on the next frame */
  requestMeasurement(node: TreeNode) {
    // Normal node measurement request, add it and set up the rAF if it doesn't exist yet
    this.pendingNodesToUpdate.add(node);
  }

  /** Update all of the node position and sizes that are in our pending set, called by TickState */
  takeMeasurementsBeforePaint = () => {
    if (this.pendingNodesToUpdate.size === 0) return;

    // Update position and size for all impacted nodes
    this.measurePotentiallyImpactedNodesAndClearPendingSet();
  };

  @action
  private measurePotentiallyImpactedNodesAndClearPendingSet = () => {
    // If a node has changed, we need to recalculate anything that it might have touched within its container
    // So we'll build a new set of containers that need to be updated
    const nodesToUpdate = new Set<TreeNode>();

    // #region ----- Find nodes that have potentially changed -----
    // Always measure normally requested nodes
    for (const node of this.pendingNodesToUpdate) {
      addPossiblyImpactedNodes(node, nodesToUpdate);
    }

    if (nodesToUpdate.size === 0) {
      console.warn('Unexpected empty nodesToUpdate set during node dimension calculation');
      return;
    }
    // #endregion

    // Perform the actual measurements and updates
    this.measureAndUpdateNodes(nodesToUpdate);

    // TODO: if we do callbacks, here is where to do it

    // Clear the pending set
    this.pendingNodesToUpdate.clear();

    // Redraw the HUD to reflect the new node positions
    this.fileState.editorState.hudState.requestDraw();
  };

  /** Measure nodes and update their positions and sizes */
  private measureAndUpdateNodes = (nodesToUpdate: Set<TreeNode>) => {
    // #region ----- DOM reads in a batch -----
    const nodeDomPositions: Record<string, Vec2> = {};
    const nodeDomSizes: Record<string, Size> = {};

    // Grab the current camera scale and pan
    const cameraState = this.fileState.editorState.cameraState;
    const scale = cameraState.scale;
    // Make sure to match the pan setting the actual camera renders with
    const panX = cameraState.panRounded.x;
    const panY = cameraState.panRounded.y;

    for (const node of nodesToUpdate) {
      const nodeEl = node.domEl;
      if (nodeEl === null) {
        console.warn('Missing DOM element for node during dimension calculation', node.label, node.id);
        continue;
      }
      // Note for the future: should this fallback to the wrapperEl if the nodeEl is null?
      const domRect = nodeEl.getBoundingClientRect();

      nodeDomSizes[node.id] = {
        width: roundOptimized(domRect.width / scale),
        height: roundOptimized(domRect.height / scale),
      };
      nodeDomPositions[node.id] = {
        x: roundOptimized((domRect.left - panX) / scale),
        y: roundOptimized((domRect.top - panY) / scale),
      };

      // Debugger helper, feel free to delete if we never run into this
      if (nodeDomPositions[node.id]!.x % 0.5 !== 0 || nodeDomPositions[node.id]!.y % 0.5 !== 0) {
        console.warn(
          'Node position is not an integer [label, panX, panY, scale, domRect.left, domRect.top]',
          node.label,
          panX,
          panY,
          scale,
          domRect.left,
          domRect.top
        );
      }
    }
    // #endregion

    // #region ----- Update state in a batch to trigger all the observers -----
    runInAction(() => {
      for (const node of nodesToUpdate) {
        const newPos = nodeDomPositions[node.id];
        if (newPos) {
          if (newPos.x !== node.xInWorld || newPos.y !== node.yInWorld) {
            this.fileState.editorState.sizeIndex.setNodePositionInWorld(node.id, newPos.x, newPos.y);
          }
        }

        const newSize = nodeDomSizes[node.id];
        if (newSize) {
          if (newSize.width !== node.width || newSize.height !== node.height) {
            this.fileState.editorState.sizeIndex.setSize(node.id, newSize);
          }
        }
      }
    });
    // #endregion
  };
}

/** Helper function to add nodes and potentially impacted descendants to the set of nodes to update */
function addPossiblyImpactedNodes(node: TreeNode, nodesToUpdate: Set<TreeNode>) {
  // Loop up from the node to find the highest ancestor with DOM layout, or the node itself – any of the found node's descendants will need to be updated
  let highestAncestorWithDOMLayout: TreeNode = node;
  for (let i = node.ancestors.length - 1; i >= 0; i--) {
    const ancestor = node.ancestors[i]!;
    if (ancestor.childrenAreFixed === true) {
      break;
    }
    highestAncestorWithDOMLayout = ancestor;
  }

  // Add the highest DOM mode ancestor to the set to update
  nodesToUpdate.add(highestAncestorWithDOMLayout);
  // Add its descendants to be recalculated
  for (const descendant of highestAncestorWithDOMLayout.descendants) {
    nodesToUpdate.add(descendant);
  }
}
