import { Type, type Static } from '@sinclair/typebox';
import { Nullable } from '../typebox-helpers';

export const UserSchema = Type.Object({
  id: Type.String(),
  email: Type.String(),
  firstName: Nullable(Type.String()),
  lastName: Nullable(Type.String()),
  profilePictureUrl: Nullable(Type.String()),
});
export type User = Static<typeof UserSchema>;
