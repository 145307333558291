// This shader expects you to define the top and right edge of a grid
// Each edge point should give the lightness and chroma of the color at that point
// The shader will then interpolate the color across the grid, interpolating in OKLCH
// it will not change the oklch hue during this interpolation

export const uniformFragmentShaderSource = /* glsl */ `#version 300 es
precision highp float;
precision highp int;

#define MAX_EDGE_SIZE 300
#define PI 3.1415926538

uniform vec2 u_resolution;

// In degrees, the hue of the gamut slice
uniform float u_hue;
// The maximum saturation possible for the hue, at the cusp
uniform float u_cusp_saturation;
// The toe is the ratio used in the oklch lightness compression, it varies by hue
uniform float u_toe;
// Which color space is being used
uniform int u_colorSpace; // 0 = rgb, 1 = p3

out vec4 fragColor;

// Constants for toe functions
const float K1 = 0.206;
const float K2 = 0.03;
const float K3 = (1.0 + K1) / (1.0 + K2);

// Toe function to adjust lightness
float toe(float x) {
    return 0.5 * (K3 * x - K1 + sqrt((K3 * x - K1) * (K3 * x - K1) + 4.0 * K2 * K3 * x));
}

// Inverse toe function
float toe_inv(float x) {
    return (x * x + K1 * x) / (K3 * (x + K2));
}

vec3 oklab_to_linear_srgb(vec3 oklab) {
    float l_ = oklab.x + 0.3963377774 * oklab.y + 0.2158037573 * oklab.z;
    float m_ = oklab.x - 0.1055613458 * oklab.y - 0.0638541728 * oklab.z;
    float s_ = oklab.x - 0.0894841775 * oklab.y - 1.2914855480 * oklab.z;

    float l = l_ * l_ * l_;
    float m = m_ * m_ * m_;
    float s = s_ * s_ * s_;

    return vec3(
        4.0767416621 * l - 3.3077115913 * m + 0.2309699292 * s,
        -1.2684380046 * l + 2.6097574011 * m - 0.3413193965 * s,
        -0.0041960863 * l - 0.7034186147 * m + 1.7076147010 * s
    );
}

vec3 oklab_to_linear_p3(vec3 oklab) {
    // First convert OKLab to LMS using precise coefficients
    float l_ = oklab.x + 0.3963377773761749 * oklab.y + 0.2158037573099136 * oklab.z;
    float m_ = oklab.x - 0.1055613458156586 * oklab.y - 0.0638541728258133 * oklab.z;
    float s_ = oklab.x - 0.0894841775298119 * oklab.y - 1.2914855480194092 * oklab.z;

    // Non-linear transformation
    float l = l_ * l_ * l_;
    float m = m_ * m_ * m_;
    float s = s_ * s_ * s_;

    // Convert LMS directly to Linear P3 using official coefficients
    return vec3(
        3.127768971361874 * l - 2.2571357625916395 * m + 0.12936679122976516 * s,
        -1.0910090184377979 * l + 2.413331710306922 * m - 0.32232269186912466 * s,
        -0.02601080193857028 * l - 0.508041331704167 * m + 1.5340521336427373 * s
    );
}

// Conversion function from OkHSV to OkLCH
vec3 okhsv_to_oklch(vec3 hsv) {
    float h = hsv.x;
    float s = hsv.y;
    float v = hsv.z;

    float hue_rad = (h / 360.0) * 2.0 * 3.14159265359;
    vec2 ab = vec2(cos(hue_rad), sin(hue_rad));

    float S_max = u_cusp_saturation;
    float S_0 = 0.5;
    float T = u_toe;
    float k = 1.0 - S_0 / S_max;

    float L_v = 1.0 - (s * S_0) / (S_0 + T - T * k * s);
    float C_v = (s * T * S_0) / (S_0 + T - T * k * s);

    float L = v * L_v;
    float C = v * C_v;

    float L_vt = toe_inv(L_v);
    float C_vt = C_v * L_vt / L_v;

    float L_new = toe_inv(L);
    C = C * L_new / L;
    L = L_new;

    if (u_colorSpace == 1) {
        // P3
        vec3 rgb_scale = oklab_to_linear_p3(vec3(L_vt, ab.x * C_vt, ab.y * C_vt));
        float scale_L = pow(1.0 / max(max(rgb_scale.r, rgb_scale.g), rgb_scale.b), 1.0/3.0);
        L = L * scale_L;
        C = C * scale_L;
    } else {
        // sRGB
        vec3 rgb_scale = oklab_to_linear_srgb(vec3(L_vt, ab.x * C_vt, ab.y * C_vt));
        float scale_L = pow(1.0 / max(max(rgb_scale.r, rgb_scale.g), rgb_scale.b), 1.0/3.0);
        L = L * scale_L;
        C = C * scale_L;
    }

    return vec3(L, C, h);
}

vec3 oklch_to_oklab(vec3 oklch) {
    float l = oklch.x;
    float c = oklch.y;
    float h = oklch.z;

    // Convert hue from degrees to radians
    float a = c * cos((h / 180.0) * PI);
    float b = c * sin((h / 180.0) * PI);

    return vec3(l, a, b);
}

float linear_to_display_p3_channel(float c) {
    float abs_c = abs(c);
    if (abs_c > 0.0031308) {
        return sign(c) * (1.055 * pow(abs_c, 1.0/2.4) - 0.055);
    }
    return c * 12.92;
}

vec3 linear_to_display_p3(vec3 rgb) {
    return vec3(
        linear_to_display_p3_channel(rgb.r),
        linear_to_display_p3_channel(rgb.g),
        linear_to_display_p3_channel(rgb.b)
    );
}

float linear_to_srgb_channel(float c) {
    float abs_c = abs(c);
    if (abs_c > 0.0031308) {
        return sign(c) * (1.055 * pow(abs_c, 1.0/2.4) - 0.055);
    }
    return c * 12.92;
}

vec3 linear_to_srgb(vec3 rgb) {
    return vec3(
        linear_to_srgb_channel(rgb.r),
        linear_to_srgb_channel(rgb.g),
        linear_to_srgb_channel(rgb.b)
    );
}

void main() {
    vec2 uv = gl_FragCoord.xy / u_resolution;

    // HSV
    vec3 hsv = vec3(u_hue, uv.x, uv.y);
    // Convert to OKLCH
    vec3 oklch = okhsv_to_oklch(hsv);
    // Convert to OKLAB for easier conversion to others
    vec3 oklab = oklch_to_oklab(oklch);

    if (u_colorSpace == 1) {
        // P3
        // Convert to linear P3, at this point it's 3 channel unbounded P3 codes, so maybe [0.8, -0.1, 1.2]
        vec3 linear_p3 = oklab_to_linear_p3(oklab);
        // Compress the unbounded channels into 0-1 ... after this it's 3 channel P3 codes in the 0-1 range
        vec3 display_p3 = linear_to_display_p3(linear_p3);
        // The canvas will handle gamma correction based on the color space setting
        fragColor = vec4(display_p3, 1.0);
    } else {
        // sRGB
        // Convert to linear srgb, at this point it's 3 channel unbounded rgb codes, so maybe [0.8, -0.1, 1.2]
        vec3 linear_srgb = oklab_to_linear_srgb(oklab);
        // Compress the unbounded channels into 0-1 ... after this it's 3 channel rgb codes in the 0-1 range
        vec3 display_srgb = linear_to_srgb(linear_srgb);
        // The canvas will handle gamma correction based on the color space setting
        fragColor = vec4(display_srgb, 1.0);
    }
}
`;
