import { Menu } from '@base-ui-components/react';
import { useLocalStorage } from '../../components/use-local-storage';
import { ColorPickerCartesianHsv } from './color-picker-cartesian-hsv';
import { ColorPickerCartesianUniform } from './color-picker-cartesian-uniform';
import {
  ColorPickerFieldsHsl,
  ColorPickerFieldsOklch,
  ColorPickerFieldsRgb,
  ColorPickerFieldsMainInputRgb,
} from './color-picker-fields';
import { ColorPickerSliderHueHsl, ColorPickerSliderHueUniform, ColorPickerSliderOpacity } from './color-picker-sliders';
import { ColorPickerSwatches } from './color-picker-swatches';
import { useState } from 'react';
import { CheckIcon } from '../../icons/check-icon';

export function ColorPickerTabSrgb(props: React.ComponentProps<'div'>) {
  const [hueMap, saveHueMap] = useLocalStorage<'standard' | 'uniform'>('rgb-color-picker-hue', 'uniform');

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuPosition] = useState({ x: 0, y: 0 });

  function handleContextMenu(event: React.PointerEvent<HTMLDivElement>) {
    if (event.buttons === 2 || (event.buttons === 1 && event.ctrlKey)) {
      event.preventDefault();
      event.stopPropagation();
      menuPosition.x = event.clientX;
      menuPosition.y = event.clientY;
      setMenuOpen(true);
    }
  }

  return (
    <div className="ColorPickerPanel" {...props}>
      <div className="flex gap-2.5">
        <div onPointerDownCapture={handleContextMenu}>
          {hueMap === 'uniform' ? <ColorPickerCartesianUniform colorSpace="rgb" /> : <ColorPickerCartesianHsv />}
        </div>

        <ColorPickerSliderOpacity />
        {hueMap === 'uniform' ? <ColorPickerSliderHueUniform /> : <ColorPickerSliderHueHsl />}

        <Menu.Root open={menuOpen} onOpenChange={setMenuOpen}>
          <Menu.Portal>
            <Menu.Positioner
              align="start"
              side="right"
              anchor={{
                getBoundingClientRect() {
                  return {
                    x: menuPosition.x,
                    y: menuPosition.y,
                    top: menuPosition.y,
                    bottom: menuPosition.y,
                    left: menuPosition.x,
                    right: menuPosition.x,
                    width: 0,
                    height: 0,
                  };
                },
              }}
            >
              <Menu.Popup className="Popup MenuPopup">
                <Menu.Group>
                  <Menu.RadioGroup value={hueMap} onValueChange={saveHueMap}>
                    <Menu.RadioItem className="MenuRadioItem" value="uniform" closeOnClick>
                      <Menu.RadioItemIndicator className="MenuRadioItemIndicator">
                        <CheckIcon className="MenuRadioItemIndicatorIcon" />
                      </Menu.RadioItemIndicator>
                      <span className="MenuRadioItemText">Uniform hue</span>
                      <span className="MenuItemCaption">OKHSV</span>
                    </Menu.RadioItem>
                    <Menu.RadioItem className="MenuRadioItem" value="standard" closeOnClick>
                      <Menu.RadioItemIndicator className="MenuRadioItemIndicator">
                        <CheckIcon className="MenuRadioItemIndicatorIcon" />
                      </Menu.RadioItemIndicator>
                      <span className="MenuRadioItemText">Classic hue</span>
                      <span className="MenuItemCaption">HSV</span>
                    </Menu.RadioItem>
                  </Menu.RadioGroup>
                </Menu.Group>
              </Menu.Popup>
            </Menu.Positioner>
          </Menu.Portal>
        </Menu.Root>
      </div>
      <div className="w-55.25">
        <ColorPickerSwatches className="mb-2" />
        <div className="flex flex-col gap-1.5">
          <ColorPickerFieldsOklch />
          <ColorPickerFieldsHsl />
          <ColorPickerFieldsRgb />
          <ColorPickerFieldsMainInputRgb />
        </div>
      </div>
    </div>
  );
}
