import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { Button } from '../../components/button';
import { PanelHeader, PanelTitle } from '../../components/panel';

export const SharePanel = observer(() => {
  const { multiplayerState } = useEditor();
  const users = Object.values(multiplayerState.userData);

  return (
    <div>
      <PanelHeader>
        <PanelTitle collapsible={false}>Collaboration</PanelTitle>

        <div className="flex gap-2">
          {users.length > 0 && (
            <div className="flex flex-row-reverse">
              {users.map((user) => (
                <div
                  key={user.clientId}
                  className="outline-panel -ml-2 flex h-6 w-6 items-center justify-center rounded text-lg font-medium outline-2"
                  style={{ backgroundColor: user.color }}
                >
                  {user.name[0]}
                </div>
              ))}
            </div>
          )}
          <Button highContrast>Share</Button>
        </div>
      </PanelHeader>
    </div>
  );
});
