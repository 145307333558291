import { AuthState } from '../auth/AuthState';
import { EditorState } from '../editor/EditorState';

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('RootStore change detected - reloading');
    window.location.reload();
  });
}

export class Root {
  private constructor() {}

  authState = new AuthState(this);

  // TODO: probably pop this off into some kind of route concept
  editorState: EditorState | null = null;
  // The EditorState will register itself onto root once it exists
  registerEditorState = (editorState: EditorState) => {
    if (this.editorState) {
      this.editorState.dispose();
      this.editorState = null;
    }

    this.editorState = editorState;
  };

  dispose = (hasPermissionToDisposeRoot?: boolean) => {
    // Since Root ends up as a reference all over the place, "for in" dispose loops might call this... just ignore them
    // only Main.tsx has permission to dispose the root
    if (!hasPermissionToDisposeRoot) {
      return;
    }

    // Loop through all properties of this class and call dispose on them if they have it
    for (const key in this) {
      const property = this[key] as any;
      if (typeof property === 'object' && property !== null && typeof property.dispose === 'function') {
        property.dispose();
      }
    }
  };

  static CreateRoot = () => {
    const root = new Root();

    // debug helper global
    (window as any).root = root;
    // shortcut to grab selected nodes
    (window as any).nodes = () => {
      const nodes = root.editorState?.selectionState.selectedNodes;
      if (nodes && nodes.length === 1) {
        return nodes[0];
      }
      return nodes;
    };

    // Bind a syncLog function to the window object that will guarantee logs appear and appear in order
    (window as any).syncLog = console.log.bind(console);

    return root;
  };
}
