import { action, computed, makeObservable, observable } from 'mobx';
import { EditorState } from './EditorState';
import { createPage, PageType } from '@paper/models/src/file/page-schema';
import { assert } from '../assert';
import { FileType } from '@paper/models/src/file/file-schema';
import { TreeNodeType } from '@paper/models/src/file/tree-node-schema';
import { createTreeNodeData, makeRootNodeId } from '@paper/models/src/file/tree-node-schema';
import { MARKED_FOR_REMOVAL_KEY } from '@paper/models/src/file/marked-for-removal-key';
import { Color } from '@paper/models/src/colors/Color';

/** Keeps track of the active page */
export class PageState {
  constructor(
    public editorState: EditorState,
    fileData: FileType
  ) {
    makeObservable(this);

    // Set the initial active page
    // Check if the URL specified a page ID
    const pageIdFromUrl = new URLSearchParams(window.location.search).get('page');
    if (pageIdFromUrl) {
      this.activePageId = pageIdFromUrl;
    } else {
      // Otherwise, just use the first page
      const pageId = fileData.pages[0]?.id;
      assert(pageId, 'Unexpected: No pages in file');
      this.activePageId = pageId;
    }
  }

  /**
   * Returns a TreeNode instance for the given id, or null if the node is not found
   */
  getPage(id: string): PageType | null {
    const page = this.editorState.fileState.data.pages.find((page) => page.id === id);
    // If we can't find the node or it's marked for removal, return null
    if (!page || page[MARKED_FOR_REMOVAL_KEY]) {
      return null;
    }

    return page;
  }

  /** Returns the list of pages in the file */
  @computed({ keepAlive: true }) get pageList(): PageType[] {
    return this.editorState.fileState.data.pages.filter((page) => !page[MARKED_FOR_REMOVAL_KEY]);
  }

  /** Whether the page list is open – possible TODO to persist this per file per user */
  @observable accessor pageListIsOpen: boolean = true;
  @action setPageListIsOpen(isOpen: boolean) {
    this.pageListIsOpen = isOpen;
  }

  @observable accessor activePageId: string;

  @action setActivePage(pageId: string, isInitialPageInit = false) {
    const cameraState = this.editorState.cameraState;
    const selectionState = this.editorState.selectionState;

    const page = this.getPage(pageId);
    if (!page) {
      console.warn(`Error: could not find the new page to make active: ${pageId}, defaulting to first page`);
      const newPageId = this.pageList[0]?.id;
      assert(newPageId, 'Error: could not find any page to make active.');
      this.setActivePage(newPageId);
      return;
    }

    if (isInitialPageInit === false) {
      // Save our camera and selection settings for this page
      const lastPageId = this.activePageId;
      cameraState.stashSettingsForPage(lastPageId);
      selectionState.stashSettingsForPage(lastPageId);

      // Update the URL with the new page id
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('page', pageId);
      window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    }

    // Inform the undo manager of the page change, giving it the old page Id – this is good to do even for the first page of the load
    this.editorState.undoManager.addPageChange(this.activePageId);

    // Update the active page id
    this.activePageId = pageId;

    // Tell the server about the new active page
    this.editorState.multiplayerState.sendPageChange(true);

    // Restore any selection settings for this page
    selectionState.restoreSettingsForPage(pageId);
    // Let everything render and then restore any camera settings for this page
    requestIdleCallback(() => {
      cameraState.restoreSettingsForPage(pageId);
    });
  }

  @computed get activePage(): PageType {
    const activePage = this.editorState.fileState.data.pages.find((page) => page.id === this.activePageId);
    assert(activePage, 'Error: could not find the active page');
    return activePage;
  }

  @action setActivePageCanvasColor(color: Color) {
    this.activePage.canvasColor = color;
  }

  @action
  addPage = (selectAfterAdd = true) => {
    // Create the first page
    const pageLabel = this.editorState.fileState.makeLabelForComponent('Page');
    const page = createPage(pageLabel);
    this.editorState.fileState.data.pages.push(page);

    // Create the root node
    const rootId = makeRootNodeId(page.id);
    const rootNode: TreeNodeType = createTreeNodeData();
    rootNode.id = rootId;
    this.editorState.fileState.data.nodes[rootId] = rootNode;

    if (selectAfterAdd) {
      this.setActivePage(page.id);
    }
  };

  @action
  deletePage = (pageId: string) => {
    if (this.pageList.length === 1) {
      console.warn('Attempted to delete the only page');
      return;
    }

    const page = this.getPage(pageId);
    if (!page) {
      console.error(`Error: could not find the page to delete: ${pageId}`);
      return;
    }

    page[MARKED_FOR_REMOVAL_KEY] = true;

    // If the page we're deleting is the active page, set the active page to the first page
    if (pageId === this.activePageId) {
      const newPageId = this.pageList[0]?.id;
      assert(newPageId, 'Error: could not find any page to make active after delete.');
      this.setActivePage(newPageId);
    }
  };
}
