import { observer } from 'mobx-react-lite';
import { PropertyPanels } from '../properties/property-panel';
import { useEditor } from '../editor-context';
import { reducePropertyValues } from '../properties/reduce-property-values';
import { NumberInput } from '../properties/number-input';
import { Field } from '../../components/field';
import { parseNumberAndUnit } from '../properties/parse-number-and-unit';
import { decimal } from '../properties/decimal';

export const LetterSpacingProperty = observer(() => {
  const { propertiesState } = useEditor();

  const values = propertiesState.getStyleValues('letterSpacing');
  const inputValue = reducePropertyValues(values.keys(), '0em');

  function commitValue(value: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Typography]) {
      node.setStyle('letterSpacing', value);
    }
  }

  return (
    <Field.Root>
      <Field.Icon>
        <svg width="14" height="12" viewBox="0 0 14 12" fill="currentcolor">
          <path
            fillRule="evenodd"
            d="M3.44189 10L6.32927 2.00293H7.67043L10.5578 10H9.2499L8.58323 8H5.41607L4.74426 10H3.44189ZM6.94998 3.40505H7.04419L8.24989 7H5.74981L6.94998 3.40505Z"
          />
          <rect y="0" width="1" height="12" fillOpacity="0.9" />
          <rect x="13" y="0" width="1" height="12" fillOpacity="0.9" />
        </svg>
      </Field.Icon>
      <Field.Control>
        <NumberInput
          units={['em']}
          increments={[0.01, 0.1]}
          value={inputValue}
          onValueCommit={commitValue}
          format={(value) => decimal(parseFloat(value) * 100) + '%'}
          parse={(value) => {
            // Make sure "Infinity" keyword isn't parsed
            if (/^\s+-?Infinity/.test(value)) {
              return null;
            }

            let [number, unit] = parseNumberAndUnit(value);

            if (Number.isNaN(number)) {
              return null;
            }

            if (unit === 'em') {
              return number + 'em';
            }

            if (unit === '%' || !unit) {
              return decimal(number / 100) + 'em';
            }

            return null;
          }}
        />
      </Field.Control>
    </Field.Root>
  );
});
