import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { observer } from 'mobx-react-lite';
import { RectUtils } from '@paper/models/src/math/rect';

export const SNAPS_LINE_COLOR = '#EE518A';

export const SnapsGraphic = observer(function SnapsGraphic() {
  const editorState = useEditor();

  useEffect(() => {
    const { snapState, cameraState, hudState } = editorState;

    function drawSnaps(ctx: CanvasRenderingContext2D) {
      if (snapState.currentSnaps === null) return;

      const lineWidth = 1 * cameraState.scaleInverse;

      ctx.strokeStyle = SNAPS_LINE_COLOR;
      ctx.lineWidth = lineWidth;

      // Draw x-snaps
      for (const snap of snapState.currentSnaps.xSnaps) {
        // Find the min and max x values to draw to
        let minY: number | null = null;
        let maxY: number | null = null;
        for (const y of snap.secondaryAxisValues) {
          if (minY === null || y < minY) minY = y;
          if (maxY === null || y > maxY) maxY = y;
        }

        // self matches can be hard to calculate in cases of proportional or mirrored resize, we'd need to adjust again
        // so commenting this out and instead favoring finding the farthest matching point in the selection bounds
        // for (const y of snap.secondaryAxisValues.selfMatches) {
        //   if (minY === null || y < minY) minY = y;
        //   if (maxY === null || y > maxY) maxY = y;
        // }
        // in favor of letting the thing doing the snapping tell us about their bounds:
        const bounds = snapState.currentBounds;
        if (bounds !== null) {
          if (bounds.minX === snap.primaryAxisValue || bounds.maxX === snap.primaryAxisValue) {
            // Edge snap, draw all the way to the farthest corner
            minY = Math.min(minY ?? bounds.maxY, bounds.minY);
            maxY = Math.max(maxY ?? bounds.minY, bounds.maxY);
          } else {
            // Center snap, draw to the middle
            const center = RectUtils.center(bounds);
            minY = Math.min(minY ?? center.y, center.y);
            maxY = Math.max(maxY ?? center.y, center.y);
          }
        }

        if (minY === null || maxY === null) continue;
        const x = snap.primaryAxisValue;
        // Draw the main line
        ctx.beginPath();
        ctx.moveTo(x, minY);
        ctx.lineTo(x, maxY);
        ctx.stroke();
      }

      // Draw y-snaps
      for (const snap of snapState.currentSnaps.ySnaps) {
        // Find the min and max values to draw to
        let minX: number | null = null;
        let maxX: number | null = null;
        for (const x of snap.secondaryAxisValues) {
          if (minX === null || x < minX) minX = x;
          if (maxX === null || x > maxX) maxX = x;
        }

        const bounds = snapState.currentBounds;
        if (bounds !== null) {
          if (bounds.minY === snap.primaryAxisValue || bounds.maxY === snap.primaryAxisValue) {
            // Edge snap, draw all the way to the farthest corner
            minX = Math.min(minX ?? bounds.maxX, bounds.minX);
            maxX = Math.max(maxX ?? bounds.minX, bounds.maxX);
          } else {
            // Center snap, draw to the middle
            const center = RectUtils.center(bounds);
            minX = Math.min(minX ?? center.x, center.x);
            maxX = Math.max(maxX ?? center.x, center.x);
          }
        }

        if (minX === null || maxX === null) continue;
        const y = snap.primaryAxisValue;
        ctx.beginPath();
        ctx.moveTo(minX, y);
        ctx.lineTo(maxX, y);
        ctx.stroke();
      }
    }

    // Register our drawing function
    hudState.worldDrawingFunctions.add(drawSnaps);
    return () => {
      hudState.worldDrawingFunctions.delete(drawSnaps);
    };
  }, [editorState]);

  return null;
});
