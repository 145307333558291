import { TreeNode } from '../tree/TreeNode';
import { useEditor } from '../editor-context';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { transaction } from 'mobx';
import { Field } from '../../components/field';
import { baseInputProps } from '../../components/input';

export const NodeLabelInput = observer(({ node }: { node: TreeNode }) => {
  const { layerTreeState, treeUtils } = useEditor();

  const inputRef = useRef<HTMLInputElement>(null);

  function saveLabelValue() {
    node.setLabel(inputRef.current?.value ?? '');
  }

  // When we blur, save the new label
  function handleBlur() {
    transaction(() => {
      saveLabelValue();
      layerTreeState.setEditingLabelNodeId(null);
    });
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    event.stopPropagation();

    if (event.key === 'Enter') {
      // If enter, blur the input to save an exit
      handleBlur();
    }

    // If escape, cancel editing without saving
    if (event.key === 'Escape') {
      layerTreeState.setEditingLabelNodeId(null);
    }

    // Tab key moves us to editing another node, either forward or backward
    if (event.key === 'Tab') {
      event.preventDefault();
      // Save this label
      saveLabelValue();

      if (event.shiftKey) {
        // If shift, move to previous node or loop to the last node
        let prevNode = layerTreeState.getPreviousVisibleNode(node) ?? layerTreeState.lastVisibleNodeInTree;
        layerTreeState.setEditingLabelNodeId(prevNode?.id ?? null);
      } else {
        // If not shift, move forward to the next node or loop to the first node
        let nextNode = layerTreeState.getNextVisibleNode(node) ?? layerTreeState.firstVisibleNodeInTree;
        layerTreeState.setEditingLabelNodeId(nextNode?.id ?? null);
      }
    }
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    // Select all text in the input when focused
    e.target.select();
  }

  return (
    <Field.Root className="-ml-1">
      <Field.Control className="proportional-nums">
        <input
          {...baseInputProps}
          ref={inputRef}
          defaultValue={node.label}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          autoFocus={true}
        />
      </Field.Control>
    </Field.Root>
  );
});
